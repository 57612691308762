import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import TextArea from '../../../components/HRMS Components/Inpute/textarea';
import { Alert } from '../../../components/Model/Model';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';
import { useFetchResponsiblePerson } from '../../../hooks/DropDown_Api';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const Add_Project = () => {

  let pageName = "Project"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_PROJECT_API?.toString();

  const { mutate: Project, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [optionsManager, setOptionsManager]: any = useState([])
  const [Manager, setManager]: any = useState('')

  const { data: ManagerData, refetch: ManagerRefetch } = useFetchResponsiblePerson()

  useEffect(() => {
    setOptionsManager(ManagerData)
  }, [ManagerData])

  const handleChangeManager = (e: any) => {
    setManager(e)
    setValue("project_manager", e?.value)
  }

  const handleReset = () => {
    reset();
    setManager(null)
    setForStartDate(null)
    setForEndDate(null)
  };

  const onSubmit = (branddetail: any) => {

    if (!StartDate && !EndDate) {
      delete branddetail.start_date
      delete branddetail.end_date

    } else if (StartDate && !EndDate) {
      delete branddetail.end_date

    } else if (!StartDate && EndDate) {
      delete branddetail.start_date
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    Project(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listProject", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const [StartDate, setStartDate]: any = useState()
  const [EndDate, setEndDate]: any = useState()
  const [forStartDate, setForStartDate]: any = useState()
  const [forEndDate, setForEndDate]: any = useState()

  const handleStartDate = (e: any) => {
    setStartDate(e)
    setValue("start_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setForStartDate(e)
  }

  const handleEndDate = (e: any) => {
    setEndDate(e)
    setValue("end_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setForEndDate(e)

  }
  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listProject" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Project Manager' name='project_manager' message='Project Manager is required' register={register} isRequired={true} isModal={false} errors={errors?.project_manager?.message} options={optionsManager} onChange={(e) => handleChangeManager(e)} Selvalue={Manager} />
                        <InputField type='text' name='name' label='Project Name' isPencil={true} message='Project Name is required' register={register} isRequired={true} hrmserrors={errors?.name?.message} />
                        <Date_Picker name='start_date' label='Start Date' message='Start Date is required' register={register} isRequired={true} hrmserrors={errors?.start_date?.message} value={forStartDate} isDisabled={disabledDate} onChange={(e: any) => handleStartDate(e)} />
                        <Date_Picker name='end_date' label='End Date' message='End Date is required' register={register} isRequired={true} hrmserrors={errors?.end_date?.message} value={forEndDate} isDisabled={disabledDate} onChange={(e: any) => handleEndDate(e)} />
                        <TextArea name='description' label='Project Description' message='Project Description is required' register={register} isRequired={true} hrmserrors={errors?.description?.message} />
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listProject")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Project

