import React, { useEffect, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { useCartonPost } from "../../hooks/Carton";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";

import Editable from "../../components/Editable_Table/Editable";
import { RiPencilLine } from "react-icons/ri";
import {
    useFetchMaterialDensityDropDown, useFetchBrandDropDown,
    useFetchColorDropDown, useFetchMaterialandHSNDropDown,
    useFetchPanniMaterialUseDropDown,
    useFetchPanniSealingTypeDropDown,
    useFetchPanniProductPrintingTypeDropDown,
    useFetchUomDropDown,
    useFetchPanniProductPakingTypeDropDown,
    useFetchPanniProductByDropDown,
    useFetchMaterialUseDropDown,
    useFetchMachineLocationDropDown,
    useFetchCartonMoistureDropDown,
    useFetchHSNDropDown
} from "../../hooks/DropDown_Api";
import { Color_Model } from "../../components/Dynamic_Form_Models/Color_Model";
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";
import useFocusOnEnter from "../../components/focus";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import CreatableSelect from "react-select/creatable";
import { UOM_Model } from "../../components/Dynamic_Form_Models/Uom_Model";
import type { UploadFile } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
// import Modal from "react-bootstrap/Modal";
import { useQueryClient } from 'react-query';

import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";

interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active",
});

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Add_Carton = () => {
    const navigate = useNavigate()
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [open, setOpen] = useState(false)
    const apiname = process.env.REACT_APP_CARTON_API?.toString()
    const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()

    // const {data:fetchAllCarton}=useCreate(apiname)
    const { mutate, data, isError, isLoading, error, isSuccess }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const [panniPrinting, setpanniPrinting] = useState("no")
    const [panniGadget, setpanniGadget] = useState("no")
    const [panniKan, setpanniKan] = useState("no")
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [openHSN, setOpenHSN] = useState(false)

    const [options, setOptions]: any = useState([])
    const [optionsPrinting, setOptionsPrinting]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchPanniTerm, setSearchPanniTerm] = useState('')
    const [ColorTotal, setColorTotal] = useState([])

    const [optionsbrand, setOptionsBrand]: any = useState([])
    const [searchTermBrand, setSearchTermBrand] = useState('')
    const [loadingBrand, setLoadingBrand] = useState(false)
    const [colorNamePanni, setColorNamePanni]: any = useState({})


    const [optionsHSN, setOptionsHSN]: any = useState([])
    const [searchHSN, setSearchHSN] = useState('')
    const [loadingHSN, setLoadingHSN] = useState(false)
    const [gstNumber, setGSTNumber]: any = useState({})


    const [optionsPanniName, setOptionsPanniName] = useState([])
    const [loadingPanniName, setLoadingPanniName] = useState(false)
    const [panniName, setPanniName]: any = useState({})
    const [showGst, setShowGst]: any = useState('')

    const [forValue, setForValue]: any = useState([])
    const [forColorValue, setForColorValue]: any = useState([])
    const [forBrandValue, setForBrandValue]: any = useState([])
    const [forHSNValue, setForHSNValue]: any = useState([])
    const [forColorsValue, setForColorsValue]: any = useState([])


    const [printingColorOpen, setPrintingColorOpen] = useState(false)
    const [brandOpen, setBrandOpen] = useState(false)


    const [colorOpen, setColorOpen] = useState(false)
    const [submittedColor, setSubmittedColor] = useState(false)
    const [submittedPrintingColor, setSubmittedPrintingColor] = useState(false)
    const [submittedBrand, setSubmittedBrand] = useState(false)


    const [savedData, setSavedData] = useState([])


    const onPrintingChange = (e: any) => {
        setpanniPrinting(e.target.value)
    }
    const onGadgetChange = (e: any) => {
        setpanniGadget(e.target.value)
    }
    const onPanniChange = (e: any) => {
        setpanniKan(e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }


    const { data: materialdensityData, refetch: materialdensityRefetch } =
        useFetchMaterialDensityDropDown(searchTerm);

    useEffect(() => {
        setOptionsPanniName(materialdensityData);
    }, [materialdensityData]);

    useEffect(() => {
        if (searchTerm.length >= 1) {
            materialdensityRefetch();
        }
    }, []);


    const { mutate: getrelated, data: relatedData } = useCreate(getRelatedHsn)
    const handleChangePanniName = (e: any) => {
        setPanniName(e)
        setValue("name_and_grade", `${e.value}`)
        getrelated({ "grade": e.value })
        setForValue({ label: e?.label, value: e?.value })

    }
    useEffect(() => {
        let temp_results = relatedData?.results.map((e: any) => {
            return (
                {
                    value: e.id,
                    label: e.hsn_code,
                    gst: e.gst.india_GST,
                    gst_id: e.gst.id
                })

        })

        setOptionsHSN(temp_results)
    }, [relatedData])
    let sdf = getValues("name_and_grade")

    const { data: colorData, refetch: colorRefetch } =
        useFetchColorDropDown(searchTerm);

    useEffect(() => {
        setOptions(colorData);
        setOptionsPrinting(colorData)
    }, [colorData]);

    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch();
        }
    }, []);

    const handleInputChange = (e: any) => {
        setSearchTerm(e)
    }
    const handlePanniInputChange = (e: any) => {
        setSearchPanniTerm(e)
    }
    const handleChange = (e: any) => {
        setValue("product_color_name", e.value)
        setForColorValue({ label: e?.label, value: e?.value })

    }




    const handleChangePanni = (e: any) => {
        const panni_color_name = e.map((value: any) => {
            return value.value
        })
        setValue("product_printing_color_name", panni_color_name)
        setValue("product_printing_total_color", e.length)

        setForColorsValue(e)


    }

    const { data: brandData, refetch: brandRefetch } =
        useFetchBrandDropDown(searchTerm);

    useEffect(() => {
        setOptionsBrand(brandData);
    }, [brandData]);

    useEffect(() => {
        if (searchTerm.length >= 1) {
            brandRefetch();
        }
    }, []);

    const handleInputChangeBrand = (e: any) => {
        setSearchTermBrand(e)
    }

    const handleChangeBrand = (e: any) => {
        setColorNamePanni(e)
        setValue("product_brand_type", `${e.type}`)
        setValue("product_brand", `${e.value}`)
        setForBrandValue({ label: e?.label, value: e?.value, type: e?.type })

    }

    const [openUOM, setOpenUOM] = useState(false)

    const [forUOMValue, setForUOMValue]: any = useState([])

    const { data: UomData, refetch: UomRefetch } = useFetchUomDropDown()
    const [optionsUom, setOptionsUom]: any = useState()
    useEffect(() => {
        setOptionsUom(UomData)
    }, [UomData])

    const handleChangeUom = (e: any) => {
        setValue("uom", `${e.value}`)

        setForUOMValue({ label: e?.label, value: e?.value })

    }




    // const handleChangeHSN = (e: any) => {
    //     setGSTNumber(e)
    //     setShowGst(e.gst)
    //     setValue("hsn", `${e.value}`)
    //     setValue("gst", `${e.gst_id}`)
    //     setForHSNValue({ label: e?.label, value: e?.value })



    // }

    const { data: HSNData, refetch: HSNRefetch } = useFetchHSNDropDown()

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    // const [openHSN, setOpenHSN] = useState(false)

    const handleChangeHSN = (e: any) => {
        setValue("material_name_and_type_category_and_hsn_code_and_gst", `${e.value}`)
        //   setDensityValue(e.material)
        //   setValue("polymer_grade", e.polymer)
        setForHSNValue({ label: e?.label, value: e?.value })
    }


    const apinameForMaterialType = process.env.REACT_APP_PANNI_MATERIAL_USE_API?.toString()
    const { mutate: mutateMaterialType, data: dataMaterialType, isError: MaterialTypeIsError, isLoading: MaterialTypeLoading, isSuccess: MaterialTypeSuccess, error: MaterialTypeError }: any = useCreate(apinameForMaterialType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueMaterialType, setValueMaterialType]: any = useState();
    const [optionsMaterialType, setOptionsMaterialType]: any = useState([]);
    const [valueMaterialTypeCreatable, setValueMaterialTypeCreatable] = useState<Option | null>();

    const { data: MaterialTypeData, refetch: MaterialTypeRefetch } = useFetchPanniMaterialUseDropDown()

    useEffect(() => {
        setOptionsMaterialType(MaterialTypeData)
    }, [MaterialTypeData])





    const handleChangeMaterialType = (e: any) => {


        MaterialTypeRefetch()

        setApiName(apinameForMaterialType)
        setDeleteApiName(apinameForMaterialType)
        setEditId(e?.value)
        setEditData(MaterialTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialType(e)


        setValue("material_use", `${e?.value}`)
        setValueMaterialTypeCreatable(null)
    }





    const handleCreateMaterialType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateMaterialType({ name: newOption.name, status: newOption.status })
            setOptionsMaterialType((prev: any) => [...prev, newOption]);
            setValueMaterialTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialTypeSuccess && optionsMaterialType) {
                await MaterialTypeRefetch();
                setValue("material_use", MaterialTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [MaterialTypeSuccess, MaterialTypeData]);



    const apinameForSealingType = process.env.REACT_APP_PANNI_SEALING_TYPE_API?.toString()
    const { mutate: mutateSealingType, data: dataSealingType, isError: SealingTypeIsError, isLoading: SealingTypeLoading, isSuccess: SealingTypeSuccess, error: SealingTypeError }: any = useCreate(apinameForSealingType)
    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueSealingType, setValueSealingType]: any = useState();
    const [optionsSealingType, setOptionsSealingType]: any = useState([]);
    const [valueSealingTypeCreatable, setValueSealingTypeCreatable] = useState<Option | null>();

    const { data: SealingTypeData, refetch: SealingTypeRefetch } = useFetchPanniSealingTypeDropDown()

    useEffect(() => {
        setOptionsSealingType(SealingTypeData)
    }, [SealingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            SealingTypeRefetch()
        }
    }, [])


    const handleChangeSealingType = (e: any) => {

        SealingTypeRefetch()

        setApiName(apinameForSealingType)
        setDeleteApiName(apinameForSealingType)
        setEditId(e?.value)
        setEditData(SealingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSealingType(e)

        setValue("product_sealing_type", `${e?.value}`)
        setValueSealingTypeCreatable(null)
    }





    const handleCreateSealingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateSealingType({ name: newOption.name, status: newOption.status })
            setOptionsSealingType((prev: any) => [...prev, newOption]);
            setValueSealingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SealingTypeSuccess && optionsSealingType) {
                await SealingTypeRefetch();
                setValue("product_sealing_type", SealingTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [SealingTypeSuccess, SealingTypeData]);


    const apinameForProductPrintingType = process.env.REACT_APP_PANNI_PRINTING_TYPE_API?.toString()
    const { mutate: mutateProductPrintingType, data: dataProductPrintingType, isError: ProductPrintingTypeIsError, isLoading: ProductPrintingTypeLoading, isSuccess: ProductPrintingTypeSuccess, error: ProductPrintingTypeError }: any = useCreate(apinameForProductPrintingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPrintingType, setValueProductPrintingType]: any = useState();
    const [optionsProductPrintingType, setOptionsProductPrintingType]: any = useState([]);
    const [valueProductPrintingTypeCreatable, setValueProductPrintingTypeCreatable] = useState<Option | null>();

    const { data: ProductPrintingTypeData, refetch: ProductPrintingTypeRefetch } = useFetchPanniProductPrintingTypeDropDown()

    useEffect(() => {
        setOptionsProductPrintingType(ProductPrintingTypeData)
    }, [ProductPrintingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPrintingTypeRefetch()
        }
    }, [])


    const handleChangeProductPrintingType = (e: any) => {
        ProductPrintingTypeRefetch()

        setApiName(apinameForProductPrintingType)
        setDeleteApiName(apinameForProductPrintingType)
        setEditId(e?.value)
        setEditData(ProductPrintingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPrintingType(e)

        setValue("product_printing_type", `${e?.value}`)
        setValueProductPrintingTypeCreatable(null)
    }





    const handleCreateProductPrintingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPrintingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPrintingType((prev: any) => [...prev, newOption]);
            setValueProductPrintingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPrintingTypeSuccess && optionsProductPrintingType) {
                await ProductPrintingTypeRefetch();
                setValue("product_printing_type", ProductPrintingTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [ProductPrintingTypeSuccess, ProductPrintingTypeData]);


    const apinameForProductPakingType = process.env.REACT_APP_PANNI_PACKING_TYPE_API?.toString()
    const { mutate: mutateProductPakingType, data: dataProductPakingType, isError: ProductPakingTypeIsError, isLoading: ProductPakingTypeLoading, isSuccess: ProductPakingTypeSuccess, error: ProductPakingTypeError }: any = useCreate(apinameForProductPakingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPakingType, setValueProductPakingType]: any = useState();
    const [optionsProductPakingType, setOptionsProductPakingType]: any = useState([]);
    const [valueProductPakingTypeCreatable, setValueProductPakingTypeCreatable] = useState<Option | null>();

    const { data: ProductPakingTypeData, refetch: ProductPakingTypeRefetch } = useFetchPanniProductPakingTypeDropDown()

    useEffect(() => {
        setOptionsProductPakingType(ProductPakingTypeData)
    }, [ProductPakingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPakingTypeRefetch()
        }
    }, [])


    const handleChangeProductPakingType = (e: any) => {

        ProductPakingTypeRefetch()

        setApiName(apinameForProductPakingType)
        setDeleteApiName(apinameForProductPakingType)
        setEditId(e?.value)
        setEditData(ProductPakingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPakingType(e)

        setValue("product_packing_type", `${e?.value}`)
        setValueProductPakingTypeCreatable(null)
    }





    const handleCreateProductPakingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPakingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPakingType((prev: any) => [...prev, newOption]);
            setValueProductPakingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPakingTypeSuccess && optionsProductPakingType) {
                await ProductPakingTypeRefetch();
                setValue("product_packing_type", ProductPakingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPakingTypeSuccess, ProductPakingTypeData]);


    const apinameForProductBy = process.env.REACT_APP_PANNI_PRODUCT_BY_API?.toString()
    const { mutate: mutateProductBy, data: dataProductBy, isError: ProductByIsError, isLoading: ProductByLoading, isSuccess: ProductBySuccess, error: ProductByError }: any = useCreate(apinameForProductBy)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductBy, setValueProductBy]: any = useState();
    const [optionsProductBy, setOptionsProductBy]: any = useState([]);
    const [valueProductByCreatable, setValueProductByCreatable] = useState<Option | null>();

    const { data: ProductByData, refetch: ProductByRefetch } = useFetchPanniProductByDropDown()

    useEffect(() => {
        setOptionsProductBy(ProductByData)
    }, [ProductByData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductByRefetch()
        }
    }, [])


    const handleChangeProductBy = (e: any) => {

        ProductByRefetch()

        setApiName(apinameForProductBy)
        setDeleteApiName(apinameForProductBy)
        setEditId(e?.value)
        setEditData(ProductByData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductBy(e)

        setValue("product_by", `${e?.value}`)
        setValueProductByCreatable(null)
    }





    const handleCreateProductBy = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductBy({ name: newOption.name, status: newOption.status })
            setOptionsProductBy((prev: any) => [...prev, newOption]);
            setValueProductByCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductBySuccess && optionsProductBy) {
                await ProductByRefetch();
                setValue("product_by", ProductByData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductBySuccess, ProductByData]);

    const apinameForProductType = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductType, setValueProductType]: any = useState();
    const [optionsProductType, setOptionsProductType]: any = useState([]);
    const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();

    const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsProductType(ProductTypeData)
    }, [ProductTypeData])





    const handleChangeProductType = (e: any) => {

        ProductTypeRefetch()

        setApiName(apinameForProductType)
        setDeleteApiName(apinameForProductType)
        setEditId(e?.value)
        setEditData(ProductTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductType(e)

        setValue("product_type", `${e?.value}`)
        setValueProductTypeCreatable(null)
    }





    const handleCreateProductType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductType({ name: newOption.name, status: newOption.status })
            setOptionsProductType((prev: any) => [...prev, newOption]);
            setValueProductTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductTypeSuccess && optionsProductType) {
                await ProductTypeRefetch();
                setValue("product_type", ProductTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductTypeSuccess, ProductTypeData]);
    const apinameForLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateLocation, data: dataLocation, isError: LocationIsError, isLoading: LocationLoading, isSuccess: LocationSuccess, error: LocationError }: any = useCreate(apinameForLocation)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueLocation, setValueLocation]: any = useState();
    const [optionsLocation, setOptionsLocation]: any = useState([]);
    const [valueLocationCreatable, setValueLocationCreatable] = useState<Option | null>();

    const { data: LocationData, refetch: LocationRefetch } = useFetchMachineLocationDropDown()

    useEffect(() => {
        setOptionsLocation(LocationData)
    }, [LocationData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            LocationRefetch()
        }
    }, [])


    const handleChangeLocation = (e: any) => {


        LocationRefetch()

        setApiName(apinameForLocation)
        setDeleteApiName(apinameForLocation)
        setEditId(e?.value)
        setEditData(LocationData)
        setRenderKey(prevKey => prevKey + 1);

        setValueLocation(e)

        setValue("product_location", `${e?.value}`)
        setValueLocationCreatable(null)
    }





    const handleCreateLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateLocation({ name: newOption.name, status: newOption.status })
            setOptionsLocation((prev: any) => [...prev, newOption]);
            setValueLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (LocationSuccess && optionsLocation) {
                await LocationRefetch();
                setValue("product_location", LocationData[0]?.value)
            }
        })();

        return () => {
        };
    }, [LocationSuccess, LocationData]);
    const apinameForMoisture = process.env.REACT_APP_CARTON_MOISTURE_API?.toString()
    const { mutate: mutateMoisture, data: dataMoisture, isError: MoistureIsError, isLoading: MoistureLoading, isSuccess: MoistureSuccess, error: MoistureError }: any = useCreate(apinameForMoisture)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueMoisture, setValueMoisture]: any = useState();
    const [optionsMoisture, setOptionsMoisture]: any = useState([]);
    const [valueMoistureCreatable, setValueMoistureCreatable] = useState<Option | null>();

    const { data: MoistureData, refetch: MoistureRefetch } = useFetchCartonMoistureDropDown()

    useEffect(() => {
        setOptionsMoisture(MoistureData)
    }, [MoistureData])





    const handleChangeMoisture = (e: any) => {


        MoistureRefetch()

        setApiName(apinameForMoisture)
        setDeleteApiName(apinameForMoisture)
        setEditId(e?.value)
        setEditData(MoistureData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMoisture(e)


        setValue("carton_moisture", `${e?.value}`)
        setValueMoistureCreatable(null)
    }





    const handleCreateMoisture = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMoisture({ name: newOption.name, status: newOption.status })
            setOptionsMoisture((prev: any) => [...prev, newOption]);
            setValueMoistureCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MoistureSuccess && optionsMoisture) {
                await MoistureRefetch();
                setValue("carton_moisture", MoistureData[0]?.value)
            }
        })();

        return () => {
        };
    }, [MoistureSuccess, MoistureData]);


    const handleReset = () => {
        reset()
        setpanniPrinting("no")
        setpanniKan("no")
        setForValue(null)
        setForColorValue(null)
        setForBrandValue(null)
        setForHSNValue(null)
        setShowGst(null)
        setValueMaterialTypeCreatable(null)
        setValueMaterialType(null)
        setValueSealingTypeCreatable(null)
        setValueSealingType(null)
        setForColorsValue(null)
        setForUOMValue(null)
        setValueProductPakingTypeCreatable(null)
        setValueProductPakingType(null)
        setValueMoistureCreatable(null)
        setValueMoisture(null)
        setValueProductByCreatable(null)
        setValueProductBy(null)
        setValueProductTypeCreatable(null)
        setValueProductType(null)
        setValueLocationCreatable(null)
        setValueLocation(null)

        setUploadProductPDF([])
        setUploadProductImage([])
        setUploadProductPacketPacking([])
        setuploadDrawingPDF([])
        setuploadBunchPDF([])
        setuploadDrawingImage([])
        setuploadBoriAndCartonImage([])
        
        // setCartonPlyValue(null)
        
    }

    const [cartonPlyId, setCartonPlyId] = useState([])

    const onSubmit = (pannidetail: any) => {
        delete pannidetail.product_empty_weight

        if (boriAndCartonImage.length == 0) {
            delete pannidetail.product_image
        }
        if (DrawingImage.length == 0) {
            delete pannidetail.bori_and_carton_image
        }

        if (productPacketPacking.length == 0) {
            delete pannidetail.bunch_packing_image
        }
        if (productPDF.length == 0) {
            delete pannidetail.product_pdf
        }
        if (DrawingPDF.length == 0) {
            delete pannidetail.drawing_pdf
        }
        if (BunchPDF.length == 0) {
            delete pannidetail.bunch_packing_pdf
        }

        delete pannidetail.name_and_grade
        // setValue("product_ply",cartonPlyId)
        pannidetail.product_ply = cartonPlyId
        const formData = new FormData();

        if (panniPrinting === "no") {
            delete pannidetail.product_brand
            delete pannidetail.product_brand_type
            delete pannidetail.product_printing_total_color
            delete pannidetail.product_printing_color_name
            delete pannidetail.product_printing_width
            delete pannidetail.product_printing_length
            delete pannidetail.product_printing_type
        }
        if (panniKan === "no") {
            delete pannidetail.product_kan_length
            delete pannidetail.product_kan_width
        }
        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value

            if (key === "product_image") {
                for (let i = 0; i < boriAndCartonImage.length; i++) {

                    formData.append('product_image', boriAndCartonImage[i])
                }
                continue
            }
            if (key === "drawing_image") {
                for (let i = 0; i < DrawingImage.length; i++) {

                    formData.append('drawing_image', DrawingImage[i])
                }
                continue
            }
            if (key === "bunch_packing_image") {
                for (let i = 0; i < productPacketPacking.length; i++) {

                    formData.append('bunch_packing_image', productPacketPacking[i])
                }
                continue
            }

            if (key === "product_pdf") {
                for (let i = 0; i < productPDF.length; i++) {

                    formData.append('product_pdf', productPDF[i])
                }
                continue
            }
            if (key === "drawing_pdf") {
                for (let i = 0; i < DrawingPDF.length; i++) {

                    formData.append('drawing_pdf', DrawingPDF[i])
                }
                continue
            }
            if (key === "bunch_packing_pdf") {
                for (let i = 0; i < BunchPDF.length; i++) {

                    formData.append('bunch_packing_pdf', BunchPDF[i])
                }
                continue
            }




            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (formvalue.hasOwnProperty('size')) {
                formData.append(key, formvalue[0])
            }
            else if (Array.isArray(value) && key === "product_printing_color_name") {
                value.forEach((val, index) => formData.append(`product_printing_color_name`, val))
            }
            else if (Array.isArray(value) && key === "product_ply") {
                value.forEach((val, index) => formData.append(`product_ply`, val))
            }
            else if (key === "carton_image" || key === "carton_drawing") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }

        }
        mutate(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listCarton', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleChangePly = (e: any) => {
        let Capital = e.target.value
        setValue("product_ply", Capital.charAt(0).toUpperCase() + Capital.slice(1))
    }

    // const handleChangeMoisture = (e: any) => {
    //     let Capital = e.target.value
    //     setValue("carton_moisture", Capital.charAt(0).toUpperCase() + Capital.slice(1))
    // }


    const [cartonPlyValue, setCartonPlyValue]:any = useState("")






    const handleCartonPly = (e: any) => {
console.log(e.target.value,"e.target.value")
        setCartonPlyValue(e.target.value)
    }





    useEffect(() => {


        if (submittedColor) {

            setForColorValue(options[0])
            setValue("product_color_name", options[0]?.value)
            // setForColorsValue(optionsPrinting[0])
            // setValue("printing_color", optionsPrinting[0]?.value)

        }


    }, [submittedColor, options])

    useEffect(() => {


        if (submittedPrintingColor) {

            // setForColorValue(options[0])
            //   setValue("color", options[0]?.value)
            setForColorsValue(optionsPrinting[0])
            setValue("product_printing_color_name", optionsPrinting[0]?.value)

        }


    }, [submittedPrintingColor, optionsPrinting])



    useEffect(() => {

        if (submittedBrand) {

            setValue("product_brand", optionsbrand[0].value)
            setValue("product_brand_type", optionsbrand[0].type)
            setForBrandValue(optionsbrand[0])
        }
    }, [submittedBrand, optionsbrand])


    const [cartonLength, setCartonLength] = useState(0)
    const [cartonWidth, setCartonWidth] = useState(0)
    const [cartonHeight, setCartonHeight] = useState(0)
    // const [kan,setKan] = useState("no")
    const [kanHeight, setKanHeight] = useState(0)
    const [kanWidth, setKanWidth] = useState(0)

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }




    const handleCartonLength = (e: any) => {
        setCartonLength(Number(e.target.value))
    }

    const handleCartonWidth = (e: any) => {
        setCartonWidth(Number(e.target.value))
    }

    const handleCartonHeight = (e: any) => {
        setCartonHeight(Number(e.target.value))
    }

    // const handleKan=(e:any)=>{
    //     setKan(e.target.value)
    // }
    const handleKanHeight = (e: any) => {
        setKanHeight(Number(e.target.value))
    }
    const handleKanWidth = (e: any) => {
        setKanWidth(Number(e.target.value))
    }

    const [message, setMessage] = useState(false)

    const [submittedHSN, setSubmittedHSN] = useState(false)


    useEffect(() => {
        if (submittedHSN) {
            setForHSNValue(optionsHSN[0])

            setValue("material_name_and_type_category_and_hsn_code_and_gst", optionsHSN[0]?.value)

        }

    }, [submittedHSN, optionsHSN])

    const [submittedUOM, setSubmittedUOM] = useState(false)


    useEffect(() => {
        if (submittedUOM) {
            setForUOMValue(optionsUom[0])

            setValue("uom", optionsUom[0]?.value)

        }

    }, [submittedUOM, optionsUom])


    const [boriAndCartonImage, setBoriAndCartonImage] = useState([])
    const [uploadBoriAndCartonImage, setuploadBoriAndCartonImage] = useState<UploadFile[]>([])
    const [DrawingImage, setDrawingImage] = useState([])
    const [uploadDrawingImage, setuploadDrawingImage] = useState<UploadFile[]>([])
    const [BunchPDF, setBunchPDF] = useState([])
    const [uploadBunchPDF, setuploadBunchPDF] = useState<UploadFile[]>([])
    const [DrawingPDF, setDrawingPDF] = useState([])
    const [uploadDrawingPDF, setuploadDrawingPDF] = useState<UploadFile[]>([])

    const [productPacketPacking, setProductPacketPacking] = useState([])
    const [uploadProductPacketPacking, setUploadProductPacketPacking] = useState<UploadFile[]>([])

    const [productImage, setProductImage]: any = useState([])
    const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])

    const [productPDF, setProductPDF] = useState([])
    const [uploadProductPDF, setUploadProductPDF] = useState<UploadFile[]>([])

    const BoriAndCartonChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setBoriAndCartonImage(tempFiles)
        setuploadBoriAndCartonImage(e.fileList)

    }


    const ProductPacketPackingOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductPacketPacking(tempFiles)
        setUploadProductPacketPacking(e.fileList)

    }
    const DrawingImageOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setDrawingImage(tempFiles)
        setuploadDrawingImage(e.fileList)

    }

    // const ProductImageOnChange = (e: any) => {
    //     e.file.status = "done"

    //     const tempFiles = e.fileList.map((file: any) => {
    //         return file.originFileObj
    //     })
    //     setProductImage(tempFiles)
    //     setUploadProductImage(e.fileList)

    // }
    const ProductPDFOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductPDF(tempFiles)
        setUploadProductPDF(e.fileList)


    }
    const DrawingPDFOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setDrawingPDF(tempFiles)
        setuploadDrawingPDF(e.fileList)


    }
    const BunchPDFOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setBunchPDF(tempFiles)
        setuploadBunchPDF(e.fileList)


    }






    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    const queryClient = useQueryClient();
    const handleRefetch = () => {
        if (apiName === apinameForMaterialType) {
            MaterialTypeRefetch()
        }
        if (apiName === apinameForSealingType) {
            SealingTypeRefetch()
        }
        if (apiName === apinameForProductPrintingType) {
            ProductPrintingTypeRefetch()
        }
        if (apiName === apinameForProductPakingType) {
            ProductPakingTypeRefetch()
        }
        if (apiName === apinameForProductBy) {
            ProductByRefetch()
        }
        if (apiName === apinameForProductType) {
            ProductTypeRefetch()
        }
        if (apiName === apinameForLocation) {
            LocationRefetch()
        }
        if (apiName === apinameForMoisture) {
            MoistureRefetch()
        }
        // queryClient.invalidateQueries(['Panni Material Use']);



       
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueMaterialType && apiName === apinameForMaterialType) {
            setValueMaterialType(null)
            setValueMaterialTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueSealingType && apiName === apinameForSealingType) {
            setValueSealingType(null)
            setValueSealingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductPrintingType && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductPakingType && apiName === apinameForProductPakingType) {
            setValueProductPakingType(null)
            setValueProductPakingTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductBy && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (OpenEditDropDownModel && valueProductType && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueLocation && apiName === apinameForLocation) {
            setValueLocation(null)
            setValueLocationCreatable(null)
        }
        if (OpenEditDropDownModel && valueMoisture && apiName === apinameForMoisture) {
            setValueMoisture(null)
            setValueMoistureCreatable(null)
        }







        if (deletesucess && apiName === apinameForMaterialType) {
            setValueMaterialType(null)
            setValueMaterialTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForSealingType) {
            setValueSealingType(null)
            setValueSealingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductPakingType) {
            setValueProductPakingType(null)
            setValueProductPakingTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }
        if (deletesucess && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForLocation) {
            setValueLocation(null)
            setValueLocationCreatable(null)
        }
        if (deletesucess && apiName === apinameForMoisture) {
            setValueMoisture(null)
            setValueMoistureCreatable(null)
        }


    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Carton</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listCarton" className="text-decoration-none text-black">Carton</Link></li>
                                    <li className="breadcrumb-item active">Add Carton</li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">

                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                                    {/* <div className="row">
                                        <h5 className="mb-0 mt-0">Carton Detail</h5>
                                    </div> */}
                                    <div className="row d-flex justify-content-md-  center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Carton Name & Grade <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("name_and_grade", { required: { value: true, message: "Carton Name & Grade is required" } })}
                                                        placeholder={'Carton Name & Grade'}
                                                        value={forValue}
                                                        onChange={handleChangePanniName}
                                                        options={optionsPanniName}
                                                    />
                                                    <span className='text-red w-100'>{errors?.name_and_grade?.message}</span>

                                                </div> */}

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="hsncode">HSN Code <span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        {...register("hsn", { required: { value: true, message: "HSN Code is required" } })}
                                                        placeholder={'Select HSN Code'}
                                                        onInputChange={handleInputChangeHSN}
                                                        onChange={handleChangeHSN}
                                                        options={optionsHSN}
                                                        value={forHSNValue}

                                                    />
                                                    <span className='text-red w-100'>{errors?.hsn?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Name & Type & Category & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            // placeholder={'Material Name & Type & Category & HSN & GST%'}
                                                            {...register("material_name_and_type_category_and_hsn_code_and_gst", { required: { value: true, message: "Material Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={forHSNValue}
                                                            // value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.material_name_and_type_category_and_hsn_code_and_gst?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Use <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                                        placeholder={'Select Material Use'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialType}
                                                        onCreateOption={handleCreateMaterialType}
                                                        onChange={handleChangeMaterialType}
                                                        onMenuOpen={() => MaterialTypeRefetch()}
                                                        value={valueMaterialTypeCreatable == null ? valueMaterialType : valueMaterialTypeCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.material_use?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="paper_color">Product Color Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("product_color_name", { required: { value: true, message: "Product Color Name is required" } })}
                                                            placeholder={'Select Product Color Name'}
                                                            value={forColorValue}
                                                            options={options}
                                                            onInputChange={handleInputChange}
                                                            components={customIndicator}
                                                            onChange={handleChange}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_color_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_width">Product Width mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_width" placeholder="Product Width mm"
                                                            {...register("product_width_mm", { required: { value: true, message: "Product Width mm is required" } })}
                                                            onChange={handleCartonWidth}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_width_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_length">Product Length mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_length" placeholder="Product Length mm"
                                                            {...register("product_length_mm", { required: { value: true, message: "Product Length mm is required" } })}
                                                            onChange={handleCartonLength} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_length_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_height">Product Height mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_height" placeholder="Product Height mm"
                                                            {...register("product_height_mm", { required: { value: true, message: "Product Height mm is required" } })}
                                                            onChange={handleCartonHeight} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_height_mm?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="cartonkan">Product Kan Yes-No <span style={{ color: 'red' }}>*</span> </label>
                                                    <select className="form-select" {...register("product_kan", { required: { value: true, message: "Product Kan Yes-No is required" }, onChange: onPanniChange })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.product_kan?.message}</span>

                                                </div>
                                                {/* {panniKan === "yes" ?
                                                    <> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="kan_width">Product Kan Width mm {panniKan === "yes" ? <span style={{ color: 'red' }}>*</span> : ''} </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled={panniKan === "no"} className="form-control" id="kan_width" placeholder="Product Width mm"
                                                            {...register("product_kan_width", { required: { value: panniKan === "yes" ? true : false, message: "Product Kan Width mm is required" } })}
                                                            onChange={handleKanWidth}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_kan_width?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="kan_length">Product Kan Length mm {panniKan === "yes" ? <span style={{ color: 'red' }}>*</span> : ''} </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled={panniKan === "no"} className="form-control" id="kan_length" placeholder="Product Length mm"
                                                            {...register("product_kan_length", { required: { value: panniKan === "yes" ? true : false, message: "Product Kan Length mm is required" } })}
                                                            onChange={handleKanHeight} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_kan_length?.message}</span>
                                                </div>

                                                {/* </> : ""} */}


                                                

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Sealing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSealingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_sealing_type", { required: { value: true, message: "Product Sealing Type is required" } })}
                                                        placeholder={'Select Product Sealing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSealingType}
                                                        onCreateOption={handleCreateSealingType}
                                                        onMenuOpen={() => SealingTypeRefetch()}
                                                        onChange={handleChangeSealingType}
                                                        value={valueSealingTypeCreatable == null ? valueSealingType : valueSealingTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_sealing_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing <span style={{ color: 'red' }}>*</span></label>

                                                    <select
                                                        className="form-select"
                                                        {...register("product_printing", {
                                                            onChange: onPrintingChange,
                                                            required: {
                                                                value: true,
                                                                message: "Product Printing is required",
                                                            },
                                                        })}
                                                    >
                                                        <option value={""} selected>
                                                            Select Any
                                                        </option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className="text-red w-100">
                                                        {errors?.product_printing?.message}
                                                    </span>
                                                </div>
                                                {/* {panniPrinting === "yes" && (
                                                    <> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Type {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductPrintingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Type is required" } })}
                                                        placeholder={'Select Product Printing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        isDisabled={panniPrinting === 'no' || ''}
                                                        options={optionsProductPrintingType}
                                                        onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                        onCreateOption={handleCreateProductPrintingType}
                                                        onChange={handleChangeProductPrintingType}
                                                        value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.product_printing_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                    Product Printing Color Name {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}
                                                    </label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("product_printing_color_name", {
                                                                required: {
                                                                    value: panniPrinting === 'no' ? false : true,
                                                                    message: "Product Printing Color Name is required",
                                                                },
                                                            })}
                                                            placeholder={"Select Product Printing Color Name"}
                                                            value={forColorsValue}
                                                            options={optionsPrinting}
                                                            isDisabled={panniPrinting === 'no' || ''}
                                                            onInputChange={handlePanniInputChange}
                                                            components={customIndicator}
                                                            onChange={handleChangePanni}
                                                            isMulti={true}
                                                            // isOptionDisabled={(optionsPanniName:any)=>
                                                            //   {
                                                            //       if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                            //       return optionsPanniName
                                                            //       }
                                                            //      }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" disabled={panniPrinting === 'no'} onClick={() => {
                                                                setPrintingColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className="text-red w-100">
                                                        {errors?.product_printing_color_name?.message}
                                                    </span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                    Product Printing Total Color
                                                    </label>
                                                    <div
                                                        className="input-group"
                                                        data-colorpicker-id={2}
                                                    >
                                                        <input
                                                            type="text"
                                                            // value={ColorTotal}
                                                            disabled
                                                            className="form-control"
                                                            id="exampleInputBrand"
                                                            placeholder=" 0"
                                                            {...register("product_printing_total_color", {
                                                                required: false,
                                                            })}
                                                        />
                                                    </div>
                                                    <span className="text-red w-100">
                                                        {errors?.product_printing_total_color?.message}
                                                    </span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="brandname">product Brand Name {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>

                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("product_brand", { required: { value: panniPrinting === 'no' ? false : true, message: "product Brand Name is required" } })}
                                                            placeholder={'Select Brand Name'}
                                                            onInputChange={handleInputChangeBrand}
                                                            onChange={handleChangeBrand}
                                                            options={optionsbrand}
                                                            isDisabled={panniPrinting === 'no' || ''}
                                                            components={customIndicator}
                                                            value={forBrandValue}

                                                            // isOptionDisabled={(optionsbrand:any)=>
                                                            //     {
                                                            //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                            //         return optionsbrand
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary"
                                                                disabled={panniPrinting === 'no'}
                                                                onClick={() => {
                                                                    setBrandOpen(true)
                                                                }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_brand?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="product_brand_type">Brand Our-Other</label>
                                                    <select disabled className="form-select" {...register("product_brand_type", { required: { value: false, message: "Brand Our-Other is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        {/* <option selected={colorNamePanni?.type === "our" ? true : false} value="our">Our</option>
                                                        <option selected={colorNamePanni?.type === "other" ? true : false} value="other">Other</option> */}
                                                        <option value={"our"}>Our</option>
                                                        <option value={"other"}>Other</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.product_brand_type?.message}</span>
                                                </div>

                                                {/* </>
                                                )} */}







                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="printinig_width">Product Printing Width mm {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="printinig_width"
                                                            disabled={panniPrinting === 'no' || ''}
                                                            placeholder="Carton Width mm" {...register("product_printing_width", { required: { value: panniPrinting === 'no' ? false : true, message: "Carton Printing Width MM is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_printing_width?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="printinig_width">Product Printing Length mm {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="printinig_width"
                                                            disabled={panniPrinting === 'no' || ''}
                                                            placeholder="Product Printing Length MM" {...register("product_printing_length", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Length mm is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_printing_length?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_empty_weight">Product Empty Automatic KG </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_empty_weight" disabled placeholder="Product Empty Automatic KG " {...register("product_empty_weight", { required: { value: false, message: "Product Empty Weight KG is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_empty_weight?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_empty_weight">Product Empty Manual KG </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_empty_weight" placeholder="Product Empty Manual KG  " {...register("product_empty_weight_manual", { required: { value: false, message: "Product Empty Weight KG is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_empty_weight_manual?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="examplecarton_empty_bunch_packing_nosInputEmail1">Product Empty Bunch Packing</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="carton_empty_bunch_packing_nos" placeholder="Product Empty Bunch Packing " {...register("product_empty_bunch_packing_nos", { required: { value: false, message: "Product Empty Bunch Packing is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_empty_bunch_packing_nos?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("uom", { required: { value: true, message: "Product U.O.M is required" } })}
                                                            placeholder={'Select U.O.M'}
                                                            onChange={handleChangeUom}
                                                            options={optionsUom}
                                                            value={forUOMValue}
                                                            components={customIndicator}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenUOM(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.uom?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Prouct Packing Type <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductPakingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_packing_type", { required: { value: true, message: "Prouct Packing Type is required" } })}
                                                        placeholder={'Select Prouct Packing Type '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductPakingType}
                                                        onCreateOption={handleCreateProductPakingType}
                                                        onChange={handleChangeProductPakingType}
                                                        onMenuOpen={() => ProductPakingTypeRefetch()}
                                                        value={valueProductPakingTypeCreatable == null ? valueProductPakingType : valueProductPakingTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_packing_type?.message}</span>

                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_special_running">Carton Special / Running <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("carton_special_running", { required: { value: true, message: "Carton Special / Running is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"special"}>Special</option>
                                                        <option value={"running"}>Running</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.carton_special_running?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="gst">GST </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="GST %" disabled value={showGst} className="form-control" id="gst"  {...register("gst", { required: { value: false, message: "GST is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gst?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="bursting_strength">Stock Minimum Bunch Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="bursting_strength" placeholder="Stock Minimum Bunch Qty " {...register("stock_minimum_bunch_qty", { required: { value: true, message: "Stock Minimum Bunch Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stock_minimum_bunch_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="bursting_strength">Stock Maximum Bunch Qty<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="bursting_strength" placeholder="Stock Maximum Bunch Qty " {...register("stock_maximum_bunch_qty", { required: { value: true, message: "Stock Maximum Bunch Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.stock_maximum_bunch_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="bursting_strength">Bursting Strength<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="bursting_strength" placeholder="Bursting Strength " {...register("bursting_strength", { required: { value: true, message: "Bursting Strength is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bursting_strength?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Carton Moisture % <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMoisture(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("carton_moisture", { required: { value: true, message: "Carton Moisture % is required" } })}
                                                        placeholder={'Select Carton Moisture %'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMoisture}
                                                        onCreateOption={handleCreateMoisture}
                                                        onChange={handleChangeMoisture}
                                                        onMenuOpen={() => MoistureRefetch()}
                                                        value={valueMoistureCreatable == null ? valueMoisture : valueMoistureCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.carton_moisture?.message}</span>

                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="carton_moisture">Carton Moisture<span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="carton_moisture" placeholder="Add Carton Moisture " {...register("carton_moisture", { onChange: handleChangeMoisture, required: { value: true, message: "Carton Moisture is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.carton_moisture?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Prouct By <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductBy(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_by", { required: { value: true, message: "Prouct By is required" } })}
                                                        placeholder={'Select Prouct By '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductBy}
                                                        onCreateOption={handleCreateProductBy}
                                                        onMenuOpen={() => ProductByRefetch()}
                                                        onChange={handleChangeProductBy}
                                                        value={valueProductByCreatable == null ? valueProductBy : valueProductByCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_by?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Prouct Type <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_type", { required: { value: true, message: "Prouct Type is required" } })}
                                                        placeholder={'Select Prouct Type '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductType}
                                                        onCreateOption={handleCreateProductType}
                                                        onMenuOpen={() => ProductTypeRefetch()}
                                                        onChange={handleChangeProductType}
                                                        value={valueProductTypeCreatable == null ? valueProductType : valueProductTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_type?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Maximum Debit Day <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Maximum Debit Day" {...register("product_maximum_debit_day", { required: { value: true, message: "Product Maximum Debit Day is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_maximum_debit_day?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Carton Location <span style={{ color: 'red' }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_location", { required: { value: true, message: "Carton Location is required" } })}
                                                        placeholder={'Select Carton Location'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsLocation}
                                                        onCreateOption={handleCreateLocation}
                                                        onMenuOpen={() => LocationRefetch()}
                                                        onChange={handleChangeLocation}
                                                        value={valueLocationCreatable == null ? valueLocation : valueLocationCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_location?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Remark </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Remark" className="form-control" id="exampleInputBrand"
                                                            {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="product_ply">Product Ply</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        {/* <input type="text" className="form-control" id="product_ply" placeholder="Add Carton Ply " {...register("product_ply", { onChange:handleChangePly,required: { value: true, message: "Carton Ply is required" } })} /> */}
                                                        {/* <div className="input-group-append " style={{ width: 0, padding: 0 }}> */}
                                                        <select className="form-select dropdown-select"
                                                            disabled={(cartonHeight && cartonLength && cartonWidth) == 0}

                                                            {...register("product_ply", { required: { value: false, message: "Product Ply  is required" } })}
                                                            onChange={handleCartonPly}
                                                        >
                                                            <option value={""}  selected>Select Any</option>
                                                            <option value={1}>1</option>
                                                            <option value={2}>2</option>
                                                            <option value={3}>3</option>
                                                            <option value={4}>4</option>
                                                            <option value={5}>5</option>
                                                            <option value={6}>6</option>
                                                            <option value={7}>7</option>
                                                            <option value={8}>8</option>
                                                            <option value={9}>9</option>
                                                            <option value={10}>10</option>
                                                        </select>

                                                        <Button className="input-group-text border-start-0 bg-primary"
                                                            disabled={cartonPlyValue === ""}
                                                            onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        {/* </div> */}

                                                    </div>
                                                    {message && <span className='text-success w-100 text-md'>Successfully Added!</span>}
                                                    <span className='text-red w-100'>{errors?.product_ply?.message}</span>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image All</label>
                                                    <Upload
                                                        {...register("product_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={BoriAndCartonChange}
                                                        multiple={true}
                                                        fileList={uploadBoriAndCartonImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>

                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bunch Packing Image All </label>
                                                    <Upload
                                                        {...register("bunch_packing_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductPacketPackingOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductPacketPacking}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>

                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Drawing Image All </label>
                                                    <Upload
                                                        {...register("drawing_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={DrawingImageOnChange}
                                                        multiple={true}
                                                        fileList={uploadDrawingImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                {/* </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex "> */}
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF All</label>
                                                    <Upload
                                                        {...register("product_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ProductPDFOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bunch Packing PDF All</label>
                                                    <Upload
                                                        {...register("bunch_packing_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={BunchPDFOnChange}
                                                        multiple={true}
                                                        fileList={uploadBunchPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-2 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Drawing PDF All</label>
                                                    <Upload
                                                        {...register("drawing_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DrawingPDFOnChange}
                                                        multiple={true}
                                                        fileList={uploadDrawingPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCarton")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>


            <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
            <Color_Model refetchForm={colorRefetch} open={printingColorOpen} setOpen={setPrintingColorOpen} setSubmitted={setSubmittedPrintingColor} />
            <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />
            <UOM_Model refetchForm={UomRefetch} open={openUOM} setOpen={setOpenUOM} setSubmitted={setSubmittedUOM} />


            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />

            {open &&


                <>
                    <Modal

                        size='xl'

                        show={open} onHide={() => {
                            setShow(false)
                            // reset()
                            setOpen(false)
                        }} aria-labelledby="contained-modal-title-vcenter"
                        centered    >
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Brand</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>

                            <Editable cartonSize={cartonPlyValue} cartonLength={cartonLength} cartonWidth={cartonWidth}
                                cartonHeight={cartonHeight}
                                kanHeight={kanHeight}
                                kanWidth={kanWidth}
                                panniKan={panniKan}
                                setOpen={setOpen}
                                setMessage={setMessage}
                                setCartonPlyId={setCartonPlyId}
                            // setSavedData={setSavedData}
                            />

                        </Modal.Body>
                        <Modal.Footer>


                        </Modal.Footer>
                    </Modal>
                </>


            }
            {/* <Footer /> */}




        </div>
    )
}

export default Add_Carton

// function typeOf(value: any): any {
//     throw new Error("Function not implemented.");
// }

