import { Button } from "antd";
// import { useDeleteTaskStatusMutation } from "../../redux/rtk/features/projectManagement/project/taskStatus/taskStatusApi";
import { AiFillDelete  } from "react-icons/ai";
import "./styles.css";
import { useEffect, useState } from "react";
import Axiosinstance from "../../../hooks/Interceptor";
import Modal from 'react-bootstrap/Modal';

const DeleteColumn = ({ id, projectId, Refetch }: any) => {

  const apiname = process.env.REACT_APP_HRMS_TASK_STATUS_API?.toString();

  const [open, setOpen]: any = useState(false)

  const onDelete = async (e: any) => {

    await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}/?force=true`).then((res: any) => Refetch())

  };



  return (
    <>
      <Button type="link" danger className='text-md text-red-500 ml-2 px-2 mb-1 text-indigo-500 rounded hover:text-indigo-300' onClick={() => setOpen(true)}>
        <AiFillDelete  />
      </Button>
      <Modal
        show={open} onHide={() => {
          setOpen(false)
        }} aria-labelledby="contained-modal-title-vcenter"
        centered  >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '16px' }}>Delete Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Sure you want to delete it?</h4>
        </Modal.Body>
        <Modal.Footer>

          <Button className="text-white"
            onClick={onDelete}
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
          >
            Delete
          </Button>
          
          <Button style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpen(false) }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteColumn;
