import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useFetchHrmsAccountDropdown } from '../../../hooks/DropDown_Api';

const Add_Transaction = () => {

  let pageName = "Transaction"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_TRANSACTION_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [optionsDebit, setOptionsDebit]: any = useState([])
  const [Debit, setDebit]: any = useState()

  const [optionsCredit, setOptionsCredit]: any = useState([])
  const [Credit, setCredit]: any = useState()
  const { data: HRMSAccountData, refetch: HRMSAccountRefetch } = useFetchHrmsAccountDropdown()

  useEffect(() => {
    setOptionsDebit(HRMSAccountData)
    setOptionsCredit(HRMSAccountData)
  }, [HRMSAccountData])

  const handleChangeDebit = (e: any) => {
    setDebit(e)
    setValue("debit", e?.value)
  }
  const handleChangeCredit = (e: any) => {
    setCredit(e)
    setValue("credit", e?.value)
  }

  const [forDate, setForDate]: any = useState()

  const handleDate = (e: any) => {
    setValue("date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setForDate(e)
  }

  const handleReset = () => {
    reset();
  };

  const onSubmit = (branddetail: any) => {

    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listTransaction", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listTransaction" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <Date_Picker name='date' label='Date' message='Date is required' register={register} isRequired={true} hrmserrors={errors?.date?.message} value={forDate} isDisabled={disabledDate} onChange={(e: any) => handleDate(e)} />
                        <ReactSelect type='text' name='debit' label='Debit Account' message='Debit Account is required' register={register} isRequired={false} errors={errors?.debit?.message} options={optionsDebit} onChange={(e) => handleChangeDebit(e)} Selvalue={Debit}/>
                        <ReactSelect type='text' name='credit' label='Credit Account' message='Credit Account is required' register={register} isRequired={false} errors={errors?.credit?.message} options={optionsCredit} onChange={(e) => handleChangeCredit(e)} Selvalue={Credit}/>
                        <InputField type='number' name='amount' label='Amount' isPencil={true} message='Amount is required' register={register} isRequired={true} hrmserrors={errors?.amount?.message} />
                        <InputField type='text' name='particulars' label='Particulars' isPencil={true} message='Particulars is required' register={register} isRequired={true} hrmserrors={errors?.particulars?.message} />
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listTransaction")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Transaction