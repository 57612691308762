import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Restricted from "../../Roles/Restricted";
import { BsEyeFill } from "react-icons/bs";
import Axiosinstance from "../../hooks/Interceptor";
import { useEffect, useState } from "react";

const Stepper_Actions = ({ row, apiname,permissionClass,setDeleteTrue,setMultipleIds,setShowOffcancasEdit,handleShowEdit,setIsNew }: any) => {
  
  
  const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
  let rawData = localStorage.getItem('Add Data');
  let addData = rawData ? JSON.parse(rawData) : null;
  
  let basicId = addData ? addData.id : null;


  const [isDeleted,setIsDeleted] = useState(false)

    const handleDeleteProduct = async (id: any) => {
        // const updatedFields = additionalFields.filter((field: any) => field.id !== id);
        // setAdditionalFields(updatedFields);
        // await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apiname}/${row.original.id}/`)
        // setDeleteTrue(true)

console.log(row.original.id,"row")
        try {
            await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apiname}/${row.original.id}/`);

            setMultipleIds((oldIds:any) => {
              console.log(oldIds,"oldids")
                // Filter out the deleted ID
                const updatedIds = oldIds.filter((oldId:any) => oldId !== row.original.id);
                console.log('Updated IDs after deletion:', updatedIds);
                return updatedIds;
            });
    
            setDeleteTrue(true);
        } catch (error) {
            console.error('Error deleting product:', error);
        }

        setIsDeleted(true)
       
    
    
      };

      useEffect(() => {
        const fetchData = async () => {
            if (isDeleted) {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForBasicDetails}/${basicId}`);
                    // console.log('response', response);
                    localStorage.setItem('Add Data',JSON.stringify(response?.data?.data))
                    setIsDeleted(false)
                    setIsNew(true)
                    // Do something with response.data
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle the error appropriately
                }
            }
        };

        fetchData();
    }, [isDeleted]);

      // useEffect(()=>{
      //   if(row){
      //     setGetIdForEdit(row.original.id)
      //   }
      // },[row])

const handleEdit=()=>{
  setShowOffcancasEdit(true)
}






  return (
    <>
      <div
        className="vertical-align-center"
        style={{
          display: "inline-block !important",
          justifyContent: "space-evenly !important",
        }}
      >

{/* <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        >
         <button
          onClick={() => {
            viewUsageModal(row.original.id);
          }}
          className="btn btn-primary text-white"
        >
          <BsEyeFill />


        </button>
        </Restricted> */}

        <Restricted
          to={`${permissionClass?.front}.change_${permissionClass?.back}`}
          fallback={""}
        >
        

          <button
          className="btn btn-primary text-white"
          onClick={()=>{ handleShowEdit(row?.original?.id)}}
        >
            <FiEdit />
        </button>
        </Restricted>

        <Restricted
          to={`${permissionClass?.front}.delete_${permissionClass?.back}`}
          fallback={""}
        >
        <button
          onClick={handleDeleteProduct}
          className="btn btn-primary text-white"
        >
          <RiDeleteBinLine />
        </button>
        </Restricted>
      </div>
    </>
  );
};

export default Stepper_Actions;

export const Deletesingle = ({
  apiname,
  confirmmodal,
  confirmmodalclose,
  masterName,
  confirmmodalsubmit,
}: any) => {
  return (
    <Modal
      show={confirmmodal}
      onHide={confirmmodalclose}
      aria-labelledby="Delete single"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete {masterName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <span>
          {" "}
          <span className="text-center">
            Are you sure you want to delete this item?
          </span>
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={confirmmodalclose}>
          Cancel
        </Button>
        <Button
          variant="info"
          onClick={confirmmodalsubmit}
          style={{ color: "white" }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export const ViewUsage = ({
  apiname,
  openViewUsage,
  handleDeletedforce,
  masterName,
  closeViewUsage,
  referance,
}: any) => {

  const [data,setData]:any = useState()

  useEffect(()=>{
    if(referance){
      setData(referance?.data?.data)
    }
  },[referance])

// console.log(referance,data?.length)
  return (
    <Modal
      show={openViewUsage}
      onHide={closeViewUsage}
      // aria-labelledby="delete multiple confirmatino force"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>View Usage {masterName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {data?.length === 0?
    <span>No Usage Found.</span>
    :

    <>
       {/* {referance?.data &&
          typeof referance?.data === "object" &&
          referance?.data?.data?.length > 0 &&
          referance?.data?.data[0]?.field && (
            <Displaydataforce data={referance?.data.data} color={apiname} />
          )} */}
           {data && (
        <ul>
          {data?.map((useddata: any) => {
            return (
              // <li>{useddata?.field!="NumberSystem"?useddata?.field:"All Code"} - <a href={`edit${useddata?.model}/${useddata?.id}`} target='blank'>View</a> </li>
              <li>
                {useddata?.field != "NumberSystem"
                  ? useddata?.field
                  : "All Code"}{" "}
                -{" "}
                <a
                  href={`${process.env.REACT_APP_FRONT_URL}edit${useddata?.model}/${useddata?.id}`}
                  target="blank"
                >
                  View
                </a>{" "}
              </li>
            );
          })}
        </ul>
      )}
    </>
    }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeViewUsage}>
          Got It
        </Button>
        {/* <Button
          variant="info"
          disabled
          style={{ color: "white" }}
        >
          Delete Anyway
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
};


export const Deleteforce = ({
  apiname,
  reconfirmdelete,
  handleDeletedforce,
  masterName,
  closereconfirmdelete,
  referance,
}: any) => {
  return (
    <Modal
      show={reconfirmdelete}
      onHide={closereconfirmdelete}
      aria-labelledby="delete multiple confirmatino force"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete {masterName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <span>
          {" "}
          <span className="text-center">
            {"Are you sure you want to delete this?"}
          </span>
        </span>
        <br />
        {referance?.data &&
          typeof referance?.data === "object" &&
          referance?.data?.data?.length > 0 &&
          referance?.data?.data[0]?.field && (
            <Displaydataforce data={referance?.data.data} color={apiname} />
          )}
        {referance?.data?.data?.map((usedepenadancy: any) => {
          return (
            <>
              {usedepenadancy.dependency !=
                `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}` ||
                (usedepenadancy?.length > 0 && (
                  <Displaydataforce
                    data={usedepenadancy.dependency}
                    color={usedepenadancy?.object?.name}
                  />
                ))}
            </>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closereconfirmdelete}>
          Cancel
        </Button>
        <Button
          variant="info"
          // onClick={handleDeletedforce}
          disabled
          style={{ color: "white" }}
        >
          Delete Anyway
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Displaydataforce = ({ data, color }: any) => {
  return (
    <>
      This{" "}
      {color === "Color"
        ? "Color"
        : color === "NumberSystem"
        ? "Number System"
        : color !== ""
        ? color?.slice(0, 1)?.toUpperCase() + color.slice(1)
        : "Department"}{" "}
      Is Used as
      {data && (
        <ul>
          {data?.map((useddata: any) => {
            return (
              // <li>{useddata?.field!="NumberSystem"?useddata?.field:"All Code"} - <a href={`edit${useddata?.model}/${useddata?.id}`} target='blank'>View</a> </li>
              <li>
                {useddata?.field != "NumberSystem"
                  ? useddata?.field
                  : "All Code"}{" "}
                -{" "}
                <a
                  href={`${process.env.REACT_APP_FRONT_URL}edit${useddata?.model}/${useddata?.id}`}
                  target="blank"
                >
                  View
                </a>{" "}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};
