import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'

interface InputField {
    type?: any,
    className?: any,
    isReset?: boolean,
    Submit?: () => void,
    Reset?: () => void,
    Cancel?: () => void;
}

const SubmitField = ({ type, className, Submit, Reset, Cancel, isReset = true  }: InputField) => {
    return (
        <div className={className ? className : "row d-flex"}>
            <div className={className ? className : " col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2"}>
                <div className={className ? className : "row d-flex "}>
                    <div className={className ? className : "col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center "}>
                        <button type='submit' className={className ? className : "border-1 rounded text-white px-4 py-1 mt-2 mx-3"} style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white", }} onChange={Submit}>
                            Submit
                        </button>
                        {isReset &&
                            <button type='reset' className={className ? className : " border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"} style={{ borderColor: "blue", background: "blue", color: "white", }} onClick={Reset}>
                                Reset
                            </button>}

                        <button type='button' className={className ? className : " border-1 rounded  px-4 py-1 mx-3 mt-2"} style={{ borderColor: "red", background: "red", color: "white", }} onClick={Cancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubmitField;