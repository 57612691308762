import { useMutation } from "react-query";
import Axiosinstance from "./Interceptor";

interface datamultidelete {
    deleteid:number|string|any
    apiname: string|undefined

}

const deletesingledata = (apiname: string|undefined, deleteid: any) => {
    return Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apiname}/${deleteid}/`)
}

export const useDelete = () => {
    return useMutation((input: datamultidelete) => {

       return  deletesingledata(input.apiname, input.deleteid)
    }

    )
}
export const useDeletemforce = () => {
    return useMutation((input: datamultidelete) => {
        return deletemultipledataforce(input.apiname,  input.deleteid )
    }

    )
}

const deletemultipledataforce = (apiname: string | undefined, deleteid: any) => {
     return Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apiname}/${deleteid}/?force=true`)
}