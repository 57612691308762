import axios from 'axios'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'



// export const Authguard = ({component:component, ...rest}:any) => {
//   const token = localStorage.getItem('token')
//   return !token? <Outlet/>:<Navigate to="/salesDashboard"/>
// }

// export const LoginAuth = ({component:component, ...rest}:any) => {
//   const token = localStorage.getItem('token')
//   return !token? <Outlet/>:<Navigate to="/salesDashboard"/>
// }

export const Authguard = ({component:component, ...rest}:any) => {
  const token = localStorage.getItem('token')

  const data = {
    token:token
  }
  
  if(!token){
    return <Outlet/>
  }


  const varifyapicall=async ()=>{
    try {
      const apicall = await axios.post(`${process.env.REACT_APP_BASE_URL}api/token/verify/`, data)
      // return true
      return true
    } catch (error) {
      // localStorage.clear()
      return false    
    }
  }

  return !token? <Outlet/>: <Navigate to="/salesDashboard"/>
}