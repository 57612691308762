import { DatePicker } from 'antd'
import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'

interface InputField {
  id?: any,
  name?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?: any
  message?: any
  value?: any,
  label?: Path<any>,
  register?: any,
  isRequired?: boolean,
  hrmserrors?:any;
  isDisabled?:any;
  defaultValue?:any;
  isShowTime?:boolean,
  onChange?: (e: any,dateString:any) => void,
}

const Date_Picker = ({ id, name, type, className, label, color, isPencil = false, register, isRequired = true, message, hrmserrors , value ,isDisabled, onChange,defaultValue,isShowTime = false }: InputField) => {
  


  return (
    <div className={className ? className :"col-sm-3 mt-2"}>
      <label htmlFor="manualcode_Disc_1">{label} {isRequired && <span style={{ color: 'red' }}>*</span>}</label>
      <div className={className ? className :"input-group" }>


        <DatePicker
          className={className ? className :"form-control"}
          {...register(name, { required: { value: isRequired, message: message } })}
          format={isShowTime?'DD-MM-YYYY HH:mm':'DD-MM-YYYY'}
          value={value}
          defaultValue={defaultValue}
          disabledDate={isDisabled}
          onChange={onChange}
           showTime={isShowTime} 
          
        />



      </div>
      {isRequired &&
        <span className={className ? className :"text-red w-100"}>{hrmserrors}</span>
      }

    </div>
  )
}

export default Date_Picker