import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'
import CreatableSelect from "react-select/creatable";
import IndicatorsContainerCreate from '../../Select Dropdown/Creatabel'

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface InputField {
    id?: any,
    name?: any,
    type?: any,
    accept?: any,
    className?: any,
    placeholder?: any,
    htmlFor?: any,
    color?: any
    isPencil?: any
    message?: any
    value?: any,
    label?: Path<any>,
    register?: any,
    isRequired?: boolean,
    errors?: any;
    options?: any;
    isDisabled?: any
    isModal?: boolean
    onChange?: (e: any) => void,
    onCreate?: (e: any) => void,
    modal?: () => void,
    Cancel?: () => void;
    createdValue?:any;
    selectedValue?:any;
    isEdit?:boolean;
    // isMulti?:boolean
  }

const Createtable = ({ id, name, type, className, label, options, register, isRequired = true, message, errors, value, createdValue,selectedValue,isEdit = false, onChange, modal, isModal = true, onCreate }: InputField) => {
  return (
    
    <div className={className ? className :"col-sm-3 mt-2"}>
    <label htmlFor="exampleInputEmail1">{label} {isRequired && <span style={{ color: 'red' }}>*</span>}</label>
    <CreatableSelect
     
     {...register(name, { required: { value: isRequired, message: message } })}
        placeholder={'Select Mould Usage'}
        options={options}
        onCreateOption={onCreate}
        // onMenuOpen={() => mouldShapeRefetch()}
        components={customCreatableIndicator}
        onChange={onChange}
        value={
          isEdit ?
          createdValue==null?value==null?options?.filter((obj:any) => obj.value === selectedValue):value:createdValue: 
          createdValue == null ? value : createdValue}
        // value={valueCavityCreatable == null ? valueCavity : valueCavityCreatable}

    />
   {isRequired &&
        <span className={className ? className : "text-red w-100"}>{errors}</span>
      }

</div>
  )
}

export default Createtable