import React, { useEffect } from 'react';
import 'antd/dist/reset.css';
import Login from './pages/login';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PermissionProvider from './Roles/PermissionProvider';
import { setError, setPermissions } from './redux/permissions';
import Axiosinstance from './hooks/Interceptor';
import { Authguard } from './Routes/Authguard';
import OnlineInquiry from './pages/Sales_Marketing/Online Inquiry/ContactForm';
import Colorlayout from './layouts/Colorlayout';
import PrivateRoute from './Routes/privateRoute';
import axios from 'axios';
import Dashboard_layout from './layouts/DashBoard_layout';
import Header from './components/Header/header';
import Sales_Dashboard from './pages/Sales_Dashboard/sales_Dashboard';
import Sidebar from './components/Sidebar/sidebar';
import Footer from './components/Footer/footer';
import Marketing_Dashboard from './pages/Marketing_Dashboard/marketing_Dashboard';
import Quotation_Accept from './pages/Quotation_Accept/Quotation_Accept';
import Antd_Sidebar from './components/Sidebar/Antd_Sidebar';
import Antd_Header from './components/Header/Antd_header';
import PageNotFound from './pages/PageNotFound';


const queryClient = new QueryClient()
const App: React.FC = () => {

  const permissions = useSelector((state: any) => state.permissions.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    let token = localStorage.getItem("token")
    axios.get(`${process.env.REACT_APP_BASE_URL}leoUser/user/get_related_permissions`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((data: any) => {
        dispatch(setPermissions(data?.data?.results))
      }).catch((error: any) => {
        dispatch(setError("Error"))
      })

  }, [])





  return (
    <>

  

      <QueryClientProvider client={queryClient} contextSharing={true}>
        <BrowserRouter>
          <Routes>

            <Route path='/addOnlinesalesinquiry' element={<OnlineInquiry />} />

            <Route path='quotationAccepted' element={
              <Quotation_Accept />
            } />

            <Route element={<Authguard />}>



              <Route path='/login' element={<Login />} />
            </Route>



            {/* <Route element={<PrivateRoute element={<Dashboard_layout /> } />}>
                <Route path='/*' element={
                        <PermissionProvider permissions={permissions}>
                          <Header isDashboard={false}/>
                          <Dashboard_layout />
                        </PermissionProvider>
                        
                      } />

              </Route> */}


            <Route element={<PrivateRoute element={<Sales_Dashboard />} />}>
              <Route path='/salesDashboard' element={
                <PermissionProvider permissions={permissions}>
                  <Header isDashboard={true} />
                  {/* <Antd_Header/> */}
                  <Sales_Dashboard />
                  {/* <Sidebar /> */}
                  <Antd_Sidebar />
                  <Footer />

                </PermissionProvider>

              } />

            </Route>


            <Route element={<PrivateRoute element={<Marketing_Dashboard />} />}>
              <Route path='/marketingDashboard' element={
                <PermissionProvider permissions={permissions}>
                  <Header isDashboard={true} />
                  {/* <Antd_Header/> */}
                  <Marketing_Dashboard />
                  {/* <Sidebar /> */}
                  <Antd_Sidebar />
                  <Footer />
                </PermissionProvider>

              } />

            </Route>



            {/* <Route element={<PrivateRoute element={<Antd_Header/>} />}> */}
            <Route element={<PrivateRoute element={<Colorlayout />} />}>
              <Route path='/*' element={
                <PermissionProvider permissions={permissions}>

                  <Colorlayout />
                  {/* <Antd_Header/> */}
                </PermissionProvider>

              } />

            </Route>

            {/* 
            <Route element={<PrivateRoute element={<HRMS />} />}>
              <Route path='/*' element={
                // <PermissionProvider permissions={permissions}>

                <HRMS />
                // </PermissionProvider>

              } />

            </Route> */}







<Route path='*' element={
                    <PageNotFound />} />
            
          </Routes>

        </BrowserRouter>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>,
      {/* <Routes>
     
      <Login/>
      </Routes> */}
    </>

  );
}

export default App;