import DatePicker from 'antd/es/date-picker';
import React, { useCallback, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import Select from "react-select"
import { useFetchRawMaterialGardeDropDown, useFetchEmployeeDropDown, useFetchMachineDropDown, useFetchSheetCodeNameDropDown } from '../../../hooks/DropDown_Api';
import { RxCross2 } from "react-icons/rx";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from "react-router-dom";
import { QueryClient, InvalidateQueryFilters } from 'react-query';

import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import { useCreate, useFetchSingle, useUpdateSingle } from '../../../hooks/Api';
import { useQueryClient } from 'react-query';
import Axiosinstance from '../../../hooks/Interceptor';
function getSteps() {
    return ['Step 1', 'Step 2'];
}


const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}




const Duplicate_Granual = ({ onOpen, setOnOpen, setNewAdded, rowData }: any) => {
    const queryClient = useQueryClient();
    const [openModel, setOpenModel] = useState(true)
    const [openModelSubmit, setOpenModelSubmit] = useState(false)
    const navigate = useNavigate()

    const [optionsProductCode, setOptionsProductCode]: any = useState([])
    const [forProductCodeValue, setForProductCodeValue]: any = useState('')
    const [ProductQty, setProductQty]: any = useState('')

    const apiname = process.env.REACT_APP_PRODUCTION_PRODUCTION_LINE_API?.toString()

    const { mutate: Granual, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    // const { data } = useFetchSingle(rowData?.id, apiname)
    // const { mutate:Granual, isSuccess, isError, error, data: gstUpdated_response, isLoading }: any = useUpdateSingle()


    const { register, handleSubmit, setError, reset, setValue, getValues, control, trigger, formState: { errors } }: any = useForm()
    const apinameGrade = process.env.REACT_APP_PRODUCTION_SHEET_RAW_MATERIAL_BULK_API?.toString()


    const { mutate: mutateGrade, data: dataGrade, isError: isErrorGrade, isLoading: isLoadingGrade, isSuccess: isSuccessGrade, error: errorGrade }: any = useCreate(apinameGrade)
    // const { register: registerGrade, handleSubmit:handleSubmitGrade, setError:setErrorGrade, formState:formStateGrade, reset:resetGrade, setValue:setValueGrade }: any = useForm();


    useEffect(() => {
        if (rowData) {
            reset(rowData)
            setTotalKg(rowData?.raw_material_betch_total_kg)
            setAddToForm(rowData?.sheet_raw_material)
            setValue("opretar", rowData?.opretar)
            setValue("helper", rowData?.helper)
            setValue("machine", rowData?.machine)
            setValue("sheet", rowData?.sheet)
            setValue("sheet", rowData?.sheet)
            setValue("sheet_raw_material", rowData?.sheet_raw_material?.map((e: any) => { e.raw_material }))
        }
    }, [rowData])


    const [employeeOptions, setEmployeeOptions]: any = useState([])
    const [HelperOptions, setHelperOptions]: any = useState([])

    // const [forOperatorValue, setForOperator]: any = useState("")
    // const [forHelperValue, setForHelper]: any = useState([])
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()



    useEffect(() => {
        setEmployeeOptions(EmployeeData)
        setHelperOptions(EmployeeData)
    }, [EmployeeData])


    // const handleChangeOperator = (e: any) => {

    //     setForOperator(e)
    //     setValue("opretar", e?.value)
    // }
    // const handleChangeHelper = (e: any) => {
    //     setForHelper(e)
    //     const helper = e?.map((e: any) => {
    //         return e.value
    //     })
    //     setValue("helper", helper)
    // }



    // const [MachineOptions, setMachineOptions]: any = useState([])
    // const [MachineValue, setMachineValue]: any = useState('')

    // const { data: MachineData, refetch: MachineRefetch } = useFetchMachineDropDown()

    // useEffect(() => {
    //     setMachineOptions(MachineData)
    // }, [EmployeeData])

    // const handleChangeMachine = (e: any) => {

    //     setMachineValue(e)
    //     setValue("machine", e?.value)
    // }

    // const [SheetCodeOptions, setSheetCodeOptions]: any = useState([])
    // const [SheetCodeValue, setSheetCodeValue]: any = useState('')
    // const [Grade, SetGrade] = useState('')
    // const [Color, SetColor] = useState('')
    // const [AllProduct, SetAllProduct] = useState('')

    // const { data: SheetCodeData, refetch: SheetCodeRefetch } = useFetchSheetCodeNameDropDown()

    // useEffect(() => {
    //     setSheetCodeOptions(SheetCodeData)
    // }, [SheetCodeData])

    // const handleChangeSheetCode = (e: any) => {
    //     SetGrade(e.grade)
    //     SetColor(e.color)
    //     SetAllProduct(e.allproduct)
    //     setSheetCodeValue(e)
    //     setValue("sheet", e?.value)
    // }

    // const { data: ProductCodeData, refetch: ProductCodeRefetch } = useFetchRawMaterialGardeDropDown()

    // useEffect(() => {
    //     setOptionsProductCode(ProductCodeData)
    // }, [ProductCodeData])

    // const handleChangeProductCode = (e: any) => {
    //     setForProductCodeValue(e)
    //     setValue('sheet_raw_material_temp', e.value)
    // }

    const [addToForm, setAddToForm]: any = useState([])

    // console.log(addToForm, "addToForm");


    const handleAdd = (e: any) => {
        e.preventDefault()

        let tempAdd = {
            id: forProductCodeValue?.value,
            label: forProductCodeValue?.label,
            kg: 0,
            type: 'text'
        }


        setAddToForm((oldFields: any) => [...oldFields, tempAdd])

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listGranual', { state: { showToast: true } })
            setOpenModel(false)
            setOnOpen(false)
            // setNewAdded(true)
            queryClient.invalidateQueries(['Fetch']);
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleFinalSubmit = async () => {
        let finalData = addToForm?.map((e: any) => {

            return {
                raw_material: e.raw_material,
                kg: parseFloat(e.kg).toFixed(3),
            }
        });
        const isFormValid = await trigger();
        if (isFormValid) {
            mutateGrade({ data: finalData })
        }

    }
    const [materialData,setMaterialData]:any = useState({})

    useEffect(() => {
        const goToCall = async () => {
            if (isSuccessGrade && dataGrade) {
                console.log('datagrade', dataGrade)
                // debugger
                let sheet_raw_material = dataGrade?.data?.map((e: any) => {
                    return e.id
                })

                // await setActiveStep(activeStep - 1)

                let final:any = { sheet_raw_material, raw_material_betch_total_kg: parseFloat(TotalKg)?.toFixed(3) }
                // await Granual(final)
                setMaterialData(final)

                setOpenModelSubmit(true)
                // setOpenModel(false)

            }
        }
        goToCall()
    }, [isSuccessGrade, dataGrade])

    const handleRemove = (data: any) => {
        // debugger
        console.log('data', data)
        setAddToForm((oldData: any) => oldData?.filter((e: any) => e.id !== data.id))
        calculateTotalSum(addToForm);
    }

    const [TotalKg, setTotalKg]: any = useState(0)

    const calculateTotalSum = (data: any) => {
        const sum = data.reduce((acc: any, item: any) => acc + (parseFloat(item.kg) || 0), 0);
        setTotalKg(sum);
        setValue("raw_material_betch_total_kg", sum)
    };

    const handleChangeKg = useCallback((e: any, data: any, index: any) => {
        const tempData = [...addToForm];
        tempData[index].kg = parseFloat(e.target.value);
        setAddToForm(tempData);
        calculateTotalSum(tempData);
    }, [addToForm]);




    const [oneData, setOneData]: any = useState()




    const convertFormDataToRawData = (formData: any) => {
        const rawData: any = {};
        formData.forEach((value: any, key: any) => {
            const isNumber = !isNaN(value) && value.trim() !== '';
            if (key === 'helper') {
                if (!rawData.helper) {
                    rawData.helper = [];
                }
                if (isNumber && !rawData.helper.includes(Number(value))) {
                    rawData.helper.push(Number(value));
                }
            } else {
                if (rawData.hasOwnProperty(key) && key === 'helper') {
                    if (!Array.isArray(rawData[key])) {
                        rawData[key] = [rawData[key]];
                    }
                    if (isNumber && !rawData[key].includes(Number(value))) {
                        rawData[key].push(Number(value));
                    }
                } else {
                    rawData[key] = isNumber ? Number(value) : value;
                }
            }
        });

        return rawData;
    };


    const onSubmit = async (granual: any) => {
        delete granual.code
        delete granual.created_at
        delete granual.created_by
        delete granual.extra_response
        delete granual.updated_at
        delete granual.updated_by

        granual.sheet_raw_material= materialData.sheet_raw_material
        granual.raw_material_betch_total_kg= materialData.raw_material_betch_total_kg

        // const formData = new FormData();
        // for (const [key, value] of Object.entries(granual)) {
        //     let formvalue: any = value
        //     if (typeof value !== "object") {
        //         formData.append(key, formvalue)
        //     }
        //     else if (Array.isArray(value) && key === "helper") {
        //         value.forEach((val, index) => formData.append(`helper`, val))
        //     }
        //     else if (Array.isArray(value) && key === "sheet_raw_material") {
        //         value.forEach((val, index) => formData.append(`sheet_raw_material`, val))
        //     }
        // }
        // const rawData = convertFormDataToRawData(formData);
         Granual(granual)
    };


//     const apinameMouldShape = process.env.REACT_APP_MOULD_SHAPE_API?.toString()
// const apinameMould = process.env.REACT_APP_MOULD_API?.toString()
// // const [dataMouldShape,setDataMouldShape]:any = useState([])
// const apinameProductCode = process.env.REACT_APP_DISPOSABLE_PRODUCT_API?.toString()
// // const [dataProductCode,setDataProductCode]:any = useState([])
//     useEffect(()=>{
//         if(data?.mould_shape.length>0){
//         const getCall = async()=>{
//         try {
//             const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameMouldShape}/fetch_multiple_ids/`, {
//               ids: data?.mould_shape
//             });
//             // console.log('Response:', response?.data?.data);
//             // setDataMouldShape(response.data?.data)
//             setSelectedMouldShape(response.data?.data.map((e:any)=>e?.id))
//             setValue('mould_shape',response.data?.data.map((e:any)=>e?.id))
//           } catch (error) {
//             console.error('Error:', error);
//           }
//         }
//         getCall()
//     }
//     if(data?.use_product_code.length>0){
//         const getCall = async()=>{
//         try {
//             const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apinameProductCode}/fetch_multiple_ids/`, {
//               ids: data?.use_product_code
//             });
//             // console.log('Response:', response?.data?.data);
//             // setDataProductCode(response.data?.data)
//             setSelectedProductCode(response.data?.data?.map((e:any)=>e?.id))
//             setValue('use_product_code',response.data?.data?.map((e:any)=>e?.id))
//           } catch (error) {
//             console.error('Error:', error);
//           }
//         }
//         getCall()
//     }
//     if(data?.mould_name_and_type){
//         const getCall = async()=>{
//         try {
//             const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameMould}/${data?.mould_name_and_type}`);
//             // console.log('Response:', response?.data?.data);
//             // setDataProductCode(response.data?.data)
//             setSelectedHSN(response.data?.data?.id)
//             setValue('mould_name_and_type',response.data?.data?.id)
//           } catch (error) {
//             console.error('Error:', error);
//           }
//         }
//         getCall()
//     }
//     },[data])




  



    const [activeStep, setActiveStep]: any = useState(0);
    const steps = getSteps();

    const handleNext = async () => {
        const isFormValid = await trigger();

        if (isFormValid) {


            setActiveStep((prevActiveStep: any) => {
                if (prevActiveStep === 1) {
                    return prevActiveStep;
                }
                return prevActiveStep + 1;
            });

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    };




    return (
        <div>

            <Modal show={openModelSubmit} onHide={() => {
                setOpenModelSubmit(false)
                // setOnOpen(false)
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        Are You Sure You Want To Genrate Duplicate Batch!
                </Modal.Body>
                <Modal.Footer>
                <Button className='border-0' onClick={handleSubmit(onSubmit)}>Submitt</Button>

                </Modal.Footer>

            </Modal>

            <Modal
                size="xl"
                show={openModel}
                onHide={() => {
                    setOpenModel(false)
                    setOnOpen(false)
                }}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title>Production Line </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper> */}
                    <div>
                    {/* <div className="row">
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Operator Code & Name <span style={{ color: 'red' }}>*</span></label>
                                            <div className="">
                                                <Select
                                                    placeholder="Operator Code & Name"
                                                    {...register("opretar", { required: { value: true, message: "Operator Code & Name is required" } })}
                                                    options={employeeOptions}
                                                    // onChange={handleChangeOperator}
                                                    // value={forOperatorValue}
                                                    components={customIndicator}
                                                    maxMenuHeight={150}
                                                    isDisabled

                                                />

                                            </div>
                                            <span className='text-red w-100'>{errors?.opretar?.message}</span>

                                        </div>


                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Helper Code & Name <span style={{ color: 'red' }}>*</span></label>
                                            <div className="">
                                                <Select
                                                    {...register("helper", { required: { value: true, message: "Helper Code & Name is required" } })}
                                                    placeholder="Helper Code & Name"
                                                    options={HelperOptions}
                                                    // onChange={handleChangeHelper}
                                                    // value={forHelperValue}
                                                    maxMenuHeight={150}
                                                    isMulti
                                                    components={customIndicator}
                                                    isDisabled

                                                />


                                            </div>
                                            <span className='text-red w-100'>{errors?.helper?.message}</span>

                                        </div>


                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Machine Code & Name <span style={{ color: 'red' }}>*</span></label>
                                            <div className="">
                                                <Select
                                                    placeholder={'Machine Code & Name'}
                                                    {...register("machine", { required: { value: true, message: "Machine Code & Name is required" } })}
                                                    // value={MachineValue}
                                                    // options={MachineOptions}
                                                    components={customIndicator}
                                                    // onChange={handleChangeMachine}
                                                    maxMenuHeight={150}
                                                    isDisabled
                                                />

                                            </div>
                                            <span className='text-red w-100'>{errors?.machine?.message}</span>

                                        </div>



                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Sheet Code, Grade, Name & Color <span style={{ color: 'red' }}>*</span></label>
                                            <div className="">
                                                <Select
                                                    {...register("sheet", { required: { value: true, message: "Sheet Code, Grade, Name & Color is required" } })}
                                                    // options={SheetCodeOptions}
                                                    components={customIndicator}

                                                    // value={SheetCodeValue}
                                                    // onChange={handleChangeSheetCode}
                                                    maxMenuHeight={150}
                                                    isDisabled
                                                />

                                            </div>
                                            <span className='text-red w-100'>{errors?.sheet?.message}</span>

                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label >Shift <span style={{ color: 'red' }}>*</span></label>
                                            <div className="input-group" >
                                                <select className="form-select" disabled
                                                    {...register("shift_time", { required: { value: true, message: "Shift is required" } })}
                                                >
                                                    <option value={""} selected>Select Any</option>
                                                    <option value={"Day"}>Day</option>
                                                    <option value={"Night"}>Night</option>
                                                </select>
                                            </div>
                                            <span className='text-red w-100'>{errors?.shift_time?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Material Grade Name </label>
                                            <div className="d-flex row" >
                                            </div>
                                            <input type="text" className="form-control" disabled
                                                // value={Grade}
                                                placeholder="Material Grade Name"
                                            />
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Material Color </label>
                                            <div className="d-flex row" >
                                            </div>
                                            <input type="text" className="form-control" disabled
                                                // value={Color}
                                                placeholder="Material Color"
                                            />
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">All Single Code & Name & Type </label>
                                            <div className="d-flex row" >
                                            </div>
                                            <input type="text" className="form-control" disabled

                                                placeholder="All Single Code & Name & Type"
                                            />
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">All Product Code & Name & Type </label>
                                            <div className="d-flex row" >
                                            </div>
                                            <input type="text" className="form-control" disabled 
                                            // value={AllProduct}

                                                placeholder="All Product Code & Name & Type"
                                            />
                                        </div>
                                    </div> */}
                    <div className="row">
                        <div className="col-sm-3 mt-2">
                            <label htmlFor="exampleInputEmail1">Raw Material Batch Total Kg. <span style={{ color: 'red' }}>*</span></label>
                            <div className="d-flex row" >
                            </div>
                            <input type="text" className="form-control" disabled
                                label="Step 2 Field"
                                value={TotalKg}

                                placeholder="Raw Material Batch Total Kg."
                                {...register("raw_material_betch_total_kg", { required: { value: false, message: "Raw Material Batch Total Kg. is required" } })}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        {addToForm?.map((data: any, index: any) => {
                            return (
                                <div className="col-sm-3 mt-2" key={data?.raw_material}>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <label htmlFor="exampleInputEmail1">Material-{data.extra_response?.raw_material?.material_grade_code} {data.extra_response?.raw_material?.grade} {data.extra_response?.raw_material?.material_type_name} Kg.<span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        {/* <div>
                                            <RxCross2 onClick={() => handleRemove(data)} />
                                        </div> */}
                                    </div>
                                    <div className=" input-group" >
                                        <input
                                            className="form-control"
                                            type='number'
                                            placeholder={data.label}
                                            value={data?.kg}
                                            onChange={(e) => handleChangeKg(e, data, index)}
                                        />

                                    </div>

                                </div>

                            )
                        })}
                    </div>

                    <div>
                        {activeStep === steps.length ? (
                            <div>

                            </div>
                        ) :
                            (
                                <div>
                                    {/* <Typography>{getStepContent(activeStep)}</Typography> */}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        {/* <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            >
                                            Back
                                        </Button> */}
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button className='border-0' onClick={handleFinalSubmit}>Submit</Button>
                                        {/* <Button onClick={handleNext}>
                                            {activeStep === steps.length - 1 ? <>
                                            </> : 
                                            <Button className='border-0' onClick={handleSubmit(onSubmit)}>Submitt</Button>}
                                        </Button> */}
                                    </Box>
                                </div>
                            )}
                    </div>
                    </div>

                </Modal.Body>
            </Modal>



        </div>
    )
}

export default Duplicate_Granual