import React, { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { useNavigate } from "react-router-dom";
import { useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import { useForm } from "react-hook-form";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Modal from "react-bootstrap/Modal";
import Add_Sales_Modal from "./Add_Sales_Modal";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import { Columns_Sales_Document, titleofheaders } from "./Columns_Sales_Document";
import Edit_Sales_Modal from "./Edit_Sales_Modal";
import Axiosinstance from "../../../hooks/Interceptor";


const Edit_Sales_Document = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Document Detail"
    const [id, setId] = useState()


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;
    const tempData = addData?.document


    const [isNew, setIsNew] = useState(false)

    const [data, setData]: any = useState(tempData);

    const [isEdited, setIsEdited]: any = useState(false)

    const [render, setRender] = useState(0)

    useEffect(() => {
        if (isNew || isEdited) {
            const rawData = localStorage.getItem('Add Data');
            const addData = rawData ? JSON.parse(rawData) : null;
            setData(addData?.document);

            setIsNew(false);
            setIsEdited(false)
        }
    }, [isNew, isEdited]);


    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    const [showOffcancasEdit, setShowOffcancasEdit] = useState(false);

    const handleCloseEdit = () => setShowOffcancasEdit(false);
    const handleShowEdit = (id: any) => {
        setId(id)
        setShowOffcancasEdit(true)
    };
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_DOCUMENT_DETAIL_API?.toString()

    const { mutate, isSuccess, isError, error, data: brandUpdated_response }: any = useUpdateSingle()
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, control }: any = useForm();
    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)





    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");

            setBasicDetailsInitiated(true);
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, brandUpdated_response, error,
        isSuccess,]);

    const [updateCompleted, setUpdateCompleted] = useState(false)

    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: basicIdTemp,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }




    const [ids, setIds] = useState([])

    const [ForEditIds, setForEditIds]: any = useState([])

    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    const prevDataRef = useRef();

    const [localIds, setLocalIds] = useState([])

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && ids?.length > 0 && isDataChanged) {
            reset(data);
            setMultipleIds(data)
            setForEditIds(data)
            prevDataRef.current = data;
        }
    }, [data, reset, ids]);




    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);
    const [addShowOffcancas, setAddShowOffcancas] = useState(false)


    const handleNext = () => {
        let tempId = {
            document: multipleIds
        }

        let tempData = {
            id: basicIdTemp,
            apiname: apinameForBasicDetails,
            data: tempId
        }
        BasicDetails(tempData);
        const currentPath = window.location.pathname;
        if (currentPath === '/addCustomerDetails') {
            setCurrentStep(currentStep + 1)
        };
    }

    const [getIdForEdit, setGetIdForEdit]: any = useState()

    const filter = {

        name: ""

    };

    const addButtonName = "Document Detail";
    const addButtonPageName = "salesDocument"
    const permissionClass = {
        front: "document",
        back: "documentdetail"
    }
    const stickyStart = 1;
    const stickyEnd = 2;

    const [activePage, setActivePage] = useState('')


    useEffect(() => {
        setTimeout(() => {
            setActivePage('Edit Sales')
        }, 500)

    }, [activePage]);

    const currentPath = window.location.pathname;



    const apinameDocument = process.env.REACT_APP_ADDRESS_API?.toString()
    useEffect(() => {
        if (data?.length > 0) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/fetch_multiple_ids/`, {
                        ids: data
                    });
                    console.log(response, "ResponseData:");
                    setIds(response?.data?.data)

                    reset(response?.data?.data)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
    }, [])


    return (
        <div>
            <div >
                <section className="">
                    <div className="">

                        <div className="card card-default">
                            <div className="row">
                                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name ? addData?.name : addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            <div className="row d-flex  ">
                                <div className="row d-flex ">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                        <button
                                            type="button" className="border-1 rounded text-white px-4 py-1"
                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                            onClick={() => setAddShowOffcancas(true)}

                                        >Add Document</button>

                                    </div>
                                </div>
                            </div>


                            <Add_Sales_Modal addShowOffcancas={addShowOffcancas} setAddShowOffcancas={setAddShowOffcancas} setAdded={setAdded} setForEditIds={setForEditIds} />

                            <Edit_Sales_Modal showOffcancasEdit={showOffcancasEdit} handleCloseEdit={handleCloseEdit} setShowOffcancasEdit={setShowOffcancasEdit} id={id} setIsEdited={setIsEdited} />


                            <Stepper_Table
                                key={render}
                                apiname={apiname}
                                filterFields={filter}
                                addButtonName={addButtonName}
                                stickyStart={stickyStart}
                                stickyEnd={stickyEnd}
                                TableColumns={Columns_Sales_Document}
                                addButtonPageName={addButtonPageName}
                                titleofheaders={titleofheaders}
                                permissionClass={permissionClass}
                                isMore={false}
                                isAddButton={false}
                                multipleIds={ForEditIds}
                                added={added}
                                setAdded={setAdded}
                                setShowOffcancas={setAddShowOffcancas}
                                localIds={localIds}
                                setGetIdForEdit={setGetIdForEdit}
                                setIsNew={setIsNew}
                                handleCloseEdit={handleCloseEdit}
                                handleShowEdit={handleShowEdit}
                                activePage={activePage}
                            />


                            <div className="row d-flex  ">
                                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className="row d-flex ">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                            <button
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                onClick={handleNext}
                                            >Next</button>
                                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                            {currentPath === '/addCustomerDetails' ?
                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <Footer /> */}
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered  style={{zIndex:'2000'}}  >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Edit_Sales_Document