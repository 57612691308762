import { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../Model/Model";
import { useForm } from 'react-hook-form';
import useFocusOnEnter from '../focus';
import { useNavigate } from 'react-router-dom';
import { useFetchMilestoneDropdown, useFetchTaskPriorityDropDown, useFetchTeamDropDown } from '../../hooks/DropDown_Api';
import IndicatorsContainer from '../Select Dropdown/Select'
import IndicatorsContainerCreate from '../Select Dropdown/Creatabel'
import ReactSelect from '../HRMS Components/Select/select';
import InputField from '../HRMS Components/Inpute/input';
import Date_Picker from '../HRMS Components/Date and Time/Date';
import TextArea from '../HRMS Components/Inpute/textarea';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

export const Task_Model = ({ refetchForm, open, setOpen, id, currentId }: any) => {


    let pageName = "Add Task"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_HRMS_TASK_API?.toString();

    const { mutate: TaskStatus, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();


    const [optionsMilestone, setOptionsMilestone]: any = useState([])
    const [Milestone, setMilestone]: any = useState('')

    const { data: MilestoneData, refetch: MilestoneRefetch } = useFetchMilestoneDropdown()

    useEffect(() => {
        setOptionsMilestone(MilestoneData)
    }, [MilestoneData])

    const handleChangeMilestone = (e: any) => {
        setMilestone(e)
        setValue("milestone", e?.value)
    }
    const [optionsPriority, setOptionsPriority]: any = useState([])
    const [Priority, setPriority]: any = useState('')

    const { data: PriorityData, refetch: PriorityRefetch } = useFetchTaskPriorityDropDown()

    useEffect(() => {
        setOptionsPriority(PriorityData)
    }, [PriorityData])

    const handleChangePriority = (e: any) => {
        setPriority(e)
        setValue("priority", e?.value)
    }

    const [optionsTeam, setOptionsTeam]: any = useState([])
    const [Team, setTeam]: any = useState('')

    const { data: TeamData, refetch: TeamRefetch } = useFetchTeamDropDown()

    useEffect(() => {
        setOptionsTeam(TeamData)
    }, [TeamData])

    const handleChangeTeam = (e: any) => {
        setTeam(e)
        setValue("project_team", e?.value)
    }

    const [OptionsMember, setOptionsMember]: any = useState([])
    const [Member, setMember]: any = useState([])

    useEffect(() => {
        const Member = Team?.Member?.map((e: any) => {
            return { value: e?.id, label: e?.username }
        })
        setOptionsMember(Member)
    }, [Team])

    const handleChangeMember = (e: any) => {
        const TeamMember = e?.map((value: any) => {
            return value.value
        })
        setValue("assign_to", TeamMember)
        setMember(e)
    }

    useEffect(() => {
        if (isSuccess) {
            setOpen(false)
            reset()
            setMember(null)
            setTeam(null)
            setPriority(null)
            setMilestone(null)
            setForStartDate(null)
            setForEndDate(null)
        }
        refetchForm()
    }, [isSuccess, data])

    const handleReset = () => {
        reset()
        setMember(null)
        setTeam(null)
        setPriority(null)
        setMilestone(null)
        setForStartDate(null)
        setForEndDate(null)
    }

    const onSubmit = (branddetail: any) => {

        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "assign_to") {
                value.forEach((val, index) => formData.append(`assign_to`, val))
                continue
            }
            else {
                formData.append(key, formvalue[0]);
            }
            if (typeof value === "object" && value !== null) {
                formData.append(key, "");
            }
            formData.append('project', id)
            formData.append('task_status', currentId)
        }
        TaskStatus(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const [forStartDate, setForStartDate]: any = useState()
    const [forEndDate, setForEndDate]: any = useState()

    const handleStartDate = (e: any) => {
        setValue("start_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
        setForStartDate(e)
    }

    const handleEndDate = (e: any) => {
        setValue("end_date", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
        setForEndDate(e)

    }
    // const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };


    return (
        <>
            <Modal
                style={{ zIndex: '1001' }}
                size='xl'

                show={open} onHide={() => {
                    setShow(false)
                    reset()
                    setOpen(false)
                }} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '16px' }}>{pageName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form
                        ref={focus}
                        onKeyUp={event => onEnterKey(event)}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-body" style={{ maxHeight: "80vh" }}>
                            {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <ReactSelect type='text' name='milestone' label='Milestone' message='Milestone is required' register={register} isRequired={true} errors={errors?.milestone?.message} options={optionsMilestone} onChange={(e) => handleChangeMilestone(e)} Selvalue={Milestone} />
                                        <ReactSelect type='text' name='priority' label='Priority' message='Priority is required' register={register} isRequired={true} errors={errors?.priority?.message} options={optionsPriority} onChange={(e) => handleChangePriority(e)} Selvalue={Priority} />
                                        <ReactSelect type='text' name='project_team' label='Team Select' message='Team Select is required' register={register} isRequired={true} errors={errors?.project_team?.message} options={optionsTeam} onChange={(e) => handleChangeTeam(e)} Selvalue={Team} />
                                        <ReactSelect type='text' name='assign_to' label='Team Member' message='Team Member is required' register={register} {...Team ? { isRequired: true } : { isRequired: false }} errors={errors?.assign_to?.message} isMulti isDisabled={!Team} options={OptionsMember} onChange={(e) => handleChangeMember(e)} Selvalue={Member} />
                                        <InputField type='text' name='name' label='Task Name' isPencil={true} message='Task Name is required' register={register} isRequired={true} hrmserrors={errors?.name?.message} />
                                        <Date_Picker name='start_date' label='Start Date' message='Start Date is required' register={register} isRequired={true} hrmserrors={errors?.start_date?.message} value={forStartDate} onChange={(e: any) => handleStartDate(e)} />
                                        <Date_Picker name='end_date' label='End Date' message='End Date is required' register={register} isRequired={true} hrmserrors={errors?.end_date?.message} value={forEndDate} onChange={(e: any) => handleEndDate(e)} />
                                        <TextArea name='description' label='Description' message='Description is request' register={register} isRequired={true} hrmserrors={errors?.description?.message} />
                                        <InputField type='number' name='completion_time' label='Completion Time' isPencil={true} message='Completion Time is required' register={register} isRequired={true} hrmserrors={errors?.completion_time?.message} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="info" className="text-white"
                        onClick={handleSubmit(onSubmit)}
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                    >
                        Submit
                    </Button>
                    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpen(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}



