import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'
import { RiPencilLine } from 'react-icons/ri'

interface InputField {
  id?: any,
  name?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?: any
  message?: any
  value?: any,
  label?: Path<any>,
  register?: any,
  isRequired?: boolean,
  hrmserrors?:any;
  isDisabled?:any
}

const TextArea = ({ id, name, type, className, label, color, isPencil = false, register, isRequired = true, message, hrmserrors , value ,isDisabled }: InputField) => {

  return (
    <div className={className ? className : "col-sm-3 mt-2"}>
      <label htmlFor="exampleInputEmail1">{label} {isRequired &&<span style={{ color: 'red' }}>*</span>}</label>
      <div className={className ? className : "input-group"} >
        <textarea className={className ? className : "form-control"} value={value} disabled={isDisabled}
          {...register(name, { required: { value: isRequired, message: message } })} placeholder={label} />
       
      </div>
      {isRequired &&
        <span className={className ? className :"text-red w-100"}>{hrmserrors}</span>
      }
    </div>
  )
}

export default TextArea