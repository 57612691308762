import { Result } from "antd";
import React from "react";
import {Link} from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={<Link to={'/salesDashboard'} className="btn btn-primary">Back Home</Link>}
                />
            </div>
        </div>
    </>
  );
};

export default PageNotFound;
