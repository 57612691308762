import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Axiosinstance from '../hooks/Interceptor'



export const useCreate = (apiname?:any) => useMutation(
    async (data: any) => {
        const postapicall = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/`, data)
        return postapicall.data
    },
    
    

    )

export const FetchAll=(FilterBy?:any ,search?:any, page?:any,pagesize?:any,orderBy?:any,apiname?:any,product?:any)=>{
    
    if (search?.includes("#")){
        search = search?.replace("#", "%23")
    }
    
    const isEmpty = Object?.values(FilterBy)?.every(x => x === null || x === '');
    // if(apiname === 'singleCode/singleCode'){
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${product}` )
    // }
    // if(apiname === "production/productionLine"){
    //     debugger
    //     const apinameForGranual = "production/productionLine";
    //     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForGranual}/search_filter/`)
    // }
    if(!isEmpty && apiname!= undefined){
        return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/search_filter/?page=${page}&page_size=${pagesize}`, FilterBy)
    }else if(isEmpty && apiname!= undefined){
        if(pagesize === "all"){
            return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/show_all/`)    
        }

        else if(orderBy &&  apiname!= undefined){

            return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/?search=${search}&page=${page}&page_size=${pagesize}&ordering=${orderBy}`)
        }
       
        else{
            return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}?search=${search}&page=${page}&page_size=${pagesize}` )
        }
       
    }
    // else if(apiname==="sales/dispatchOrder"){
    //     return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/search_filter/?page=${page}&page_size=${pagesize}`, {"is_dispatched":false})
    // }
    else if(apiname!= undefined){
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/`)
    }
    




   
}


export const fetchProductType = (FilterBy?:any ,search?:any, page?:any,pagesize?:any,orderBy?:any,apiname?:any,product?:any)=>{
    
   
    // if(apiname === 'singleCode/singleCode'){
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/get_by_productType/?type=${product}` )
    // }
}


export const useFetch = (FilterBy?:any ,search?:any,page?:any,pagesize?:any,orderBy?:any,apiname?:any,product?:any) => {

    return useQuery(['Fetch',page,FilterBy,search,pagesize,orderBy,apiname,product],()=>FetchAll(FilterBy,search,page,pagesize,orderBy,apiname,product),{
        
        refetchOnMount:true,
        refetchOnWindowFocus:false,
        keepPreviousData:true,
        
        select:(data:any)=>{
            const count = data.data.count
            const next = data.data.next
            const prev = data.data.previous
            const results = data?.data?.results.map((data:any)=>{
                return data
            })
            return {results, count, next, prev}
        },

        
    })
}


// const FetchSingleApi=(id?:any,apiname?:any,trigger?:any)=>{
//     return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`)
// }

const FetchSingleApi = (id?: any, apiname?: any, trigger?: any) => {
    if (!id || !apiname  ) {
        throw new Error("Both id and apiname must be provided.");
    }

    const url = `${process.env.REACT_APP_BASE_URL}${apiname}/${id}`;
    return Axiosinstance.get(url);
}

export const useFetchSingle = (id?:any,apiname?:any,trigger?:any) => {
    return useQuery(['Single',id,apiname,trigger],()=>FetchSingleApi(id,apiname,trigger),{
        select:(data:any)=>{
            const results = data.data?.data
            return results
        }
    })
}






// export const Update=async(input:any, status:any=false)=>{
//     if (status){
//         const apicall = await Axiosinstance.patch(`${process.env.REACT_APP_BASE_URL}${input.apiname}/${input.id}/?edit=yes`,input.data)
//         return await apicall.data    
//     }
//     const apicall = await Axiosinstance.patch(`${process.env.REACT_APP_BASE_URL}${input.apiname}/${input.id}/`,input.data)
//     return await apicall.data
// }

export const Update = async (input: any, status: any = false) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}${input.apiname}/${input.id}/`;
        const apiUrl = status ? `${url}?edit=yes` : url;
        const response = await Axiosinstance.patch(apiUrl, input.data);
        return response.data;
    } catch (error) {
        // Handle or throw the error
        console.error('API call error:', error);
        throw error;
    }
};

export const Updateforcewithyes=async(input:any,apiname?:any)=>{
    const apicall = await Axiosinstance.patch(`${process.env.REACT_APP_BASE_URL}${input.apiname}/${input.id}/?edit=yes`,input.data)
    return await apicall.data
}
export const Updateforcewithno=async(input:any,apiname?:any)=>{
    const apicall = await Axiosinstance.patch(`${process.env.REACT_APP_BASE_URL}${input.apiname}/${input.id}/?edit=no`,input.data)
    return await apicall.data
}
export const useUpdateSingle = (status?:any) => {
    return useMutation((input: any) => Update(input,status))
}

export const useUpdateSingleforcewithyes = () => {
    return useMutation((input: any) => Updateforcewithyes(input))
}
export const useUpdateSingleforcewithno = () => {
    return useMutation((input: any) => Updateforcewithno(input))
}






export const ExportExcelApi=(apiname?:any)=>{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apiname}/export_csv`)
}


export const UseExportExcel = () => {
    return useQuery('Export Excel',ExportExcelApi,{
        select:(data:any)=>{
                const excel = data.data
                return excel
            }
    })
}

export const Delete=(temp_data?:any,apiname?:any)=>{

    return Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/deletelist/`, temp_data);

}

// export const useDeleteMultipleColor = (temp_data?:any) => {
//     return useMutation((temp_data: any) => updateColor(temp_data))
// }



const FetchAllPermissions=(searchTerm?:any)=>{

    if(searchTerm){
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERMISSIONS_API}/fetch_all?searchTerm=${searchTerm}`)
    }else{
        return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PERMISSIONS_API}/fetch_all/`)
    }
}

export const useFetchAllPermissions = (searchTerm?:any) => {
    return useQuery(['permissions',searchTerm],()=>FetchAllPermissions(searchTerm),{
        refetchOnMount:true,
        refetchOnWindowFocus:false,
        keepPreviousData:true,
        select:(data:any)=>{
            return data.data?.results
        }
    })
}






