import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputField from '../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../hooks/Api';
import useFocusOnEnter from '../../components/focus';
import SubmitField from '../../components/HRMS Components/Button/Submit';
import { useFetchDepartmentDropDown } from '../../hooks/DropDown_Api';
import ReactSelect from '../../components/HRMS Components/Select/select';
import { Department_Model } from '../../components/Dynamic_Form_Models/Department_Model';
import Status from '../../components/HRMS Components/Select/Status';
import Createtable from '../../components/HRMS Components/Select/Createtable';
import TextArea from '../../components/HRMS Components/Inpute/textarea';
import Date from '../../components/HRMS Components/Date and Time/Date';
import Time from '../../components/HRMS Components/Date and Time/Time';
import ImageUplaod from '../../components/HRMS Components/Upload/Image';
import type { UploadFile } from 'antd/es/upload/interface';
import PdfUplaod from '../../components/HRMS Components/Upload/PDF';


const Test = () => {
  let pageName= "Department"

  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HR_DEPARTMENT_API?.toString();

  const {
    mutate: gst,
    data,
    isError,
    isLoading,
    isSuccess,
    error,
  }: any = useCreate(apiname);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  }: any = useForm();

  // useEffect(() => {
  //     if (data) {

  //         reset(data)
  //     }
  // }, [data]);

  const handleReset = () => {
    reset();
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (key === "image") {
        for (let i = 0; i < logo.length; i++) {

          formData.append('image', logo[i])
        }
        continue
      }
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [optionsDepartment, setOptionsDepartment]: any = useState();

  const { data: departmentData, refetch: departmentRefetch } = useFetchDepartmentDropDown();

  useEffect(() => {
    setOptionsDepartment(departmentData);
  }, [departmentData]);

  const [input, setInput]: any = useState();
  const [suggestion, setSuggestions]: any = useState([]);

  const handleChange = (e: any) => {
    // let value = e.target.value = e?.target?.value?.toLowerCase()
    // ?.split(' ')
    // ?.map((s:any) => s?.charAt(0)?.toUpperCase() + s?.substring(1))
    // ?.join(' ');
    let matches = [];
    if (e.length >= 1) {
      const regex = new RegExp(`${e}`, "gi");
      matches = optionsDepartment?.filter((item: any) => regex.test(item.label));
    }
    setSuggestions(matches);
    setInput(e);
    setValue('city', e?.value)
  };
  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)


  useEffect(() => {
    if (submitted) {
      setInput(optionsDepartment[0])

      setValue("machine_department", optionsDepartment[0]?.value)

    }

  }, [submitted, optionsDepartment])

  let options = [
    {
      label: 'Active', value: 'active'
    },
    {
      label: 'Inactive', value: 'inactive'
    }]


  const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
  const [logo, setlogo]: any = useState([])

  const FileOnChange = (e: any) => {
    e.file.status = "done"

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    })
    setlogo(tempFiles)
    setUploadFile(e.fileList)

  }


  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HR </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listDepartment" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <InputField name='department' label='Department' isPencil={true} message='Department is required' register={register} isRequired={false} hrmserrors={errors?.department?.message} />
                        <ReactSelect label='City' name='city' message='City is required' register={register} isRequired={false} isModal={true} errors={errors?.city?.message} options={optionsDepartment} onChange={(e) => handleChange(e)} Selvalue={input} modal={() => setOpen(true)} />
                        <Createtable label='State' name='state' message='State is required' register={register} isRequired={false} isModal={true} errors={errors?.state?.message} />
                        <TextArea name='unpaid_leave' label='Unpaid Leave' message='Unpaid Leave is required' register={register} isRequired={false} hrmserrors={errors?.unpaid_leave?.message} />
                        <Date name='unpaid_leave' label='Unpaid Leave' message='Unpaid Leave is required' register={register} isRequired={false} hrmserrors={errors?.unpaid_leave?.message} />
                        <Time name='unpaid_leave' label='Unpaid Leave' message='Unpaid Leave is required' register={register} isRequired={false} hrmserrors={errors?.unpaid_leave?.message} />
                        <Status label='Status' name='status' message='City is required' register={register} isRequired={false} errors={errors?.status?.message} options={options} />
                        <div className="row d-flex">
                          <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                            <div className="row d-flex ">
                              <ImageUplaod label='Image' name="image" message='Image is required' register={register} isRequired={false} isMulti={false} onChange={(e: any) => FileOnChange(e)} Selvalue={uploadFile} />
                              <PdfUplaod label='PDF' name="pdf" message='PDF is required' register={register} isRequired={false} isMulti={false} onChange={(e: any) => FileOnChange(e)} Selvalue={uploadFile} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listDepartment")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Department_Model refetchForm={departmentRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
    </div>
  )
}

export default Test