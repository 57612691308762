import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Columns_Bank_Accounts_Transaction, titleofheaders } from "./Columns_Bank_Accounts_Transaction"
import Table from "../../../components/Dynamic_Table/Table/Table";
const List_Bank_Transaction = () => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Accounts";

  const Breadcrumb_Title_Name = "Bank Accounts Transaction";

  const apiname = process.env.REACT_APP_ACCOUNTING_BANK_ACCOUNT_ENTRY_API?.toString();

  const filter = {

    department_code: "",
    department:"",
    created_by: "",
    updated_by: "",
    status:""
  };

  const addButtonName = "Bank Accounts Transaction";
  const addButtonPageName = "BankAccountsTransaction"
  const permissionClass = {
    front: "hrms",
    back: "department"
  }
  const stickyStart = 1;
  const stickyEnd = 2;

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_Bank_Accounts_Transaction}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders}
                  permissionClass={permissionClass}
                />
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Bank_Transaction;
