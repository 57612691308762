import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';
import Time from '../../../components/HRMS Components/Date and Time/Time';
import { useFetchResponsiblePerson } from '../../../hooks/DropDown_Api';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Axiosinstance from '../../../hooks/Interceptor';
import { Modal } from 'react-bootstrap';
import { Deletesinglewithdependancy } from '../../../components/modal';

const Edit_Attendance = () => {

  let pageName = "Attendance"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_ATTENDANCE_API?.toString();
  const { id } = useParams()
  const { data } = useFetchSingle(id, apiname)
  const [valuesofform,setValuesofform]=useState<any>()
  const [openEditModel, setOpenEditModel] = useState(false)
  const [expirationDate, setExpirationDate] = useState()
  const [confirmmodal,setConfirmmodal]=useState(false)

  const { mutate, isSuccess, isError, error, data: Updated_response }: any = useUpdateSingle();
  const { mutate:mutateyes, isSuccess:yessuccess, isError:yeserror, error:yeserrordetail, data: yesupdated_response }:any = useUpdateSingleforcewithyes()
  const { mutate:mutateno, isSuccess:nosuccess, isError:noerror, error:noerrordetail, data: noupdated_response }:any = useUpdateSingleforcewithno()
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [optionsUser, setOptionsUser]: any = useState([])
  const [User, setUser]: any = useState('')
  const [SelectedUser, setSelectedUser]: any = useState('')

  const { data: UserData, refetch: UserRefetch } = useFetchResponsiblePerson()

  useEffect(() => {
    setOptionsUser(UserData)
  }, [UserData])

  const handleChangeUser = (e: any) => {
    setUser(e)
    setValue("user", e?.value)
  }

  const apinameManager = process.env.REACT_APP_LEOUSER_API?.toString()
  useEffect(() => {
      if (data?.user) {
          const getCall = async () => {
              try {
                  const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameManager}/${data?.user}/`);
                  setSelectedUser(response?.data?.data?.id)
                  setValue("user",response?.data?.data?.id)
              } catch (error) {
                  console.error('Error:', error);
              }
          }
          getCall()
      }

  }, [data])

  useEffect(() => {
    if (data) {
      reset(data)
      setForStartDate(dayjs(data?.in_time, 'DD/MM/YYYY'))
      setForEndDate(dayjs(data?.out_time, 'DD/MM/YYYY'))
      setForOpenTimeValue(dayjs(data?.in_time?.split(" ")[1], 'HH:mm'))
      setForCloseTimeValue(dayjs(data?.out_time?.split(" ")[1], 'HH:mm'))
      

    }
  }, [data])

  const onSubmit = (branddetail: any) => {
    delete branddetail.punch_by
    delete branddetail.total_hour
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } 
    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData
    }
    mutate(data);
    setValuesofform(data)
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess||yessuccess||nosuccess) {
        setValuesofform(null)
        setShow(true)
        navigate('/listAnnouncement', { state: { showEditToast: true }})
    }
  
 }, [data, isSuccess,yessuccess,
    nosuccess,Updated_response])

    useEffect(()=>{
        if(isError)
        {
            if(error.response.status===405){
                setConfirmmodal(true)
            }
        }
        else if (isError||yeserror||noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    },[isError,yeserror,noerror])
useEffect(() => {
    setTimeout(() => {
        setShow(false)
    }, 3000)

}, [show]);

    const yesupdatemodal=()=>{
    setConfirmmodal(false);
    mutateyes(valuesofform)
    }
    const cancelupdatemodal=()=>{
       setConfirmmodal(false) ;
    }

    const noupdatemodal = () => {
      setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
      setExpirationDate(e.target.value)
      setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {


      let ok = {
          id: id,
          apiname: apiname,
          data: data,
      }

      mutateno(ok)
      setConfirmmodal(false);
      setOpenEditModel(false)
  }




  const [StartDate, setStartDate]: any = useState()
  const [EndDate, setEndDate]: any = useState()
  const [forStartDate, setForStartDate]: any = useState()
  const [forEndDate, setForEndDate]: any = useState()

  const [forOpenTimeValue, setForOpenTimeValue]: any = useState()
  const [forCloseTimeValue, setForCloseTimeValue]: any = useState()

  const[dateValue,setDateValue] = useState('')
  const [timeValue,setTimeValue] = useState('')
  const[EndDateValue,setEndDateValue] = useState('')
  const [EndTimeValue,setEndTimeValue] = useState('')



  const handleStartDate = (date:any,dateString:any) => {
    setValue("in_time", `${dateString} ${timeValue?timeValue:data?.in_time?.split(" ")[1]}`)
    setForStartDate(date)
    setDateValue(dateString)
  }
  const handleStartTime = (e: any,timeString:any) => {
    setValue("in_time", `${dateValue?dateValue:data?.in_time?.split(" ")[0]} ${timeString}`)
    setForOpenTimeValue(e)
    setTimeValue(timeString)
  }
  const handleEndTime = (date:any,dateString:any) => {
    // setValue("out_time", `${forEndDate?.$D}-${forEndDate?.$M + 1}-${forEndDate?.$y}` + " " + `${e?.$H}:${e?.$m}`)
    setValue("out_time", `${EndDateValue?EndDateValue:data?.out_time?.split(" ")[0]} ${dateString}`)
    setForCloseTimeValue(date)
    setEndDateValue(dateString)
  }

  const handleEndDate = (e: any,timeString:any) => {
    // setValue("out_time", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setValue("out_time", `${timeString} ${EndTimeValue?EndTimeValue:data?.out_time?.split(" ")[1]}`)
    setForEndDate(e)
    setEndTimeValue(timeString)

  }
  

  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };



  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listAttendance" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect type='text' name='user' label='User' isPencil={true} message='User is required' register={register} isRequired={true} isModal={false} errors={errors?.user?.message} options={optionsUser} onChange={(e) => handleChangeUser(e)} Selvalue={User} isEdit={true} editValue={SelectedUser} />
                        <Date_Picker name='in_time' label='Start Date' message='Start Date is required' register={register} isRequired={false} hrmserrors={errors?.start_date?.message} value={forStartDate} isDisabled={disabledDate} onChange={(e,dateString) => handleStartDate(e,dateString)} />
                        <Time name='in_time' label='Start Time' message='Start Time is required' register={register} isRequired={false} hrmserrors={errors?.start_time?.message} value={forOpenTimeValue} onChange={(e,timeString) => { handleStartTime(e,timeString) }} />
                        <Date_Picker name='out_time' label='End Date' message='End Date is required' register={register} isRequired={false} hrmserrors={errors?.out_time?.message} value={forEndDate} isDisabled={disabledDate} onChange={(e,dateString) => handleEndDate(e,dateString)} />
                        <Time name='out_time' label='End Time' message='End Time is required' register={register} isRequired={false} hrmserrors={errors?.out_time?.message} value={forCloseTimeValue} onChange={(e,timeString) => { handleEndTime(e,timeString) }} />
                        <InputField type='text' name='comment' label='Comment' isPencil={true} message='Comment is required' register={register} isRequired={false} hrmserrors={errors?.comment?.message} />
                        <InputField type='text' name='ip' label='IP Address' isPencil={true} message='IP Address is required' register={register} isRequired={false} hrmserrors={errors?.ip?.message} />
                        {/* <Status label='Status' name='status' message='City is required' register={register} isRequired={true} errors={errors?.status?.message} options={options} /> */}
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} isReset={false} Cancel={() => navigate("/listLeaveApplication")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
    </div>
  )
}

export default Edit_Attendance