import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import { useFetchHrmsAccountDropdown } from '../../../hooks/DropDown_Api';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
// import Date from '../../../components/HRMS Components/Date and Time/Date';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';
import Modal from 'react-bootstrap/esm/Modal';
import { Deletesinglewithdependancy } from '../../../components/modal';

const Edit_Cash_Transaction = () => {

  let pageName = "Cash Transaction"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ACCOUNTING_CASH_TRANSACTION_ENTRY_API?.toString();
  const { id } = useParams()
  const { data } = useFetchSingle(id, apiname)

  const [valuesofform, setValuesofform] = useState<any>()
  const [openEditModel, setOpenEditModel] = useState(false)
  const [expirationDate, setExpirationDate] = useState()
  const [confirmmodal, setConfirmmodal] = useState(false)

  const { mutate, isSuccess, isError, error, data: Updated_response, isLoading }: any = useUpdateSingle()
  const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
  const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [TransactionTypeValue, setTransactionTypeValue]: any = useState()
  const [SelTransactionTypeValue, setSelTransactionTypeValue]: any = useState()

  const TransactionType = [ 
    { label:'Cash Withdraw' ,value:'Cash Withdraw' },
    { label:'Cash Deposit' ,value:'Cash Deposit' }
  ]

  const handleTransactionType = (e:any) =>{
    setTransactionTypeValue(e)
    setValue("type",e?.value)
  }

  const [forDate, setForDate]: any = useState('')
  const handleDate = (date: any,dateString:any) => {
    
    setForDate(date)
    setValue("date_time",  dateString)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };

  useEffect(()=>{
    if(data){
      reset(data)
      setValue("in_amount"?'in_amount':'out_amount',data?.in_amount?data?.in_amount:data?.out_amount)
      setForDate(dayjs(data?.date_time, 'DD/MM/YYYY HH:mm'))
      setSelTransactionTypeValue(data?.type)
    }
  },[data])  

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } 
    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData
    }
    mutate(data);
    setValuesofform(data)
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess || yessuccess || nosuccess) {
      setValuesofform(null)
      setShow(true)
      navigate('/listCashTransaction', { state: { showEditToast: true } })
    }

  }, [data, isSuccess, yessuccess,
    nosuccess, Updated_response])

  useEffect(() => {
    if (isError) {
      if (error.response.status === 405) {
        setConfirmmodal(true)
      }
    }
    else if (isError || yeserror || noerror) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isError, yeserror, noerror])
  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }

  const noupdatemodal = () => {
    setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {


    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
  }

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listBankTransaction" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Transaction Type' name='type' message='Transaction Type is required' register={register} isRequired={true} isModal={false} isEdit={true} isMulti={false} errors={errors?.type?.message} options={TransactionType} Selvalue={TransactionTypeValue} onChange={(e)=>handleTransactionType(e)} editValue={SelTransactionTypeValue}/>
                        <Date_Picker name='date_time' label='Date Time' isPencil={true} message='Date is required' register={register} isRequired={true} hrmserrors={errors?.date_time?.message} isShowTime={true} value={forDate} isDisabled={disabledDate} onChange={(date, dateString) => handleDate(date, dateString)}/>
                        <InputField type='text' name='note' label='Transaction Note' isPencil={true} message='Transaction note is required' register={register} isRequired={false} hrmserrors={errors?.note?.message} />
                        <InputField type='number' name={TransactionTypeValue?.value === 'Cash Withdraw'?'out_amount':'in_amount'} label='Transaction Amount' isPencil={true} message='Transaction Amount is required' register={register} isRequired={true} hrmserrors={TransactionTypeValue==='Cash Withdraw'?errors?.out_amount?.message:errors?.in_amount?.message} />
                       
                      
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} isReset={false} Cancel={() => navigate("/listCashTransaction")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <label htmlFor="exampleInputEmail1">Choose Date</label>
                <input type="date" className="form-control" id="exampleInputBrand"

                  {...register("expiration_date", { onChange: handleExpirationDate })}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
            onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal>
      <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
        yesupdatemodal={yesupdatemodal}
        cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
    </div>
  )
}

export default Edit_Cash_Transaction