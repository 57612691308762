import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import { useFetchCityDropDown, useFetchCityPincodeDropDown, useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import { City_Model } from "../../../components/Dynamic_Form_Models/City_Model";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Employee_Model } from "../../../components/Dynamic_Form_Models/Employee_Model";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const Add_Branch_Office = () => {
    let pageName = "Branch Office"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_BRANCH_API?.toString()


    const { mutate: Branch, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;


    const [forCityValue, setForCityValue]: any = useState([])
    const [forCityPincodeValue, setForCityPincodeValue]: any = useState()
    const [setPincode, SetPincode]: any = useState()
    const [cityOptions, setCityOptions]: any = useState()
    const [openCity, setOpenCity]: any = useState(false)
    const [openEmployee, setOpenEmployee]: any = useState(false)
    const [openOwner, setOpenOwner]: any = useState(false)
    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    const [SubmittedEmployee, setSubmittedEmployee]: any = useState(false)
    const [SubmittedOwner, setSubmittedOwner]: any = useState(false)
    const [cityPincodeOptions, setCityPincodeOptions]: any = useState()

    const [byCity, setByCity] = useState()
    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        let temp = cityData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.label}`,
                    state: e.state,
                    country: e.country,
                    district: e.district,
                }
            )
        })
        setCityOptions(temp)
    }, [cityData])

    const [forPincode, setForPincode]:any = useState("")
    const [forPincodeId, setForPincodeId] = useState("")

    const [forState, setForState]: any = useState("")
    const [forCountry, setForCountry]: any = useState("")
    const [forDistrict, setForDistrict]: any = useState("")

    const handleChangeCity = (e: any) => {
        // 
        setForCityValue(e)
        setValue("city", e?.value)
        setForState(e?.state)
        setForDistrict(e?.district)
        setForCountry(e?.country)
        // setValue("state",e?.stateID)
        // setValue("country",e?.countryID)
        // setValue("district",e?.districtID)
        setByCity(e?.value)
        setForPincode(e?.label)
        setForPincodeId(e?.value)
        setForCityPincodeValue(null)
        setValue('pincode',null)

    }
    const { data: cityPincodeData, refetch: cityPincodeRefetch } = useFetchCityPincodeDropDown(byCity)


    useEffect(() => {
        setCityPincodeOptions(cityPincodeData)
    }, [cityPincodeData])

    const handleChangePincode = (e: any) => {
        setForCityPincodeValue(e)
        setValue("pincode", e?.value)
    }

    const [employeeOptions, setEmployeeOptions]: any = useState([])
    const [BunchemployeeOptions, setBunchemployeeOptions]: any = useState([])
    const [forEmployeeValue, setForEmployeeValue]: any = useState("")
    const [forResponsiblePersonValue, setForResponsiblePerson]: any = useState("")
    const [forResponsibleShethValue, setForResponsibleSheth]: any = useState("")
    const [totalEmployee, setTotalEmployee]: any = useState(0)
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(EmployeeData)
        setBunchemployeeOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        let employee = e?.map((e: any) => {
            return e?.value
        })
        setValue("staff_code_and_name", employee)
        setForEmployeeValue(e)
        setTotalEmployee(employee?.length)
        setValue("staff_qty", employee?.length)
    }
    const handleChangeResponsiblePerson = (e: any) => {

        setForResponsiblePerson(e)
        setValue("responsible_person", e?.value)
    }
    const handleChangeResponsibleSheth = (e: any) => {

        setForResponsibleSheth(e)
        setValue("sheth_responsible_person", e?.value)
    }


    const onSubmit = (branch: any) => {
        delete branch.staff_code_and_name
        delete branch.staff_qty
        const formData = new FormData();
        for (const [key, value] of Object.entries(branch)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            if (Array.isArray(value) && key === "staff_code_and_name") {

                value.forEach((val, index) => formData.append(`staff_code_and_name`, val))

            }
        }
        Branch(formData)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listBranch', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error, isSuccess])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 5000)

    }, [show]);


    const handleReset = () => {
        reset()
        setForCityValue(null)
        setForCityPincodeValue(null)
        setForDistrict(null)
        setForCountry(null)
        setForState(null)
        setForEmployeeValue(null)
        setTotalEmployee(null)
        setForResponsiblePerson(null)
        setForResponsibleSheth(null)
        setForPincode(null)
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    // const [forCityValue, setForCityValue]: any = useState([])

    useEffect(() => {
        if (SubmittedCity) {

            setForCityValue(cityOptions[0])
            setForPincode(cityOptions[0]?.value)
            setByCity(cityOptions[0]?.value)

            setValue("city", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])
    useEffect(() => {
        if (SubmittedEmployee) {

            setForResponsiblePerson(employeeOptions[0])


            setValue("responsible_person", employeeOptions[0]?.value)

        }

    }, [SubmittedEmployee, employeeOptions])
    useEffect(() => {
        if (SubmittedOwner) {

            setForResponsibleSheth(BunchemployeeOptions[0])


            setValue("sheth_responsible_person", BunchemployeeOptions[0]?.value)

        }

    }, [SubmittedOwner, BunchemployeeOptions])

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Branch Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Branch Name" {...register("name", { onChange: handleValue, required: { value: true, message: "Branch Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Branch City Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city", { required: { value: true, message: "Branch City Code & Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={forCityValue}
                                                            maxMenuHeight={130}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Branch City Pincode <span style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        {...register("pincode", { required: { value: true, message: "Branch City Pincode is required" } })}
                                                        options={cityPincodeOptions}
                                                        onChange={handleChangePincode}
                                                        value={forCityPincodeValue}
                                                        isDisabled={!forPincode}
                                                        components={customIndicator}
                                                        maxMenuHeight={130}

                                                    />
                                                    <span className='text-red w-100'>{errors?.pincode?.message}</span>
                                                </div>




                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Branch Staff Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" id="exampleInputBrand" placeholder="Use Branch Staff Code & Name" {...register("staff_code_and_name", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.staff_code_and_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Use Branch Staff Qty</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" value={totalEmployee} id="exampleInputBrand" placeholder="Branch Staff Qty" {...register("staff_qty", { required: { value: false, message: "Field is required" } })} />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.staff_qty?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Branch Responsible Person Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("responsible_person", { required: { value: true, message: "Branch Responsible Person Code & Name is required" } })}
                                                            options={employeeOptions}
                                                            onChange={handleChangeResponsiblePerson}
                                                            value={forResponsiblePersonValue}
                                                            components={customIndicator}
                                                            maxMenuHeight={100}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.responsible_person?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Branch Responsible Owner Code & Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("sheth_responsible_person", { required: { value: true, message: "Branch Responsible Sheth Code & Name is required" } })}
                                                            options={BunchemployeeOptions}
                                                            onChange={handleChangeResponsibleSheth}
                                                            value={forResponsibleShethValue}
                                                            components={customIndicator}
                                                            maxMenuHeight={100}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenOwner(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.sheth_responsible_person?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Re-Mark</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Remark" {...register("remark", { required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                </div>









                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listBranch")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openEmployee} setOpen={setOpenEmployee} setSubmitted={setSubmittedEmployee} />
            <Employee_Model refetchForm={EmployeeRefetch} open={openOwner} setOpen={setOpenOwner} setSubmitted={setSubmittedOwner} />
        </div>
    )
}

export default Add_Branch_Office