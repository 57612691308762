import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import { useFetchDepartmentDropDown, useFetchProject, useFetchResponsiblePerson } from '../../../hooks/DropDown_Api';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Department_Model } from '../../../components/Dynamic_Form_Models/Department_Model';
import Status from '../../../components/HRMS Components/Select/Status';
import Createtable from '../../../components/HRMS Components/Select/Createtable';
import TextArea from '../../../components/HRMS Components/Inpute/textarea';
import { Alert } from '../../../components/Model/Model';


const Add_Team = () => {
  let pageName = "Team"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_PROJECT_TEAM_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [optionsTeamMember, setOptionsTeamMember]: any = useState([])
  const [TeamMember, setTeamMember]: any = useState('')

  const { data: TeamMemberData, refetch: TeamMemberRefetch } = useFetchResponsiblePerson()

  useEffect(() => {
    setOptionsTeamMember(TeamMemberData)
  }, [TeamMemberData])

  const handleChangeTeamMember = (e: any) => {
    const TeamMember = e?.map((value: any) => {
      return value.value
    })
    setValue("user", TeamMember)
    setTeamMember(e)
  }

  const [optionsProject, setOptionsProject]: any = useState([])
  const [Project, setProject]: any = useState('')

  const { data: ProjectData, refetch: ProjectRefetch } = useFetchProject()

  useEffect(() => {
    setOptionsProject(ProjectData)
  }, [ProjectData])

  const handleChangeProject = (e: any) => {
    setProject(e)
    setValue("project", e?.value)
  }

  const handleReset = () => {
    reset();
    setProject(null)
    setTeamMember(null)
    
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } 
      else if (Array.isArray(value) && key === "user") {
        value.forEach((val, index) => formData.append(`user`, val))
        continue
      }
      else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listTeam", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);


  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listTeam" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect name='project' label='Project Name' message='Project Name is required' register={register} isModal={false} isRequired={true} errors={errors?.project?.message} options={optionsProject} onChange={(e) => handleChangeProject(e)} Selvalue={Project}/>
                        <InputField type='text' name='name' label='Team Name' isPencil={true} message='Team Name is required' register={register} isRequired={true} hrmserrors={errors?.name?.message} />
                        <ReactSelect name='user' label='Team Member/s' message='Team Member is required' register={register} isModal={false} isRequired={true} errors={errors?.user?.message} options={optionsTeamMember} onChange={(e) => handleChangeTeamMember(e)} Selvalue={TeamMember} isMulti/>
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listTeam")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Team