import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import { useFetchAccountDropDown, useFetchCityDropDown, useFetchGradeDropDown, useFetchPincodeDropDown, useFetchZoneDropDown, useFetchPersonTypeDropDown, useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import useFocusOnEnter from "../../../components/focus";
import Nav from 'react-bootstrap/Nav';
// import {Tabs,Tab} from 'react-bootstrap'
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
// import { Steps } from 'antd';
import { Button, Steps, theme } from 'antd';
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Add_Customer_Grade from "../Customer_Grade/Add_Customer_Grade";
import Add_Personal_Details from "../Personal Details/Add_Personal_Details";
import Add_Contact_Format from "../Contact_Format/Add_Contact_Format";
import Add_Address_Details from "../Address_Details/Add_Address_Details";
import Add_Sales_Document from "../Sales_Document/Add_Sales_Document";
import Add_Bank_Format from "../Bank_Format/Add_Bank_Format";
import Add_Bank_copy from "../Bank_Format/Add_Bank_copy";
import Add_Transport_Format from "../Transport_Format/Add_Transport_Format";
import Add_Customer_Condition from "../Customer Condition/Add_Customer_Condition";
import Add_Basic_Details from "../Basic Details/Add_Basic_Details";
import Edit_Personal_Details from "../Personal Details/Edit_Personal_Details";
import Edit_Contact_Format from "../Contact_Format/Edit_Contact_Format";
import Edit_Basic_Details from "../Basic Details/Edit_Basic_Details";
import Edit_Address_Details from "../Address_Details/Edit_Address_Details";
import Edit_Sales_Document from "../Sales_Document/Edit_Sales_Document";
import Edit_Bank_Format from "../Bank_Format/Edit_Bank_Format";
import Edit_Customer_Condition from "../Customer Condition/Edit_Customer_Condition";
import Edit_Transport_Format from "../Transport_Format/Edit_Transport_Format";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const { Step } = Steps;

const Add_Customer_Details = () => {

    const { token } = theme.useToken();
    const [current, setCurrent]: any = useState(0);

    const { register, handleSubmit, setError, formState, reset, setValue, getValues, control, trigger }: any = useForm({
        mode: "all"
    });


    // const methods = useForm();


    const { errors, isValid } = formState;




    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {
        setValue("person_type", e?.value)
        setValuePersonTypeCreatable(null)
    }

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                setValue("person_type", personTypeData[0].value)

            }
        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);






      

       




    const findInvalidFields = () => {
        const invalidFields = Object.entries(errors).map(([name, error]: any) => {
            return { name, message: error.message };
        });
        // console.log('Invalid Fields:', invalidFields);
        return invalidFields;
    };

    // Submit handler that checks for invalid fields
    const submitWithValidationCheck = (data: any) => {
        const invalidFields = findInvalidFields();
        if (invalidFields.length === 0) {
            // No invalid fields, proceed with submission
            onSubmit(data);
            //   console.log("Go")
        } else {

            findInvalidFields();
        }
    };


    useEffect(() => {
        findInvalidFields();
    }, [errors]);



    const skip = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const isStepOptional = (step: number) => {
        return step === 1;
    };





    let pageName = "Person Details"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const [visitingCard, setVisitingCard] = useState(null)
    const onLogoChange = (e: any) => {
        setVisitingCard(e.target.files[0].name)
    }

    const apiname = process.env.REACT_APP_CUSTOMER_DETAILS_API?.toString();

    const {
        mutate,
        data,
        isError,
        isLoading,
        isSuccess,
        error,
    }: any = useCreate(apiname);



    // console.log("isValid",isValid)

    const [addressValue, setAddressValue] = useState("")

    const handleAddressValue = (e: any) => {
        setAddressValue(e.target.value)
        // console.log(e,"e");

    }

    const [documentType, setDocumentType] = useState("")
    const [regexForDocumentNumber, setRegexForDocumentNumber]: any = useState()

    const handleDocumentType = (e: any) => {


        const type = e.target.value
        if (type === "Lan") {
            setDocumentType("Lan Number")
            setRegexForDocumentNumber(/^[0-9]\d{2,4}\d{6,8}$/)
        } else if (type === "Mobile") {
            setDocumentType("Mobile Number")
            setRegexForDocumentNumber(/^(\+\d{1,3}[- ]?)?\d{10}$/g)
        } else if (type === "Fax") {
            setDocumentType("Fax Number")
            setRegexForDocumentNumber(/[\+? *[1-9]+]?[0-9 ]+/gm)
        }
    }

    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const [employeeOptions, setEmployeeOptions] = useState([])
    const [forEmployeeValue, setForEmployeeValue]: any = useState("")
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        setForEmployeeValue(e)
        setValue("employee_code", e.value)
    }


    const onSubmit = (data: any) => {

        // 

        if (addressValue != "") {
            const addressData = {
                address: addressValue,
                city_and_village: forCityValue?.value,
                pincode: valuePincode?.value,
            };

            delete data?.city_and_village
            delete data?.pincode
            data["address"] = JSON.stringify(addressData)

        }

        if (!visitingCard) {
            delete data?.customer_visiting_card_image
        }


        if (isGST === "no") {
            delete data?.customer_gst_type
            delete data?.gst_no
            delete data?.gst_category
        }
        if (isTDS === "no") {
            delete data?.tds_details_number
        }
        if (isTargettedMonthly === "no") {
            delete data?.targeted_monthly_amount
        }


        const formData = new FormData();
        for (const [key, value] of Object?.entries(data)) {
            const formvalue: any = value


            if (Array.isArray(value) && key === "city_and_village") {
                value.forEach((val, index) => formData.append(`city_and_village`, val))
            }
            else if (key === "customer_visiting_card_image") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            } else if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }


        mutate(formData);
    }





    const [isGST, setIsGST] = useState("no")
    const [isTDS, setIsTDS] = useState("no")
    const [isTargettedMonthly, setIsTargettedMonthly] = useState("no")







    const handleGSTType = (e: any) => {
        setIsGST(e.target.value)
        // setValue("gst_type_registration",e)
    }



    const handleTDS = (e: any) => {
        setIsTDS(e.target.value)
    }

    const handleTragettedMonthlyBox = (e: any) => {
        setIsTargettedMonthly(e.target.value)
    }





    const [forCityValue, setForCityValue]: any = useState("")
    let cityVal = getValues("city_and_village")

    // console.log(forCityValue,"cityVal",cityVal)
    const [cityOptions, setCityOptions]: any = useState()
    const [totalCity, setTotalCity]: any = useState(0)

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        setCityOptions(cityData)
    }, [cityData])


    const [forState, setForState] = useState("")
    const [forCountry, setForCountry] = useState("")
    const [forDistrict, setForDistrict] = useState("")
    const [forTaluka, setForTaluka] = useState("")


    const handleChangeCity = (e: any) => {



        setForCityValue(e)
        setValue("city_and_village", e?.value)


        setForState(e?.state)
        setForCountry(e?.country)
        setForDistrict(e?.district)
        setForTaluka(e?.taluka)


    }


    const [zoneOptions, setZoneOptions]: any = useState("")

    const { data: zoneData, refetch: zoneRefetch } = useFetchZoneDropDown()


    useEffect(() => {
        setZoneOptions(zoneData)
    }, [zoneData])

    const [valueZone, setValueZone]: any = useState()
    // console.log("valueZone",valueZone)


    const handleChangeZone = (e: any) => {
        // 

        setValueZone(e)
        setValue("zone", e?.value)

    }


    const [pincodeOptions, setPincodeOptions]: any = useState()

    const { data: pincodeData, refetch: pincodeRefetch } = useFetchPincodeDropDown()


    useEffect(() => {
        setPincodeOptions(pincodeData)
    }, [pincodeData])


    const [valuePincode, setValuePincode]: any = useState("")


    const handleChangePincode = (e: any) => {

        setValuePincode(e)
        setValue("pincode", e?.value)

    }




    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [forAccountValue, setForAccountValue]: any = useState([])


    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])



    const handleChangeAccount = (e: any) => {
        setValue("account_type", e?.value)
        setForAccountValue(e)
    }







    const [requirementDate, setRequirementDate] = useState(null)

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    // const disabledDate: RangePickerProps['disabledDate'] = (current) => {





    //     return  current && current  < dayjs().startOf('day');
    //   };


    const handleRequirementDate = (e: any) => {
        setRequirementDate(e)
        setValue("company_date_of_birth", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    }

    const [forGradeValue, setForGradeValue]: any = useState([])

    const [optionsGrade, setoptionsGrade]: any = useState([])

    const { data: gradeData, refetch: gradeRefetch } = useFetchGradeDropDown()

    useEffect(() => {
        setoptionsGrade(gradeData)
    }, [gradeData])



    const handleChangeGrade = (e: any) => {
        setValue("customer_grade", e?.value)
        setForGradeValue(e)
    }








    const handleReset = () => {
        reset()
        setRequirementDate(null)
        setIsGST("no")
        setIsTDS("no")
        setIsTargettedMonthly("no")
        setForCityValue(null)
        setValueZone(null)
        setValuePincode(null)
        setForAccountValue(null)
        setForState("")
        setForCountry("")
        setForDistrict("")
        setForTaluka("")
        setVisitingCard(null)

    }


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listPersonDetail', { state: { showToast: true } })

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    const [valuePan, setValuePan] = useState('')

    const handlePan = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.toUpperCase())
            .join(' ');


    }






    const [currentStep, setCurrentStep] = useState(0);
    const [goBackFromContactDetails,setGoBackFromContactDetails] = useState(false)
    const [successPersonalDetails,setSuccessPersonalDetails] = useState(false)


    // const renderStepContent = (step:any) => {
    //     switch (step) {
    //         case 0:
    //             if (successPersonalDetails) {
                    
    //                 // If successPersonalDetails is true, return the component for the next step
    //                 return <Add_Contact_Format setGoBackFromContactDetails={setGoBackFromContactDetails}/>;
    //             } else  {
    //                 // Otherwise, return the component for the current step
    //                 return <Add_Personal_Details setSuccessPersonalDetails={setSuccessPersonalDetails} />;
    //             }   
    
    //         case 1:
    //             return <Add_Contact_Format />;
    
    //         case 2:
    //             // return <Step3Component />;
    //             break;
    
    //         default:
    //             return null;
    //     }
    // };

  
    let rawData = localStorage.getItem('Add Data');
    let addData:any = rawData ? JSON.parse(rawData) : null;

    let personal_detail:any =  addData ? addData?.personal_detail?.id : null;
    let contact_detail = addData ? addData.contact_detail?.length>0 : null;
    // let contact_detail = addData ? addData.contact_detail?.id : null;
    let address = addData ? addData.address?.length>0 : null;
    let document = addData ? addData.document?.length>0 : null;
    // let bank = addData ? addData.bank?.id : null;
    let bank = addData ? addData.bank?.length>0  : null;
    let customer_condition = addData ? addData.customer_condition?.id : null;
    let transport_detail = addData ? addData.transport_detail?.id : null;

    let rawDataPersonType = localStorage.getItem('PersonType');
    let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    let personTypeLabel = addDataPersonType?.label === 'Employee'

    // let rawData = localStorage.getItem('Add Data');
    // let addData = rawData ? JSON.parse(rawData) : null;

    // let personal_detail =  addData ? addData.personal_detail?.id : null;
    // let contact_detail = addData ? addData.contact_detail?.id : null;
    // let address = addData ? addData.address?.length>0 : null;
    // let document = addData ? addData.document?.length>0 : null;
    // let bank = addData ? addData.bank : null;
    // let customer_condition = addData ? addData.customer_condition : null;
    // let transport_detail = addData ? addData.transport_detail : null;

    // let rawDataPersonType = localStorage.getItem('PersonType');
    // let addDataPersonType = rawDataPersonType ? JSON.parse(rawDataPersonType) : null;
    // let personTypeLabel = addDataPersonType?.label === 'Employee'


    console.log('personTypeLabel',personTypeLabel)

    
    
    

    const renderStepContent = (step:any) => {
        // if (step === 0) {
        //     if (successPersonalDetails) {
        //         return <Add_Contact_Format setGoBackFromContactDetails={setGoBackFromContactDetails} currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
        //     } else {
        //         return <Add_Personal_Details setSuccessPersonalDetails={setSuccessPersonalDetails} currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
        //     }
        // }
        
        switch (step) {
            case 0:
                if(addData){
                return <Edit_Basic_Details currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }else{
                    return <Add_Basic_Details currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }
            case 1:
                if(personal_detail){
                    return <Edit_Personal_Details currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }else{
                    return <Add_Personal_Details currentStep={currentStep} setCurrentStep={setCurrentStep}/>;    
                }
            case 2:
                if(contact_detail){
                return <Edit_Contact_Format  currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }else{
                    return <Add_Contact_Format  currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }
            case 3:
                if(address){
                    return <Edit_Address_Details currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }else{
                    return <Add_Address_Details currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }
            case 4:
                if(document){
                    return <Edit_Sales_Document currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }else{

                    return <Add_Sales_Document currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }
            case 5:
                if(bank){
                
                    return <Edit_Bank_Format currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }else{
                    // return <Add_Bank_Format currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                    return <Add_Bank_copy currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
                }
            // case 6:
            //     if(customer_condition){

            //         return <Edit_Customer_Condition currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
            //     }else{
            //         return <Add_Customer_Condition currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
            //     }
            // case 7:
            //     if(transport_detail){
            //         return <Edit_Transport_Format currentStep={currentStep} setCurrentStep={setCurrentStep}/>;
            //     }else{

            //         return <Add_Transport_Format currentStep={currentStep} setCurrentStep={setCurrentStep}/>;

            //     }
            // case 8:
            //     break;

            case 6:
                // Handle the logic for step 6 (Customer Condition)
                if (customer_condition) {
                    return <Edit_Customer_Condition currentStep={currentStep} setCurrentStep={setCurrentStep} />;
                } else {
                    return <Add_Customer_Condition currentStep={currentStep} setCurrentStep={setCurrentStep} />;
                }
    
            case 7:
                // Check if personTypeLabel is true to handle transport details
                if (!personTypeLabel) {
                    if (transport_detail) {
                        return <Edit_Transport_Format currentStep={currentStep} setCurrentStep={setCurrentStep} />;
                    } else {
                        return <Add_Transport_Format currentStep={currentStep} setCurrentStep={setCurrentStep} />;
                    }
                } else {
                    // If personTypeLabel is false, end the switch here (making case 6 the last step)
                    if (customer_condition) {
                        return <Edit_Customer_Condition currentStep={currentStep} setCurrentStep={setCurrentStep} />;
                    } else {
                        return <Add_Customer_Condition currentStep={currentStep} setCurrentStep={setCurrentStep} />;
                    }
                    // break;
                }
    
            case 8:
               
                    break;
                
                
            default:
                return <div>Unexpected step</div>;
        }
    };
    

    // const handleNext = () => {
    //     if (currentStep < 7) { 
    //       setCurrentStep(currentStep + 1);
    //     }
    //   };
    
    //   const handlePrev = () => {
    //     if (currentStep > 0) {
    //       setCurrentStep(currentStep - 1);
    //     }
    //   };
   


    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                            <div>


                            <div>
                           
      <Steps current={currentStep} onChange={setCurrentStep}>  
      
      <Step title="Basic Details" />
        <Step title="Personal Detail" />
        <Step title="Contact Detail" />
        <Step title="Addresses" />
        <Step title="Document Detail" />
        <Step title="Bank" />
        <Step title="Condition" />
{!personTypeLabel ?
        <Step title="Transport" />
:null}
      </Steps>
      {/* <FormProvider {...methods}> */}
      <div className="steps-content">{renderStepContent(currentStep)}</div>
      {/* </FormProvider> */}
    </div>


                               
                            </div>
                        </div>
                    </div>
                </section>
                
            </div>
        </div>
    )
}

export default Add_Customer_Details

