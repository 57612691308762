import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import { useFetchBankAccountDropDown, useFetchCustomerDropDown, useFetchHrmsAccountDropdown, useFetchPersonTypeDropDown } from '../../../hooks/DropDown_Api';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import Date from '../../../components/HRMS Components/Date and Time/Date';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Axiosinstance from '../../../hooks/Interceptor';
import Modal from 'react-bootstrap/esm/Modal';
import { Deletesinglewithdependancy } from '../../../components/modal';

const Edit_Person_Transaction = () => {

  let pageName = "Person Transaction"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ACCOUNTING_PERSON_TRANSACTION_ENTRY_API?.toString();
  const { id } = useParams()
  const { data } = useFetchSingle(id, apiname)

  const [valuesofform, setValuesofform] = useState<any>()
  const [openEditModel, setOpenEditModel] = useState(false)
  const [expirationDate, setExpirationDate] = useState()
  const [confirmmodal, setConfirmmodal] = useState(false)

  const { mutate, isSuccess, isError, error, data: Updated_response, isLoading }: any = useUpdateSingle()
  const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
  const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [TransactionTypeValue, setTransactionTypeValue]: any = useState('')
  const [SelTransactionTypeValue, setSelTransactionTypeValue]: any = useState('')

  const TransactionType = [
    { label: 'Payment', value: 'Payment' },
    { label: 'Receive', value: 'Receive' }
  ]

  const handleTransactionType = (e: any) => {
    setTransactionTypeValue(e)
    setValue("type", e?.value)
  }
  const [PaymentMethodValue, setPaymentMethodValue]: any = useState()
  const [SelPaymentMethodValue, setSelPaymentMethodValue]: any = useState()

  const PaymentMethod = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Bank', value: 'Bank' },
    { label: 'By Cheque', value: 'By cheque' }
  ]

  const handlePaymentMethod = (e: any) => {
    setPaymentMethodValue(e)
    setValue("payment_method", e?.value)
  }

  const [forDate, setForDate]: any = useState('')
  const handleDate = (date: any, dateString: any) => {

    setForDate(date)
    setValue("date_time", dateString)
  }



  const [optionsBank, setOptionsBank] = useState()
  const [BankValue, setBankValue]: any = useState("")
  const [SelBankValue, setSelBankValue]: any = useState("")

  const { data: BankData, refetch: BankRefetch } = useFetchBankAccountDropDown()

  useEffect(() => {
    setOptionsBank(BankData)
  }, [BankData])

  const handleChangeBank = (e: any) => {
    setBankValue(e)
    setValue("bank_account", e?.value)
  }

  const [valuePersonType, setValuePersonType]: any = useState();
  const [SelectedPersonType, setSelectedPersonType]: any = useState();

  const [optionsPersonType, setOptionsPersonType]: any = useState([])

  const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

  useEffect(() => {
    setOptionsPersonType(personTypeData)
  }, [personTypeData])

  const handleChangePersonType = (e: any) => {
    setValuePersonType(e)
    setValue('person_type', e.value)
  }


  const [optionsCompany, setOptionsCompany] = useState([])
  const [CompanyData, setCompanyData]: any = useState([])
  const [companyValue, setCompanyValue]: any = useState("")
  const [SelCompanyValue, setSelCompanyValue]: any = useState("")
  const [SelcompanyData, setSelCompanyData]: any = useState("")

  // const { data: companyData, refetch: companyRefetch } = useFetchCustomerDropDown()
  const apinamePerson = process.env.REACT_APP_EMPLOYEE_API?.toString()
  useEffect(() => {
    Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamePerson}/get_by_personType/?name=${valuePersonType?.label?valuePersonType?.label:SelcompanyData}`).then((res: any) => { setCompanyData(res?.data?.results) })

  }, [valuePersonType,SelcompanyData])

  useEffect(() => {
    const Data = CompanyData.map((e: any) => {
      return {
        label: e.customer_name,
        value: e.id
      }
    })
    setOptionsCompany(Data)
  }, [CompanyData])

  const handleChangeCompany = (e: any) => {
    setCompanyValue(e)
    setValue("customer_name", e?.value)
    setValue('current_due', 0)
  }

  const apinameType = process.env.REACT_APP_PERSON_TYPE_API?.toString()
  const apinameBank = process.env.REACT_APP_ACCOUNTING_BANK_ACCOUNT_ENTRY_API?.toString()
  useEffect(() => {

    if (data?.person_type) {
      const getCall = async () => {
        try {
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameType}/${data?.person_type}/`);
          setSelectedPersonType(response?.data?.data?.id)
          setSelCompanyData(response?.data?.data?.name)
          setValue("person_type", response?.data?.data?.id)

        } catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }
    if (data?.customer_name) {
      const getCall = async () => {
        try {
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinamePerson}/${data?.customer_name}/`);
          setSelCompanyValue(response?.data?.data?.id)
          setValue("customer_name", response?.data?.data?.id)

        } catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }
    if (data?.bank_account) {
      const getCall = async () => {
        try {
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameBank}/${data?.bank_account}/`);
          setSelBankValue(response?.data?.data?.id)
          setValue("bank_account", response?.data?.data?.id)

        } catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }

  }, [data])

  useEffect(() => {
    if (data) {
      reset(data)
      setSelTransactionTypeValue(data?.type)
      setValue('type', data?.type)
      setSelPaymentMethodValue(data?.payment_method)
      setValue('payment_method', data?.payment_method)
      setForDate(dayjs(data?.date_time, 'DD/MM/YYYY HH:mm'))
    }
  }, [data])

  const onSubmit = (branddetail: any) => {
    if (PaymentMethodValue?.value != 'Bank') {
      delete branddetail.bank_account
    }
    if (PaymentMethodValue?.value != 'By cheque') {
      delete branddetail.cheque_number
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      }
    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData
    }
    mutate(data);
    setValuesofform(data)
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess || yessuccess || nosuccess) {
      setValuesofform(null)
      setShow(true)
      navigate('/listPersonTransaction', { state: { showEditToast: true } })
    }

  }, [data, isSuccess, yessuccess,
    nosuccess, Updated_response])

  useEffect(() => {
    if (isError) {
      if (error.response.status === 405) {
        setConfirmmodal(true)
      }
    }
    else if (isError || yeserror || noerror) {
      setShow(true)
      setshowmessage("danger")
    }
  }, [isError, yeserror, noerror])
  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 3000)

  }, [show]);

  const yesupdatemodal = () => {
    setConfirmmodal(false);
    mutateyes(valuesofform)
  }
  const cancelupdatemodal = () => {
    setConfirmmodal(false);
  }

  const noupdatemodal = () => {
    setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
    setExpirationDate(e.target.value)
    setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {


    let ok = {
      id: id,
      apiname: apiname,
      data: data,
    }

    mutateno(ok)
    setConfirmmodal(false);
    setOpenEditModel(false)
  }

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listPersonTransaction" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Transaction Type' name='type' message='Transaction Type is required' register={register} isRequired={true} isModal={false} isEdit={true} isMulti={false} errors={errors?.type?.message} options={TransactionType} Selvalue={TransactionTypeValue} onChange={(e) => handleTransactionType(e)} editValue={SelTransactionTypeValue} />
                        <ReactSelect label='Payment Method' name='payment_method' message='Payment Method is required' register={register} isRequired={true} isModal={false} isEdit={true} isMulti={false} errors={errors?.payment_method?.message} options={PaymentMethod} Selvalue={PaymentMethodValue} onChange={(e) => handlePaymentMethod(e)} editValue={SelPaymentMethodValue} />
                        {PaymentMethodValue?.value === 'Bank' || SelPaymentMethodValue === 'Bank' ?
                          <ReactSelect label='Bank Account' name='bank_account' message='Bank Account is required' register={register} isRequired={true} isModal={false} isEdit={true} isMulti={false} errors={errors?.bank_account?.message} Selvalue={BankValue} options={optionsBank} onChange={(e) => handleChangeBank(e)} editValue={SelBankValue} /> : ''}
                        {PaymentMethodValue?.value === 'By cheque' ||SelPaymentMethodValue === 'By cheque' ?
                          <InputField type='number' name='cheque_number' label='Cheque Number' isPencil={true} message='Cheque Number required' register={register} isRequired={true} hrmserrors={errors?.cheque_number?.message} /> : ''}
                        <ReactSelect label='Person Type' name='person_type' message='Person Type is required' register={register} isRequired={true} isModal={false} isEdit={true} isMulti={false} errors={errors?.person_type?.message} OptionDisabled={(optionsPersonType) => optionsPersonType.label === 'Employee'} Selvalue={valuePersonType} options={optionsPersonType} onChange={(e) => handleChangePersonType(e)} editValue={SelectedPersonType} />
                        <ReactSelect label='Person Name' name='customer_name' message='Person is required' register={register} isRequired={true} isModal={false} isEdit={true} isMulti={false} errors={errors?.customer_name?.message} Selvalue={companyValue} options={optionsCompany} onChange={(e) => handleChangeCompany(e)} editValue={SelCompanyValue} />
                        <InputField type='number' name='current_due' label='Current Due' isPencil={false} message='Current Due required' register={register} isRequired={false} hrmserrors={errors?.current_due?.message} isDisabled />
                        <Date name='date_time' label='Date Time' isPencil={true} message='Date is required' register={register} isRequired={true} hrmserrors={errors?.date_time?.message} value={forDate} onChange={(date, dateString) => handleDate(date, dateString)} isShowTime={true} />

                        <InputField type='text' name='note' label='Note' isPencil={true} message='Note is required' register={register} isRequired={false} hrmserrors={errors?.note?.message} />
                        <InputField type='number' name='amount' label='Amount' isPencil={true} message='Amount is required' register={register} isRequired={true} hrmserrors={errors?.amount?.message} />

                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} isReset={false} Cancel={() => navigate("/listPersonTransaction")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
        centered    >
        <Modal.Header closeButton>
          <Modal.Title>Select a Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-12 mt-2">
                <label htmlFor="exampleInputEmail1">Choose Date</label>
                <input type="date" className="form-control" id="exampleInputBrand"

                  {...register("expiration_date", { onChange: handleExpirationDate })}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
          <button
            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
            onClick={handleSubmit(handleConfirm)}
          >Confirm</button>
        </Modal.Footer>
      </Modal>
      <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
        yesupdatemodal={yesupdatemodal}
        cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
    </div>
  )
}

export default Edit_Person_Transaction