import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Add_Person_Transaction from '../pages/Account Payment/Person Transaction/Add_Person_Transaction'
import List_Person_Transaction from '../pages/Account Payment/Person Transaction/List_Person_Transaction'
import List_Bank_Transaction from '../pages/Account Payment/Bank Transaction/List_Bank_Transaction'
import Add_Bank_Transaction from '../pages/Account Payment/Bank Transaction/Add_Bank_Transaction'
import List_Other_Transaction from '../pages/Account Payment/Other Transaction/List_Other_Transaction'
import Add_Other_Transaction from '../pages/Account Payment/Other Transaction/Add_Other_Transaction'
import Add_Other_Transaction_Accounts from '../pages/Account Payment/Other Transaction Accounts/Add_Other_Transaction_Accounts'
import List_Other_Transaction_Accounts from '../pages/Account Payment/Other Transaction Accounts/List_Other_Transaction_Accounts'
import Add_Cash_Transaction from '../pages/Account Payment/Cash Transaction/Add_Cash_Transaction'
import List_Cash_Transaction from '../pages/Account Payment/Cash Transaction/List_Cash_Transaction'
import Add_Bank_Accounts_Transaction from '../pages/Account Payment/Bank Accounts/Add_Bank_Accounts_Transaction'
import List_Bank_Accounts_Transaction from '../pages/Account Payment/Bank Accounts/List_Bank_Accounts_Transaction'
import Edit_Bank_Accounts_Transaction from '../pages/Account Payment/Bank Accounts/Edit_Bank_Accounts_Transaction'
import Edit_Bank_Transaction from '../pages/Account Payment/Bank Transaction/Edit_Bank_Transaction'
import Edit_Cash_Transaction from '../pages/Account Payment/Cash Transaction/Edit_Cash_Transaction'
import Edit_Person_Transaction from '../pages/Account Payment/Person Transaction/Edit_Person_Transaction'
import Edit_Other_Transaction_Accounts from '../pages/Account Payment/Other Transaction Accounts/Edit_Other_Transaction_Accounts'
import Edit_Other_Transaction from '../pages/Account Payment/Other Transaction/Edit_Other_Transaction'
import PageNotFound from '../pages/PageNotFound'



const Accounts = () => {
    return (
        <div>
            <div className='wrapper'>
                <Routes>
                    <Route path='addPersonTransaction' element={
                        <Add_Person_Transaction />
                    } />
                    <Route path='editcustomerTransactionEntry/:id' element={
                        <Edit_Person_Transaction />
                    } />
                    <Route path='listPersonTransaction' element={
                        <List_Person_Transaction />
                    } />
                    <Route path='addBankTransaction' element={
                        <Add_Bank_Transaction />
                    } />
                    <Route path='editbankdepositwithdrawtransaction/:id' element={
                        <Edit_Bank_Transaction />
                    } />
                    <Route path='listBankTransaction' element={
                        <List_Bank_Transaction />
                    } />

                    <Route path='addOtherTransaction' element={
                        <Add_Other_Transaction />
                    } />
                    <Route path='editothersTransactionEntry/:id' element={
                        <Edit_Other_Transaction />
                    } />
                    <Route path='listOtherTransaction' element={
                        <List_Other_Transaction />
                    } />


                    <Route path='addOtherTransactionAccounts' element={
                        <Add_Other_Transaction_Accounts />
                    } />
                    <Route path='edittransactionAccountEntry/:id' element={
                        <Edit_Other_Transaction_Accounts />
                    } />
                    <Route path='listOtherTransactionAccounts' element={
                        <List_Other_Transaction_Accounts />
                    } />

                    <Route path='addCashTransaction' element={
                        <Add_Cash_Transaction />
                    } />
                    <Route path='editcashtransactionentry/:id' element={
                        <Edit_Cash_Transaction />
                    } />
                    <Route path='listCashTransaction' element={
                        <List_Cash_Transaction />
                    } />

                    <Route path='addBankAccountsTransaction' element={
                        <Add_Bank_Accounts_Transaction />
                    } />
                    <Route path='editbankaccountentry/:id' element={
                        <Edit_Bank_Accounts_Transaction />
                    } />
                    <Route path='listBankAccountsTransaction' element={
                        <List_Bank_Accounts_Transaction />
                    } />
                    <Route path='*' element={
                        <PageNotFound />} />

            </Routes>
        </div>

        </div >
    )
}

export default Accounts
