import { toast } from "react-toastify";

// remove all falsy property from  object
export function removeFalsyProperties(obj:any) {
  const newObj:any = {};
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop]) {
      newObj[prop] = obj[prop];
    }
  }
  return newObj;
}

export function stringShorter(str:any, length:any) {
  return str?.length > length ? str.slice(0, length) + "..." : str;
}

export function buildQuery(obj:any) {
  const queryParams = [];
  for (const key in obj) {
    if (obj[key]) {
      queryParams.push(`${key}=${obj[key]}`);
    }
  }

  return queryParams.length ? queryParams.join("&") : "";
}

export function toastHandler(message:any, status:any) {
  if (status === "warning") {
    toast.warning(message)
  } else if (status === "success") {
    toast.success(message);
  }
}