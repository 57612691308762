import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGstPost } from "../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../hooks/Api";
import axios from "axios";
import Select from "react-select";
import {
  useFetchMaterialTypeDropDown,
  useFetchMaterialDensityDropDown,
  useFetchCavityDropDown,
  useFetchColorDropDown,
  useFetchGSTDropDown,
  useFetchMouldDropDown,
  useFetchPriceListDropDown,
  useFetchPanniDropDown,
  useFetchProductMLDropDown,
  useFetchHSNMaterialNameDropDown,
  useFetchPanniMaterialUseDropDown,
  useFetchProductActualMLDropDown,
  useFetchMouldDiameterMMDropDown,
  useFetchMouldShapeDiameterDropDown,
  useFetchCurrentLipDropDown,
  useFetchPanniSealingTypeDropDown,
  useFetchProductOurOtherDropDown,
  useFetchMaterialUseDropDown,
  useFetchCategoryDropDown,
  useFetchSingleCodeProductTypeDropDown,
} from "../../hooks/DropDown_Api";
import Calculation from "../Panni/calculation";
import { Alert } from "../../components/Model/Model";
import CreatableSelect from "react-select/creatable";
import useFocusOnEnter from "../../components/focus";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import { Mould_Model } from "../../components/Dynamic_Form_Models/Mould";

import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { Color_Model } from "../../components/Dynamic_Form_Models/Color_Model";
import { Cavity_Model } from "../../components/Dynamic_Form_Models/Cavity_Model";
import type { UploadFile } from 'antd/es/upload/interface';
import { Upload } from 'antd';
import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import Axiosinstance from "../../hooks/Interceptor";

const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
  IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
  readonly label: string;
  //   readonly value: string;
  readonly name: string;
}
const createOption = (label: string) => ({
  label,
  // value: label,
  name: label,
  status: "active",
});

// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Add_SingleCode = () => {
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const PageTitle = "Single Code";
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_SINGLE_CODE_API?.toString();

  const {
    mutate: singlecode,
    data,
    isError,
    isLoading,
    isSuccess,
    error,
  }: any = useCreate(apiname);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
    getValues,
  }: any = useForm();

  const [openHSN, setOpenHSN] = useState(false)

  const [colorOpen, setColorOpen] = useState(false)
  const [OpenProduct, setOpenProduct] = useState(false)
  const [MouldShapeOpen, setMouldShapeOpen] = useState(false)
  const [submittedColor, setSubmittedColor] = useState(false)
  const [submittedMouldShape, setSubmittedMouldShape] = useState(false)
  const [submittedProduct, setSubmittedProduct] = useState(false)


  const [searchTerm, setSearchTerm] = useState("");
  const [optionsProductType, setOptionsProductType]: any = useState([]);
  const [optionsNameAndGrade, setOptionsNameAndGrade] = useState([]);
  const [optionsCavity, setOptionsCavity]: any = useState([]);
  const [optionsMouldShape, setOptionsMouldShape]: any = useState([]);
  const [optionsHSN, setOptionsHSN]: any = useState([]);
  const [optionsPanni, setOptionsPanni] = useState([]);

  const [optionsColor, setOptionsColor]: any = useState([]);
  const [optionsGst, setOptionsGst] = useState([]);
  const [optionsMould, setOptionsMould] = useState([]);

  //   const [optionsPriceList, setOptionsPriceList] = useState([]);

  const [gstNumber, setGSTNumber]: any = useState({});

  const [productType, setProductType]: any = useState("");

  // const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString();
  const [ProductLength, setProductLength] = useState(1)
  const [ProductWidth, setProductWidth] = useState(1)
  const [gauge, setGauge] = useState(0)
  const [panniGadget, setpanniGadget] = useState("no")
  const [panniKan, setpanniKan]: any = useState("no");
  const [panniKanLength, setPanniKanLength]: any = useState("");

  const handleSetPanniKanLength = (e: any) => {
    setPanniKanLength(Number(e.target.value))
  }

  const handleSetPanniLength = (e: any) => {
    setProductLength(Number(e.target.value))
  }

  const handleSetPanniWidth = (e: any) => {
    setProductWidth(e.target.value)
  }

  const handleSetGauge = (e: any) => {
    setValue("thickness_gauge", e.target.value * 4)
  }

  const onGadgetChange = (e: any) => {
    setpanniGadget(e.target.value)
  }

  const onPanniChange = (e: any) => {
    setpanniKan(e.target.value)
  }






  // const { data: productTypeData, refetch: productTypeRefetch } = useFetchMaterialTypeDropDown(searchTerm);

  // useEffect(() => {
  //   setOptionsProductType(productTypeData);
  // }, [productTypeData]);


  // const handleInputChangeProductType = (e: any) => {
  //   setSearchTerm(e);
  // };
  // const handleChangeProductType = (e: any) => {
  //   setProductTypeValue(e);
  //   setProductType(e.label);
  //   setValue("product_type", e.value);
  //   setValue("panni", "");
  // };



  const apinameForProductType = process.env.REACT_APP_SINGLE_CODE_PRODUCT_TYPE_API?.toString()
  const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductType, setValueProductType]: any = useState();

  // const [optionsProductType, setOptionsProductType]: any = useState([]);
  const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();
  console.log(valueProductTypeCreatable, "valueProductTypeCreatable");

  const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchSingleCodeProductTypeDropDown()

  useEffect(() => {
    setOptionsProductType(ProductTypeData)
  }, [ProductTypeData])


  const handleChangeProductType = (e: any) => {

    ProductTypeRefetch()

    setApiName(apinameForProductType)
    setDeleteApiName(apinameForProductType)
    setEditId(e?.value)
    setEditData(ProductTypeData)
    setRenderKey(prevKey => prevKey + 1);

    setValueProductType(e)

    setValue("product_type", `${e?.value}`)
    setValueProductTypeCreatable(null)
  }





  const handleCreateProductType = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateProductType({ name: newOption.name, status: newOption.status })
      setOptionsProductType((prev: any) => [...prev, newOption]);
      setValueProductTypeCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductTypeSuccess && optionsProductType) {
        await ProductTypeRefetch();
        setValue("product_type", ProductTypeData[0]?.value)
      }
    })();

    return () => {
    };
  }, [ProductTypeSuccess, ProductTypeData]);


  const [forHSNValue, setForHSNValue]: any = useState([])
  const [DensityValue, setDensityValue]: any = useState()


  const { data: HSNData, refetch: HSNRefetch } = useFetchHSNMaterialNameDropDown()

  useEffect(() => {
    setOptionsHSN(HSNData)
  }, [HSNData])

  const handleChangeHSN = (e: any) => {
    setValue("HSN_code", `${e.value}`)
    // setDensityValue(e.material)
    // setValue("polymer_grade", e.polymer)
    setForHSNValue({ label: e?.label, value: e?.value })
  }


  const apinameForMaterialType = process.env.REACT_APP_PANNI_MATERIAL_USE_API?.toString()
  const { mutate: mutateMaterialType, data: dataMaterialType, isError: MaterialTypeIsError, isLoading: MaterialTypeLoading, isSuccess: MaterialTypeSuccess, error: MaterialTypeError }: any = useCreate(apinameForMaterialType)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueMaterialType, setValueMaterialType]: any = useState();
  const [optionsMaterialType, setOptionsMaterialType]: any = useState([]);
  const [valueMaterialTypeCreatable, setValueMaterialTypeCreatable] = useState<Option | null>();

  const { data: MaterialTypeData, refetch: MaterialTypeRefetch } = useFetchPanniMaterialUseDropDown()

  useEffect(() => {
    setOptionsMaterialType(MaterialTypeData)
  }, [MaterialTypeData])





  const handleChangeMaterialType = (e: any) => {
    MaterialTypeRefetch()

    setApiName(apinameForMaterialType)
    setDeleteApiName(apinameForMaterialType)
    setEditId(e?.value)
    setEditData(MaterialTypeData)
    setRenderKey(prevKey => prevKey + 1);

    setValueMaterialType(e)
    setValue("material_type", `${e?.value}`)
    setValueMaterialTypeCreatable(null)
  }





  const handleCreateMaterialType = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateMaterialType({ name: newOption.name, status: newOption.status })
      setOptionsMaterialType((prev: any) => [...prev, newOption]);
      setValueMaterialTypeCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (MaterialTypeSuccess && optionsMaterialType) {
        await MaterialTypeRefetch();
        setValue("material_type", MaterialTypeData[0]?.value)
      }
    })();

    return () => {
    };
  }, [MaterialTypeSuccess, MaterialTypeData]);

  const { data: nameAndGradeData, refetch: nameAndGradeRefetch } =
    useFetchMaterialDensityDropDown(searchTerm);

  // const { mutate: getrelated, data: relatedData } = useCreate(getRelatedHsn);

  useEffect(() => {
    setOptionsNameAndGrade(nameAndGradeData);
  }, [nameAndGradeData]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      nameAndGradeRefetch();
    }
  }, []);

  const handleInputChangeNameAndGrade = (e: any) => {
    setSearchTerm(e);
  };

  const handleChangeNameAndGrade = (e: any) => {
    setNameAndGradeValue(e);
    setValue("product_grade_name", `${e.value}`);
    // getrelated({ grade: e.value });
  };

  // useEffect(() => {
  //   let temp_results = relatedData?.results.map((e: any) => {
  //     return {
  //       value: e.id,
  //       label: e.hsn_code,
  //       gst_id: e.gst.id,
  //       gst: e.gst.india_GST,
  //     };
  //   });

  //   setOptionsHSN(temp_results);
  // }, [relatedData]);

  const { data: cavityData, refetch: cavityRefetch } =
    useFetchCavityDropDown(searchTerm);
  // const { data: mouldData, refetch: mouldRefetch } =
  //   useFetchMouldDropDown(searchTerm);

  useEffect(() => {
    setOptionsCavity(cavityData);
  }, [cavityData]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      cavityRefetch();
    }
  }, []);

  const handleInputChangeCavity = (e: any) => {
    setSearchTerm(e);
  };

  const handleChangeCavity = (e: any) => {
    // setCavityValue(e);
    // const temp_mould = mouldData?.filter((opt: any) => opt.value === e.mould);
    // setOptionsMould(temp_mould);
    setValue("cavity_to_product", e.value);
    setProductValue({ label: e?.label, value: e?.value })
  };
  const { data: MouldShapeData, refetch: MouldShapeRefetch } =
    useFetchMouldShapeDiameterDropDown(searchTerm);
  // const { data: mouldData, refetch: mouldRefetch } =
  //   useFetchMouldDropDown(searchTerm);

  useEffect(() => {
    setOptionsMouldShape(MouldShapeData);
  }, [MouldShapeData]);





  const handleChangeMouldShape = (e: any) => {
    setMouldShapeValue(e);
    setValue("mould_shape", e.value);
    setMouldValue({ label: e?.label, value: e?.value })
  };

  // useEffect(() => {
  //     setOptionsMould(mouldData)
  // }, [mouldData])

  // useEffect(() => {
  //   if (searchTerm.length >= 1) {
  //     mouldRefetch();
  //   }
  // }, []);

  const handleInputChangeMould = (e: any) => {
    setSearchTerm(e);
  };

  const [cavityQty, setCavityQty]: any = useState();

  const handleChangeMould = (e: any) => {
    setMouldCodeAndCavityValue(e);
    setValue("use_mould_code_and_cavity", e.value);
    setCavityQty(e.cavityQty);
    setValue("cavity_qty", e.cavityQty);
  };

  // const { data: hsnData, refetch: hsnRefetch } = useFetchHSNMaterialNameDropDown(searchTerm)

  // useEffect(() => {
  //     setOptionsHsn(hsnData)
  // }, [hsnData])

  // useEffect(() => {
  //     if (searchTerm.length >= 1) {
  //         hsnRefetch()
  //     }
  // }, [])

  const handleInputChangeHsn = (e: any) => {
    setSearchTerm(e);
  };

  // const handleChangeHSN = (e: any) => {
  //   // setGSTNumber(e)
  //   setHSNValue(e);
  //   setValue("HSN_code", e.value);
  //   setValue("gst", e.gst_id);
  // };

  const { data: colorData, refetch: colorRefetch } =
    useFetchColorDropDown(searchTerm);

  useEffect(() => {
    setOptionsColor(colorData);
  }, [colorData]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      colorRefetch();
    }
  }, []);

  const handleInputChangeColor = (e: any) => {
    setSearchTerm(e);
  };

  const handleChangeColor = (e: any) => {
    setColorValue(e);
    setValue("color", e.value);
    setColorValue({ label: e?.label, value: e?.value })
  };

  const { data: gstData, refetch: gstRefetch } =
    useFetchGSTDropDown(searchTerm);

  useEffect(() => {
    setOptionsGst(gstData);
  }, [gstData]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      gstRefetch();
    }
  }, []);

  const handleInputChangeProductGst = (e: any) => {
    setSearchTerm(e);
  };

  const handleChangeProductGst = () => { };

  const handleInputChangeExportGst = (e: any) => {
    setSearchTerm(e);
  };

  const handleChangeExportGst = () => { };

  const { data: panniData, refetch: panniRefetch } =
    useFetchPanniDropDown(searchTerm);

  useEffect(() => {
    setOptionsPanni(panniData);
  }, [panniData]);

  useEffect(() => {
    if (searchTerm.length >= 1) {
      panniRefetch();
    }
  }, []);

  const handleInputChangePanni = (e: any) => {
    setSearchTerm(e);
  };
  // const [panniGadget, setPanniGadget] = useState("");
  const [panniGadgetSize, setPanniGadgetSize] = useState("");
  // const [panniKan, setPanniKan] = useState("");
  // const [panniKanLength, setPanniKanLength] = useState("");
  const [panniKanWidth, setPanniKanWidth] = useState("");
  const [panniType, setPanniType] = useState("");
  const [panniMaterial, setPanniMaterial] = useState("");
  const [panniSealing, setPanniSealing] = useState("");
  const [panniLength, setPanniLength] = useState("");
  const [panniWidth, setPanniWidth] = useState("");
  const [panniMicron, setPanniMicron] = useState("");
  const [panniGauge, setPannigauge] = useState("");
  const [panniOneNosWeight, setPanniOneNosWeight] = useState("");
  const [panniOneKgNos, setPanniOneKgNos] = useState("");

  const handleChangePanni = (e: any) => {
    setPanniValue(e);
    setValue("panni", e.value);
    setPanniType(e.type);
    // setPanniGadget(e.panniGadget);
    setPanniGadgetSize(e.panniGadgetSize);
    // setPanniKan(e.panniKan);
    setPanniKanLength(e.panniKanLength);
    setPanniKanWidth(e.panniKanWidth);

    setPanniMaterial(e.panniMaterial);
    setPanniSealing(e.panniSealing);
    setPanniLength(e.panniLength);
    setPanniWidth(e.panniWidth);
    setPanniMicron(e.panniMicron);
    setPannigauge(e.panniGauge);
    setPanniOneNosWeight(e.panniOneNosWeight);
    setPanniOneKgNos(e.panniOneKgNos);
  };


  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const [prductTypeValue, setProductTypeValue]: any = useState();
  const [nameAndGradeValue, setNameAndGradeValue]: any = useState();

  const [HSNValue, setHSNValue]: any = useState();
  const [colorValue, setColorValue]: any = useState();
  const [MouldValue, setMouldValue]: any = useState();
  const [ProductValue, setProductValue]: any = useState();
  const [panniValue, setPanniValue]: any = useState();
  const [cavityValue, setCavityValue]: any = useState();
  const [MouldShapeValue, setMouldShapeValue]: any = useState();

  const [mouldCodeAndCavityValue, setMouldCodeAndCavityValue]: any = useState();
  const [priceListValue, setPriceListValue]: any = useState();
  const [valueonePcsWeight, setValueonePcsWeight]: any = useState('');
  const [ValuepricelistOnepcs, setValuepricelistOnepcs]: any = useState('');
  const [ValueProductionOnepcs, setValueProductionOnepcs]: any = useState('');



  const handleReset = () => {
    reset();
    setProductType(null)
    setProductType(null);
    setProductTypeValue(null);
    setNameAndGradeValue(null);
    setHSNValue(null);
    setColorValue(null);
    setPanniValue(null);
    setCavityValue(null);
    setMouldCodeAndCavityValue(null);
    setPriceListValue(null);
    setCavityQty(null);
    setUploadProductPDF([]);
    setuploadBoriAndCartonImage([]);
    // setPrductCategoryValue(null)
  };

  useEffect(() => {
   
    if (panniGadget === 'no' || panniGadget === '') {
        setValue("gadget_size_mm", "")
    }
    if (panniKan === 'no' || panniKan === '') {
        setValue("product_kan_length_mm", "")
        setValue("product_kan_width_mm", "")
    }
}, [ panniGadget, panniKan])

  const onSubmit = (branddetail: any) => {

    if (boriAndCartonImage.length == 0) {
      delete branddetail.product_image
    }

    if (productPDF.length == 0) {
      delete branddetail.product_pdf_drawing
    }

    if (productType === "Disposable") {
      delete branddetail.panni;
      delete branddetail.product_sealing_type
      delete branddetail.product_our_other
      delete branddetail.product_special_running_type
    }
    if (panniGadget === "no") {
      delete branddetail.gadget_size_mm
    }

    if (panniKan === "no") {
      delete branddetail.product_kan_length_mm
      delete branddetail.product_kan_width_mm
    }
    // if (panniGadget === 'no') {
    // delete branddetail.thickness_gauge;
    // }

    if (productType === "Lldp" || productType === "Ld") {
      delete branddetail.cavity_to_product;
      delete branddetail.current_lip;
      delete branddetail.mould_shape;
      delete branddetail.price_list_mould_diameter_mm;
      delete branddetail.product_actual_ml;
      delete branddetail.product_ml_price_list;
    }
    if (valueProductOurOther === undefined) {
      delete branddetail?.product_our_other
    }
    if (valueProductSpacial === undefined) {
      delete branddetail?.product_special_running_type
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (key === "product_image") {
        for (let i = 0; i < boriAndCartonImage.length; i++) {

          formData.append('product_image', boriAndCartonImage[i])
        }
        continue
      }

      if (key === "product_pdf_drawing") {
        for (let i = 0; i < productPDF.length; i++) {

          formData.append('product_pdf_drawing', productPDF[i])
        }
        continue
      }
      if (valueonePcsWeight && key === 'product_1_pcs_weight') {
        let decimalNumber = Number(valueonePcsWeight);
        const formattedString = decimalNumber.toFixed(3);
        formData.append('product_1_pcs_weight', formattedString)
        continue
      }
      if (ValuepricelistOnepcs && key === 'price_list_1_pcs_weight') {
        let decimalNumber = Number(ValuepricelistOnepcs);
        const formattedString = decimalNumber.toFixed(2);
        formData.append('price_list_1_pcs_weight', formattedString)
        continue
      }
      if (ValueProductionOnepcs && key === 'product_1_pcs_weight') {
        let decimalNumber = Number(ValueProductionOnepcs);
        const formattedString = decimalNumber.toFixed(2);
        formData.append('product_1_pcs_weight', formattedString)
        continue
      }
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else if (Array.isArray(value) && key === "color") {
        value.forEach((val, index) => formData.append(`color`, val));
      } else if (key === "product_image" || key === "product_drawing") {
        if (
          typeof value === "object" &&
          value !== null &&
          formvalue.length > 0
        ) {
          formData.append(key, formvalue[0]);
        } else {
          formData.append(key, "");
        }
      }
    }
    singlecode(formData);
  };

  const [logo, setlogo] = useState("");
  const [logoPdf, setlogoPdf] = useState("");

  const onLogoChange = (e: any) => {
    setlogo(e.target.files[0].name);
  };

  const onLogoPDFChange = (e: any) => {
    setlogoPdf(e.target.files[0].name);
  };

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listSinglecode", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  const handleValue = (e: any) => {
    e.target.value = e.target.value
      .toLowerCase()
      .split(" ")
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };
  const [optionsPriceList, setOptionsPriceList]: any = useState([]);
  const { data: priceData, refetch: priceRefetch } =
    useFetchProductMLDropDown();



  useEffect(() => {
    setOptionsPriceList(priceData);
  }, [priceData]);

  const apinameForPrice = process.env.REACT_APP_PRODUCT_ML_API?.toString();

  const {
    mutate: mutatePrice,
    data: dataPrice,
    isError: cpriceIsError,
    isLoading: priceLoading,
    isSuccess: priceSuccess,
    error: priceError,
  }: any = useCreate(apinameForPrice);

  //   const [optionsPrice, setOptionsCavity]:any = useState([]);

  const [valuePriceList, setValuePriceList]: any = useState();

  const [isLoadingValue, setIsLoadingValue]: any = useState();



  const [valuePriceCreatable, setValuePriceCreatable] = useState<Option | null>();

  const handleCreatePrice = (inputValue: string) => {
    // setValueCavityCreatable(inputValue)

    // setIsLoadingValue(true);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setIsLoadingValue(false);

    //   // setValue("name", `${newOption?.value}`)

    //   mutatePrice({
    //     name: newOption.name,
    //     status: newOption.status,
    //   });
    //   setOptionsPriceList((prev: any) =>
    //     prev ? [...prev, newOption] : [newOption]
    //   );
    //   setValuePriceCreatable(newOption);
    // }, 0);
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutatePrice({ name: newOption.name, status: newOption.status })
      setOptionsPriceList((prev: any) => [...prev, newOption]);
      setValuePriceCreatable(newOption);
    }, 0);
  };

  const handleChangePriceList = (e: any) => {

    priceRefetch()

    setApiName(apinameForPrice)
    setDeleteApiName(apinameForPrice)
    setEditId(e?.value)
    setEditData(priceData)
    setRenderKey(prevKey => prevKey + 1);

    setValuePriceList(e)


    setValue("product_ml_price_list", e?.value);
    setValuePriceCreatable(null);
  };

  useEffect(() => {
    (async () => {
      if (priceSuccess && optionsPriceList) {
        await priceRefetch();
        setValue("product_ml_price_list", priceData[0]?.value);
      }
    })();

    return () => { };
  }, [priceSuccess, priceData]);


  const { data: ProductActualMLData, refetch: ProductActualMLRefetch } = useFetchProductActualMLDropDown(searchTerm);

  useEffect(() => {
    setOptionsProductActualML(ProductActualMLData);
  }, [ProductActualMLData]);

  const apinameForProductActualML = process.env.REACT_APP_PRODUCT_ACTUAL_ML_API?.toString();

  const {
    mutate: mutateProductActualML,
    data: dataProductActualML,
    isError: ProductActualMLIsError,
    isLoading: ProductActualMLLoading,
    isSuccess: ProductActualMLSuccess,
    error: ProductActualMLError,
  }: any = useCreate(apinameForProductActualML);

  //   const [optionsPrice, setOptionsCavity]:any = useState([]);

  const [valueProductActualML, setValueProductActualML]: any = useState();
  const [optionsProductActualML, setOptionsProductActualML]: any = useState([]);
  const [valueProductActualMLCreatable, setValueProductActualMLCreatable] = useState<Option | null>();

  const handleCreateProductActualML = (inputValue: string) => {
    // setValueCavityCreatable(inputValue)

    // setIsLoadingValue(true);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setIsLoadingValue(false);

    //   // setValue("name", `${newOption?.value}`)

    //   mutateProductActualML({
    //     name: newOption.name,
    //     status: newOption.status,
    //   });
    //   setOptionsProductActualML((prev: any) =>
    //     prev ? [...prev, newOption] : [newOption]
    //   );
    //   setValueProductActualMLCreatable(newOption);
    // }, 0);

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateProductActualML({ name: newOption.name, status: newOption.status })
      setOptionsProductActualML((prev: any) => [...prev, newOption]);
      setValueProductActualMLCreatable(newOption);
    }, 0);

  };

  const handleChangeProductActualML = (e: any) => {

    ProductActualMLRefetch()

    setApiName(apinameForProductActualML)
    setDeleteApiName(apinameForProductActualML)
    setEditId(e?.value)
    setEditData(ProductActualMLData)
    setRenderKey(prevKey => prevKey + 1);

    setValueProductActualML(e)

    setValue("product_actual_ml", `${e?.value}`);
    setValueProductActualMLCreatable(null);
  };

  useEffect(() => {
    (async () => {
      if (ProductActualMLSuccess && optionsProductActualML) {
        await ProductActualMLRefetch();
        setValue("product_actual_ml", ProductActualMLData[0]?.value);
      }
    })();

    return () => { };
  }, [ProductActualMLSuccess, ProductActualMLData]);


  const { data: MouldDiameterMMData, refetch: MouldDiameterMMRefetch } = useFetchMouldDiameterMMDropDown(searchTerm);

  useEffect(() => {
    setOptionsMouldDiameterMM(MouldDiameterMMData);
  }, [MouldDiameterMMData]);

  const apinameForMouldDiameterMM = process.env.REACT_APP_PRICE_LIST_MOULD_DIAMETER_MM_API?.toString();

  const {
    mutate: mutateMouldDiameterMM,
    data: dataMouldDiameterMM,
    isError: MouldDiameterMMIsError,
    isLoading: MouldDiameterMMLoading,
    isSuccess: MouldDiameterMMSuccess,
    error: MouldDiameterMMError,
  }: any = useCreate(apinameForMouldDiameterMM);

  //   const [optionsPrice, setOptionsCavity]:any = useState([]);

  const [valueMouldDiameterMM, setValueMouldDiameterMM]: any = useState();
  const [optionsMouldDiameterMM, setOptionsMouldDiameterMM]: any = useState([]);
  const [valueMouldDiameterMMCreatable, setValueMouldDiameterMMCreatable] = useState<Option | null>();

  const handleCreateMouldDiameterMM = (inputValue: string) => {
    // setValueCavityCreatable(inputValue)

    // setIsLoadingValue(true);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setIsLoadingValue(false);

    //   // setValue("name", `${newOption?.value}`)

    //   mutateMouldDiameterMM({
    //     name: newOption.name,
    //     status: newOption.status,
    //   });
    //   setOptionsMouldDiameterMM((prev: any) =>
    //     prev ? [...prev, newOption] : [newOption]
    //   );
    //   setValueMouldDiameterMMCreatable(newOption);
    // }, 0);
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateMouldDiameterMM({ name: newOption.name, status: newOption.status })
      setOptionsMouldDiameterMM((prev: any) => [...prev, newOption]);
      setValueMouldDiameterMMCreatable(newOption);
    }, 0);
  };

  const handleChangeMouldDiameterMM = (e: any) => {

    MouldDiameterMMRefetch()

    setApiName(apinameForMouldDiameterMM)
    setDeleteApiName(apinameForMouldDiameterMM)
    setEditId(e?.value)
    setEditData(MouldDiameterMMData)
    setRenderKey(prevKey => prevKey + 1);

    setValueMouldDiameterMM(e)

    setValue("price_list_mould_diameter_mm", `${e?.value}`);
    setValueMouldDiameterMMCreatable(null);
  };

  useEffect(() => {
    (async () => {
      if (MouldDiameterMMSuccess && optionsMouldDiameterMM) {
        await MouldDiameterMMRefetch();
        setValue("price_list_mould_diameter_mm", MouldDiameterMMData[0]?.value);
      }
    })();

    return () => { };
  }, [MouldDiameterMMSuccess, MouldDiameterMMData]);

  const { data: CurrentLipData, refetch: CurrentLipRefetch } = useFetchCurrentLipDropDown(searchTerm);

  useEffect(() => {
    setOptionsCurrentLip(CurrentLipData);
  }, [CurrentLipData]);

  const apinameForCurrentLip = process.env.REACT_APP_CURRENT_LIP_API?.toString();

  const {
    mutate: mutateCurrentLip,
    data: dataCurrentLip,
    isError: CurrentLipIsError,
    isLoading: CurrentLipLoading,
    isSuccess: CurrentLipSuccess,
    error: CurrentLipError,
  }: any = useCreate(apinameForCurrentLip);


  const [valueCurrentLip, setValueCurrentLip]: any = useState();
  const [optionsCurrentLip, setOptionsCurrentLip]: any = useState([]);
  const [valueCurrentLipCreatable, setValueCurrentLipCreatable] = useState<Option | null>();

  const handleCreateCurrentLip = (inputValue: string) => {

    // setIsLoadingValue(true);
    // setTimeout(() => {
    //   const newOption = createOption(inputValue);
    //   setIsLoadingValue(false);


    //   mutateCurrentLip({
    //     name: newOption.name,
    //     status: newOption.status,
    //   });
    //   setOptionsCurrentLip((prev: any) =>
    //     prev ? [...prev, newOption] : [newOption]
    //   );
    //   setValueCurrentLipCreatable(newOption);
    // }, 0);
    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateCurrentLip({ name: newOption.name, status: newOption.status })
      setOptionsCurrentLip((prev: any) => [...prev, newOption]);
      setValueCurrentLipCreatable(newOption);
    }, 0);
  };

  const handleChangeCurrentLip = (e: any) => {

    CurrentLipRefetch()

    setApiName(apinameForCurrentLip)
    setDeleteApiName(apinameForCurrentLip)
    setEditId(e?.value)
    setEditData(CurrentLipData)
    setRenderKey(prevKey => prevKey + 1);

    setValueCurrentLip(e)

    setValue("current_lip", `${e?.value}`);
    setValueCurrentLipCreatable(null);
  };

  useEffect(() => {
    (async () => {
      if (CurrentLipSuccess && optionsCurrentLip) {
        await CurrentLipRefetch();
        setValue("current_lip", CurrentLipData[0]?.value);
      }
    })();

    return () => { };
  }, [CurrentLipSuccess, CurrentLipData]);


  const apinameForSealingType = process.env.REACT_APP_PANNI_SEALING_TYPE_API?.toString()
  const { mutate: mutateSealingType, data: dataSealingType, isError: SealingTypeIsError, isLoading: SealingTypeLoading, isSuccess: SealingTypeSuccess, error: SealingTypeError }: any = useCreate(apinameForSealingType)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueSealingType, setValueSealingType]: any = useState();
  const [optionsSealingType, setOptionsSealingType]: any = useState([]);
  const [valueSealingTypeCreatable, setValueSealingTypeCreatable] = useState<Option | null>();

  const { data: SealingTypeData, refetch: SealingTypeRefetch } = useFetchPanniSealingTypeDropDown()

  useEffect(() => {
    setOptionsSealingType(SealingTypeData)
  }, [SealingTypeData])


  useEffect(() => {
    if (searchTerm?.length >= 1) {
      SealingTypeRefetch()
    }
  }, [])


  const handleChangeSealingType = (e: any) => {

    SealingTypeRefetch()

    setApiName(apinameForSealingType)
    setDeleteApiName(apinameForSealingType)
    setEditId(e?.value)
    setEditData(SealingTypeData)
    setRenderKey(prevKey => prevKey + 1);

    setValueSealingType(e)


    setValue("product_sealing_type", `${e?.value}`)
    setValueSealingTypeCreatable(null)
  }





  const handleCreateSealingType = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateSealingType({ name: newOption.name, status: newOption.status })
      setOptionsSealingType((prev: any) => [...prev, newOption]);
      setValueSealingTypeCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (SealingTypeSuccess && optionsSealingType) {
        await SealingTypeRefetch();
        setValue("product_sealing_type", SealingTypeData[0].value)
      }
    })();

    return () => {
    };
  }, [SealingTypeSuccess, SealingTypeData]);



  const apinameForProductOurOther = process.env.REACT_APP_PRODUCT_OUR_OTHER_API?.toString()
  const { mutate: mutateProductOurOther, data: dataProductOurOther, isError: ProductOurOtherIsError, isLoading: ProductOurOtherLoading, isSuccess: ProductOurOtherSuccess, error: ProductOurOtherError }: any = useCreate(apinameForProductOurOther)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductOurOther, setValueProductOurOther]: any = useState();
  const [optionsProductOurOther, setOptionsProductOurOther]: any = useState([]);
  const [valueProductOurOtherCreatable, setValueProductOurOtherCreatable] = useState<Option | null>();

  const { data: ProductOurOtherData, refetch: ProductOurOtherRefetch } = useFetchProductOurOtherDropDown()

  useEffect(() => {
    setOptionsProductOurOther(ProductOurOtherData)
  }, [ProductOurOtherData])





  const handleChangeProductOurOther = (e: any) => {
    ProductOurOtherRefetch()

    setApiName(apinameForProductOurOther)
    setDeleteApiName(apinameForProductOurOther)
    setEditId(e?.value)
    setEditData(ProductOurOtherData)
    setRenderKey(prevKey => prevKey + 1);

    setValueProductOurOther(e)

    setValue("product_our_other", `${e?.value}`)
    setValueProductOurOtherCreatable(null)
  }





  const handleCreateProductOurOther = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    // setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      // setIsLoadingValue(false);


      mutateProductOurOther({ name: newOption.name, status: newOption.status })
      setOptionsProductOurOther((prev: any) => [...prev, newOption]);
      setValueProductOurOtherCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductOurOtherSuccess && optionsProductOurOther) {
        await ProductOurOtherRefetch();
        setValue("product_our_other", ProductOurOtherData[0]?.value)
      }
    })();

    return () => {
    };
  }, [ProductOurOtherSuccess, ProductOurOtherData]);

  const apinameForProductSpacial = process.env.REACT_APP_MATERIAL_USE_API?.toString()
  const { mutate: mutateProductSpacial, data: dataProductSpacial, isError: ProductSpacialIsError, isLoading: ProductSpacialLoading, isSuccess: ProductSpacialSuccess, error: ProductSpacialError }: any = useCreate(apinameForProductSpacial)
  // const [isLoadingValue, setIsLoadingValue] = useState(false);

  const [valueProductSpacial, setValueProductSpacial]: any = useState();
  const [optionsProductSpacial, setOptionsProductSpacial]: any = useState([]);
  const [valueProductSpacialCreatable, setValueProductSpacialCreatable] = useState<Option | null>();

  const { data: ProductSpacialData, refetch: ProductSpacialRefetch } = useFetchMaterialUseDropDown()

  useEffect(() => {
    setOptionsProductSpacial(ProductSpacialData)
  }, [ProductSpacialData])


  useEffect(() => {
    if (searchTerm?.length >= 1) {
      ProductSpacialRefetch()
    }
  }, [])


  const handleChangeProductSpacial = (e: any) => {

    ProductSpacialRefetch()

    setApiName(apinameForProductSpacial)
    setDeleteApiName(apinameForProductSpacial)
    setEditId(e?.value)
    setEditData(ProductSpacialData)
    setRenderKey(prevKey => prevKey + 1);

    setValueProductSpacial(e)

    setValue("product_special_running_type", `${e?.value}`)
    setValueProductSpacialCreatable(null)
  }





  const handleCreateProductSpacial = (inputValue: string) => {

    inputValue = inputValue.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setIsLoadingValue(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoadingValue(false);


      mutateProductSpacial({ name: newOption.name, status: newOption.status })
      setOptionsProductSpacial((prev: any) => [...prev, newOption]);
      setValueProductSpacialCreatable(newOption);
    }, 0);


  };

  useEffect(() => {
    (async () => {
      if (ProductSpacialSuccess && optionsProductSpacial) {
        await ProductSpacialRefetch();
        setValue("product_special_running_type", ProductSpacialData[0]?.value)
      }
    })();

    return () => {
    };
  }, [ProductSpacialSuccess, ProductSpacialData]);


  const [submittedHSN, setSubmittedHSN] = useState(false)


  useEffect(() => {
    if (submittedHSN) {
      setForHSNValue(optionsHSN[0])

      setValue("hsn_code", optionsHSN[0]?.value)

    }

  }, [submittedHSN, optionsHSN])


  useEffect(() => {


    if (submittedColor) {

      setColorValue(optionsColor[0])
      setValue("color", optionsColor[0]?.value)


    }


  }, [submittedColor, optionsColor])
  useEffect(() => {


    if (submittedMouldShape) {

      setMouldValue(optionsMouldShape[0])
      setValue("mould_shape", optionsMouldShape[0]?.value)


    }


  }, [submittedMouldShape, optionsMouldShape])
  useEffect(() => {


    if (submittedProduct) {

      setCavityValue(optionsCavity[0])
      setValue("cavity_to_product", optionsCavity[0]?.value)


    }


  }, [submittedProduct, optionsCavity])


  const [boriAndCartonImage, setBoriAndCartonImage] = useState([])
  const [uploadBoriAndCartonImage, setuploadBoriAndCartonImage] = useState<UploadFile[]>([])
  const [productPDF, setProductPDF] = useState([])
  const [uploadProductPDF, setUploadProductPDF] = useState<UploadFile[]>([])

  const BoriAndCartonChange = (e: any) => {
    e.file.status = "done"

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    })
    setBoriAndCartonImage(tempFiles)
    setuploadBoriAndCartonImage(e.fileList)

  }

  const ProductPDFOnChange = (e: any) => {
    e.file.status = "done"

    const tempFiles = e.fileList.map((file: any) => {
      return file.originFileObj
    })
    setProductPDF(tempFiles)
    setUploadProductPDF(e.fileList)

  }


  const [renderKey, setRenderKey] = useState(0);
  const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
  const [deleteApiName, setDeleteApiName]: any = useState("")
  const [deleteid, setDeleteid] = useState();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
  const [editData, setEditData]: any = useState()
  const [editId, setEditId]: any = useState()
  const [apiName, setApiName]: any = useState()


  const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
  const [confirmforcemodal, setConfirmforcemodal] = useState(false);

  const {
    mutate: singleDelete,
    data: deletedata,
    isError: deletecoloriserror,
    isSuccess: deletesucess,
  } = useDelete();
  const {
    mutate: deleteMultipleMutet,
    data: deleteMultiple,
    isError: deleteMultipleIsError,
    isSuccess: deleteMultipleSuccess,
  } = useDeletemultiple();
  const {
    mutate: deleteMultipleMutetforce,
    isSuccess: forcefullysuccess,
    isError: forcefullyerror,
  } = useDeletemultipleforce();
  const {
    mutate: deleteforce,
    isSuccess: forcefullysingledeletesuccess,
    isError: forcefullydeleteerror,
  } = useDeletemforce();




  const handleRefetch = () => {
    if (apiName === apinameForMaterialType) {
      MaterialTypeRefetch()
    }
    if (apiName === apinameForPrice) {
      priceRefetch()
    }
    if (apiName === apinameForSealingType) {
      SealingTypeRefetch()
    }
    if (apiName === apinameForProductActualML) {
      ProductActualMLRefetch()
    }
    if (apiName === apinameForMouldDiameterMM) {
      MouldDiameterMMRefetch()
    }
    if (apiName === apinameForCurrentLip) {
      CurrentLipRefetch()
    }
    if (apiName === apinameForProductOurOther) {
      ProductOurOtherRefetch()
    }
    if (apiName === apinameForProductSpacial) {
      ProductSpacialRefetch()
    }
    if (apiName === apinameForProductType) {
      ProductTypeRefetch()
    }





    // else if(apiName === apinameForTaluka){
    //   TalukaRefetch()
    // }
  }


  useEffect(() => {
    if (OpenEditDropDownModel && valueMaterialType && apiName === apinameForMaterialType) {
      setValueMaterialType(null)
      setValueMaterialTypeCreatable(null)
    }
    if (OpenEditDropDownModel && valuePriceList && apiName === apinameForPrice) {
      setValuePriceList(null)
      setValuePriceCreatable(null)
    }
    if (OpenEditDropDownModel && valueSealingType && apiName === apinameForSealingType) {
      setValueSealingType(null)
      setValueSealingTypeCreatable(null)
    }
    if (OpenEditDropDownModel && valueProductActualML && apiName === apinameForProductActualML) {
      setValueProductActualML(null)
      setValueProductActualMLCreatable(null)
    }
    if (OpenEditDropDownModel && valueMouldDiameterMM && apiName === apinameForMouldDiameterMM) {
      setValueMouldDiameterMM(null)
      setValueMouldDiameterMMCreatable(null)
    }
    if (OpenEditDropDownModel && valueCurrentLip && apiName === apinameForCurrentLip) {
      setValueCurrentLip(null)
      setValueCurrentLipCreatable(null)
    }
    if (OpenEditDropDownModel && valueProductOurOther && apiName === apinameForProductOurOther) {
      setValueProductOurOther(null)
      setValueProductOurOtherCreatable(null)
    }
    if (OpenEditDropDownModel && valueProductSpacial && apiName === apinameForProductSpacial) {
      setValueProductSpacial(null)
      setValueProductSpacialCreatable(null)
    }
    if (OpenEditDropDownModel && valueProductType && apiName === apinameForProductType) {
      setValueProductType(null)
      setValueProductTypeCreatable(null)
    }








    if (deletesucess && apiName === apinameForMaterialType) {
      setValueMaterialType(null)
      setValueMaterialTypeCreatable(null)
    }
    if (deletesucess && apiName === apinameForPrice) {
      setValuePriceList(null)
      setValuePriceCreatable(null)
    }
    if (deletesucess && apiName === apinameForSealingType) {
      setValueSealingType(null)
      setValueSealingTypeCreatable(null)
    }
    if (deletesucess && apiName === apinameForProductActualML) {
      setValueProductActualML(null)
      setValueProductActualMLCreatable(null)
    }
    if (deletesucess && apiName === apinameForMouldDiameterMM) {
      setValueMouldDiameterMM(null)
      setValueMouldDiameterMMCreatable(null)
    }
    if (deletesucess && apiName === apinameForCurrentLip) {
      setValueCurrentLip(null)
      setValueCurrentLipCreatable(null)
    }
    if (deletesucess && apiName === apinameForProductOurOther) {
      setValueProductOurOther(null)
      setValueProductOurOtherCreatable(null)
    }
    if (deletesucess && apiName === apinameForProductSpacial) {
      setValueProductSpacial(null)
      setValueProductSpacialCreatable(null)
    }
    if (deletesucess && apiName === apinameForProductType) {
      setValueProductType(null)
      setValueProductTypeCreatable(null)
    }



  }, [OpenEditDropDownModel, apiName, deletesucess])


  const [isExist, setIsExist] = useState(true)


  const CustomOption = ({ innerProps, label, value }: any) => (


    <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <span>{label}</span>
      <div style={{ float: "right", justifyContent: "end" }}>



        {isExist ?

          <Custom_Actions_Buttons value={value}
            apiname={deleteApiName}
            mutate={singleDelete}
            // masterName={addButtonName}
            deleteid={deleteid}
            handleDeletedforce={handleDeletedforce}
            closereconfirmdelete={closereconfirmdelete}
            deletemodalask={deletemodalask}
            setEditOpen={setOpenEditDropDownModel}
          // permissionClass={permissionClass}
          />
          : ""}

      </div>
    </div>


  )


  const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate,
    Option: CustomOption
  }



  useEffect(() => {
    if (deletesucess === true) { }
    // CountryRefetch();
  }, [deletesucess, editData?.results]);
  useEffect(() => {
    return () => {
      setDeleteid(undefined);
    };
  }, []);
  const handleConfirmDelete = () => {
    setDeleteConfirm(true);
  };
  const closereconfirmdelete = () => {
    setConfirmforcemodal(false);
    setDeleteMultipleid([]);
  };

  const confirmmodalclose = () => {
    setDeleteConfirmsingle(false);
    setDeleteid(undefined);
  };
  const confirmmodalsubmit = () => {
    setDeleteConfirmsingle(false);
    singleDelete({ deleteid, apiname: deleteApiName });
  };
  const deletemodalask = (deleteidselect: any) => {
    setDeleteConfirmsingle(true);
    setDeleteid(deleteidselect);
  };
  const handleDeletedforce = () => {
    setConfirmforcemodal(false);
    if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
    else if (deleteMultipleSuccess) {
      deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
    }
  };
  useEffect(() => {
    if (forcefullysuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullyerror) {
      setShow(true);
      setshowmessage("danger");
    } else if (forcefullysingledeletesuccess) {
      setShow(true);
      setshowmessage("success");
    } else if (forcefullydeleteerror) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [
    forcefullysuccess,
    forcefullyerror,
    forcefullysingledeletesuccess,
    forcefullydeleteerror,
  ]);

  useEffect(() => {
    if (deleteMultipleSuccess) {
      const showdepenedancymsg = deleteMultiple?.data?.data;
      const dependacyfound = showdepenedancymsg?.filter((color: any) => {
        if (
          color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
        ) {
          return color;
        }
      });
      if (dependacyfound?.length === deleteMultipleid?.length) {
        deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
      } else {
        setConfirmforcemodal(true);
      }
    } else if (deleteMultipleIsError) {
      setShow(true);
      setshowmessage("danger");
    } else if (deletesucess) {
      if (deletedata.data?.data?.length > 0) {
        setConfirmforcemodal(true);
      } else if (deleteid) {
        setShow(true);
        setshowmessage("success");
        deleteforce({ deleteid, apiname: deleteApiName });
      }
    }
  }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

  const handleMultipleDeleted = () => {
    deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
    setDeleteConfirm(false);
  };



  console.log(valueProductOurOther, "valueProductOurOther")


  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{PageTitle}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Masters
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#" className="text-decoration-none text-black">
                      Add {PageTitle}
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add GST</span></h5>
                            </div> */}
              <form
                ref={focus}
                onKeyUp={(event) => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="card-body">
                  {show && showmessage === "danger" ? (
                    <Alert
                      color={showmessage}
                      message={error?.response?.data?.message}
                    />
                  ) : (
                    ""
                  )}

                  {/* {show && showmessage === "success" && <Alert color={showmessage} message="Brand successfully Updated" />} */}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        {/* <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product Category <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            // id="Panni Name & Grade"
                            {...register("product_category", {
                              required: {
                                value: true,
                                message: "Field is required",
                              },
                            })}
                            onChange={handleChangeCategory}
                            options={optionsCategory}
                            components={customIndicator}
                            value={prductCategoryValue}

                          />
                          <span className="text-red w-100">
                            {errors?.product_type?.message}
                          </span>
                        </div> */}
                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product Type <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <Select
                            // id="Panni Name & Grade"
                            {...register("product_type", {
                              required: {
                                value: true,
                                message: "Field is required",
                              },
                            })}
                            onInputChange={handleInputChangeProductType}
                            onChange={handleChangeProductType}
                            options={optionsProductType}
                            components={customIndicator}
                            value={prductTypeValue}

                          /> */}

                          <CreatableSelect
                            isClearable={() => {
                              setValueProductType(null)
                              setValue(null)
                            }}
                            {...register("product_type", { required: { value: true, message: "Product Type is required" } })}
                            placeholder={'Select Product Type'}
                            // menuIsOpen={true}

                            components={customCreatableIndicator}
                            options={optionsProductType}
                            onMenuOpen={() => ProductTypeRefetch()}
                            onCreateOption={handleCreateProductType}
                            onChange={handleChangeProductType}
                            value={valueProductTypeCreatable == null ? valueProductType : valueProductTypeCreatable}


                          />
                          <span className="text-red w-100">
                            {errors?.product_type?.message}
                          </span>
                        </div>

                        {valueProductType?.label === "Disposable" || valueProductTypeCreatable?.label === "Disposable" ? (
                          // { productCategoryLable ==="Disposable" && prductTypeValue&& ( 
                          <>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Material Name & Type & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                              <div className="d-flex row" >
                                <Select
                                  placeholder={'Select Material Name & Type'}
                                  {...register("HSN_code", { required: { value: true, message: "Material Name & Type & Category & HSN & GST% is required" } })}
                                  onChange={handleChangeHSN}
                                  options={optionsHSN}
                                  components={customIndicator}
                                  value={forHSNValue}
                                  // value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                  className="dropdown-select"
                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setOpenHSN(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>

                              </div>
                              <span className='text-red w-100'>{errors?.HSN_code?.message}</span>


                            </div>

                            {/* <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Type Name & Density & Sutra & GST%
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  placeholder="Type Name & Density & Sutra & GST%"
                                  disabled
                                  value={DensityValue}
                                />

                              </div>

                            </div> */}
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Material Type <span style={{ color: 'red' }}>*</span></label>
                              <CreatableSelect
                                isClearable={() => {
                                  setValueMaterialType(null)
                                  setValue(null)
                                }}
                                {...register("material_type", { required: { value: true, message: "Material Type is required" } })}
                                placeholder={'Select Material Type'}
                                components={customCreatableIndicator}
                                options={optionsMaterialType}
                                onCreateOption={handleCreateMaterialType}
                                onChange={handleChangeMaterialType}
                                onMenuOpen={() => MaterialTypeRefetch()}
                                value={valueMaterialTypeCreatable == null ? valueMaterialType : valueMaterialTypeCreatable}

                              />
                              <span className='text-red w-100'>{errors?.material_type?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Color Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="d-flex row" >
                                <Select
                                  {...register("color", {
                                    required: {
                                      value: true,
                                      message: "Field is required",
                                    },
                                  })}
                                  placeholder={"Product Color Name"}
                                  onInputChange={handleInputChangeColor}
                                  onChange={handleChangeColor}
                                  components={customIndicator}
                                  options={optionsColor}
                                  value={colorValue}
                                  className="dropdown-select"

                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setColorOpen(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.color?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="d-flex row" >
                                <Select
                                  {...register("cavity_to_product", {
                                    required: {
                                      value: true,
                                      message: "Product Name is required",
                                    },
                                  })}
                                  onInputChange={handleInputChangeCavity}
                                  onChange={handleChangeCavity}
                                  options={optionsCavity}
                                  components={customIndicator}
                                  value={cavityValue}
                                  className="dropdown-select"
                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setOpenProduct(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.cavity_to_product?.message}
                              </span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Price-List 1-PCS Weight Gram
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  placeholder="Product Price-List 1-PCS Weight Gram"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  {...register("price_list_1_pcs_weight", {
                                    onChange: ((e: any) => setValuepricelistOnepcs(e.target.value)),
                                    required: {
                                      value: false,
                                      message: "Product Price-List 1-PCS Weight Gram is required",
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.price_list_1_pcs_weight?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Production 1-PCS Weight Gram
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  placeholder="Product Production 1-PCS Weight Gram"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  {...register("product_1_pcs_weight", {
                                    onChange: ((e: any) => setValueProductionOnepcs(e.target.value)),
                                    required: {
                                      value: false,
                                      message: "Product Production 1-PCS Weight Gram is required",
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_1_pcs_weight?.message}
                              </span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product ML Price List <span style={{ color: 'red' }}>*</span>
                              </label>


                              <CreatableSelect
                                isClearable={() => {
                                  setValuePriceList(null);
                                  setValue(null);
                                }}
                                {...register("product_ml_price_list", {
                                  onChange: handleValue,
                                  required: {
                                    value: true,
                                    message: "Field is required",
                                  },
                                })}
                                components={customCreatableIndicator}
                                options={optionsPriceList}
                                onCreateOption={handleCreatePrice}
                                onChange={handleChangePriceList}
                                onMenuOpen={() => priceRefetch()}
                                value={
                                  valuePriceCreatable == null
                                    ? valuePriceList
                                    : valuePriceCreatable
                                }
                              />

                              <span className="text-red">
                                {errors?.product_ml_price_list?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product ML Actual <span style={{ color: 'red' }}>*</span>
                              </label>

                              <CreatableSelect
                                isClearable={() => {
                                  setValueProductActualML(null);
                                  setValue(null);
                                }}
                                {...register("product_actual_ml", {
                                  onChange: handleValue,
                                  required: {
                                    value: true,
                                    message: "Product ML Actual is required",
                                  },
                                })}
                                components={customCreatableIndicator}
                                options={optionsProductActualML}
                                onCreateOption={handleCreateProductActualML}
                                onChange={handleChangeProductActualML}
                                onMenuOpen={() => ProductActualMLRefetch()}
                                value={
                                  valueProductActualMLCreatable == null
                                    ? valueProductActualML
                                    : valueProductActualMLCreatable
                                }
                              />
                              <span className="text-red w-100">
                                {errors?.product_actual_ml?.message}
                              </span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Price-List Mould Diameter mm <span style={{ color: 'red' }}>*</span>
                              </label>

                              <CreatableSelect
                                isClearable={() => {
                                  setValueMouldDiameterMM(null);
                                  setValue(null);
                                }}
                                {...register("price_list_mould_diameter_mm", {
                                  onChange: handleValue,
                                  required: {
                                    value: true,
                                    message: "Price-List Mould Diameter mm is required",
                                  },
                                })}
                                components={customCreatableIndicator}
                                options={optionsMouldDiameterMM}
                                onCreateOption={handleCreateMouldDiameterMM}
                                onChange={handleChangeMouldDiameterMM}
                                onMenuOpen={() => MouldDiameterMMRefetch()}
                                value={
                                  valueMouldDiameterMMCreatable == null
                                    ? valueMouldDiameterMM
                                    : valueMouldDiameterMMCreatable
                                }
                              />
                              <span className="text-red w-100">
                                {errors?.price_list_mould_diameter_mm?.message}
                              </span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Mould Shape & Diameter & Width & Length <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-group">
                                <Select
                                  {...register("mould_shape", {
                                    required: {
                                      value: true,
                                      message: "Product Name is required",
                                    },
                                  })}
                                  onChange={handleChangeMouldShape}
                                  options={optionsMouldShape}
                                  components={customIndicator}
                                  value={MouldValue}
                                  className="dropdown-select"

                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setMouldShapeOpen(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>

                              </div>
                              <span className="text-red w-100">
                                {errors?.mould_shape?.message}
                              </span>
                            </div>


                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Final Height(mm)
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  placeholder="Product Final Height(mm)"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  {...register("product_final_height_mm", {
                                    onChange: handleValue,
                                    required: {
                                      value: false,
                                      message: "Field is required",
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_final_height_mm?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Current Height(mm)
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  placeholder="Product Current Height(mm)"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  {...register("product_current_height_mm", {
                                    onChange: handleValue,
                                    required: {
                                      value: false,
                                      message: "Field is required",
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_current_height_mm?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Current Lip <span style={{ color: 'red' }}>*</span>
                              </label>

                              <CreatableSelect
                                isClearable={() => {
                                  setValueCurrentLip(null);
                                  setValue(null);
                                }}
                                {...register("current_lip", {
                                  required: {
                                    value: true,
                                    message: "Current Lip is required",
                                  },
                                })}
                                components={customCreatableIndicator}
                                options={optionsCurrentLip}
                                onCreateOption={handleCreateCurrentLip}
                                onChange={handleChangeCurrentLip}
                                onMenuOpen={() => CurrentLipRefetch()}
                                value={
                                  valueCurrentLipCreatable == null
                                    ? valueCurrentLip
                                    : valueCurrentLipCreatable
                                }
                              />
                              <span className="text-red w-100">
                                {errors?.current_lip?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Production Minimum Kg.
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  placeholder="Production Minimum Kg."
                                  className="form-control"
                                  id="exampleInputBrand"
                                  {...register("product_minimum_kg", {
                                    onChange: handleValue,
                                    required: {
                                      value: false,
                                      message: "Production Minimum Kg. is required",
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_minimum_kg?.message}
                              </span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Production 1 Kg. In Pcs
                              </label>
                              <div className="input-group">
                                <input
                                  disabled
                                  type="text"
                                  placeholder="Production 1 Kg. In Pcs"
                                  className="form-control"
                                  id="exampleInputBrand"
                                // {...register("product_minimum_kg", {
                                //   onChange: handleValue,
                                //   required: {
                                //     value: false,
                                //     message: "Production 1 Kg. In Pcs is required",
                                //   },
                                // })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.product_minimum_kg?.message}
                              </span>
                            </div>
                          </>
                        ) : null}

                        {valueProductType?.label != "Disposable" && valueProductType?.label || valueProductTypeCreatable?.label != "Disposable" && valueProductTypeCreatable?.label ? (
                          // { productCategoryLable ==="Liner" && prductTypeValue&& (
                          <>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Material Name & Type & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                              <div className="d-flex row" >
                                <Select
                                  placeholder={'Select Material Name & Type'}
                                  {...register("hsn_code", { required: { value: false, message: "Material Name & Type & Category & HSN & GST% is required" } })}
                                  onChange={handleChangeHSN}
                                  options={optionsHSN}
                                  components={customIndicator}
                                  value={forHSNValue}
                                  // value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                  className="dropdown-select"
                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setOpenHSN(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>

                              </div>
                              <span className='text-red w-100'>{errors?.hsn_code?.message}</span>


                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Material Type <span style={{ color: 'red' }}>*</span></label>
                              <CreatableSelect
                                isClearable={() => {
                                  setValueMaterialType(null)
                                  setValue(null)
                                }}
                                {...register("material_type", { required: { value: true, message: "Material Type is required" } })}
                                placeholder={'Select Material Type'}
                                components={customCreatableIndicator}
                                options={optionsMaterialType}
                                onCreateOption={handleCreateMaterialType}
                                onChange={handleChangeMaterialType}
                                onMenuOpen={() => MaterialTypeRefetch()}
                                value={valueMaterialTypeCreatable == null ? valueMaterialType : valueMaterialTypeCreatable}

                              />
                              <span className='text-red w-100'>{errors?.material_type?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Product Color Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="d-flex row" >
                                <Select
                                  // id="Panni Name & Grade"
                                  {...register("color", {
                                    required: {
                                      value: true,
                                      message: "Field is required",
                                    },
                                  })}
                                  placeholder={"Product Color Name"}
                                  onInputChange={handleInputChangeColor}
                                  onChange={handleChangeColor}
                                  options={optionsColor}
                                  components={customIndicator}
                                  value={colorValue}
                                  className="dropdown-select"
                                />
                                <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                  <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                    setColorOpen(true)
                                  }}><AiOutlinePlus /></Button>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.color?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Width mm <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control"
                                  // disabled={panniType != "" && gadget != "yes" ? false : true}
                                  id="exampleInputBrand" placeholder="Product Width mm"
                                  {...register("product_width_mm", {
                                    onChange: handleSetPanniWidth,
                                    required: { value: true, message: "Width is required" },
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: "input numbers only"
                                    }
                                  })}
                                //  onChange={(e:any)=>{
                                //     setPanniWidth(e.target.value)
                                //  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_width_mm?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Length mm <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control"
                                  // disabled={panniType != "" ? false : true}
                                  id="exampleInputBrand" placeholder="Product Length mm"
                                  {...register("product_length_mm", {
                                    onChange: handleSetPanniLength, required: { value: true, message: "Length is required" }
                                    , pattern: {
                                      value: /^[0-9]+$/,
                                      message: "input numbers only"
                                    }
                                  })}
                                //  onChange={(e:any)=>{
                                //     setPanniLength(Number(e.target.value))
                                //  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_length_mm?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Thickness Micron <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control"
                                  // disabled={panniType != "" ? false : true}
                                  id="exampleInputBrand" placeholder="Thickness Micron" {...register("thickness_micron", {
                                    onChange: handleSetGauge, required: { value: true, message: "Micron is required" }, pattern: {
                                      value: /^[0-9]+$/,
                                      message: "input numbers only"
                                    }
                                  })}

                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.thickness_micron?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Thickness Gauge
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Thickness Gauge"
                                  disabled
                                  // value={gauge}
                                  {...register("thickness_gauge", { required: { value: false, message: "Thickness Gauge is required" } })}
                                />
                              </div>
                              <span className='text-red w-100'>{errors?.thickness_gauge?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Gadget Yes-No </label>
                              <select className="form-select" {...register("gadget_yes_no", { onChange: onGadgetChange, required: { value: false, message: "Gadget is required" } })} disabled={(panniType == "single patto" ? true : false)}>
                                <option value={"no"} selected>Select Any</option>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"} >No</option>
                              </select>
                              <span className='text-red w-100'>{errors?.gadget_yes_no?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Gadget Size mm</label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="text" className="form-control" id="exampleInputBrand"
                                  placeholder="Add Gadget Size mm" {...register("gadget_size_mm", { required: { value: panniGadget === 'no' ? false : true, message: "Gadget is required" } })}
                                  // onChange={handleGadgetSize}
                                  disabled={panniGadget === 'no' || ''}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.gadget_size_mm?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Kan </label>

                              <select className="form-select" {...register("product_kan_yes_no", { onChange: onPanniChange, required: { value: false, message: "Product Kan is required" } })}
                                disabled={(panniType == "single patto" ? true : false)}
                              >
                                <option value={"no"} selected>Select Any</option>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"} >No</option>
                              </select>
                              <span className='text-red w-100'>{errors?.product_kan_yes_no?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Kan Width mm</label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control" id="exampleInputBrand"
                                  disabled={panniKan === "no" || ""}
                                  placeholder="Product Width mm" {...register("product_kan_width_mm", { required: { value: panniKan === "no" ? false : true, message: "Product Kan Width is required" } })} />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_kan_width_mm?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Kan Length mm</label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control" id="exampleInputBrand"
                                  disabled={panniKan === "no" || ""}
                                  placeholder="Product Length mm" {...register("product_kan_length_mm", { required: { value: panniKan === "no" ? false : true, message: "Product Kan Length is required" } })} onChange={handleSetPanniKanLength}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_kan_length_mm?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Sealing Type <span style={{ color: 'red' }}>*</span></label>
                              <CreatableSelect
                                isClearable={() => {
                                  setValueSealingType(null)
                                  setValue(null)
                                }}
                                {...register("product_sealing_type", { required: { value: true, message: "Product Sealing Type is required" } })}
                                placeholder={'Select Product Sealing Type'}
                                // menuIsOpen={true}
                                components={customCreatableIndicator}
                                options={optionsSealingType}
                                onCreateOption={handleCreateSealingType}
                                onChange={handleChangeSealingType}
                                onMenuOpen={() => SealingTypeRefetch()}
                                value={valueSealingTypeCreatable == null ? valueSealingType : valueSealingTypeCreatable}

                              />
                              <span className='text-red w-100'>{errors?.product_sealing_type?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product ML</label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control" id="exampleInputBrand"
                                  placeholder="Product ML" {...register("product_ml", { required: { value: false, message: "Product ML is required" } })} />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_ml?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Actual ML</label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control" id="exampleInputBrand"
                                  placeholder="Product Actual ML" {...register("product_ml_actual", { required: { value: false, message: "Product Actual ML is required" } })} />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_ml_actual?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product 1 Pcs Weight Gram
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product 1 Pcs Weight Gram"
                                  // disabled
                                  // value={oneNosWeight}
                                  {...register("product_1_pcs_weight", { onChange: ((e: any) => setValueonePcsWeight(e.target.value)), required: { value: false, message: "Product 1 Pcs Weight Gram" } })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_1_pcs_weight?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product 1 KG In Pcs
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product 1 KG in Nos"
                                  disabled
                                // value={oneKgNos}
                                // {...register("panni_1KG_in_nos", { required: { value: false, message: "KG is required" } })}
                                />
                              </div>
                              <span className='text-red w-100'>{errors?.panni_1KG_in_nos?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Production Minimum Kg <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control" id="exampleInputBrand" placeholder="Production Minimum Kg"
                                  {...register("product_minimum_kg", { onChange: handleValue, required: { value: true, message: "Production Minimum Kg is required" } })} />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_minimum_kg?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Production Maximum Kg <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="number" className="form-control" id="exampleInputBrand" placeholder="Production Maximum Kg"
                                  {...register("product_maximum_kg", { onChange: handleValue, required: { value: true, message: "Production Maximum Kg is required" } })} />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_maximum_kg?.message}</span>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Product Bursting Strength <span style={{ color: 'red' }}>*</span></label>
                              <div className="input-group" data-colorpicker-id={2}>
                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Bursting Strength" {...register("product_bursting_strength", { onChange: handleValue, required: { value: true, message: "Product Bursting Strength is required" } })} />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                </div>
                              </div>
                              <span className='text-red w-100'>{errors?.product_bursting_strength?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Production Our-Other</label>
                              {/* <span style={{ color: 'red' }}>*</span> */}
                              <CreatableSelect
                                isClearable={() => {
                                  setValueProductOurOther(null)
                                  setValue(null)
                                }}
                                {...register("product_our_other", { required: { value: false, message: "Production Our-Other is required" } })}
                                placeholder={'Select Production Our-Other'}
                                // menuIsOpen={true}
                                components={customCreatableIndicator}
                                options={optionsProductOurOther}
                                onCreateOption={handleCreateProductOurOther}
                                onChange={handleChangeProductOurOther}
                                onMenuOpen={() => ProductOurOtherRefetch()}
                                value={valueProductOurOtherCreatable == null ? valueProductOurOther : valueProductOurOtherCreatable}

                              />
                              <span className='text-red w-100'>{errors?.product_our_other?.message}</span>
                            </div>

                            <div className="col-sm-3 mt-2">

                              <label htmlFor="exampleInputEmail1">Prouct Type {/*<span style={{ color: 'red' }}>*</span>*/}</label>

                              <CreatableSelect
                                isClearable={() => {
                                  setValueProductSpacial(null)
                                  setValue(null)
                                }}
                                {...register("product_special_running_type", { required: { value: false, message: "Prouct Type is required" } })}
                                placeholder={'Select Prouct Type '}
                                // menuIsOpen={true}
                                components={customCreatableIndicator}
                                options={optionsProductSpacial}
                                onCreateOption={handleCreateProductSpacial}
                                onChange={handleChangeProductSpacial}
                                onMenuOpen={() => ProductSpacialRefetch()}
                                value={valueProductSpacialCreatable == null ? valueProductSpacial : valueProductSpacialCreatable}

                              />
                              <span className='text-red'>{errors?.product_special_running_type?.message}</span>

                            </div>

                            {/* <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni<span style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                // id="Panni Name & Grade"
                                {...register("panni", {
                                  required: {
                                    value: true,
                                    message: "Field is required",
                                  },
                                })}
                                onInputChange={handleInputChangePanni}
                                onChange={handleChangePanni}
                                options={optionsPanni}
                                value={panniValue}
                              />
                              <span className="text-red w-100">
                                {errors?.panni?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Type
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniType}
                                />
                              </div>
                              <span className="text-red w-100">
                                {errors?.type?.message}
                              </span>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Material
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniMaterial}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Sealing Type
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniSealing}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Length mm
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniLength}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Width mm
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniWidth}
                                />
                              </div>
                            </div> */}

                            {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Baloon Width mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control"
                                                                disabled
                                                                id="exampleInputBrand" 
                                                            {...register("width", {
                                                                onChange: handleSetPanniWidth, required: { value: true, message: "Width is required" },
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: "input numbers only"
                                                                }
                                                            })}
                                                        //  onChange={(e:any)=>{
                                                        //     setPanniWidth(e.target.value)
                                                        //  }}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.width?.message}</span>
                                                </div> */}

                            {/* <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">Gadget</label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniGadget}
                                />
                              </div>
                            </div>
                            {panniGadget === "yes" ? (
                              <>
                                <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Gadget Size mm
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                      value={panniGadgetSize}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Kan
                              </label>

                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniKan}
                                />
                              </div>
                            </div>
                            {panniKan === "yes" ? (
                              <>
                                <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Panni Kan Length mm
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                      value={panniKanLength}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-3 mt-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Panni Kan Width mm
                                  </label>
                                  <div
                                    className="input-group"
                                    data-colorpicker-id={2}
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="exampleInputBrand"
                                      disabled
                                      value={panniKanWidth}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Micron
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniMicron}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Gauge
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniGauge}
                                />
                              </div>
                            </div>

                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni 1 Nos weight Gram
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniOneNosWeight}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni 1 KG in Nos
                              </label>
                              <div
                                className="input-group"
                                data-colorpicker-id={2}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  disabled
                                  value={panniOneKgNos}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3 mt-2">
                              <label htmlFor="exampleInputEmail1">
                                Panni Balloon
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="exampleInputBrand"
                                  {...register("panni_balloon", {
                                    onChange: handleValue,
                                    required: {
                                      value: true,
                                      message: "Field is required",
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text border-start-0 bg-primary">
                                    <RiPencilLine color="white" />
                                  </span>
                                </div>
                              </div>
                              <span className="text-red w-100">
                                {errors?.panni_balloon?.message}
                              </span>
                            </div> */}
                          </>
                        ) : null}
                        {/* )} */}

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Remark
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              placeholder="Remark"
                              className="form-control"
                              id="exampleInputBrand"
                              {...register("remark", {
                                onChange: handleValue,
                                required: {
                                  value: false,
                                  message: "Field is required",
                                },
                              })}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine color="white" />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.remark?.message}
                          </span>
                        </div>


                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Product Status <span className="text-red">*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register("status", {
                              required: {
                                value: true,
                                message: "Status is required",
                              },
                              maxLength: 30,
                            })}
                          >
                            <option value="" selected>
                              -Status-
                            </option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>

                          <span className="text-red">
                            {errors?.status?.message}
                          </span>
                        </div>
                        <div className="row d-flex">
                          <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                            <div className="row d-flex ">
                              <div className="col-sm-3 mt-2 image-upload">
                                <label htmlFor="exampleInputEmail1" className="col-12">Product Image All </label>
                                <Upload
                                  {...register("product_image")}
                                  accept="image/png, image/jpeg"
                                  listType="picture"
                                  onChange={BoriAndCartonChange}
                                  multiple={true}
                                  fileList={uploadBoriAndCartonImage}
                                  onPreview={(e: any) => { return "" }}
                                  key={"image"}
                                >
                                  <img src={cameraLogo} alt='' className="uploaded-image" />
                                </Upload>
                              </div>
                              <div className="col-sm-3 mt-2 image-upload">
                                <label htmlFor="exampleInputEmail1" className="col-12">Product PDF Drawing All</label>
                                <Upload
                                  {...register("product_pdf_drawing")}
                                  accept=".doc,.docx,.xml,.pdf"
                                  listType="text"
                                  onChange={ProductPDFOnChange}
                                  multiple={true}
                                  fileList={uploadProductPDF}
                                  onPreview={(e: any) => { return "" }}
                                  key={"pdf"}
                                >
                                  <img src={pdfLogo} alt='' className="uploaded-image" />
                                </Upload>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          <button
                            type="button"
                            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "rgb(0, 54, 101)",
                              background: "rgb(0, 54, 101)",
                              color: "white",
                            }}
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "blue",
                              background: "blue",
                              color: "white",
                            }}
                            onClick={handleReset}
                          >
                            Reset
                          </button>

                          <button
                            type="button"
                            className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                            style={{
                              borderColor: "red",
                              background: "red",
                              color: "white",
                            }}
                            onClick={() => navigate("/listSinglecode")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />
      <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
      <Mould_Model refetchForm={MouldShapeRefetch} open={MouldShapeOpen} setOpen={setMouldShapeOpen} setSubmitted={setSubmittedMouldShape} />
      <Cavity_Model refetchForm={cavityRefetch} open={OpenProduct} setOpen={setOpenProduct} setSubmitted={setSubmittedProduct} />


      {/* Edit Modal HTML */}
      <Modal
        show={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <span className="text-center">Sure you want to delete it?</span>
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="info"
            className="text-white"
            onClick={handleMultipleDeleted}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Deleteforce
        key={renderKey}
        handleDeletedforce={handleDeletedforce}
        reconfirmdelete={confirmforcemodal}
        masterName={""}
        closereconfirmdelete={closereconfirmdelete}
        referance={
          deletesucess
            ? deletedata
            : ""
        }
        apiname={apiName}
      />
      <Deletesingle
        key={renderKey}
        confirmmodal={deleteConfirmsingle}
        confirmmodalclose={confirmmodalclose}
        masterName={""}
        confirmmodalsubmit={confirmmodalsubmit}
        apiname={apiName}
      />

      <SimpleInput_Edit key={renderKey}
        refetchForm={handleRefetch}
        open={OpenEditDropDownModel}
        setOpen={setOpenEditDropDownModel}
        id={editId}
        // superAccountId={superAccountId} 
        // optionsSuperAccount={optionsSuperAccount}
        // isSuperAccount={isSuperAccount}
        apiname={apiName} />


    </div>
  );
};

export default Add_SingleCode;
