import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchAccountTypeDropDown, useFetchAddressTypeDropDown, useFetchCityDropDown, useFetchCityPincodeDropDown, useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import "./Multiple.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import Axiosinstance from "../../../hooks/Interceptor";
import Table from "../../../components/Dynamic_Table/Table/Table";
import { Columns_Address_Details, titleofheaders } from "./Columns_Address_Details"
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Address_Modal = ({ setCurrentStep,currentStep,addShowOffcancas, setAddShowOffcancas, setAdded, setForEditIds }: any) => {
    let pageName = "Address Details"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_ADDRESS_API?.toString()


    const { mutate: Address, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;



    const onSubmit = (data: any) => {

        if (buildingType != "block no") {
            delete data?.bunglow_name
            delete data?.society_name
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        Address(formData)
        setAdded(true)

    }



    const [optionsCity, setOptionsCity]: any = useState()
    const [valueCityCreatable, setValueCityCreatable] = useState<Option | null>();

    const [valueCity, setValueCity]: any = useState()

    const apinameForCity = process.env.REACT_APP_CITY_API?.toString()

    const { mutate: mutateCity, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCity)


    const { data: CityData, refetch: CityRefetch } = useFetchCityDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
    useEffect(() => {
        setOptionsCity(CityData)
    }, [CityData])

    const handleChangeCity = (e: any) => {
        setValueCity(e)

        setValue("city", `${e?.value}`)
        setValueCityCreatable(null)
        // setValueDistrict(e?.district)
        // setValueState(e?.state)
        // setValueCountry(e?.country)
        // setValueTaluka(e?.taluka)
        // setCountryValue(e?.value)
        setForPincode(e?.label)
        setForPincodeId(e?.value)
        setByCity(e?.value)
    }


    const handleCreateCity = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            // setIsLoadingValue(false);


            mutateCity({ name: newOption.name, status: newOption.status })
            setOptionsCity((prev: any) => [...prev, newOption]);
            setValueCityCreatable(newOption);
        }, 0);


    };

    // const [cityValue,setCityValue]=useState("")
    useEffect(() => {
        (async () => {
            if (CountrySuccess && optionsCity) {
                await CityRefetch();
                setValue("city", CityData[0]?.value)
            }
            // setCityValue(CityData[0]?.value)
        })();

        return () => {
        };
    }, [CountrySuccess, CityData]);


    const [cityPincodeOptions, setCityPincodeOptions]: any = useState()
    const [forCityPincodeValue, setForCityPincodeValue]: any = useState()
    const [byCity, setByCity] = useState()
    const [forPincode, setForPincode]: any = useState("")
    const [forPincodeId, setForPincodeId] = useState("")



    const { data: cityPincodeData, refetch: cityPincodeRefetch } = useFetchCityPincodeDropDown(byCity)


    useEffect(() => {
        setCityPincodeOptions(cityPincodeData)
    }, [cityPincodeData])

    const handleChangePincode = (e: any) => {
        setForCityPincodeValue(e)
        setValue("pincode", e?.value)
    }



    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {
        setValue("type", e?.value)
        setValuePersonTypeCreatable(null)
    }

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
            }
            setValue("type", personTypeData[0].value)
        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);






    const [forValue, setForValue]: any = useState()
    const [employeeOptions, setEmployeeOptions] = useState()
    const [forEmployeeValue, setForEmployeeValue]: any = useState(0)


    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])



    // const [valueDistrict, setValueDistrict]: any = useState()
    // const [valueState, setValueState]: any = useState()
    // const [valueCountry, setValueCountry]: any = useState()
    // const [valuePincode,setValuePincode]= useState()
    // const [valueTaluka, setValueTaluka]: any = useState()


    const [buildingType, setBuildingType]: any = useState("")
    const handleBuildingType = (e: any) => {
        setBuildingType(e.target.value)
    }


    const handleReset = () => {
        reset()
        setForEmployeeValue(null)
        setBuildingType(null)
        setValueCity(null)
        setForCityPincodeValue(null)
        setForPincode(null)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    const [multipleIds, setMultipleIds]: any = useState([])
    const currentPath = window.location.pathname;

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // if (currentPath === '/addCustomerDetails') {
            //     setCurrentStep(currentStep + 1)
            // };

            reset()
            setForEmployeeValue(null)
            setBuildingType(null)
            setValueCity(null)
            setForCityPincodeValue(null)
            setForPincode(null)
            setValueAddressTypeCreatable(null)
            setValueAddressType(null)
            // setCurrentStep(currentStep + 1);
            // navigate('/listAddress', { state: { showToast: true } })
        }
    }, [isSuccess])
    // const [added,setAdded] = useState(false)

    useEffect(() => {
        // console.log('Effect triggered:', { isSuccess, data });
        if (isSuccess) {

            if (data) {
                console.log(data,"Addressdata")
                setMultipleIds((oldIds:any) => {
                    const newIds = [...oldIds, data.results.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setForEditIds((oldIds = []) => {
                    const newIds = [...oldIds, data.results.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });



                setAdded(false)
            }
        }
    }, [isSuccess, data]);




    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [isSuccess, data, show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [valueAddressType, setValueAddressType]: any = useState();
    const [optionsAddressType, setOptionsAddressType]: any = useState([]);
    const [valueAddressTypeCreatable, setValueAddressTypeCreatable] = useState<Option | null>();

    const apinameForAddressType = process.env.REACT_APP_SALES_ADDRESS_TYPE_API?.toString()
    const { mutate: mutateAddressType, data: dataAddressType, isError: AddressTypeIsError, isLoading: AddressTypeLoading, isSuccess: AddressTypeSuccess, error: AddressTypeError }: any = useCreate(apinameForAddressType)

    const { data: AddressTypeData, refetch: AddressTypeRefetch } = useFetchAddressTypeDropDown()

    useEffect(() => {
        setOptionsAddressType(AddressTypeData)
    }, [AddressTypeData])

    const handleChangeAddressType = (e: any) => {
        setValueAddressType(e)
        setValue("address_type", `${e?.value}`)
        setValueAddressTypeCreatable(null)

    }


    const handleAddressTypeCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateAddressType({ name: newOption.name, status: newOption.status })
            setOptionsAddressType((prev: any) => [...prev, newOption]);
            setValueAddressTypeCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (AddressTypeSuccess && optionsAddressType) {
                await AddressTypeRefetch();
                setValue("address_type", AddressTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [AddressTypeSuccess, AddressTypeData]);




    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;

    // const [ids, setIds]: any = useState([])
    // console.log(ids,"AddressIDs")
    // useEffect(() => {
    //     if (isSuccess) {
    //         if (data) {
    //             console.log(data,"AddressData")
    //             setIds((oldIds: any) => ([...oldIds, data?.results?.id]));

    //             setAdded(false)

    //         }
    //     }
    // }, [isSuccess, data]);


    // useEffect(() => {


    //     if (isSuccess && data) {

    //         let tempId = {
    //             address: ids
    //         }
            
    //         let tempData = {
    //             id: basicId,
    //             apiname: apinameForBasicDetails,
    //             data: tempId
    //         }
    //         console.log(tempId,"tempIdtempId",tempData)
    //         BasicDetails(tempData);
    //     }

    // }, [data, isSuccess, ids])







    const handleNext = () => {
        let tempId = {
            address: multipleIds
        }

        let tempData = {
            id: basicId,
            apiname: apinameForBasicDetails,
            data: tempId
        }
        BasicDetails(tempData);
        // BasicDetails({})
    }


    useEffect(()=>{
        if(basicDetailsIsSuccess && basicDetailsData){
            console.log(basicDetailsData,"basicDetailsData");

        localStorage.setItem('Add Data',JSON.stringify(basicDetailsData?.data))
        // setCurrentStep(currentStep + 1);
        }
    },[basicDetailsIsSuccess,basicDetailsData])



    return (
        <div>
            <div className="" >
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="">

                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}

                            {/* <div className="col-sm-3 mt-2">
                                                <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                    {...register("type", { required: { value: true, message: "Field is required" } })}
                                                    isClearable={()=>{
                                                        setValuePersonType(null)
                                                        setValue(null)}}
                               
                                                        placeholder={'Person Type'}
                                                        options={optionsPersonType}
                                                        onCreateOption={handleCreatePersonType}
                                                        onChange={handleChangePersonType}
                                                        value={valuePersonTypeCreatable==null?valuePersonType:valuePersonTypeCreatable}
                                                        

                                                    />
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>

                                                </div> */}


                            {/* <h3>Add</h3> */}
                            {/* <div className="row d-flex  ">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                                    onClick={handleShow}

                                                                >Add Addresses</button>

                                                            </div>
                                                        </div>
                                                    </div> */}




                            {/* <Offcanvas show={showOffcancas } onHide={handleClose}  placement={'end'} style={{ width: '88vw',zIndex:'10000' }} > */}
                            <Offcanvas show={addShowOffcancas} onHide={() => setAddShowOffcancas(false)} placement={'end'} style={{ width: '178vh', zIndex: '10000' }} >
                                <Offcanvas.Header closeButton>
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className="card card-default">
                                        <h3>Add</h3>

                                        <form ref={focus}
                                            onKeyUp={event => onEnterKey(event)}
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">








                                                            <div className="col-sm-3 mt-2">




                                                                <label >Address Type<span style={{ color: "red" }}>*</span></label>
                                                                <CreatableSelect
                                                                    isClearable={() => {
                                                                        setValueAddressType(null)
                                                                        setValue(null)
                                                                    }}
                                                                    {...register("address_type", { required: { value: true, message: "Address Type is required" } })}
                                                                    placeholder={'Select Address Type'}
                                                                    components={customCreatableIndicator}
                                                                    options={optionsAddressType}
                                                                    onCreateOption={handleAddressTypeCreate}
                                                                    onChange={handleChangeAddressType}
                                                                    value={valueAddressTypeCreatable == null ? valueAddressType : valueAddressTypeCreatable}

                                                                />
                                                                <span className='text-red w-100'>{errors?.address_type?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Premises Type <span style={{ color: 'red' }}>*</span></label>

                                                                <select className="form-select" {...register("premises_type", { onChange: handleBuildingType, required: { value: true, message: "Building Type is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="block no">Block No</option>
                                                                    <option value="shop no">Shop No</option>
                                                                    <option value="godown no">Godown No</option>
                                                                    <option value="flat no">Flat No</option>
                                                                    <option value="survey no">Survey No</option>
                                                                    <option value="factory no">Factory No</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.premises_type?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Premises Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Premises Number" {...register("number", { onChange: handleValue, required: { value: true, message: "Number is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.number?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Premises Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Premises Name"
                                                                        disabled={buildingType != "block no"}
                                                                        {...register("bunglow_name", { onChange: handleValue, required: { value: false, message: "Bunglow Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.bunglow_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Building Name </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Building Name" {...register("building_name", { onChange: handleValue, required: { value: false, message: "Building Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.building_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Society Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"
                                                                        disabled={buildingType != "block no"}
                                                                        placeholder="Society Name" {...register("society_name", { onChange: handleValue, required: { value: false, message: "Society Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.society_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Street Address </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Street Address" {...register("street", { onChange: handleValue, required: { value: false, message: "Street Address is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.street?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Landmark </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Landmark" {...register("landmark", { onChange: handleValue, required: { value: false, message: "Landmark is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.landmark?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Area </label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Area" {...register("area", { onChange: handleValue, required: { value: false, message: "Area is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.area?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >City <span style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    isClearable={() => {
                                                                        setValueCity(null)
                                                                        setValue(null)
                                                                    }}
                                                                    {...register("city", { required: { value: true, message: "City Name is required" } })}
                                                                    placeholder={'Select City Name'}
                                                                    options={optionsCity}
                                                                    onChange={handleChangeCity}
                                                                    value={valueCityCreatable == null ? valueCity : valueCityCreatable}
                                                                    components={customIndicator}

                                                                />
                                                                <span className='text-red w-100'>{errors?.city?.message}</span>

                                                            </div>





                                                            <div className="col-sm-3 mt-2">

                                                                <label >Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" placeholder="Mobile Number"
                                                                        {...register('mobile_number', {
                                                                            required: "Mobile Number is required",

                                                                            pattern: {
                                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                                message: 'Please enter a valid number',
                                                                            },
                                                                        })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.mobile_number?.message}</span>

                                                            </div>















                                                            <div className="col-sm-3 mt-2">

                                                                <label >Re-Mark</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Re-Mark"
                                                                        {...register("remark", { required: { value: false, message: "Field is required" } })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.remark?.message}</span>

                                                            </div>







                                                            <div className="col-sm-3 mt-2">
                                                                <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="inactive">Inactive</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.status?.message}</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row d-flex  ">
                                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                                    onClick={handleSubmit(onSubmit)}
                                                                >Submit</button>
                                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                                {/* <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>setCurrentStep(currentStep - 1)}>Back</button> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </Offcanvas.Body>
                            </Offcanvas>












                            {/* <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleNext}
                                                    >Next</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>setCurrentStep(currentStep - 1)}>Back</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}






                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Address_Modal