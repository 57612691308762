import { configureStore } from '@reduxjs/toolkit'
import toaststate from './toaststate'
import table from './table'
import permissions from './permissions'
import isDash from './isDash'
import priceListCalculation from './priceListCalculation'
import { apiSlice } from '../hooks/apiSlice'
import payrollSlice from './payrollSlice'

export default configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    payroll: payrollSlice,
    toast: toaststate,
    table: table,
    permissions: permissions,
    isDash: isDash,
    priceListCalculation:priceListCalculation
    
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
})