import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';
import Time from '../../../components/HRMS Components/Date and Time/Time';
import { useFetchResponsiblePerson } from '../../../hooks/DropDown_Api';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import type { ConfigProviderProps } from 'antd';
import { Tooltip } from '@mui/material';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'
import { BsEyeFill } from 'react-icons/bs';
import pdfLogo from "../../../images/Export/pdf upload.png";
import { Modal } from 'react-bootstrap';

type SizeType = ConfigProviderProps['componentSize'];
const Add_Attendance = () => {

  let pageName = "Attendance"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_ATTENDANCE_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();


  const [optionsUser, setOptionsUser]: any = useState([])
  const [User, setUser]: any = useState('')

  const { data: UserData, refetch: UserRefetch } = useFetchResponsiblePerson()

  useEffect(() => {
    setOptionsUser(UserData)
  }, [UserData])

  const handleChangeUser = (e: any) => {
    setUser(e)
    setValue("user", e?.value)

  }


  const handleReset = () => {
    reset();
    setlogoPdf(null)
  };

  const onSubmit = (branddetail: any) => {
    if (!forCloseTimeValue && !forEndDate) {
      delete branddetail.out_time
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listAttendance", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  useEffect(() => {
    setValue('in_time', `${currentDate} ${currentTime}`)

  }, [])


  const [StartDate, setStartDate]: any = useState()
  const [EndDate, setEndDate]: any = useState()
  const [forStartDate, setForStartDate]: any = useState()
  const [forEndDate, setForEndDate]: any = useState()

  const [forOpenTimeValue, setForOpenTimeValue]: any = useState()
  const [forCloseTimeValue, setForCloseTimeValue]: any = useState()
  const [dateValue, setDateValue] = useState('')
  const [timeValue, setTimeValue] = useState('')
  const [EndDateValue, setEndDateValue] = useState('')
  const [EndTimeValue, setEndTimeValue] = useState('')

  const currentDate = moment().format("DD-MM-YYYY")
  const currentTime = moment().format("HH:mm")


  const handleStartDate = (date: any, dateString: any) => {
    setValue("in_time", `${dateString} ${timeValue ? timeValue : ''}`)
    setForStartDate(date)
    setDateValue(dateString)
  }
  const handleStartTime = (e: any, timeString: any) => {
    setValue("in_time", `${dateValue ? dateValue : ""} ${timeString}`)
    setForOpenTimeValue(e)
    setTimeValue(timeString)
  }
  const handleEndTime = (date: any, dateString: any) => {
    // setValue("out_time", `${forEndDate?.$D}-${forEndDate?.$M + 1}-${forEndDate?.$y}` + " " + `${e?.$H}:${e?.$m}`)
    setValue("out_time", `${EndDateValue ? EndDateValue : ''} ${dateString}`)
    setForCloseTimeValue(date)
    setEndDateValue(dateString)
  }

  const handleEndDate = (e: any, timeString: any) => {
    // setValue("out_time", `${e?.$D}-${e?.$M + 1}-${e?.$y}`)
    setValue("out_time", `${timeString} ${EndTimeValue ? EndTimeValue : ''}`)
    setForEndDate(e)
    setEndTimeValue(timeString)

  }


  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current > dayjs().startOf('day'); };


  const [file, setFile] = useState(null);
 
  useEffect(() => {
    const generateFile = async () => {
      // Create a workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([["Sr.No.", 'Employee Code', "Date", "In-Time", "Out-Time"], ['1', 'EY-1001', 'DD-MM-YYYY', 'HH:mm (24 Hour)', 'HH:mm (24 Hour)'], ['2', 'EY-1001', `${currentDate}`, '09:05', '18:15']]);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Attendance');

      // Generate a Blob from the workbook
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const fileBlob: any = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Set the file state
      setFile(fileBlob);
    };

    generateFile();
  }, []);

  const handleDownload = () => {
    if (file) {
      saveAs(file, 'Attendance_Formate.xlsx');
    }
  };


  const [UploadData, setUploadData] = useState([]);
  const [logoPdf, setlogoPdf] = useState(null)
  
  const handleFileUpload = (e: any) => {
    setlogoPdf(e.target.files[0].name)
    // console.log(e.target.files[0].name,"e.target.files[0].name");
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData: any = XLSX.utils.sheet_to_json(sheet);
      setUploadData(parsedData);
      
    };
  }

  const [modal, setModal]: any = useState(false)

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listAttendance" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        {/* <ReactSelect type='text' name='user' label='User' isPencil={true} message='User is required' register={register} isRequired={true} isModal={false} errors={errors?.user?.message} options={optionsUser} onChange={(e) => handleChangeUser(e)} Selvalue={User} />
                        <Date_Picker name='in_time' label='Start Date' message='Start Date is required' register={register} isRequired={false} hrmserrors={errors?.start_date?.message} value={forStartDate} isDisabled={disabledDate} onChange={(e, dateString) => handleStartDate(e, dateString)} defaultValue={dayjs(currentDate, 'DD-MM-YYYY')} />
                        <Time name='in_time' label='Start Time' message='Start Time is required' register={register} {...forStartDate ? { isRequired: true } : { isRequired: false }} hrmserrors={errors?.start_time?.message} value={forOpenTimeValue} onChange={(e, timeString) => { handleStartTime(e, timeString) }} defaultValue={dayjs(currentTime, "HH:mm")} />
                        <Date_Picker name='out_time' label='End Date' message='End Date is required' register={register} isRequired={false} hrmserrors={errors?.out_time?.message} value={forEndDate ? forEndDate : forStartDate} isDisabled={disabledDate} onChange={(e, dateString) => handleEndDate(e, dateString)} />
                        <Time name='out_time' label='End Time' message='End Time is required' register={register} {...forEndDate ? { isRequired: true } : { isRequired: false }} hrmserrors={errors?.out_time?.message} value={forCloseTimeValue ? forCloseTimeValue : forOpenTimeValue} onChange={(e, timeString) => { handleEndTime(e, timeString) }} />
                        <InputField type='text' name='comment' label='Comment' isPencil={true} message='Comment is required' register={register} isRequired={false} hrmserrors={errors?.comment?.message} />
                        <InputField type='text' name='ip' label='IP Address' isPencil={true} message='IP Address is required' register={register} isRequired={false} hrmserrors={errors?.ip?.message} /> */}
                          <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <label className='me-2' style={{display:'flex',alignItems:'center', fontSize: '16px !important'}}>Download Excel<br/> Formate</label>
                          <Tooltip title={<span style={{fontSize:'15px'}}>Download Excel Formate</span>} placement="top">

                            <Button type="primary" icon={<DownloadOutlined />} size={'large'} onClick={handleDownload} />
                          </Tooltip>
                          </div>
                        <div className="row d-flex">
                          <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                            <div className="row d-flex ">
                              <div className="col-sm-2 mt-2 image-upload">
                                <label htmlFor="exampleInputEmail1" className="col-12">Upload Excel</label>
                                <label style={{ cursor: "pointer" }}>
                                  <img src={pdfLogo} alt='' className="uploaded-image" />
                                  <input data-required="image" type="file" id="file_upload" accept=".xlsx, .xls" className="image-input" data-traget-resolution="image_resolution" {...register("logo_pdf", { onChange: handleFileUpload, required: false })} hidden />
                                </label>
                                <p>{logoPdf}</p>
                              </div>
                              <div className="col-sm-1 mt-2">
                                <label></label>
                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                  <Button className="btn text-white" style={{ backgroundColor: '#1677ff' }} onClick={() => setModal(true)} disabled={UploadData.length === 0}><BsEyeFill /></Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listAttendance")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Modal
        size='xl'
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Uploaded Excel Data {""}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflowY:'auto',maxHeight:'500px'}}>
          {UploadData.length > 0 && (
            <table className="table" style={{overflow:'auto',width:'100%'}}
            >
              <thead>
                <tr>
                  {Object.keys(UploadData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {UploadData.map((row: any, index: any) => (
                  <tr key={index}>
                    {Object.values(row).map((value: any, index: any) => (
                      <td key={index}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="primary"
            danger
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Add_Attendance