import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
import { useFetchProject } from '../../../hooks/DropDown_Api';
import Axiosinstance from '../../../hooks/Interceptor';
import { Modal } from 'react-bootstrap';
import { Deletesinglewithdependancy } from '../../../components/modal';


const Edit_Taskstatus = () => {

  let pageName = "Task Status"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HRMS_TASK_STATUS_API?.toString();
  const { id } = useParams()
  const { data } = useFetchSingle(id, apiname)
  const [valuesofform,setValuesofform]=useState<any>()
  const [openEditModel, setOpenEditModel] = useState(false)
  const [expirationDate, setExpirationDate] = useState()
  const [confirmmodal,setConfirmmodal]=useState(false)

  const { mutate, isSuccess, isError, error, data: Updated_response }: any = useUpdateSingle();
  const { mutate:mutateyes, isSuccess:yessuccess, isError:yeserror, error:yeserrordetail, data: yesupdated_response }:any = useUpdateSingleforcewithyes()
  const { mutate:mutateno, isSuccess:nosuccess, isError:noerror, error:noerrordetail, data: noupdated_response }:any = useUpdateSingleforcewithno()
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [optionsProject, setOptionsProject]: any = useState([])
  const [Project, setProject]: any = useState('')
  const [SelectedProject, setSelectedProject]: any = useState('')

  const { data: ProjectData, refetch: ProjectRefetch } = useFetchProject()

  useEffect(() => {
    setOptionsProject(ProjectData)
  }, [ProjectData])

  const handleChangeProject = (e: any) => {
    setProject(e)
    setValue("project", e?.value)
  }

  const apinameProject = process.env.REACT_APP_HRMS_PROJECT_API?.toString()
  useEffect(() => {
    if (data?.project) {
      const getCall = async () => {
        try {
          const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameProject}/${data?.project}/`);
        
          setSelectedProject(response?.data?.data?.id)
          setValue("project", response?.data?.data?.id)
        } catch (error) {
          console.error('Error:', error);
        }
      }
      getCall()
    }

  }, [data])

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      }
    }
    let data = {
      apiname: apiname,
      id: id,
      data: formData
    }
    mutate(data);
    setValuesofform(data)
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess||yessuccess||nosuccess) {
        setValuesofform(null)
        setShow(true)
        navigate('/listTaskStatus', { state: { showEditToast: true }})
    }
  
 }, [data, isSuccess,yessuccess,
    nosuccess,Updated_response])

    useEffect(()=>{
        if(isError)
        {
            if(error.response.status===405){
                setConfirmmodal(true)
            }
        }
        else if (isError||yeserror||noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    },[isError,yeserror,noerror])
useEffect(() => {
    setTimeout(() => {
        setShow(false)
    }, 3000)

}, [show]);

    const yesupdatemodal=()=>{
    setConfirmmodal(false);
    mutateyes(valuesofform)
    }
    const cancelupdatemodal=()=>{
       setConfirmmodal(false) ;
    }

    const noupdatemodal = () => {
      setOpenEditModel(true)
  }


  const handleExpirationDate = (e: any) => {
      setExpirationDate(e.target.value)
      setValue("expiration_date", e.target.value)
  }

  const handleConfirm = (data: any) => {


      let ok = {
          id: id,
          apiname: apiname,
          data: data,
      }

      mutateno(ok)
      setConfirmmodal(false);
      setOpenEditModel(false)
  }

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listTaskStatus" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Edit {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Project' name='project' message='Project is required' register={register} isRequired={true} isModal={false} errors={errors?.project?.message} options={optionsProject} onChange={(e) => handleChangeProject(e)} Selvalue={Project} isEdit={true} editValue={SelectedProject}/>
                        <InputField type='text' name='name' label='Task Status Name' isPencil={true} message='TaskPriority Name is required' register={register} isRequired={true} hrmserrors={errors?.name?.message} />
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} isReset={false} Cancel={() => navigate("/listTaskStatus")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
    </div>
  )
}

export default Edit_Taskstatus