import { DatePicker, TimePicker } from 'antd'
import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'

interface InputField {
  id?: any,
  name?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?: any
  message?: any
  value?: any,
  label?: Path<any>,
  register?: any,
  isRequired?: boolean,
  hrmserrors?:any;
  isDisabled?:any;
  dateFormat?:any;
  defaultValue?:any;
  onChange?: (e: any, timeString:any) => void,
}

const Time = ({ id, name, type, className, label, color, isPencil = false, register, isRequired = true, message, hrmserrors , value ,isDisabled, onChange, dateFormat, defaultValue }: InputField) => {
  return (
    <div className={className ? className :"col-sm-3 mt-2"}>
      <label htmlFor="manualcode_Disc_1">{label} {isRequired && <span style={{ color: 'red' }}>*</span>}</label>
      <div className={className ? className :"input-group" }>


        <TimePicker
          className={className ? className :"form-control"}
          format={'HH:mm'}
          value={value}
          disabledDate={isDisabled}
          defaultValue={defaultValue}
          showNow={false}
          {...register(name, { required: { value: isRequired, message: message } })}
          onChange={onChange}
        />



      </div>
      {isRequired &&
        <span className={className ? className :"text-red w-100"}>{hrmserrors}</span>
      }

    </div>
  )
}

export default Time