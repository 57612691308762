import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export const Deletesingle=({confirmmodal,confirmmodalclose,masterName,confirmmodalsubmit}:any)=>{
    return(
      <Modal  show={confirmmodal} onHide={confirmmodalclose} aria-labelledby="update single"
      centered    >
        <Modal.Header closeButton>
          <Modal.Title>Update {masterName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>  <span> <span className='text-center'>Are you sure you want to Update this item?</span></span>
  </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={confirmmodalclose}>
            Cancel
          </Button>
          <Button variant="info"   onClick={confirmmodalsubmit} style={{color:"white"}}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
  export const Deletesinglewithdependancy=({confirmmodal,noupdatemodal,masterName,yesupdatemodal,cancelupdatemodal,foundependacy}:any)=>{
    return(
      <Modal  show={confirmmodal} onHide={cancelupdatemodal} aria-labelledby="update single"
      centered   style={{zIndex:'2000'}} >
        <Modal.Header closeButton>
          <Modal.Title>Update {masterName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>  <span> <span className='text-center'>{process.env.REACT_APP_DEPENDACY_EDIT}</span></span>
        <ul>
        {foundependacy?.data?.map((dependancy:any,index:number)=>{
            return(
 <li key={index}>{dependancy?.field!=="NumberSystem"?dependancy.field:"All Code"} - <a href={`${process.env.REACT_APP_FRONT_URL}edit${dependancy?.model}/${dependancy?.id}`} target='blank'>View</a> </li>)
        })}
        </ul>
  </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ cancelupdatemodal}>
            Cancel
          </Button>
          <button className='btn bg-danger'   onClick={noupdatemodal} >
            No
          </button>
          <button className='btn bg-primary'   onClick={ yesupdatemodal} style={{color:"white"}}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    )
  }