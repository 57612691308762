import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchBankAccountTypeDropDown, useFetchCityDropDown, useFetchCityPincodeDropDown, useFetchCustomerDropDown, useFetchEmployeeDropDown, useFetchIFSCDropDown, useFetchPersonTypeDropDown, useFetchPriorityDropDown } from "../../../hooks/DropDown_Api";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Upload, UploadFile } from "antd";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Axiosinstance from "../../../hooks/Interceptor";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { Modal } from "react-bootstrap";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Bank_Format = ({ setCurrentStep, currentStep }: any) => {
    let pageName = "Bank Format"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_BANK_API?.toString()
    const { mutate: Bank, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)

    const [valuePersonType, setValuePersonType]: any = useState();
    const [PersonType, setPersonType]: any = useState("");
    const [logo, setlogo]: any = useState([])
    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);


    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;

    const onPersonTypeChange = (e: any) => {
        setPersonType(e.target.value)
    }


    const [employeeOptions, setEmployeeOptions] = useState()
    const [forEmployeeValue, setForEmployeeValue]: any = useState(0)

    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])

    const handleChangeEmployee = (e: any) => {

        setForEmployeeValue(e)
        setValue("employee", e.value)

    }

    const [options, setOptions] = useState([])
    const [forCustomerValue, setCustomerValue]: any = useState(0)
    const { data: customerData, refetch: customerRefetch } = useFetchCustomerDropDown()


    useEffect(() => {
        setOptions(customerData)
    }, [customerData])

    const handleChange = (e: any) => {

        setCustomerValue(e)
        setValue("customer", e?.value)

    }




    const apiIFSC = process.env.REACT_APP_IFSC_API?.toString()
    // const  { mutate: IFSC, data:dataIFSC, isError:isErrorIFSC, isLoading:isLoadingIFSC, isSuccess:isSuccessIFSC, error:errorIFSC }:any = useCreate(apiIFSC)
    const [ifscValue, setIfscValue] = useState("")
    const [changeIFSC, setChangeIFSC] = useState(false)
    const [verify, setVerify] = useState(false)
    const [ifscError, setIFSCError] = useState(false)
    const searchTerm = verify ? ifscValue : null;
    const { data: ifscData, refetch: ifscRefetch, isLoading: ifscIsLoading, isError: ifscIsError, error: ifscErrorFromApi }: any = useFetchIFSCDropDown(searchTerm)

    useEffect(() => {
        if (ifscIsError) {
            setValue("ifsc_code", "")
            setChangeIFSC(false)
            setVerify(false)
        }
    }, [ifscIsError])

    const handleChangeIFSC = () => {
        // ifscIsSuccess(false)
        setChangeIFSC(false)
        setVerify(false)
    }

    const handleIFSC = (e: any) => {
        setVerify(false)
        setIfscValue(e.target.value)
        setIFSCError(false)

    }

    const onSubmitIFSC = (e: any) => {

        setVerify(true)
        setChangeIFSC(true)

    }

    useEffect(() => {
        if (ifscIsError) {
            setIFSCError(true)
        }
    }, [ifscIsError, ifscError])

    const [BankName, setBankName]: any = useState()
    const [CityName, setCityName]: any = useState()
    const [BranchName, setBranchName]: any = useState()
    const [District, setDistrict]: any = useState()
    const [State, setState]: any = useState()
    const [Country, setCountry]: any = useState()
    const [Micr, setMicr]: any = useState()
    const [Pincode, setPincode]: any = useState()

    const [ifscID, setIfscId]: any = useState("")

    useEffect(() => {
        if (ifscData) {
            setIfscId(ifscData?.id)
            // setValue("ifsc_code",)
            setBankName(ifscData?.BANK)
            setCityName(ifscData?.CITY)
            setBranchName(ifscData?.BRANCH)
            setDistrict(ifscData?.DISTRICT)
            setState(ifscData?.STATE)
            setCountry(ifscData?.COUNTRY)
            setMicr(ifscData?.MICR)
            setPincode(ifscData?.PINCODE)
        }
    }, [ifscData])



    const apinamePriority = process.env.REACT_APP_BANK_PRIORITY_TYPE_API?.toString()
    const { mutate: mutatePriority, data: dataPriority, isError: isErrorPriority, isLoading: isLoadingPriority, isSuccess: isSuccessPriority, error: errorPriority }: any = useCreate(apinamePriority)
    const [optionsPriority, setOptionsPriority]: any = useState([]);
    const [valuePriority, setValuePriority]: any = useState();
    const [valuePriorityCreatable, setValuePriorityCreatable] = useState<Option | null>();
    const { data: PriorityData, refetch: PriorityRefetch } = useFetchPriorityDropDown()
    useEffect(() => {
        setOptionsPriority(PriorityData)
    }, [PriorityData])

    const handleCreatePriority = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutatePriority({ name: newOption.name, status: newOption.status })
            setOptionsPriority((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValuePriorityCreatable(newOption);
        }, 0);

    };

    const handleChangePriority = (e: any) => {

        PriorityRefetch()
        setApiName(apinamePriority)
        setDeleteApiName(apinamePriority)
        setEditId(e?.value)
        setEditData(PriorityData)
        setRenderKey(prevKey => prevKey + 1);

        setValuePriority(e)
        setValue("priority", `${e?.value}`)
        setValuePriorityCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessPriority && optionsPriority && valuePriorityCreatable != null) {
                await PriorityRefetch();
                setValue("priority", PriorityData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessPriority, PriorityData]);


    const apinameAccountType = process.env.REACT_APP_BANK_ACCOUNT_TYPE_API?.toString()
    const { mutate: mutateAccountType, data: dataAccountType, isError: isErrorAccountType, isLoading: isLoadingAccountType, isSuccess: isSuccessAccountType, error: errorAccountType }: any = useCreate(apinameAccountType)
    const [optionsAccountType, setOptionsAccountType]: any = useState([]);
    const [valueAccountType, setValueAccountType]: any = useState();
    const [valueAccountTypeCreatable, setValueAccountTypeCreatable] = useState<Option | null>();
    const { data: AccountTypeData, refetch: AccountTypeRefetch } = useFetchBankAccountTypeDropDown()
    useEffect(() => {
        setOptionsAccountType(AccountTypeData)
    }, [AccountTypeData])

    const handleCreateAccountType = async (inputValue: string) => {
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setValue("name", `${newOption?.value}`)
            mutateAccountType({ name: newOption.name, status: newOption.status })
            setOptionsAccountType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setValueAccountTypeCreatable(newOption);
        }, 0);

    };

    const handleChangeAccountType = (e: any) => {

        AccountTypeRefetch()
        setApiName(apinameAccountType)
        setDeleteApiName(apinameAccountType)
        setEditId(e?.value)
        setEditData(AccountTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAccountType(e)
        setValue("account_type", `${e?.value}`)
        setValueAccountTypeCreatable(null)
    }

    useEffect(() => {
        (async () => {
            if (isSuccessAccountType && optionsAccountType && valueAccountTypeCreatable != null) {
                await AccountTypeRefetch();
                setValue("account_type", AccountTypeData[0]?.value)
            }
        })();
        return () => {
        };
    }, [isSuccessAccountType, AccountTypeData]);


    const onSubmit = (data: any) => {


        // setVerify(true)
        // setChangeIFSC(true)

        // if(!verify || changeIFSC){
        //     setIFSCError(true)
        // }

        // setIFSCError(false)
        data.ifsc_code = ifscID


        if (!logo) {
            delete data.image
        }
        if (PersonType != "Customer") {
            delete data.customer
        }
        if (PersonType != "Employee") {
            delete data.employee
        }

        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            const formvalue: any = value

            if (key === "image") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('image', logo[i])
                }
            }

            if (typeof value !== "object") {
                if (value != undefined) {
                    formData.append(key, formvalue)
                }
            }

        }
        Bank(formData)

    }

    const handleReset = () => {
        reset()
        setPersonType("")
        setBankName(null)
        setCityName(null)
        setBranchName(null)
        setDistrict(null)
        setState(null)
        setCountry(null)
        setMicr(null)
        setPincode(null)
        setCustomerValue(null)
        setForEmployeeValue(null)
        setValuePriorityCreatable(null)
        setValuePriority(null)
        setValueAccountTypeCreatable(null)
        setValueAccountType(null)
        setUploadFile([])

    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const handleValueUppercase = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.toUpperCase())
            .join(' ');
    }

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;




    useEffect(() => {
        if (isSuccess && data) {


            let tempId = {
                bank: data?.data?.id
            }

            let tempData = {
                id: basicId,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
            // BasicDetails({})

        }
    }, [data, isSuccess])



    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            //  const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
            // navigate('/listBank', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const [uploadFile, setUploadFile] = useState<UploadFile[]>([])


    const FileOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }

    const onLogoChange = (e: any) => {

        setlogo(e.target.files)

    }



    useEffect(() => {
        if (ifscError) {
            setTimeout(() => {
                setIFSCError(false)
            }, 3000)
        }
    }, [ifscError]);


    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_no', temp)
    }



    const currentPath = window.location.pathname;




    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinamePriority) {
            PriorityRefetch()
        }
        if (apiName === apinameAccountType) {
            AccountTypeRefetch()
        }


    }


    useEffect(() => {
        if (OpenEditDropDownModel && valuePriority && apiName === apinamePriority) {
            setValuePriority(null)
            setValuePriorityCreatable(null)
        }
        if (OpenEditDropDownModel && valueAccountType && apiName === apinameAccountType) {
            setValueAccountType(null)
            setValueAccountTypeCreatable(null)
        }




        if (deletesucess && apiName === apinamePriority) {
            setValuePriority(null)
            setValuePriorityCreatable(null)
        }
        if (deletesucess && apiName === apinameAccountType) {
            setValueAccountType(null)
            setValueAccountTypeCreatable(null)
        }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    const handleContactNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_no', temp)
    }

    return (
        <div>
            <div className="" >
                <section className="content-header py-1 px-3">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Sales And Marketing</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                            <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name?addData?.name:addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Source Name</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                {/* <div className="col-sm-4 mt-2">
                                                <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-select" {...register("type", { onChange:onPersonTypeChange, required: { value: true, message: "Priority is required" } })}>

                                                    <option value={""} selected>Select Any</option>
                                                    <option value="Customer">Customer</option>
                                                    <option value="Employee">Employee</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>

                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label >Priority</label>
                                                    {/* <select className="form-select" {...register("priority", { required: { value: true, message: "Priority is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="First">First</option>
                                                        <option value="Second">Second</option>
                                                        <option value="Third">Third</option>
                                                    </select> */}

                                                    <CreatableSelect
                                                        {...register("priority", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={() => {
                                                            setValuePriority(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsPriority}
                                                        onCreateOption={handleCreatePriority}
                                                        onChange={handleChangePriority}
                                                        onMenuOpen={() => PriorityRefetch()}
                                                        value={valuePriorityCreatable == null ? valuePriority : valuePriorityCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.priority?.message}</span>

                                                </div>






                                                {/* {PersonType === "Customer" ? <>
                                                    <div className="col-sm-3 mt-2">

                                                        <label >Customer Code,Name & City<span style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            {...register("customer", { required: { value: false, message: "Customer Code,Name & City is required" } })}
                                                            value={forCustomerValue}
                                                            options={options}
                                                            onChange={handleChange}
                                                        // isOptionDisabled={(options: any) => {
                                                        //     if (options.status === "inactive" || options.deleted == true) {
                                                        //         return options
                                                        //     }
                                                        // }}

                                                        />
                                                        <span className='text-red w-100'>{errors?.customer?.message}</span>
                                                    </div>

                                                </> : ""}
                                                {PersonType === "Employee" ? <>
                                                    <div className="col-sm-3 mt-2">


                                                        <label >Employee Code,Surname & Name <span style={{ color: 'red' }}>*</span></label>

                                                        <Select
                                                            {...register("employee", { required: { value: false, message: "Employee Code,Surname & Name is required" } })}
                                                            onChange={handleChangeEmployee}
                                                            options={employeeOptions}
                                                            value={forEmployeeValue}
                                                        // isOptionDisabled={(options: any) => {
                                                        //     if (options.status === "inactive" || options.deleted == true) {
                                                        //         return options
                                                        //     }
                                                        // }}

                                                        />
                                                        <span className='text-red w-100'>{errors?.employee?.message}</span>
                                                    </div>
                                                </> : ""} */}


                                                <div className="col-sm-3 mt-2">

                                                    <label >Bank IFSC Code <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled={changeIFSC} placeholder="Bank IFSC Code" onChange={handleValueUppercase} {...register("ifsc_code", { onChange: handleIFSC, required: { value: true, message: "Bank IFSC Code is required" } })} />
                                                        {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div> */}
                                                        <div className="input-group-append" style={{ width: "62px", padding: 0 }}>
                                                            {verify ?
                                                                <>
                                                                    <Button className="input-group-text border-start-0 bg-info" onClick={handleChangeIFSC}>
                                                                        Change
                                                                    </Button>
                                                                </>
                                                                :

                                                                <>
                                                                    <Button className="input-group-text border-start-0 bg-success" disabled={ifscValue == "" || ifscIsLoading} onClick={onSubmitIFSC}>
                                                                        {ifscIsLoading ? "Verifying..." : "Verify"}
                                                                    </Button>
                                                                </>}

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.ifsc_code?.message}</span>
                                                    {ifscError && <span className='text-red w-100'>{"IFSC Not Found"}</span>}
                                                </div>

                                                <div className="col-sm-3 mt-2">


                                                    <label >Bank Name </label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" value={BankName} placeholder="Branch Name" />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name_and_city?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">


                                                    <label >City Name</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control" value={CityName} placeholder="Branch Name" />

                                                    </div>

                                                    <span className='text-red w-100'>{errors?.city?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label >Branch Name</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled value={BranchName} placeholder="Branch Name" />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.branch_name?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Bank Account Type <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <select className="form-select" {...register("account_type", { required: { value: true, message: "Bank Account Type is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="Savings">Savings</option>
                                                        <option value="Current">Current</option>
                                                        <option value="CC">CC</option>
                                                    </select> */}
                                                    <CreatableSelect
                                                        {...register("account_type", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={() => {
                                                            setValueAccountType(null)
                                                            setValue(null)
                                                        }}
                                                        components={customCreatableIndicator}
                                                        options={optionsAccountType}
                                                        onCreateOption={handleCreateAccountType}
                                                        onChange={handleChangeAccountType}
                                                        onMenuOpen={() => AccountTypeRefetch()}
                                                        value={valueAccountTypeCreatable == null ? valueAccountType : valueAccountTypeCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.account_type?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label >Bank Account Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" placeholder="Bank Account Number" {...register("account_number", { required: { value: true, message: "Bank Account Number is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.account_number?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Bank Swift Code</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Bank Swift Code" {...register("swift_code", { onChange: handleValueUppercase, required: { value: false, message: "Bank Swift Code is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.swift_code?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label >Bank IEC Code</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Bank IEC Code" {...register("iec_code", { onChange: handleValueUppercase, required: { value: false, message: "Bank IEC Code is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.iec_code?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Bank Micr Code</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled value={Micr} placeholder="Bank Micr Code" />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.micr_code?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Check Print Name</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Check Print Name" {...register("cheque_print_name", { onChange: handleValue, required: { value: false, message: "Check Print Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.cheque_print_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label >Amount Transfer Account Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Amount Transfer Account Name" {...register("amount_transfer_account_name", { onChange: handleValue, required: { value: true, message: "Amount Transfer Account Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.amount_transfer_account_name?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">

                                                    <label >District</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"
                                                            value={District}
                                                            placeholder="District"

                                                        />

                                                    </div>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >State</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"
                                                            value={State}
                                                            placeholder="State"

                                                        />

                                                    </div>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Country</label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"
                                                            value={Country}
                                                            placeholder="Country"
                                                        />

                                                    </div>


                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label >Pin Code </label>
                                                    <div className="input-group">
                                                        <input type="text" disabled className="form-control"
                                                            value={Pincode}
                                                            placeholder="State"

                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.account_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label >Pan No. <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Pan No." {...register("pan_number", { onChange: handleValueUppercase, required: { value: true, message: "Pan No. is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.pan_number?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label >Bank Contact Number-1 <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Bank Contact Number-1" 
                                                            {...register('bank_contact_number_1', {
                                                                required: "Bank Contact Number-1 is required",

                                                                pattern: {
                                                                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })}
                                                        // onChange={handleNumbercode}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bank_contact_number_1?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label >Bank Contact Number-2 </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Bank Contact Number-2"
                                                            {...register('bank_contact_number_2', {
                                                                required: false,

                                                                pattern: {
                                                                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })}
                                                        // onChange={handleNumbercode}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bank_contact_number_2?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Bank Email Address-1 <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" id="exampleInputBrand" placeholder="Bank Email Address-1"
                                                            {...register('bank_email_id_1', {
                                                                required: 'Email is required',
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bank_email_id_1?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Bank Email Address-2 </label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" id="exampleInputBrand" placeholder="Bank Email Address-2"
                                                            {...register('bank_email_id_2', {
                                                                required: false,
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bank_email_id_2?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">


                                                    <label >Re-Mark</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control"

                                                            placeholder={'Re-Mark'}


                                                            {...register('re_mark', { required: { value: false, message: "Field is required" } })}
                                                        //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>







                                                <div className="col-sm-3 mt-2">
                                                    <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Cheque Image</label>
                                                    <Upload
                                                        {...register("image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={FileOnChange}
                                                        multiple={true}
                                                        fileList={uploadFile}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">

                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{display:'flex',justifyContent:'space-between'}}>
                                                    <div>
                                                        {currentPath === '/addCustomerDetails' ?
                                                            <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => setCurrentStep(currentStep + 1)}>Skip</button>
                                                            : null}

                                                        {currentPath === '/addCustomerDetails' ?
                                                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                            : null}
                                                    </div>
                                                    <div>

                                                        {!verify && <span style={{ color: "#4287f5" }}>Don't Forget To Verify Your IFSC Before Submit</span>}
                                                        <button
                                                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                            onClick={handleSubmit(onSubmit)}
                                                        >Submit</button>
                                                        <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Add_Bank_Format