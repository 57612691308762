import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../../components/Model/Model";
import { useForm } from 'react-hook-form';
import { RiPencilLine } from "react-icons/ri";
import { useFetchEmployeeDropDown } from '../../hooks/DropDown_Api';
import Select from 'react-select';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'


const customIndicator = {
    IndicatorsContainer:IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer:IndicatorsContainerCreate
}


export const Designation_Model = ({refetchForm,open,setOpen,setSubmitted}:any) => {


    const { register, handleSubmit:handleSubmit, formState: { errors },reset,setValue}: any = useForm({
        mode: "onChange"
    });


    const apinameforDesignation = process.env.REACT_APP_DESIGNATION_API?.toString()


    const  { mutate, data,isSuccess,error,isError,success}:any = useCreate(apinameforDesignation)
  


    useEffect(()=>{
        if(isSuccess){
            setSubmitted(true)
            setOpen(false)
            reset()
            // setForGSTValue(null)
        }
        refetchForm()
            },[isSuccess,data])


   
  
 
            const handleReset = () => {
                reset()
                setForDesignationValue(null)
            }
        
            const onSubmit = (branddetail: any) => {
                const formData = new FormData();
                for (const [key, value] of Object.entries(branddetail)) {
                    const formvalue: any = value
                 
        
        
                    if (typeof value !== "object") {
                        formData.append(key, formvalue)
                    }
                    else if (Array.isArray(value) && key === "use_employee_code_and_surname_and_name") {
                        value.forEach((val, index) => formData.append(`use_employee_code_and_surname_and_name`, val))
                    }
                    
                   
        
        
        
        
        
        
        
        
        
        
        
                }
                mutate(formData);
            }
        
        const[forDesignationValue,setForDesignationValue]:any = useState()
        const[designationOptions,setDesignationOptions] = useState()
        const[employeeQty,setEmployeeQty] = useState(0)
        
        
            const {data:designationData, refetch:designationRefetch} = useFetchEmployeeDropDown()
        
        
            useEffect(()=>{
                setDesignationOptions(designationData)
            },[designationData])
        
        
            const handleChangeDesignation=(e:any)=>{
                let employees = e?.map((e:any)=>{
                    return e.value
                })
                setForDesignationValue(e)
                setValue("use_employee_code_and_surname_and_name",employees)
                setEmployeeQty(employees?.length)
                setValue("employee_qty",employees?.length)
            }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


  return (
    <>
    <Modal  
    
    size='xl'
    
    
    show={open} onHide={()=>{
        setShow(false)
        reset()
        setOpen(false)}} aria-labelledby="contained-modal-title-vcenter"
centered    >
  <Modal.Header closeButton>
    <Modal.Title style={{fontSize:'16px'}}>Designation</Modal.Title>
  </Modal.Header>
  <Modal.Body> 
     
  



  <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh"}}>    
                                {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                            
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Designation Name</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Designation Name" {...register("designation",{ onChange:handleValue, required: { value: true, message: "From Date is required" }})} />
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label >Use Employee Code,Surname & Name</label>
                                                        <Select 
                                                            {...register("use_employee_code_and_surname_and_name",{required: { value: false, message: "Field is required" }})}
                                                            value={forDesignationValue}
                                                            options={designationOptions}
                                                            onChange={handleChangeDesignation}
                                                            components={customIndicator}
                                                            isMulti
                                                          
                                                            className="dropdown-select"

                                                        />

                                                <span className='text-red w-100'>{errors?.use_employee_code_and_surname_and_name?.message}</span>
                                                       
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Qty</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" disabled value={employeeQty}  placeholder="Designation Name" {...register("employee_qty",{ required: { value: false, message: "From Date is required" }})} />
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.employee_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status</label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                    <option value={""}  selected>Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>


                                               
                                            
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

<button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listSalesDesignation")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </form>
      
</Modal.Body>
  <Modal.Footer>
  
    <Button variant="info" className="text-white" 
 onClick={handleSubmit(onSubmit)}
 style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
    >
      Submit
    </Button>
    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
 onClick={handleReset}
 >
   Reset
 </Button>
    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={()=>{setOpen(false)}}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>
    </>
  )
}



