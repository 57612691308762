import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'
import { RiPencilLine } from 'react-icons/ri'

interface InputField {
  id?: any,
  name?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?: any
  message?: any
  value?: any,
  label?: Path<any>,
  register?: any,
  isRequired?: boolean,
  hrmserrors?: any;
  isDisabled?: any
}

const InputField = ({ id, name, type, className, label, color, isPencil = false, register, isRequired = true, message, hrmserrors, value, isDisabled }: InputField) => {

  const handleUpper = (e: any) => {
    e.target.value = e.target.value.toLowerCase()
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  return (
    <div className={className ? className : "col-md-3 mt-2"}>
      <label htmlFor="exampleInputEmail1">{label} {isRequired && <span style={{ color: 'red' }}>*</span>}</label>
      <div className={className ? className : "input-group"} >
        <input type={type} className={className ? className : "form-control"} value={value} disabled={isDisabled}
          {...register(name, { onChange: handleUpper, required: { value: isRequired, message: message } })}


          id={id}
          placeholder={label} />
        {isPencil && <div className={className ? className : "input-group-append"}>
          <span className={className ? className : "input-group-text border-start-0 bg-primary"}><RiPencilLine color={color} /></span>
        </div>}
      </div>
      {isRequired &&
        <span className={className ? className : "text-red w-100"}>{hrmserrors}</span>
      }
    </div>
  )
}

export default InputField