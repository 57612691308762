import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useBrandEdit, useUpdateBrand } from "../../hooks/brand";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';


const Edit_Brand = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()

    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    // const { data } = useBrandEdit(id)
    const apiname = process.env.REACT_APP_BRAND_API?.toString()

    const { data } = useFetchSingle(id, apiname)

    const onLogoChange = (e: any) => {

        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { mutate, isSuccess, isError, error, data: brandUpdated_response }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const { register, handleSubmit, setError, formState: { errors, dirtyFields, isDirty }, reset, setValue } = useForm();

    useEffect(() => {
        if (data) {
            setValue("id", data.id)
            setValue("name", data.name)
            setValue("type", data.type)
            setValue("status", data.status)
            setValue("logo", data.logo)
            setValue("logo_pdf", data.logo_pdf)
            setValue("brand_code", data.brand_code)

        }
    }, [data]);


    const onSubmit = (branddetail: any) => {

        if (branddetail.logo?.length == 1 && branddetail.logo_pdf?.length != 1) {
            delete branddetail.logo_pdf

        } else if (branddetail.logo_pdf?.length == 1 && branddetail.logo?.length != 1) {
            delete branddetail.logo
        } else if (branddetail.logo?.length == 1 && branddetail.logo_pdf?.length) {
        }
        else {
            delete branddetail.logo
            delete branddetail.logo_pdf
        }

        const formData = new FormData();


        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (key === "logo" || key === "logo_pdf") {
                if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                    formData.append(key, formvalue[0])
                }
                else {
                    formData.append(key, "")
                }
            }
        }
        let data = {
            id: id,
            apiname: apiname,
            data: formData
        }
        mutate(data);
        setValuesofform(data)
    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listBrand', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, brandUpdated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Brand</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listbrand" className="text-decoration-none text-black">Brand</Link></li>
                                    <li className="breadcrumb-item active">Edit Brand</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Edit Brand</span></h5>
                            </div> */}
                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>

                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Brand Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Lee-10"  {...register("name", { onChange: handleValue })} />

                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Our/Other Brand <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("type")}>
                                                        <option disabled selected>Select Any</option>
                                                        <option value="our">Our</option>
                                                        <option value="other">Other</option>
                                                        <option value={"No Brand"}>No Brand</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status")}>
                                                        <option disabled selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Brand Code </label>
                                                    <input disabled type="text" className="form-control" id="exampleInputBrand" {...register("brand_code")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Upload Logo</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("logo",
                                                            { onChange: onLogoChange }
                                                        )} />
                                                    </label>
                                                    <a href={data?.logo} target="_blank" ><p>{logo == null ? data?.logo?.split("/").pop() : logo}</p></a>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Logo PDF</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file" id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution" defaultValue="" hidden {...register("logo_pdf",
                                                            { onChange: onLogoPDFChange }
                                                        )} />
                                                    </label>
                                                    <a href={data?.logo_pdf} target="_blank"><p>{logoPdf == null ? data?.logo_pdf?.split("/").pop() : logoPdf}</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                {/* <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button type="button" className="btn border rounded bg-white px-4 mt-2 mx-4" >Cancel</button>
                                                    <button type="button" className="btn border rounded text-white px-4 mt-2 " onClick={handleSubmit(onSubmit)}>Submit</button>

                                                </div> */}


                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={!isDirty}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listBrand")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>





                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                />
                            </div>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>



                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>

                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
        </div>
    )
}

export default Edit_Brand