import React, { useEffect, useState } from "react";
import { Styles } from "../../../../../components/Freeze_Columns/freezeStyle";
import FilterInput from "../../../../../components/Table Component/FilterInput";
import Actions from "../../../../../components/Cell/Actions";
import Actions_Yes_No from "../../../../../components/Cell/ActionsYes-No";

import { Status } from "../../../../Cell/Status";
import Restricted from "../../../../../Roles/Restricted";
import dayjs from 'dayjs';
import { Link, useNavigate } from "react-router-dom";
import Axiosinstance from "../../../../../hooks/Interceptor";
import Assign_Online_Inquiry from "../../../../Cell/Assign_Online_Inquiry";
import Quotation_cell from "../../../../Cell/Quotation";
import "./body.css"
import Order_Actions from "../../../../Cell/Order_Action";
import Discount_Actions from "../../../../Cell/Discount_Approval";
import Discount_Actions_View from "../../../../Cell/Discount_Actions_View";
import Dispatch_Order from "../../../../Cell/Dispatch_Order";
import Dispatched from "../../../../Cell/Dispatch";
import Verify_Dispatch_Products from "../../../../Cell/Verify_Dispatch_Products";
import Dispatch_Selection from "../../../../Cell/Dispatch_selection";
import Download_Invoice from "../../../../Cell/Invoice";
import Stepper_Actions from "../../../../Cell/Stepper_Actions";
import Customer_Details from "../../../../Cell/Customer_Details";
import Maintenance_Responsible from "../../../../Cell/Maintenance_Responsible";
import Maintenance_Status from "../../../../Cell/Maintenance_Status";
import Duplicate_Granual_Action from "../../../../Cell/Duplicate_Granual_Action";
import Sheet_Roll_Code from "../../../../Cell/Sheet_Roll_Code";
import Task_Board from "../../../../Cell/Task_Board";


export interface IBody {
  getTableProps?: any;
  headerGroups?: any;
  allColumns?: any;
  filter?: Object;
  handleDragStart?: (e?: any) => void;
  handleDragOver?: (e?: any) => void;
  handleOnDrop?: (e?: any) => void;
  handleDragEnter?: (e?: any) => void;
  FilterBy?: any;
  setFilterBy?: any;
  setPage?: (e?: any) => void;
  getTableBodyProps?: any;
  rows?: [];
  prepareRow?: any;
  pageSize?: any;
  page?: any;
  apiname?: string;
  singleDelete?: any;
  addButtonName?: String;
  deleteid?: any;
  handleDeletedforce?: (e?: any) => void;
  closereconfirmdelete?: any;
  deletemodalask?: any;
  Update?: any;
  refetch?: any;
  stickyHeader: any;
  data: any
  setData: any
  productType: any;
  DispatchedOptions: any;
  productTypeDisposable: any
  productTypeOptions: any
  RawMaterialGradeOptions: any
  // setProductType:any;
  // ProductTypeForSingleCode?: any;
  // setProductTypeForSingleCode?: any;
  refetch_For_Change?: any;
  permissionClass: any
  choiceData?: any
  idTableFooter?: any
  totalOfAll?: any
  setCustomerId?: any
  setDos?: any
  dos?: any
  setIsChecked?: any
  TapeRollOptions?: any
  BoxPattiOptions?: any
  PanniOptions?: any
  EmployeeOptions?: any
  CustomerOptions?: any
  viewUsageModal?: (e?: any) => void;
  closeViewUsageModal?: (e?: any) => void;
  setDeleteTrue?: any
  setMultipleIds?: any
  updatedIds?: any
  setShowOffcancas?: (e?: any) => void;
  setGetIdForEdit?: any
  handleCloseEdit?: any
  handleShowEdit?: any
  setIsNew?: any
  // setShowOffcancasEdit?:any
}

const Body = ({
  getTableProps,
  headerGroups,
  allColumns,
  filter,
  handleDragStart,
  handleDragOver,
  handleOnDrop,
  handleDragEnter,
  FilterBy,
  setFilterBy,
  setPage,
  getTableBodyProps,
  rows,
  prepareRow,
  pageSize,
  page,
  apiname,
  singleDelete,
  addButtonName,
  deleteid,
  handleDeletedforce,
  closereconfirmdelete,
  viewUsageModal,
  closeViewUsageModal,
  deletemodalask,
  Update,
  refetch,
  stickyHeader,
  data,
  setData,
  choiceData,
  productType,
  productTypeDisposable,
  productTypeOptions,
  RawMaterialGradeOptions,
  setCustomerId,
  DispatchedOptions,
  // setProductType,
  // ProductTypeForSingleCode,
  // setProductTypeForSingleCode,
  refetch_For_Change,
  permissionClass,
  idTableFooter = false,
  totalOfAll,
  setDos,
  dos,
  setIsChecked,
  TapeRollOptions,
  BoxPattiOptions,
  PanniOptions,
  EmployeeOptions,
  CustomerOptions,
  setDeleteTrue,
  setMultipleIds,
  updatedIds,
  // setShowOffcancasEdit,
  setGetIdForEdit,
  handleCloseEdit,
  handleShowEdit,
  setIsNew,
}: IBody) => {




  const navigate = useNavigate()




  let pageName = "/salesOrder"
  let pagePriceList = "/addPricelist"





  return (
    <>
      <Restricted
        to={`${permissionClass?.front}.view_${permissionClass?.back}`}
        fallback={`You are not authorized to see list of ${apiname
          ?.split("/")
          ?.at(-1)}`}
      >

        {apiname === "singleCode/singleCode" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">

                  <Link to={"/listSinglecode"} style={productType === "Disposable" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "Disposable" ? "active" : ""
                    }`}>
                    <b>
                      Disposable
                    </b>
                  </Link>
                </li>
                <li className="nav-item">


                  <Link to={"/listSinglecode/LLDP"} style={productType === "LLDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "LLDPE" ? "active" : ""
                    }`}>
                    <b>
                      LLDPE
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listSinglecode/LDPE"} style={productType === "LDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "LDPE" ? "active" : ""
                    }`}>
                    <b>
                      LDPE
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listSinglecode/HDPE"} style={productType === "HDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "HDPE" ? "active" : ""
                    }`}>
                    <b>
                      HDPE
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listSinglecode/LDPEShrink"} style={productType === "LDPE Shrink" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "LDPE Shrink" ? "active" : ""
                    }`}>
                    <b>
                      LDPE Shrink
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listSinglecode/Shrink"} style={productType === "Shrink" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "Shrink" ? "active" : ""
                    }`}>
                    <b>
                      Shrink
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listSinglecode/PP"} style={productType === "Pp" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "Pp" ? "active" : ""
                    }`}>
                    <b>
                      PP
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listSinglecode/Other"} style={productType === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productType === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        ) : ""}





        {apiname === "allCode/disposableProduct" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listDisposableproducts"} style={productTypeDisposable === "Disposable" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "Disposable" ? "active" : ""
                    }`}>
                    <b>
                      Disposable
                    </b>
                  </Link>


                </li>
                <li className="nav-item">





                  <Link to={"/listDisposableproducts/LLDPE"} style={productTypeDisposable === "LLDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "LLDPE" ? "active" : ""
                    }`}>
                    <b>
                      LLDPE
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listDisposableproducts/LDPE"} style={productTypeDisposable === "LDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "LDPE" ? "active" : ""
                    }`}>
                    <b>
                      LDPE
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listDisposableproducts/HDPE"} style={productTypeDisposable === "HDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "HDPE" ? "active" : ""
                    }`}>
                    <b>
                      HDPE
                    </b>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/listDisposableproducts/LDPEShrink"} style={productTypeDisposable === "LDPE Shrink" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "LDPE Shrink" ? "active" : ""
                    }`}>
                    <b>
                      LDPE Shrink
                    </b>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/listDisposableproducts/Shrink"} style={productTypeDisposable === "Shrink" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "Shrink" ? "active" : ""
                    }`}>
                    <b>
                      Shrink
                    </b>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/listDisposableproducts/PP"} style={productTypeDisposable === "PP" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "PP" ? "active" : ""
                    }`}>
                    <b>
                      PP
                    </b>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/listDisposableproducts/Other"} style={productTypeDisposable === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>

              </ul>
            </div>
          </>
        ) : ""}




        {apiname === "productType" ? (
          <>

            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listProducttype"} style={productTypeOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>
                <li className="nav-item">





                  <Link to={"/listProductType/Disposable"} style={productTypeOptions === "Disposable" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeOptions === "Disposable" ? "active" : ""
                    }`}>
                    <b>
                      Disposable
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listProductType/Liner"} style={productTypeOptions === "Liner" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeOptions === "Liner" ? "active" : ""
                    }`}>
                    <b>
                      Liner
                    </b>
                  </Link>
                </li>

                <li className="nav-item">

                  <Link to={"/listProductType/Other"} style={productTypeOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        ) : ""}
        {apiname === "rawMaterialGrade" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listRawmaterialgrade"} style={RawMaterialGradeOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${RawMaterialGradeOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>
                <li className="nav-item">





                  <Link to={"/listRawMaterialGrade/PP"} style={RawMaterialGradeOptions === "PP" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${RawMaterialGradeOptions === "PP" ? "active" : ""
                    }`}>
                    <b>
                      PP
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listRawMaterialGrade/LD"} style={RawMaterialGradeOptions === "LD" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${RawMaterialGradeOptions === "LD" ? "active" : ""
                    }`}>
                    <b>
                      LD
                    </b>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/listRawMaterialGrade/LLDP"} style={RawMaterialGradeOptions === "LLDP" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${RawMaterialGradeOptions === "LLDP" ? "active" : ""
                    }`}>
                    <b>
                      LLDP
                    </b>
                  </Link>
                </li>

                <li className="nav-item">

                  <Link to={"/listRawMaterialGrade/HM"} style={RawMaterialGradeOptions === "HM" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${RawMaterialGradeOptions === "HM" ? "active" : ""
                    }`}>
                    <b>
                      HM
                    </b>
                  </Link>
                </li>

                <li className="nav-item">

                  <Link to={"/listRawMaterialGrade/Other"} style={RawMaterialGradeOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${RawMaterialGradeOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        ) : ""}







        {apiname === "tapeRoll/taperoll" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listTaperoll"} style={TapeRollOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${TapeRollOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>
                <li className="nav-item">





                  <Link to={"/listTaperoll/Bopp"} style={TapeRollOptions === "Bopp" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${TapeRollOptions === "Bopp" ? "active" : ""
                    }`}>
                    <b>
                      BOPP
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listTaperoll/PP"} style={TapeRollOptions === "Pp" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${TapeRollOptions === "Pp" ? "active" : ""
                    }`}>
                    <b>
                      PP
                    </b>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/listTaperoll/Other"} style={TapeRollOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${TapeRollOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>




              </ul>
            </div>
          </>
        ) : ""}




        {apiname === "boxPatti/boxPatti" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listBoxpatti"} style={BoxPattiOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${BoxPattiOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>
                <li className="nav-item">





                  <Link to={"/listBoxpatti/Bopp"} style={BoxPattiOptions === "Bopp" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${BoxPattiOptions === "Bopp" ? "active" : ""
                    }`}>
                    <b>
                      BOPP
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listBoxpatti/PP"} style={BoxPattiOptions === "Pp" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${BoxPattiOptions === "Pp" ? "active" : ""
                    }`}>
                    <b>
                      PP
                    </b>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/listBoxpatti/Other"} style={BoxPattiOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${BoxPattiOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>




              </ul>
            </div>
          </>
        ) : ""}
        {apiname === "panni/panniView" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listPanni"} style={PanniOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${PanniOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>
                <li className="nav-item">

                  <Link to={"/listPanni/LLDP"} style={PanniOptions === "LLDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${PanniOptions === "LLDPE" ? "active" : ""
                    }`}>
                    <b>
                      LLDPE
                    </b>
                  </Link>
                </li>
                
                <li className="nav-item">

                  <Link to={"/listPanni/LD"} style={PanniOptions === "LDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${PanniOptions === "LDPE" ? "active" : ""
                    }`}>
                    <b>
                      LDPE
                    </b>
                  </Link>
                </li>

                <li className="nav-item">

                  <Link to={"/listPanni/HM"} style={PanniOptions === "HDPE" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${PanniOptions === "HDPE" ? "active" : ""
                    }`}>
                    <b>
                      HDPE
                    </b>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/listPanni/LDPEShrink"} style={productTypeDisposable === "LDPE Shrink" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${productTypeDisposable === "LDPE Shrink" ? "active" : ""
                    }`}>
                    <b>
                      LDPE Shrink
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listPanni/PP"} style={PanniOptions === "PP" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${PanniOptions === "PP" ? "active" : ""
                    }`}>
                    <b>
                      PP
                    </b>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/listPanni/Other"} style={PanniOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${PanniOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>




              </ul>
            </div>
          </>
        ) : ""}

        {apiname === "leoUser/employee/employee" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listEmployee"} style={EmployeeOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${EmployeeOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>

                <li className="nav-item">

                  <Link to={"/listEmployee/MonthlySalary"} style={EmployeeOptions === "Monthly Salary" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${EmployeeOptions === "Monthly Salary" ? "active" : ""
                    }`}>
                    <b>
                      Monthly Salary
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listEmployee/DailyWages"} style={EmployeeOptions === "Daily Wages" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${EmployeeOptions === "Daily Wages" ? "active" : ""
                    }`}>
                    <b>
                      Daily Wages
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listEmployee/YearlySalary"} style={EmployeeOptions === "Yearly Salary" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${EmployeeOptions === "Yearly Salary" ? "active" : ""
                    }`}>
                    <b>
                      Yearly Salary
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listEmployee/Contract"} style={EmployeeOptions === "Contract" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${EmployeeOptions === "Contract" ? "active" : ""
                    }`}>
                    <b>
                      Contract
                    </b>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/listEmployee/Other"} style={EmployeeOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${EmployeeOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>




              </ul>
            </div>
          </>
        ) : ""}

        {apiname === "person/employee/person" ? (
          <>
            <div className="m-2">
              <ul className="nav nav-tabs">
                <li className="nav-item">


                  <Link to={"/listPersonDetail"} style={CustomerOptions === "All" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${CustomerOptions === "All" ? "active" : ""
                    }`}>
                    <b>
                      All
                    </b>
                  </Link>


                </li>

                <li className="nav-item">

                  <Link to={"/listPersonDetail/Employee"} style={CustomerOptions === "Employee" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${CustomerOptions === "Employee" ? "active" : ""
                    }`}>
                    <b>
                      Employee
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listPersonDetail/Customer"} style={CustomerOptions === "Customer" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${CustomerOptions === "Customer" ? "active" : ""
                    }`}>
                    <b>
                      Customer
                    </b>
                  </Link>
                </li>
                <li className="nav-item">

                  <Link to={"/listPersonDetail/Supplier"} style={CustomerOptions === "Supplier" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${CustomerOptions === "Supplier" ? "active" : ""
                    }`}>
                    <b>
                    Supplier
                    </b>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/listPersonDetail/Other"} style={CustomerOptions === "Other" ? { color: "white", backgroundColor: '#013564', marginRight: '5px' } : { color: "#003665", backgroundColor: 'rgb(124 167 205)', marginRight: '5px' }} className={`nav-link ${CustomerOptions === "Other" ? "active" : ""
                    }`}>
                    <b>
                      Other
                    </b>
                  </Link>
                </li>




              </ul>
            </div>
          </>
        ) : ""}


        {/* {apiname === "sales/dispatchOrder"   ? (
        <>
          <div className="m-2">
            <ul className="nav nav-tabs">
              
              <li className="nav-item">
              

<Link to={"/listDispatchOrder/Partially"} style={DispatchedOptions === "Partially" ?{ color: "white", backgroundColor:'#013564',marginRight:'5px' }:{ color: "#003665", backgroundColor:'rgb(124 167 205)',marginRight:'5px' }} className={`nav-link ${
                    DispatchedOptions === "Partially" ? "active" : ""
                  }`}>
                    <b>
                    Partially
                </b>
                </Link>
              </li>
              <li className="nav-item">
              
                 <Link to={"/listDispatchOrder/Not"} style={DispatchedOptions === "Not" ?{ color: "white", backgroundColor:'#013564',marginRight:'5px' }:{ color: "#003665" , backgroundColor:'rgb(124 167 205)',marginRight:'5px'}}className={`nav-link ${
                    DispatchedOptions === "Not" ? "active" : ""
                  }`}>
                  <b>
                  Not
                  </b>
                  </Link>
              </li>
            </ul>
          </div>
        </>
      ):""} */}












        <div
          className="table-responsive"
          style={pageName || pagePriceList ? {
            height: "auto",
            width: "100%",
            maxHeight: "auto",
            minHeight: "auto",
          } : {
            height: "90%",
            width: "100%",
            maxHeight: "630px",
            minHeight: "200px",
          }}
        >
          <Styles className="clearfix">
            <table
              className="table table-bordered table-hover  table sticky"
              {...getTableProps()}
              style={pageName || pagePriceList ? { height: "auto", width: "auto" } : { height: "420px", width: "auto" }}
            >
              <thead className="header text-center">
                {headerGroups.map((group: any) => {
                  return (
                    <>
                      <tr {...group.getHeaderGroupProps()} className="tr">
                        {group.headers.map((column: any, index: any) => {
                          let styleCount = allColumns.filter(
                            (sub: any, index: any) => {
                              if (index <= stickyHeader && sub.isVisible) {
                                return sub;
                              }
                            }
                          );


                          return (
                            <th
                              draggable
                              className={` ${index <= 3 ? "customth" : ""}`}
                              {...column.getHeaderProps(
                                !filter && column.getSortByToggleProps()
                              )}
                              id={column.id}
                              key={column.id}
                              onDragStart={handleDragStart}
                              onDragOver={handleDragOver}
                              onDrop={handleOnDrop}
                              onDragEnter={handleDragEnter}
                              style={column.id === "quotation" ?
                                {
                                  boxSizing: "border-box",
                                  width: "150px",
                                  position: "sticky",
                                  zIndex: "3",
                                  right: "0px"
                                } :
                                index < styleCount.length
                                  ? {
                                    boxSizing: "border-box",
                                    width: "150px",
                                    position: "sticky",
                                    zIndex: "3",
                                    left:
                                      index === 0
                                        ? "0px"
                                        : index === 1
                                          ? "150px"
                                          : index === 2
                                            ? "300px"
                                            : "450px",
                                    background: column.isSorted
                                      ? column.isSortedDesc
                                        ? "red"
                                        : "green"
                                      : "#CCCCCC",
                                  }
                                  : {
                                    boxSizing: "border-box",
                                    width: "150px",
                                    background: column.isSorted
                                      ? column.isSortedDesc
                                        ? "red"
                                        : "green"
                                      : "#CCCCCC",
                                  }
                              }
                            >
                              {column.render("Header")}
                              <FilterInput
                                filter={filter}
                                column={column}
                                FilterBy={FilterBy}
                                setFilterBy={setFilterBy}
                                setPage={setPage}
                              />
                            </th>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </thead>
              <tbody className="body" {...getTableBodyProps()}>
                {rows?.map((row: any, index: any) => {
                  prepareRow(row);
                  return (
                    <tr className="tr table-row" {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        if (cell.column.id === "srno") {
                          return (
                            <td
                              className={`td customtd`}
                              {...cell.getCellProps([{ size: "10" }])}
                            >
                              {pageSize != "all"
                                ? Number(pageSize) * page -
                                Number(pageSize) +
                                index +
                                1
                                : index + 1}
                            </td>
                          );
                        } else if (cell.column.id === "Actions") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Actions
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                  deleteid={deleteid}
                                  handleDeletedforce={handleDeletedforce}
                                  closereconfirmdelete={closereconfirmdelete}
                                  closeViewUsageModal={closeViewUsageModal}
                                  viewUsageModal={viewUsageModal}
                                  deletemodalask={deletemodalask}
                                  permissionClass={permissionClass}
                                />
                              }
                            </td>
                          );
                        } else if (cell.column.id === "Stepper_Actions") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Stepper_Actions
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                  deleteid={deleteid}
                                  handleDeletedforce={handleDeletedforce}
                                  closereconfirmdelete={closereconfirmdelete}
                                  closeViewUsageModal={closeViewUsageModal}
                                  viewUsageModal={viewUsageModal}
                                  deletemodalask={deletemodalask}
                                  permissionClass={permissionClass}
                                  setDeleteTrue={setDeleteTrue}
                                  setMultipleIds={setMultipleIds}
                                  updatedIds={updatedIds}
                                  // setShowOffcancasEdit={setShowOffcancasEdit}
                                  setGetIdForEdit={row}
                                  handleCloseEdit={handleCloseEdit}
                                  handleShowEdit={handleShowEdit}
                                  setIsNew={setIsNew}
                                />
                              }
                            </td>
                          );
                        }

                        else if (cell.column.id === "Actions_Yes_No") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Actions_Yes_No
                                  choiceData={choiceData}
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                  deleteid={deleteid}
                                  handleDeletedforce={handleDeletedforce}
                                  closereconfirmdelete={closereconfirmdelete}
                                  deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "order_actions") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Order_Actions
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                  deleteid={deleteid}
                                  // handleDeletedforce={handleDeletedforce}
                                  // closereconfirmdelete={closereconfirmdelete}
                                  // deletemodalask={deletemodalask}
                                  permissionClass={permissionClass}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "Discount_Actions") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Discount_Actions
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                  deleteid={deleteid}
                                  // handleDeletedforce={handleDeletedforce}
                                  // closereconfirmdelete={closereconfirmdelete}
                                  // deletemodalask={deletemodalask}
                                  permissionClass={permissionClass}
                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "Discount_Actions_View") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Discount_Actions_View
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                  deleteid={deleteid}
                                  // handleDeletedforce={handleDeletedforce}
                                  // closereconfirmdelete={closereconfirmdelete}
                                  // deletemodalask={deletemodalask}
                                  permissionClass={permissionClass}
                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "assignTo") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Assign_Online_Inquiry
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "MaintenanceResponsible") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Maintenance_Responsible
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "MaintenanceStatus") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Maintenance_Status
                                  row={row}
                                  apiname={apiname}
                                  mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "quotation") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Quotation_cell
                                  row={row}
                                  apiname={apiname}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        // else if (cell.column.id === "customer_details") {
                        //   return (
                        //     <td className="td" style={{boxSizing:"border-box",width:"150px",position:"sticky",zIndex:"3",right:"0px"}} {...cell.getCellProps()}>
                        //       {
                        //         <Quotation_cell
                        //           row={row}
                        //           apiname={apiname}
                        //           // mutate={singleDelete}
                        //           masterName={addButtonName}
                        //           // deleteid={deleteid}
                        //           // handleDeletedforce={handleDeletedforce}
                        //           // closereconfirmdelete={closereconfirmdelete}
                        //           // deletemodalask={deletemodalask}

                        //         />
                        //       }
                        //     </td>
                        //   );
                        // }
                        else if (cell.column.id === "dispatch_order") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Dispatch_Order
                                  row={row}
                                  apiname={apiname}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "dispatch_selection") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Dispatch_Selection
                                  row={row}
                                  apiname={apiname}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                  setCustomerId={setCustomerId}
                                  setDos={setDos}
                                  dos={dos}
                                  setIsChecked={setIsChecked}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "dispatched") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Dispatched
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "verify") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Verify_Dispatch_Products
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "download_invoice") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Download_Invoice
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "customer_details_edit") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Customer_Details
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "sheet_roll_code") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Sheet_Roll_Code
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "duplicate_granual") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Duplicate_Granual_Action
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "Task_Board") {
                          return (
                            <td className="td" style={{ boxSizing: "border-box", width: "150px", position: "sticky", zIndex: "3", right: "0px" }} {...cell.getCellProps()}>
                              {
                                <Task_Board
                                  row={row}
                                  apiname={apiname}
                                  refetch={refetch}
                                  // mutate={singleDelete}
                                  masterName={addButtonName}
                                // deleteid={deleteid}
                                // handleDeletedforce={handleDeletedforce}
                                // closereconfirmdelete={closereconfirmdelete}
                                // deletemodalask={deletemodalask}

                                />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.id === "status") {
                          return (
                            <td className="td" {...cell.getCellProps()}>
                              {
                                <Status
                                  row={row}
                                  update={Update}
                                  apiname={apiname}
                                  refetch_f={refetch}
                                />
                              }
                            </td>
                          );
                        } else {
                          return (
                            <td
                              className={`td ${index <= 3 ? "customtd" : ""}`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}

















              </tbody>





              {idTableFooter &&

                <>
                  <tfoot>
                    <tr className="tr table-row font-weight-bold" style={{ width: "auto", textAlign: "center", backgroundColor: "rgb(204, 204, 204)" }}>
                      <td className="td sticky-column" style={{ backgroundColor: "rgb(204, 204, 204)", color: "black", width: "300px", position: "sticky", left: 0 }}>
                        Total
                      </td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_godown_stock}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_balance_stock}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_lee_price}</td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_order_qty}</td>
                      <td style={{ width: "150px" }} >{totalOfAll[0]?.total_discount_after_tax}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_discount}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_one_kg_rate}</td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_box_weight}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.basic_discount}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_basic_discount}</td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_basic}</td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}></td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_gst_amount}</td>
                      <td style={{ width: "150px" }}>{totalOfAll[0]?.total_net_amount}</td>
                      <td style={{ width: "150px" }}></td>
                      {/* <td style={{width:"150px"}}></td> */}
                    </tr>
                  </tfoot>

                </>}






            </table>
          </Styles>
        </div>
      </Restricted>
    </>
  );
};

export default Body;
