import React, { useEffect, useState, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate } from "../../hooks/Api";
import { useFetchCategoryDropDown, useFetchGstTypeDropDown, useFetchHSNProductTypeDropDown, useFetchMaterialDensityDropDown, useFetchMaterialTypeDropDown } from "../../hooks/DropDown_Api";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import { AiOutlinePlus } from "react-icons/ai";
import Button from "react-bootstrap/esm/Button";
import { Material_Density_Model } from "../../components/Dynamic_Form_Models/Material_Density_Model";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";

const customIndicator = {
    IndicatorsContainer:IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer:IndicatorsContainerCreate
}



interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Hsn = () => {
    const[open,setOpen] = useState(false)
    const [submitted,setSubmitted] = useState(false)

    const navigate = useNavigate()
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [options, setOptions]:any = useState([])
    const [optionsType, setOptionsType] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [showGst, setShowGst]:any = useState({})
    const [showDen, setShowDen] = useState("")
    const apiname = process.env.REACT_APP_HSN_API?.toString()


    const { mutate: hsn, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)


    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues,watch }: any = useForm();


    const value = getValues("gst")
 
    const { data: colorData, refetch: colorRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])



    const handleChange = (e: any) => {
        setShowGst(e)
        setShowDen(e)
        setValue("grade_type", e.value)
        setValue("gst", e.gst_id)
        setValue("density",e.density)
        setForValue({label:e?.label,value:e?.value})

      
        
    }

    const [valueCategory, setValueCategory]:any = useState();
    const [optionsCategory, setOptionsCategory]: any = useState([]);
    const [valueCategoryCreatable, setValueCategoryCreatable] = useState<Option | null>();
   
    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const apinameForCategory = process.env.REACT_APP_PRODUCT_CATEGORY_API?.toString()
    const { mutate: mutateCategory, data: dataCategory, isError: CategoryIsError, isLoading: CategoryLoading, isSuccess: CategorySuccess, error: CategoryError }: any = useCreate(apinameForCategory)


    const { data: CategoryData, refetch: CategoryRefetch } = useFetchCategoryDropDown()
    
    useEffect(() => {
        setOptionsCategory(CategoryData)
    }, [CategoryData])

    const handleChangeCategory = (e: any) => {

        CategoryRefetch()

        setApiName(apinameForCategory)
        setDeleteApiName(apinameForCategory)
        setEditId(e?.value)
        setEditData(CategoryData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCategory(e)

        setValue("product_categories", `${e?.value}`)
        setValueCategoryCreatable(null)
    }

    const handleCreate = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOption(inputValue);
        setIsLoadingValue(false);
       
        
        mutateCategory({name:newOption.name,status:newOption.status})
        setOptionsCategory((prev:any) => [...prev, newOption]);
        setValueCategoryCreatable(newOption);
      }, 0);

   
    };


    useEffect(() => {
        (async () => {
            if(CategorySuccess && optionsCategory){
            await CategoryRefetch();
            }
            setValue("product_categories",CategoryData[0].value)
        })();
        
        return () => {
        };
      }, [CategorySuccess,CategoryData]);


    const [valueProductType, setValueProductType]:any = useState();
    const [optionsProductType, setOptionsProductType]: any = useState([]);
    const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();
   
    // const [isLoadingValue, setIsLoadingValue] = useState(false);
    const apinameForProductType = process.env.REACT_APP_PARTICULAR_SECTOR_NAME_API?.toString()
    const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)


    const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchHSNProductTypeDropDown()
    
    useEffect(() => {
        setOptionsProductType(ProductTypeData)
    }, [ProductTypeData])

    const handleChangeProductType = (e: any) => {

        ProductTypeRefetch()

        setApiName(apinameForProductType)
        setDeleteApiName(apinameForProductType)
        setEditId(e?.value)
        setEditData(ProductTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductType(e)

        setValue("particular_sector_name", `${e?.value}`)
        setValueProductTypeCreatable(null)
    }

    const handleCreateProductType = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOption(inputValue);
        setIsLoadingValue(false);
       
        
        mutateProductType({name:newOption.name,status:newOption.status})
        setOptionsProductType((prev:any) => [...prev, newOption]);
        setValueProductTypeCreatable(newOption);
      }, 0);

   
    };


    useEffect(() => {
        (async () => {
            if(ProductTypeSuccess && optionsProductType){
            await ProductTypeRefetch();
            }
            setValue("particular_sector_name",ProductTypeData[0].value)
        })();
        
        return () => {
        };
      }, [ProductTypeSuccess,ProductTypeData]);



      const [valueGst, setValueGst]:any = useState();
    const [optionsGst, setOptionsGst]: any = useState([]);
    const [valueGstCreatable, setValueGstCreatable] = useState<Option | null>();
   
    const apinameForGst = process.env.REACT_APP_GST_TYPE_API?.toString()
    const { mutate: mutateGst, data: dataGst, isError: GstIsError, isLoading: GstLoading, isSuccess: GstSuccess, error: GstError }: any = useCreate(apinameForGst)

    const { data: GstData, refetch: GstRefetch } = useFetchGstTypeDropDown()
    
    useEffect(() => {
        setOptionsGst(GstData)
    }, [GstData])


    const handleChangeGst = (e: any) => {
        GstRefetch()

        setApiName(apinameForGst)
        setDeleteApiName(apinameForGst)
        setEditId(e?.value)
        setEditData(GstData)
        setRenderKey(prevKey => prevKey + 1);

        setValueGst(e)
        setValue("gst_type", `${e?.value}`)
        setValueGstCreatable(null)
    }

    const handleCreateGst = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOption(inputValue);
        setIsLoadingValue(false);
       
        
        mutateGst({name:newOption.name,status:newOption.status})
        setOptionsGst((prev:any) => [...prev, newOption]);
        setValueGstCreatable(newOption);
      }, 0);

   
    };



    useEffect(() => {
        (async () => {
            if(GstSuccess && optionsGst){
            await GstRefetch();
            }
            setValue("gst_type",GstData[0].value)
        })();
        
        return () => {
        };
      }, [GstSuccess,GstData]);





    const [forValue,setForValue]:any = useState([])

  

    const handleReset = () => {
        reset()
        setShowGst({})
        setShowDen("")
        setForValue(null)
        setValueProductTypeCreatable(null)
        setValueProductType(null)
        setValueCategoryCreatable(null)
        setValueCategory(null)
        setValueGstCreatable(null)
        setValueGst(null)
    }

    const onSubmit = (hsndetail: any) => {
        const formData = new FormData();

        for (const [key, value] of Object.entries(hsndetail)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            // else {
            //     formData.append(key, formvalue[0])
            // }
        }
        hsn(formData);

    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listHSN', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    useEffect(()=>{
        if(submitted){
            setForValue(options[0])
            setValue("grade_type",options[0].value)
            setValue("gst",options[0]?.gst_id)
            setValue("density",options[0]?.density)
            setShowGst(options[0])

        }

    },[submitted,options])




const handleValue=(e:any)=>{
    e.target.value = e.target.value.toLowerCase()
.split(' ')
.map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
.join(' ');
}


const handleHSNcode = (e: any) => {
    let temp = e.target.value.replace(/\D/g, ''); 
    if (temp.length > 8) temp = temp.substring(0, 8); 
    if (temp.length > 4) {
        temp = temp.slice(0, 4) + ' ' + temp.slice(4);
    }
    e.target.value = temp;

    setValue('hsn_code',temp)
}

const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {
        if (apiName === apinameForProductType) {
            ProductTypeRefetch()
        }
        if (apiName === apinameForCategory) {
            CategoryRefetch()
        }
        if (apiName === apinameForGst) {
            GstRefetch()
        }
        
        // queryClient.invalidateQueries(['Panni Material Use']);



       
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueProductType && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueCategory && apiName === apinameForCategory) {
            setValueCategory(null)
            setValueCategoryCreatable(null)
        }
        if (OpenEditDropDownModel && valueGst && apiName === apinameForGst) {
            setValueGst(null)
            setValueGstCreatable(null)
        }
      






        if (deletesucess && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForCategory) {
            setValueCategory(null)
            setValueCategoryCreatable(null)
        }
        if (deletesucess && apiName === apinameForGst) {
            setValueGst(null)
            setValueGstCreatable(null)
        }
      
       


    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };




    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>HSN Code</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={"/dashboard"} className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={"#"} className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/listHSN"} className="text-decoration-none text-black">HSN Code</Link></li>
                                    <li className="breadcrumb-item active">Add HSN Code</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                        <form ref={focus} onKeyUp={event => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="HSN Code" 
                                                       
                                                        
                                                        {...register('hsn_code', {
                                                            
                                                            required: 'Field is required',
                                                            pattern: {
                                                                value: /^(\d{1,4})(?:\s(\d{1,4}))?(?:\s(\d{1,2}))?$/gm ,
                                                                message: 'No more than 10 digits',
                                                            },
                                                        })}
                                                        onChange={handleHSNcode}
                                                        
                                                        
                                                        
                                                        // {...register("hsn_code")} 
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.hsn_code?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Product Code & Name & Type</label>
                                                    <Select
                                                        // placeholder={'Select Gst'}
                                                        options={optionsType}
                                                        onChange={handleChangeType}
                                                        isMulti
                                                    // {...register("grade_type")}
                                                    // name="grade_type"
                                                    />
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code Qty</label>
                                                    <input disabled type="text"
                                                        className="form-control" id="exampleInputBrand" placeholder="HSN Code Qty" {...register("hsn_qty")} />
                                                </div> */}
                                                {/* <div className="col-sm-3 mt-2" >
                                                    <label htmlFor="exampleInputEmail1">Grade Type <span style={{color:'red'}}>*</span></label>
                                                    <Select
                                                                                                        {...register("grade_type",{required: { value: true, message: "Field is required" }})}
                                                        value={forValue}
                                                        options={options}
                                                        onChange={handleChange}

                                                    />
                                                 <span className='text-red w-100'>{errors?.grade_type?.message}</span>

                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                <label htmlFor="name_and_grade">Grade Type & Density & Sutra <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group" ></div> */}
                                                    <div className="d-flex row" >
                                                        <Select 
                                                        placeholder={'Grade Type & Density & Sutra'}
                                                            {...register("grade_type",{required: { value: true, message: "Field is required" }})}
                                                            value={forValue}
                                                            options={options}
                                                            components={customIndicator}
                                                            onChange={handleChange}
                                                            className="dropdown-select"
                                                            maxMenuHeight={100}

                                                        />
                                                        <div className="input-group-append" style={{width:0, padding:0}}>
                                                            <Button className="input-group-text border-start-0 bg-primary"  onClick={()=>{
                                                                setOpen(true)}}><AiOutlinePlus/></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.grade_type?.message}</span>

                                                </div>





                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Type</label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueProductType(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("particular_sector_name", { required: { value: false, message: "Product Type is required" } })}
                                                        placeholder={'Select Product Type'}
                                                        components={ customCreatableIndicator }
                                                        // menuIsOpen={true}
                                                        options={optionsProductType}
                                                        onCreateOption={handleCreateProductType}
                                                        onChange={handleChangeProductType}
                                                        value={valueProductTypeCreatable==null?valueProductType:valueProductTypeCreatable}
                                                        maxMenuHeight={100}
                                                        onMenuOpen={() => ProductTypeRefetch()}
                                                    />
                                                    {/* <div className="input-group"> */}
                                                        {/* <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Type" {...register("particular_sector_name", { onChange: handleValue, required: { value: false, message: "Field is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    {/* </div> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Category </label>
                                                    
                                                    
                                                     <CreatableSelect
                                                    isClearable={()=>{setValueCategory(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("product_categories", { required: { value: false, message: "Product Category is required" } })}
                                                        placeholder={'Select Product Category'}
                                                        components={ customCreatableIndicator }
                                                        // menuIsOpen={true}
                                                        options={optionsCategory}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeCategory}
                                                        maxMenuHeight={100}
                                                        value={valueCategoryCreatable==null?valueCategory:valueCategoryCreatable}
                                                        onMenuOpen={() => CategoryRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.product_categories?.message}</span>
                                                </div>
                                               
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">GST Type</label>
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueGst(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("gst_type", { required: { value: false, message: "GST Type is required" } })}
                                                        placeholder={'Select GST Type'}
                                                        components={ customCreatableIndicator }
                                                        options={optionsGst}
                                                        onCreateOption={handleCreateGst}
                                                        onChange={handleChangeGst}
                                                        value={valueGstCreatable==null?valueGst:valueGstCreatable}
                                                        maxMenuHeight={100}
                                                        onMenuOpen={() => GstRefetch()}
                                                    />
                                                    <span className='text-red w-100'>{errors?.gst_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">GST%</label>
                                                    <input disabled type="text"
                                                        value={showGst?.gst}
                                                        className="form-control" id="exampleInputBrand" placeholder="GST%" {...register("gst")} />

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Density</label>
                                                    <div className="input-group">
                                                        <input disabled type="text" className="form-control" id="exampleInputBrand" 
                                                        placeholder="Material Density" {...register("density")} 
                                                        />

                                                        {/* <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="status">Status <span style={{color:'red'}}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}>
                                                        <option value="" selected>Select Any </option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listHSN")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Material_Density_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted}/>
  {/* Edit Modal HTML */}
  <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )
}

export default Add_Hsn