import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from "react-select"
import axios from 'axios'
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { RiPencilLine } from "react-icons/ri";
import InputField from "../../components/Input/input";
import pdfLogo from "../../images/Export/pdf upload.png";
import cameraLogo from "../../images/Export/Camera.png";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { useFetchCategoryDropDown, useFetchCompanyNameDropDown, useFetchDepartmentDropDown, useFetchHSNDropDown, useFetchMachineBrandDropDown, useFetchMachineLocationDropDown, useFetchMachineNameDropDown, useFetchMaterialandHSNDropDown } from "../../hooks/DropDown_Api";
import DatePicker, { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import moment from "moment";
import { Deletesinglewithdependancy } from "../../components/modal";
import useFocusOnEnter from "../../components/focus";
import { Upload, UploadFile } from "antd";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import CreatableSelect from "react-select/creatable";
import { Department_Model } from "../../components/Dynamic_Form_Models/Department_Model";
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});



const Edit_Machine = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_MACHINE_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const { mutate, isSuccess, isError, error, data: gstUpdated_response, isLoading }: any = useUpdateSingle()
    const [optionsHSN, setOptionsHSN]: any = useState([])
    const [options, setOptions]: any = useState([])
    const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()
    const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue }: any = useForm();

    const [selDepartment, setSelDepartment] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [selMachine, setSelMachine] = useState('')
    const [selectedMachine, setSelectedMachine] = useState('')
    const [selHSN, setSelHSN] = useState('')
    const [selectedHSN, setSelectedHSN] = useState('')
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [logo, setlogo]: any = useState([])
    const [logoPdf, setlogoPdf]: any = useState([])
    const [optionsMachine, setOptionsMachine]: any = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()

    const [open, setOpen] = useState(false)
    const [openHSN, setOpenHSN] = useState(false)


    const apinameForCavity = process.env.REACT_APP_MACHINE_NAME_API?.toString()
    const { mutate: mutateCavity, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: cavitySuccess, error: cavityError }: any = useCreate(apinameForCavity)


    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()

    const [Warranty, setWarranty] = useState("no")
    const onWarrantyChange = (e: any) => {
        setWarranty(e.target.value)
    }
    const [Guarantee, setGuarantee] = useState("no")
    const onGuaranteeChange = (e: any) => {
        setGuarantee(e.target.value)
    }

    const { data: colorData, refetch: colorRefetch } = useFetchDepartmentDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])

    const handleChange = (e: any) => {
        setSearchTerm(e)
        setValue("machine_department", e.value)
        setSelDepartment(e)
    }


    const { data: HSNData, refetch: HSNRefetch } = useFetchMaterialandHSNDropDown(searchTerm)

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    const handleChangeHSN = (e: any) => {
        setSelHSN(e)
        setValue("hsn_code", `${e.value}`)
        // setHSN(e.hsn)
        // setDensity(e.material)
        // setPolymer(e.polymer)
        setValue("polymer_grade", e.polymer)
    }

    // const { data: hsnData, refetch: hsnRefetch } = useFetchHSNDropDown(searchTerm)

    // useEffect(() => {
    //     setOptionsHSN(hsnData)
    // }, [hsnData])


    // useEffect(() => {
    //     if (searchTerm.length >= 1) {
    //         hsnRefetch()
    //     }
    // }, [])

    // const {mutate:getrelated,data:relatedData} = useCreate(getRelatedHsn)

    // const handleChangeHSN = (e: any) => {
    //     setSelHSN(e)
    //     setValue("hsn_code", `${e.value}`)
    // }

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [valueCavityCreatable, setValueCavityCreatable] = useState<Option | null>();
    const [valueCavity, setValueCavity]: any = useState();

    const { data: machineNameData, refetch: machineNameRefetch } = useFetchMachineNameDropDown()
    useEffect(() => {
        setOptionsMachine(machineNameData)
    }, [machineNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            machineNameRefetch()
        }
    }, [])


    const handleChangeMachineName = (e: any) => {

        setValue("machine_name", `${e?.value}`)
        setSelMachine(e)
        setValueCavity(e)
        setValueCavityCreatable(null)
    }

    const handleCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCavity({ name: newOption.name, status: newOption.status })
            setOptionsMachine((prev: any) => [...prev, newOption]);
            setValueCavityCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (cavitySuccess && optionsMachine && valueCavityCreatable != null) {
                await machineNameRefetch();

                setValue("machine_name", machineNameData[0].value)
            }
        })();

        return () => {
        };
    }, [cavitySuccess, machineNameData]);

    const [valueCategory, setValueCategory]: any = useState();
    const [valueCategoryCreatable, setValueCategoryCreatable] = useState<Option | null>();
    const [optionsCategory, setOptionsCategory]: any = useState([]);
    const [selectedCategory, setSelectedCategory]: any = useState('');
    const apinameForCategory = process.env.REACT_APP_PRODUCT_CATEGORY_API?.toString()
    const { mutate: mutateCategory, data: dataCategory, isError: CategoryIsError, isLoading: CategoryLoading, isSuccess: CategorySuccess, error: CategoryError }: any = useCreate(apinameForCategory)


    const { data: CategoryData, refetch: CategoryRefetch } = useFetchCategoryDropDown()

    useEffect(() => {
        setOptionsCategory(CategoryData)
    }, [CategoryData])

    const handleChangeCategory = (e: any) => {
        CategoryRefetch()

        setApiName(apinameForCategory)
        setDeleteApiName(apinameForCategory)
        setEditId(e?.value)
        setEditData(CategoryData)
        setRenderKey(prevKey => prevKey + 1);




        setValue("product_categories", e?.value)
        //   setSelCategory(e)
        // getrelated({"category":e.value})
        setValueCategory(e)
    }

    const handleCreateCategory = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCategory({ name: newOption.name, status: newOption.status })
            setOptionsCategory((prev: any) => [...prev, newOption]);
            setValueCategoryCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (CategorySuccess && optionsCategory && valueCategoryCreatable != null) {
                await CategoryRefetch();

                setValue("product_categories", CategoryData[0].value)
            }
        })();

        return () => {
        };
    }, [CategorySuccess, CategoryData]);

    // useEffect(() => {
    //     let temp_results = relatedData?.results.map((e:any)=>{
    //         console.log(e);
    //         return (
    //             {

    //                 value:e.id,
    //                 label:e.hsn_code,
    //                 // label:`${e.grade_type?.material_type_name}::${e?.particular_sector_name?.name}::${e?.hsn_code}::${e?.gst?.india_GST}`,
    //                 gst_id:e.gst.id,
    //                 gst:e.gst.india_GST,
    //             })

    //     })

    //     setOptionsHSN(temp_results)
    // }, [relatedData])


    useEffect(() => {
        if (Guarantee === 'no') {
            setValue("guarantee_months", "")
        }
        if (Warranty === 'no') {
            setValue("warranty_time", "")
        }
    }, [Guarantee, Warranty])

    var from = data?.machine_manufacturer_date;
    var to = data?.machine_purchase_date;
    var one = moment(from, "DD-MM-YYYY").add(1, 'd').toISOString();
    var two = moment(to, "DD-MM-YYYY").add(1, 'd').toISOString();

    const [uploadFile, setUploadFile]: any = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc]: any = useState<UploadFile[]>([])

    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedMachine(data?.machine_name?.id)
            setValue("machine_name", data?.machine_name?.id)
            setSelectedHSN(data?.hsn_code?.id)
            setValue("hsn_code", data?.hsn_code?.id)
            setSelectedDepartment(data?.machine_department?.id)
            setValue("machine_department", data?.machine_department?.id)
            setSelectedCategory(data.product_categories?.id)
            setValue('product_categories', data?.product_categories?.id)
            setValue("machine_manufacturer_date", one?.split("T")[0])
            setValue("machine_purchase_date", two?.split("T")[0])
            setSelectedMachineBrand(data.machine_brand?.id)
            setValue("machine_brand", data?.machine_brand?.id)
            setSelectedMachineLocation(data.machine_location?.id)
            setValue("machine_location", data?.machine_location?.id)
            // getrelated({"category": data.product_categories.id})
            setWarranty(data?.warranty_yes_no)
            setGuarantee(data?.guarantee_yes_no)
            setSelectedCompanyName(data?.purchase_company_name_and_city?.id)
            setValue("purchase_company_name_and_city", data?.purchase_company_name_and_city?.id)

            if (data?.machine_manufacturer_date && data?.machine_purchase_date) {
                setFromDate(dayjs(data?.machine_manufacturer_date, 'DD/MM/YYYY'))
                setToDate(dayjs(data?.machine_purchase_date, 'DD/MM/YYYY'))
            }

            let tempImages = data?.image?.map((img: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${img?.id}`,
                    name: `${img.title}`,
                    status: 'done',
                    url: img?.doc,
                    thumbUrl: img?.doc,
                }

                return ctx
            })

            let tempPdf = data?.pdf?.map((pdf: any, index: number) => {
                let ctx: UploadFile = {
                    uid: `${pdf?.id}`,
                    name: `${pdf.title}`,
                    status: 'done',
                    url: pdf?.doc,
                    thumbUrl: pdf?.doc,
                }

                return ctx
            })



            setUploadFile(tempImages)
            setUploadFileDoc(tempPdf)


        }
    }, [data]);


    const [selectedMachineBrand, setSelectedMachineBrand]: any = useState('')
    const [optionsMachineBrand, setOptionsMachineBrand]: any = useState([]);
    const [valueMachineBrandCreatable, setValueMachineBrandCreatable] = useState<Option | null>();
    const [valueMachineBrand, setValueMachineBrand]: any = useState();
    const apinameForMachineBrand = process.env.REACT_APP_MACHINE_BRAND_API?.toString()
    const { mutate: mutateMachineBrand, data: dataMachineBrand, isError: MachineBrandIsError, isLoading: MachineBrandLoading, isSuccess: MachineBrandSuccess, error: MachineBrandError }: any = useCreate(apinameForMachineBrand)



    const { data: machineBrandData, refetch: machineBrandRefetch } = useFetchMachineBrandDropDown()
    // console.log(machineBrandData, "optionsBrand");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMachineBrand(machineBrandData)
    }, [machineBrandData])
    const handleChangeMachineBrand = (e: any) => {
        machineBrandRefetch()

        setApiName(apinameForMachineBrand)
        setDeleteApiName(apinameForMachineBrand)
        setEditId(e?.value)
        setEditData(machineBrandData)
        setRenderKey(prevKey => prevKey + 1);


        setValueMachineBrand(e)
        setValue("machine_brand", `${e?.value}`)
        setValueMachineBrandCreatable(null)
    }

    const handleCreateMachineBrand = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMachineBrand({ name: newOption.name, status: newOption.status })
            setOptionsMachineBrand((prev: any) => [...prev, newOption]);
            setValueMachineBrandCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (MachineBrandSuccess && optionsMachineBrand) {
                await machineBrandRefetch();
            }
            setValue("machine_brand", machineBrandData[0].value)
        })();

        return () => {
        };
    }, [MachineBrandSuccess, machineBrandData]);

    const [selectedMachineLocation, setSelectedMachineLocation]: any = useState('')
    const [optionsMachineLocation, setOptionsMachineLocation]: any = useState()
    const [valueMachineLocationCreatable, setValueMachineLocationCreatable] = useState<Option | null>();
    const [valueMachineLocation, setValueMachineLocation]: any = useState();
    const apinameForMachineLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateMachineLocation, data: dataMachineLocation, isError: MachineLocationIsError, isLoading: MachineLocationLoading, isSuccess: MachineLocationSuccess, error: MachineLocationError }: any = useCreate(apinameForMachineLocation)



    const { data: machineLocationData, refetch: machineLocationRefetch } = useFetchMachineLocationDropDown()
    // console.log(machineLocationData, "optionsLocation");
    // const [optionsCavityName, setOptionsCavityName] = useState(cavityNameData)
    useEffect(() => {
        setOptionsMachineLocation(machineLocationData)
    }, [machineLocationData])
    const handleChangeMachineLocation = (e: any) => {
        machineLocationRefetch()

        setApiName(apinameForMachineLocation)
        setDeleteApiName(apinameForMachineLocation)
        setEditId(e?.value)
        setEditData(machineLocationData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMachineLocation(e)

        setValue("machine_location", `${e?.value}`)
        setValueMachineLocationCreatable(null)
    }

    const handleCreateMachineLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMachineLocation({ name: newOption.name, status: newOption.status })
            setOptionsMachineLocation((prev: any) => [...prev, newOption]);
            setValueMachineLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MachineLocationSuccess && optionsMachineLocation) {
                await machineLocationRefetch();
            }
            setValue("machine_location", machineLocationData[0].value)
        })();

        return () => {
        };
    }, [MachineLocationSuccess, machineLocationData]);



    const apinameForCompanyName = process.env.REACT_APP_RAWMATERIAL_COMPANY_NAME_API?.toString()
    const { mutate: mutateCompanyName, data: dataCompanyName, isError: CompanyNameIsError, isLoading: CompanyNameLoading, isSuccess: CompanyNameSuccess, error: CompanyNameError }: any = useCreate(apinameForCompanyName)

    const [valueCompanyName, setValueCompanyName]: any = useState();
    const [optionsCompanyName, setOptionsCompanyName]: any = useState([]);
    const [valueCompanyNameCreatable, setValueCompanyNameCreatable] = useState<Option | null>();
    const [selectedCompanyName, setSelectedCompanyName] = useState('')

    const { data: CompanyNameData, refetch: CompanyNameRefetch } = useFetchCompanyNameDropDown()

    useEffect(() => {
        setOptionsCompanyName(CompanyNameData)
    }, [CompanyNameData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            CompanyNameRefetch()
        }
    }, [])


    const handleChangeCompanyName = (e: any) => {
        CompanyNameRefetch()

        setApiName(apinameForCompanyName)
        setDeleteApiName(apinameForCompanyName)
        setEditId(e?.value)
        setEditData(CompanyNameData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCompanyName(e)

        setValue("purchase_company_name_and_city", `${e?.value}`)
        setValueCompanyNameCreatable(null)
    }





    const handleCreateCompanyName = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCompanyName({ name: newOption.name, status: newOption.status })
            setOptionsCompanyName((prev: any) => [...prev, newOption]);
            setValueCompanyNameCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CompanyNameSuccess && optionsCompanyName) {
                await CompanyNameRefetch();
            }
            setValue("purchase_company_name_and_city", CompanyNameData[0].value)
        })();

        return () => {
        };
    }, [CompanyNameSuccess, CompanyNameData]);

    // const handleFromDate = (e: any) => {
    //     setValue("machine_purchase_date", e.target.value)
    // }

    const [imageID, setImageID]: any = useState([])
    const [pdfID, setPdfID]: any = useState([])

    const onSubmit = (branddetail: any) => {
        if (Guarantee === "no") {
            branddetail.guarantee_months = ''
        }
        if (Warranty === "no") {
            branddetail.warranty_time = ''
        }

        if (!fromDate && !toDate) {
            delete branddetail.machine_manufacturer_date;
            delete branddetail.machine_purchase_date;
        } else if (fromDate && !toDate) {
            delete branddetail.machine_purchase_date;
        } else if (!fromDate && toDate) {
            delete branddetail.machine_manufacturer_date;
        }
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
            if (key === "machine_manufacturer_date") {
                const renewdate = moment(branddetail.machine_manufacturer_date, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                );
                formData.append(key, renewdate);
                continue
            }
            else if (key === "machine_purchase_date") {
                const renewdate = moment(branddetail.machine_purchase_date, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                );
                formData.append(key, renewdate);
                continue
            }
            if (key === "image") {
                imageID.map((old: any) => {
                    formData.append('machine_image', old?.id)
                })
                for (let i = 0; i < logo.length; i++) {
                    formData.append('image', logo[i])
                }
            }
            if (key === "pdf") {
                pdfID.map((old: any) => {
                    formData.append('machine_pdf', old?.id)
                })
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdf', logoPdf[i])
                }
            }
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")




    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setValuesofform(null)
            setShow(true)
            navigate('/listMachinename', { state: { showEditToast: true } })
        }

    }, [data, isSuccess, yessuccess,
        nosuccess, gstUpdated_response])

    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true)
    //         setshowmessage("success")
    //         navigate('/listMachinename', { state: { showEditToast: true } })
    //     }
    //     else if (isError) {
    //         setShow(true)
    //         setshowmessage("danger")
    //     }
    // }, [gstUpdated_response, error, isSuccess])

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShow(false)
    //     }, 3000)

    // }, [show]);

    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const onLogoChange = (e: any) => {
        setlogo(e.target.files)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files)
    }
    const [fromDate, setFromDate]: any = useState("")
    const [toDate, setToDate]: any = useState("")

    const handleFromDate = (date: any, dateString: any) => {
        if (date == null) {
            setFromDate("")
            setValue("machine_manufacturer_date", ``)
            return
        }
        setFromDate(dayjs(date, 'DD/MM/YYYY'))
        setValue("machine_manufacturer_date", `${date?.$D}-${date?.$M + 1}-${date?.$y}`)
    }

    const handleToDate = (date: any, dateString: any) => {
        if (date == null) {
            setToDate("")
            setValue("machine_purchase_date", ``)
            return
        }
        setToDate(dayjs(date, 'DD/MM/YYYY'))
        setValue("machine_purchase_date", `${date?.$D}-${date?.$M + 1}-${date?.$y}`)
    }


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        return current && current < dayjs().startOf('day');
    };



    let dateOne = data?.machine_manufacturer_date
    let fromDateValue = dateOne
    let dateTwo = data?.machine_purchase_date
    let toDateValue = dateTwo


    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }

    const FileOnChange = (e: any) => {
        e.file.status = "done"

        let tempOldFiles: any = []


        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }
                tempOldFiles.push(ctx)

            }
        })

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        }).filter((e: any) => e != undefined)

        setImageID(tempOldFiles)
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"


        let tempOldFiles: any = []
        e.fileList.map((e: any) => {
            if (!("originFileObj" in e)) {
                let ctx = {
                    id: parseInt(e.uid),
                    doc: e.url
                }

                tempOldFiles.push(ctx)
            }
        })



        const tempFiles = e.fileList.map((file: any) => {
            // console.log(file,"file");
            return file.originFileObj
        }).filter((e: any) => e != undefined)


        setPdfID(tempOldFiles)
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }

    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    // const [forValue, setForValue]: any = useState([])
    // const [forHSNValue, setForHSNValue]: any = useState([])
    const [submitted, setSubmitted] = useState(false)


    useEffect(() => {
        if (submitted) {
            setSelDepartment(options[0])

            setValue("machine_department", options[0].value)

        }

    }, [submitted, options])


    const [submittedHSN, setSubmittedHSN] = useState(false)


    useEffect(() => {
        if (submittedHSN) {
            setSelHSN(optionsHSN[0])

            setValue("hsn_code", optionsHSN[0].value)

        }

    }, [submittedHSN, optionsHSN])



    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_no', temp)
    }


    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {
        if (apiName === apinameForCategory) {
            CategoryRefetch()
        }
        if (apiName === apinameForMachineLocation) {
            machineLocationRefetch()
        }
        if (apiName === apinameForMachineBrand) {
            machineBrandRefetch()
        }
        if (apiName === apinameForCompanyName) {
            CompanyNameRefetch()
        }

        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueCategory && apiName === apinameForCategory) {
            setValueCategory(null)
            setValueCategoryCreatable(null)
        }
        if (OpenEditDropDownModel && valueMachineLocation && apiName === apinameForMachineLocation) {
            setValueMachineLocation(null)
            setValueMachineLocationCreatable(null)
        }
        if (OpenEditDropDownModel && valueMachineBrand && apiName === apinameForMachineBrand) {
            setValueMachineBrand(null)
            setValueMachineBrandCreatable(null)
        }
        if (OpenEditDropDownModel && valueCompanyName && apiName === apinameForCompanyName) {
            setValueCompanyName(null)
            setValueCompanyNameCreatable(null)
        }







        if (deletesucess && apiName === apinameForCategory) {
            setValueCategory(null)
            setValueCategoryCreatable(null)
        }
        if (deletesucess && apiName === apinameForMachineLocation) {
            setValueMachineLocation(null)
            setValueMachineLocationCreatable(null)
        }
        if (deletesucess && apiName === apinameForMachineBrand) {
            setValueMachineBrand(null)
            setValueMachineBrandCreatable(null)
        }
        if (deletesucess && apiName === apinameForCompanyName) {
            setValueCompanyName(null)
            setValueCompanyNameCreatable(null)
        }



    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };

    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Machine Code</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Masters</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listMachinename" className="text-decoration-none text-black">Machine Code</Link></li>
                                    <li className="breadcrumb-item active">Edit Machine Code</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Material Density</span></h5>
                            </div> */}
                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    {/* {show && showmessage === "success" && <Alert color={showmessage} message="Material Addedd Successfull" />} */}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Name & Type & Category & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            placeholder={'Select Machine Name & Type'}
                                                            {...register("hsn_code", { required: { value: false, message: "Machine Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.hsn_code?.message}</span>


                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Machine Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                    <CreatableSelect
                                                    isClearable={()=>{setValueCavity(null)
                                                        setValue(null)}}
                                                        {...register("machine_name", { required: { value: true, message: "Machine Name is required" } })}
                                                        placeholder={'Select Machine Name'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsMachine}
                                                        onCreateOption={handleCreate}
                                                        onChange={handleChangeMachineName}
                                                        // value={valueCavityCreatable==null?valueCavity:valueCavityCreatable}
                                                        value={valueCavityCreatable==null?valueCavity==null?optionsMachine?.filter((obj:any) => obj.value === selectedMachine):valueCavity:valueCavityCreatable}


                                                    />
                                                         <Select
                                                            {...register("machine_name", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Machine Name'}
                                                            onChange={handleChangeMachineName}
                                                            options={optionsMachine}
                                                            components={customIndicator}
                                                            value={selMachine === "" ? optionsMachine?.find((obj: any) => obj.value === selectedMachine) : selMachine}
                                                        /> 

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>

                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Department <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("machine_department", { required: { value: false, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChange}
                                                            components={customIndicator}
                                                            options={options}
                                                            value={selDepartment === "" ? options?.find((obj: any) => obj.value === selectedDepartment) : selDepartment}
                                                            // isOptionDisabled={(options: any) => {
                                                            //     if (options.status === "inactive" || options.deleted == true) {
                                                            //         return options
                                                            //     }
                                                            // }}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.machine_status?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">This Machine Product <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >



                                                        <CreatableSelect
                                                            isClearable={() => {
                                                                setValueCategory(null)
                                                                setValue(null)
                                                            }}
                                                            {...register("product_categories", { required: { value: true, message: "This Machine Product is required" } })}
                                                            placeholder={'Select This Machine Product'}
                                                            components={customCreatableIndicator}
                                                            options={optionsCategory}
                                                            onCreateOption={handleCreateCategory}
                                                            onChange={handleChangeCategory}
                                                            // value={valueCategoryCreatable==null?valueCategory:valueCategoryCreatable}
                                                            value={valueCategoryCreatable == null ? valueCategory == null ? optionsCategory?.filter((obj: any) => obj.value === selectedCategory) : valueCategory : valueCategoryCreatable}
                                                            onMenuOpen={() => CategoryRefetch()}

                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_categories?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Plant Machine Number</label>
                                                    <div className="input-group" >

                                                        <input type="text" placeholder="Plant Machine Number" className="form-control"
                                                            {...register("plant_machine_number", { required: { value: false, message: "Plant Machine Number is required" } })}
                                                        />

                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.plant_machine_number?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Model No.</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Machine Model No."} className="form-control"   {...register("machine_model_no", { required: { value: false, message: "Machine Model No. is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_model_no?.message}</span>

                                                </div>



                                               




                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Purchase Date</label>
                                                    <div className="input-group" >

                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={toDate == undefined ? dayjs(toDateValue, "DD/MM/YYYY") : toDate}
                                                            // disabledDate={disabledDate}
                                                            {...register("machine_purchase_date", { required: { value: true, message: "Field is required" } })}

                                                            onChange={handleToDate}
                                                        />
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_purchase_date?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine Manufacture Date</label>
                                                    <DatePicker
                                                        className="form-control"
                                                        format={dateFormatList}
                                                        value={fromDate == undefined ? dayjs(fromDateValue, "DD/MM/YYYY") : fromDate}
                                                        // value={toDate == undefined ? dayjs(toDateValue, "DD/MM/YYYY") : toDate}

                                                        // disabledDate={disabledDate}
                                                        {...register("machine_manufacturer_date", { required: { value: true, message: "Field is required" } })}

                                                        onChange={handleFromDate}
                                                    />
                                                    <span className='text-red w-100'>{errors?.machine_manufacturer_date?.message}</span>


                                                </div>


                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Location</label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMachineLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("machine_location", { required: { value: false, message: "Machine Location is required" } })}
                                                        placeholder={'Select Machine Location'}
                                                        components={customCreatableIndicator}
                                                        options={optionsMachineLocation}
                                                        onCreateOption={handleCreateMachineLocation}
                                                        onChange={handleChangeMachineLocation}
                                                        // value={valueMachineLocationCreatable==null?valueMachineLocation:valueMachineLocationCreatable}
                                                        value={valueMachineLocationCreatable == null ? valueMachineLocation == null ? optionsMachineLocation?.filter((obj: any) => obj.value === selectedMachineLocation) : valueMachineLocation : valueMachineLocationCreatable}
                                                        onMenuOpen={() => machineLocationRefetch()}
                                                    />
                                                    <span className='text-red'>{errors?.machine_location?.message}</span>

                                                </div>

















                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Length mm</label>
                                                    <div className="input-group" >

                                                        <input type={"number"} isPencil={true} placeholder={"Machine Length mm"} className="form-control"   {...register("machine_length_mm", { onChange: handleValue, required: { value: false, message: "Machine Length mm is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_length_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Width mm</label>
                                                    <div className="input-group" >

                                                        <input type={"number"} isPencil={true} placeholder={"Machine Width mm"} className="form-control"   {...register("machine_width_mm", { onChange: handleValue, required: { value: false, message: "Machine Width mm is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_width_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Height mm</label>
                                                    <div className="input-group" >

                                                        <input type={"number"} isPencil={true} placeholder={"Machine Height mm"} className="form-control"   {...register("machine_height_mm", { onChange: handleValue, required: { value: false, message: "Machine Height mm is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_height_mm?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Weight KG</label>
                                                    <div className="input-group" >

                                                        <input type={"number"} isPencil={true} placeholder={"Machine Weight KG"} className="form-control"   {...register("machine_weight", { onChange: handleValue, required: { value: false, message: "Machine Weight KG is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_weight?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Description</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Machine Description"} className="form-control"   {...register("machine_description", { onChange: handleValue, required: { value: false, message: "Machine Description is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_description?.message}</span>

                                                </div> */}

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Brand</label>
                                                    {/* <div className="input-group" > */}

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMachineBrand(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("machine_brand", { required: { value: false, message: "Machine Brand is required" } })}
                                                        placeholder={'Select Machine Brand'}
                                                        components={customCreatableIndicator}
                                                        options={optionsMachineBrand}
                                                        onCreateOption={handleCreateMachineBrand}
                                                        onChange={handleChangeMachineBrand}
                                                        // value={valueMachineBrandCreatable==null?valueMachineBrand:valueMachineBrandCreatable}
                                                        value={valueMachineBrandCreatable == null ? valueMachineBrand == null ? optionsMachineBrand?.filter((obj: any) => obj.value === selectedMachineBrand) : valueMachineBrand : valueMachineBrandCreatable}
                                                        onMenuOpen={() => machineBrandRefetch()}
                                                    />
                                                    {/* <input type={"text"} isPencil={true} placeholder={"Machine Brand"} className="form-control"   {...register("machine_brand", { onChange: handleValue, required: { value: false, message: "Machine Brand is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div> */}
                                                    <span className='text-red'>{errors?.machine_brand?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Company Name & City</label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCompanyName(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("purchase_company_name_and_city", { required: { value: true, message: "Machine Company Name & City is required" } })}
                                                        placeholder={'Select Machine Company Name & City'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsCompanyName}
                                                        onCreateOption={handleCreateCompanyName}
                                                        onChange={handleChangeCompanyName}
                                                        // value={valueCompanyNameCreatable==null?valueCompanyName:valueCompanyNameCreatable}
                                                        value={valueCompanyNameCreatable == null ? valueCompanyName == null ? optionsCompanyName?.filter((obj: any) => obj.value === selectedCompanyName) : valueCompanyName : valueCompanyNameCreatable}
                                                        onMenuOpen={() => CompanyNameRefetch()}

                                                    />
                                                    <span className='text-red'>{errors?.purchase_company_name_and_city?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Machine New-Old</label>
                                                    <select className="form-select" {...register("machine_old_new",
                                                        {
                                                            required: { value: false, message: "Machine New-Old is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"old"}>Machine Old</option>
                                                        <option value={"new"}>Machine New</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.machine_status?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Country Mobile Code</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} placeholder={"Country Mobile Code"} className="form-control"  {...register("country_code", { onChange: handleValue, required: { value: false, message: "Country Mobile Code is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.country_code?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Service Mobile Number</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true}
                                                            // onInput={maxLengthCheck}
                                                            placeholder={"Service Mobile Number"} className="form-control"   {...register("service_mobile_no", {
                                                                required: false,

                                                                pattern: {
                                                                    // value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                    value: /^(\d{1,5})(?:\s(\d{1,5}))?(?:\s(\d{1,2}))?$/gm,
                                                                    message: 'Please enter a valid number',
                                                                },
                                                            })} color={"green"}
                                                            onChange={handleNumbercode}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.service_mobile_no?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Guarantee Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("guarantee_yes_no",
                                                        {
                                                            onChange: onGuaranteeChange,
                                                            required: { value: true, message: "machine Status is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" disabled selected >Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.guarantee_yes_no?.message}</span>


                                                </div>
                                                {/* {Guarantee ==="yes"? */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Guarantee Month</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Guarantee Month"} className="form-control" disabled={Guarantee === "no" || ""} {...register("guarantee_months", { onChange: handleValue, required: { value: false, message: "Guarantee Month is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.guarantee_months?.message}</span>

                                                </div>
                                                {/* :''} */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Warranty Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("warranty_yes_no",
                                                        {
                                                            onChange: onWarrantyChange,
                                                            required: { value: true, message: "Warranty is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" disabled selected >Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.warranty_yes_no?.message}</span>


                                                </div>
                                                {/* {Warranty === "yes" ? */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Warranty Month</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Warranty Month"} className="form-control" disabled={Warranty === "no" || ""}  {...register("warranty_time", { onChange: handleValue, required: { value: false, message: "Warranty Month is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.warranty_time?.message}</span>

                                                </div>
                                                {/* : ""} */}
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Remark</label>
                                                    <div className="input-group" >

                                                        <input type={"text"} isPencil={true} placeholder={"Remark"} className="form-control"   {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} color={"green"} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red'>{errors?.remark?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status",
                                                        {
                                                            required: { value: false, message: " Status is required" }, maxLength: 30,

                                                        }
                                                    )}>
                                                        <option value="" selected >Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>


                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Machine Code</label>
                                                    <div className="input-group" >

                                                        <input type="text" disabled placeholder="Machine code" className="form-control"
                                                            {...register("machine_code", { required: { value: true, message: "Machine Code is required" } })}
                                                        />

                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red'>{errors?.machine_code?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Machine Image All</label>


                                                    {uploadFile.length > 0 ?

                                                        <>
                                                            <Upload
                                                                {...register("image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={FileOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadFile]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"image"}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </> :
                                                        <>
                                                            <Upload
                                                                {...register("image")}
                                                                accept="image/png, image/jpeg"
                                                                listType="picture"
                                                                onChange={FileOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}

                                                            >
                                                                <img src={cameraLogo} alt='' className="uploaded-image" />
                                                            </Upload>
                                                        </>
                                                    }





                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Machine PDF  Drawing All</label>
                                                    <label style={{ cursor: "pointer" }}>
                                                    </label>
                                                    {uploadFileDoc.length > 0 ?
                                                        <>
                                                            <Upload
                                                                {...register("pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocOnChange}
                                                                multiple={true}
                                                                defaultFileList={[...uploadFileDoc]}
                                                                onPreview={(e: any) => { return "" }}
                                                                key={"pdf"}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </> : <>
                                                            <Upload
                                                                {...register("pdf")}
                                                                accept=".doc,.docx,.xml,.pdf"
                                                                listType="text"
                                                                onChange={DocOnChange}
                                                                multiple={true}
                                                                onPreview={(e: any) => { return "" }}
                                                            >
                                                                <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                {/* <p>{logoPdf == null ? data?.pdf?.split("/").pop() : logoPdf}</p> */}

                                                            </Upload>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={!isDirty}
                                                    >Submit</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listMachinename")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Department_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />

            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    )

}

export default Edit_Machine