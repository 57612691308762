import { Button, Tooltip } from "antd";
import "./styles.css";
import { AiFillDelete } from "react-icons/ai";

const Task = ({ taskS: task, btnLoading, btnId, handleDeleteTask }: any) => {

  return (
    <div className="task new-column-card" draggable="true">
      <div className="task__tags ">
        <span className="task__tag task__tag--illustration">
          {task?.priority}
        </span>
        <button className="task__options">
          <Tooltip title="Delete Task">
            <Button type="link" danger className='text-md text-red-500 ml-2 px-2 mb-1 text-indigo-500 rounded hover:text-indigo-300' onClick={() => handleDeleteTask(task?.value)}>
              <AiFillDelete />
            </Button>
          </Tooltip>
        </button>
      </div>
      <p className="mt-2 text-color font-semibold">{task?.label}</p>
      <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{task?.description}</p>} placement="top" arrow >
      <div className="m-2 text-color" style={{ fontSize: "13px" }}>
        {task?.description.length >=40  ? task?.description?.slice(0, 40)?.concat('...') : task?.description}
      </div>
      </Tooltip>
      <div className="task__stats">
        <span>
          <time>
            <i className="fas fa-flag"></i>
            {" "} {(task?.startDate)} /{" "}
            {(task?.endDate)}
          </time>
        </span>
        
        <span>Time : {task?.completion_time} Hours</span>
      </div>
    </div>
  );
};

export default Task;
