
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import Actions from "../../../components/Cell/Actions";
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import noImage from "../../../images/noImage.png"
import { Columns_Address_Details } from "../Address_Details/Columns_Address_Details";
import Edit_Customer_Details from "./Edit_Customer_Detail";
// import Columns_Address_Details from "../Address_Details/Columns_Address_Details"


interface Data {
  selection: any
  code: any
  name: any
  city_and_village: any
  zone: any
  owner_name: any
  owner_surname: any
  contact_no: any
  email: any
  address: any
  pincode: any
  gst_type_registration: any
  customer_gst_type: any
  gst_no: any
  gst_category: any
  tds_details: any
  tds_details_number: any
  pan_number: any
  customer_type: any
  account_type: any
  customer_organization_type: any
  company_date_of_birth: any
  customer_payment_method: any
  customer_credit_day_limit: any
  customer_credit_amount_limit: any
  late_payment_interest: any
  customer_running_not: any
  targeted_monthly_box: any
  targeted_monthly_amount: any
  opening_balance: any
  reference: any
  customer_group: any
  // koru_1:any
  // koru_2:any
  // koru_3:any
  // koru_4:any
  customer_title: any
  customer_visiting_card_image: any


  customer_details_edit: any
  created_at: any
  created_by: any
  updated_by: any
  updated_at: any

  status: any;
  // actions: any;
}


export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },


  {
    id: `code`,
    Header: `Code`,
  },

  {
    id: `name`,
    Header: `Name`,
  },

  {
    id: `city_and_village`,
    Header: `City Code & Name`,
  },


  {
    id: `zone`,
    Header: `Zone`,
  },


  {
    id: `owner_name`,
    Header: `Owner Name`,
  },


  {
    id: `owner_surname`,
    Header: `Owner Surname`,
  },


  {
    id: `contact_no`,
    Header: `Contact Number`,
  },
  {
    id: `email`,
    Header: `Email ID`,
  },
  {
    id: `address`,
    Header: `Address`,
  },

  {
    id: `pincode`,
    Header: `Pincode`,
  },

  {
    id: `gst_type_registration`,
    Header: `GST Type Registration`,
  },

  {
    id: `customer_gst_type`,
    Header: `Customer GST Type`,
  },


  {
    id: `gst_no`,
    Header: `GST No.`,
  },

  {
    id: `gst_category`,
    Header: `GST Category`,
  },

  {
    id: `tds_details`,
    Header: `TDS Details`,
  },

  {
    id: `tds_details_number`,
    Header: `TDS Details Number`,
  },


  {
    id: `pan_number`,
    Header: `Pan Number`,
  },
  {
    id: `customer_type`,
    Header: `Customer Type`,
  },
  {
    id: `account_type`,
    Header: `Account Type`,
  },





  {
    id: `customer_organization_type`,
    Header: `Organization Type`,
  },
  {
    id: `company_date_of_birth`,
    Header: `Starting Date`,
  },
  {
    id: `customer_payment_method`,
    Header: `Payment Method`,
  },






  {
    id: `customer_credit_day_limit`,
    Header: `Credit Day Limit`,
  },
  {
    id: `customer_credit_amount_limit`,
    Header: `Credit Amount Limit`,
  },
  {
    id: `late_payment_interest`,
    Header: `Late Payment Interest`,
  },





  {
    id: `customer_running_not`,
    Header: `Customer Runnig-Not`,
  },
  {
    id: `targeted_monthly_box`,
    Header: `Targetted Monthly Box`,
  },
  {
    id: `targeted_monthly_amount`,
    Header: `Monthly Amount`,
  },




  {
    id: `opening_balance`,
    Header: `Opening Balance`,
  },
  {
    id: `reference`,
    Header: `Reference By`,
  },
  {
    id: `customer_group`,
    Header: `Customer Group`,
  },



  // {
  //   id: `koru_1`,
  //   Header: `Blank-1`,
  // },
  // {
  //   id: `koru_2`,
  //   Header: `Blank-2`,
  // },
  // {
  //   id: `koru_3`,
  //   Header: `Blank-3`,
  // },
  // {
  //   id: `koru_4`,
  //   Header: `Blank-4`,
  // },
  {
    id: `customer_title`,
    Header: `Customer Title`,
  },
  {
    id: `customer_visiting_card_image`,
    Header: `Visiting Card Image`,
  },









  {
    id: 'status',
    Header: 'Status',
  },
  {
    id: 'created_at',
    Header: `Created At`,
  },


  {
    id: 'created_by',
    Header: `Created By`,


  },
  {
    id: 'updated_at',
    Header: `Updated At`,

  },
  {
    id: 'updated_by',
    Header: `Updated By`,

  },
]


// export const Columns_Customer_Detail_Employee: any = [




//   {
//     id: `srno`,
//     Header: () => <div style={{display:"inline"}}>Sr.<br/>No</div>,
//     sticky:"left",
//   },




//   {
//     id: `code`,
//     Header: `Code`,
//     accessor: `code`,
//     sticky:"left",

//   },

//   {
//     id: `person_type`,
//     Header: () => <div style={{display:"inline"}}>Person<br/>Type</div>,
//     accessor: `person_type`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.person_type?.name}
//               </div>
//             )
//       }
//   },

//   {
//     id: `group`,
//     Header: () => <div style={{display:"inline"}}>Account<br/>Group</div>,
//     accessor: `group`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.extra_response?.group?.category_name?row.original.extra_response?.group?.category_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
//               </div>
//             )
//       }
//   },
//   {
//     id: `account_type`,
//     Header: () => <div style={{display:"inline"}}>Account<br/>Type</div>,
//     accessor: `account_type`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.extra_response?.group?.accountStatementType_name?row.original.extra_response?.group?.accountStatementType_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
//               </div>
//             )
//       }
//   },

//   {
//     id: `customer_name`,
//     Header: () => <div style={{display:"inline"}}>Customer<br/>Name</div>,
//     accessor: `customer_name`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.customer_name?row.original.customer_name:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
//               </div>
//             )
//       }
//   },

//   {
//     id: `name`,
//     Header: () => <div style={{display:"inline"}}>First<br/>Name</div>,
//     accessor: `name`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.name}
//               </div>
//             )
//       }
//   },
//   {
//     id: `father_name`,
//     Header: () => <div style={{display:"inline"}}>Middle<br/>Name</div>,
//     accessor: `father_name`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.father_name}
//               </div>
//             )
//       }
//   },
//   {
//     id: `surname`,
//     Header: () => <div style={{display:"inline"}}>Last<br/>Name</div>,
//     accessor: `surname`,
//     Cell: ({ row }: any) => {
//             return(
//               <div>
//                 {row.original.surname}
//               </div>
//             )
//       }
//   },








//   {
//     id: 'status',
//     Header: 'Status',
//     accessor:'status',


//   },

//   {
//     id: 'created_at',
//     Header: () => <div style={{display:"inline"}}>Created<br/>At</div>,
//     accessor:'created_at',
//     Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
//   },


//   {
//     id: 'created_by',
//     Header: () => <div style={{display:"inline"}}>Created<br/>By</div>,
//     accessor:'created_by',
//     Cell: ({ row }: any) => row.original.created_by?.username


//   },
//   {
//     id: 'updated_at',
//     Header: () => <div style={{display:"inline"}}>Updated<br/>At</div>,
//     accessor:'updated_at',
//     Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
//   },
//   {
//     id: 'updated_by',
//     Header: () => <div style={{display:"inline"}}>Updated<br/>By</div>,
//     accessor:'updated_by',
//     Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
//   },



//   {
//     id: 'Actions',
//     Header: 'Actions',


//   },
//   // {
//   //   id: 'customer_details_edit',
//   //   Header: () => <div style={{display:"inline"}}>Direct<br/>Edit</div>,


//   // },

//   {
//     id: 'Selection',
//     Header: ({ getToggleAllRowsSelectedProps }: any) => {
//       const [data, setData] = useState([])

//       const columns: any = useMemo(() => Columns_Customer_Detail_Employee, [])
//       const defaultColumn: any = useMemo(() => {
//         return {
//           Filter: Columnlevel
//         }
//       }
//         , [])

//       const tableInstance: any = useTable({
//         columns,
//         data: data,
//         defaultColumn,

//       },


//       )
//       const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
//       let { selectedFlatRows} = tableInstance
//       const { } = useMutation((deleteid: any) => {
//         const temp_data = {
//           id: deleteMultipleid
//         }
//         return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CUSTOMER_DETAILS_API}/deletelist/`, temp_data,
//           {
//             headers: {
//               'Authorization': `Bearer ${localStorage.getItem('token')}`
//             }
//           });
//       });
//       return (
//         <>
//           <div>
//             <Checkbox

//               onClick={() => {
//                 setDeleteMultipleid(() => {
//                   const temp = selectedFlatRows?.map((e: any) => {
//                     return e.original.id;
//                   });
//                   return temp;
//                 });
//               }}

//               {...getToggleAllRowsSelectedProps()} />
//           </div>
//         </>
//       );
//     },

//     Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
//       // Add your onClick function here
//     }} />
//   },
// ]


export const Columns_Customer_Detail_Employee: any = [



  {
    id: `srno`,
    Header: () => <div>Sr<br />No</div>,
    sticky: "left",

  },

  {
    id: `person_type`,
    Header: () => <div style={{ display: "inline" }}>Person<br />Type</div>,
    sticky: "left",
    accessor: `person_type`,
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.person_type?.name}
        </div>
      )
    }
  },
  {
    id: `code`,
    Header: () => <div style={{ display: "inline" }}>Employee<br />Code</div>,
    accessor: 'code',

  },

  {
    id: 'name',
    Header: () => <div style={{ display: "inline" }}>Employee First<br />Name</div>,
    accessor: 'name',


  },

  {
    id: 'father_name',
    Header: () => <div style={{ display: "inline" }}>Employee Middle<br />Name</div>,
    accessor: 'father_name',


  },
  {
    id: 'surname',
    Header: () => <div style={{ display: "inline" }}>Employee Last<br />Name</div>,
    accessor: 'surname',


  },

  {
    id: `salary_type`,
    Header: () => <div>Salary<br />Type</div>,
    accessor: 'salary_type',
    // Cell: ({row}:any) => {
    //   const convertToUpper = row.original.salary_type?.name
    //   if (!convertToUpper) return "";
    //   // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
    //   return convertToUpper.split("_").map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")

    // }

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.salary_type?.name}
        </div>
      )
    }

  },

  {
    id: 'city_name_and_distict_name',
    Header: () => <div style={{ display: "inline" }}>City Name &<br />District Name</div>,
    accessor: 'city_name_and_distict_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {`${row.original.extra_response?.city_name_and_distict_name?.city_name ? row.original.extra_response?.city_name_and_distict_name?.city_name : row.original.extra_response?.city_name_and_distict_name?.village_name}::${row.original.extra_response?.city_name_and_distict_name?.district_name}`}
        </div>
      )
    }


  },

  {
    id: 'department',
    // Header: 'Department',
    Header: () => <div style={{ display: "inline" }}>Department</div>,
    accessor: 'department',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.department?.department_name}
        </div>
      )
    }


  },


  {
    id: 'designation',
    Header: () => <div style={{ display: "inline" }}>Designation</div>,
    // Header: 'Designation',
    accessor: 'designation',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.designation?.designation_name}
        </div>
      )
    }


  },
  {
    id: 'customer_grade',
    Header: () => <div style={{ display: "inline" }}>Grade</div>,
    // Header: 'Grade',
    accessor: 'customer_grade',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.customer_grade?row.original.customer_grade:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }


  },


  {
    id: 'administration_type',
    Header: () => <div style={{ display: "inline" }}>Administration<br />Type</div>,
    accessor: 'administration_type',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.administration_type?.name}
        </div>
      )
    }

  },

  {
    id: 'weekly_off',
    Header: () => <div style={{ display: "inline" }}>Weekly<br />Off</div>,
    accessor: 'weekly_off',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.weekly_off?.name}
        </div>
      )
    }

  },

  {
    id: 'gender',
    Header: () => <div style={{ display: "inline" }}>Gender</div>,
    // Header: 'Gender',
    accessor: 'gender',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.gender}
        </div>
      )
    }
  },

  
  
  {
    id: 'title',
    Header: () => <div style={{ display: "inline" }}>Title</div>,
    // Header: 'Title',
    accessor: 'title',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.title?.name}
        </div>
      )
    }
  },


  {
    id: 'qualification',
    Header: () => <div style={{ display: "inline" }}>Qualification</div>,
    // Header: 'Qualification',
    accessor: 'qualification',
  },


  {
    id: 'reference',
    Header: () => <div style={{ display: "inline" }}>Reference<br />By</div>,

    // Header: 'Reference By',
    accessor: 'reference',
    Cell: ({ row }: any) => {
      return (
        `${row.original.reference === null || row.original.reference === "" ? "Other" : `${row.original.extra_response?.reference?.code} ${row.original.extra_response?.reference?.name} ${row.original.extra_response?.reference?.father_name} ${row.original.extra_response?.reference?.surname}`}`
      )
    }

  },

  {
    id: 'other_reference',
    Header: () => <div style={{ display: "inline" }}>Other Reference<br />By</div>,

    accessor: 'other_reference',
    Cell: ({ row }: any) => row.original.other_reference ? row.original.other_reference : <span style={{ color: 'red', fontSize: "20px" }}>****</span>,

  },
  
  {
    id: `type`,
    Header: () => <div style={{ display: "inline" }}>Account<br />Type</div>,
    // Header: `Type`,
    accessor: 'type',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.group?.accountStatementType_name}
        </div>
      )
    }
  },

  {
    id: `group`,
    Header: () => <div style={{ display: "inline" }}>Account<br />Category</div>,
    // Header: `Type`,
    accessor: 'group',

    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.group?.category_name}
        </div>
      )
    }
  },

  {
    id: 're_mark',
    Header: 'Remark',
    accessor: 're_mark',
    Cell: ({ row }: any) => row.original.re_mark ? row.original.re_mark : <span style={{ color: 'red', fontSize: "20px" }}>****</span>,

  },


  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
  },

  
  


  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split(" ").join("  ") : "-")
  },
  {
    id: 'created_by',
    Header: () => <div>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_by?.username : "-")
  },
  {
    id: 'updated_at',
    Header: () => <div>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split(" ").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },
  {
    id: `image_all`,
    Header: () => <div style={{ display: "inline" }}>Image<br />All</div>,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)
      const [currentIndex, setCurrentIndex] = useState(0);
      const [fieldImages, setFieldImages] = useState([])

      const handleShowImages = (field: any) => {
        console.log(field, "field")
        setDeleteConfirm(true)
        setFieldImages(field.image_all)
      }
      const goToPrev = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? fieldImages?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };

      const goToNext = () => {
        const isLastSlide = currentIndex === fieldImages?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };
      return (
        <>
          <div >

            {!row.original.image_all ? <>
              <a

                href="#"

              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => handleShowImages(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.image_all.length > 0 ? row.original.image_all.at(-1).doc : noImage)} data-toggle="modal">
                <img src={row.original.image_all[0]?.doc ? row.original.image_all[0]?.doc : noImage} alt='no image' width={50} height={50} />
              </a>
            </>}

          </div>

          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">

                </div>



                <div className="carousel-inner">
                  {row.original.image_all?.map((imgSrc: any, index: any) => (
                    <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                      <img src={imgSrc?.doc} className="d-block w-100" alt={`Slide ${index}`} />
                    </div>
                  ))}
                </div>

                <button className="carousel-control-prev" type="button" onClick={goToPrev} >
                  {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(-1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> 
                  <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={goToNext} >
                  {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                  <svg width="151px" height="151px" viewBox="-6 -6 36.00 36.00" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(4.92,4.92), scale(0.59)"><rect x="-6" y="-6" width="36.00" height="36.00" rx="18" fill="#ffffff" strokeWidth="0">
                  </rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M10 7L15 12L10 17" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
              <p>{currentIndex + 1}/{fieldImages.length}</p>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }
  },
 



  {
    id: `document_pdf`,
    Header: () => <div style={{ display: "inline" }}>Machine PDF<br /> Drawing All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) => {
      const [deleteConfirm, setDeleteConfirm] = useState(false)

      return (
        <>
          <div>
            {!row.original.document_pdf || row.original.document_pdf.length === 0 ? <>
              <a

                href="#"
                className="text-danger"
              >
                No Document Uploaded
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(row.original)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={(row.original.document_pdf.length > 0 ? row.original.document_pdf.at(-1).doc : null)} data-toggle="modal">
                <p>{(row.original.document_pdf?.length > 0 ? row.original.document_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}</p>

              </a>
            </>}

          </div>
          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >

            <Modal.Body>
              {row.original.document_pdf?.map((document: any) => (
                <li style={{ color: 'black' }}>
                  <a style={{ color: 'black', textDecoration: 'none' }} target="_blank" href={document?.doc}>
                    {document?.doc?.split('/').pop()}
                  </a>
                </li>))}
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>
      )
    }

  },
  // {
  //   id: `image_all`,
  //   Header: () => <div style={{ display: "inline" }}>Image<br />All</div>,
  //   Cell: ({ row }: any) => {
  //     const [deleteConfirm, setDeleteConfirm] = useState(false)
  //     return (
  //       <>
  //         <div >

  //           {!row.original.image_all ? <>
  //             <a

  //               href="#"

  //             >
  //               <img src={noImage} height={60} width={100} />
  //             </a>
  //           </> : <>
  //             <a
  //               onClick={() => setDeleteConfirm(true)}
  //               href={`#galleryModal${row.original.name}`}

  //               data-large-src={(row.original.image_all.length > 0 ? row.original.image_all.at(-1).doc : noImage)} data-toggle="modal">
  //               <img src={(row.original.image_all.length > 0 ? row.original.image_all.at(-1).doc : noImage)} height={60} width={100} />
  //             </a>
  //           </>}

  //         </div>
  //         <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
  //           centered    >

  //           <Modal.Body>
  //             <img src={(row.original.image_all?.length > 0 ? row.original.image_all.at(-1).doc : noImage)} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

  //           </Modal.Body>
  //           <Modal.Footer>
  //             <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

  //           </Modal.Footer>
  //         </Modal>
  //       </>)
  //   }
  // },






  // {
  //   id: `document_pdf`,
  //   Header: () => <div style={{ display: "inline" }}>Document<br />PDF All</div>,

  //   // accessor: `pdf`,
  //   Cell: ({ row }: any) =>



  //     <div>
  //       <a target="_blank" href={(row.original.document_pdf?.length > 0 ? row.original.document_pdf?.at(-1).doc : null)}>
  //         {(row.original.document_pdf?.length > 0 ? row.original.document_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
  //       </a>
  //     </div>

  // },
  {
    id: `agreement_pdf`,
    Header: () => <div style={{ display: "inline" }}>Agreement<br />PDF All</div>,

    // accessor: `pdf`,
    Cell: ({ row }: any) =>



      <div>
        <a target="_blank" href={(row.original.agreement_pdf?.length > 0 ? row.original.agreement_pdf?.at(-1).doc : null)}>
          {(row.original.agreement_pdf?.length > 0 ? row.original.agreement_pdf?.at(-1).doc?.split('/').pop() : <span className="text-danger">No Document Uploaded</span>)}
        </a>
      </div>

  },


  // {
  //   id: 'employee',
  //   Header: 'Employee',
  //   accessor:'employee',


  // },











  {
    id: 'Actions',
    Header: 'Actions',


  },


  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Customer_Detail_Employee, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMPLOYEE_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]


