import { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUpdateSingle, useUpdateSingleforcewithyes, useUpdateSingleforcewithno } from "../../../hooks/Api";
import Select from "react-select"
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import { Columns_Contact_Format, titleofheaders } from "./Columns_Contact_Format";
import Add_Contact_Format_Modal from "./Add_Contact_Format_Modal";
import Edit_Contact_Format_Modal from "./Edit_Contact_Format_modal";

const Edit_Contact_Format = ({ currentStep, setCurrentStep }: any) => {
    let pageName = "Contact Detail"
    const [id, setId] = useState()


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;
    const tempData = addData?.contact_detail


    const [isNew, setIsNew] = useState(false)

    const [data, setData]: any = useState(tempData);

    const [isEdited, setIsEdited]: any = useState(false)

    const [render, setRender] = useState(0)

    useEffect(() => {
        if (isNew || isEdited) {
            const rawData = localStorage.getItem('Add Data');
            const addData = rawData ? JSON.parse(rawData) : null;
            setData(addData?.contact_detail);

            setIsNew(false);
            setIsEdited(false)
        }
    }, [isNew, isEdited]);


    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    const [showOffcancasEdit, setShowOffcancasEdit] = useState(false);

    const handleCloseEdit = () => setShowOffcancasEdit(false);
    const handleShowEdit = (id: any) => {
        setId(id)
        setShowOffcancasEdit(true)
    };
    const navigate = useNavigate()

    const apiname = process.env.REACT_APP_CONTACT_DETAIL_API?.toString()
    const { mutate, isSuccess, isError, error, data: ContactUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, control }: any = useForm();
    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)





    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");

            setBasicDetailsInitiated(true);
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, ContactUpdated_response, error,
        isSuccess]);

    const [updateCompleted, setUpdateCompleted] = useState(false)

    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: basicIdTemp,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }




    const [ids, setIds] = useState([])

    const [ForEditIds, setForEditIds]: any = useState([])

    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    const prevDataRef = useRef();

    const [localIds, setLocalIds] = useState([])

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && ids?.length > 0 && isDataChanged) {
            reset(data);
            setMultipleIds(data)
            setForEditIds(data)
            prevDataRef.current = data;
        }
    }, [data, reset, ids]);




    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);
    const [addShowOffcancas, setAddShowOffcancas] = useState(false)

    const handleNext = () => {
        let tempId = {
            contact_detail: multipleIds
        }

        let tempData = {
            id: basicIdTemp,
            apiname: apinameForBasicDetails,
            data: tempId
        }
        BasicDetails(tempData);
        const currentPath = window.location.pathname;
        if (currentPath === '/addCustomerDetails') {
            setCurrentStep(currentStep + 1)
        };
    }

    const [getIdForEdit, setGetIdForEdit]: any = useState()

    const filter = {

        name: ""

    };

    const addButtonName = "Contact Detail";
    const addButtonPageName = "contactDetail"
    const permissionClass = {
        front: "LeoUser",
        back: "contactdetail"
    }
    const stickyStart = 1;
    const stickyEnd = 2;
    const [activePage, setActivePage] = useState('')


    useEffect(() => {
        setTimeout(() => {
            setActivePage('Edit Contact Detail')
        }, 500)

    }, [activePage]);

    const currentPath = window.location.pathname;

    useEffect(() => {
        if (data?.length > 0) {
            const getCall = async () => {
                try {
                    const response = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}${apiname}/fetch_multiple_ids/`, {
                        ids: data
                    });
                    console.log(response, "ResponseData:");
                    setIds(response?.data?.data)

                    reset(response?.data?.data)

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            getCall()
        }
    }, [])


    return (
        <div>
            <div >
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name ? addData?.name : addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            <div className="row d-flex  ">
                                <div className="row d-flex ">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                        <button
                                            type="button" className="border-1 rounded text-white px-4 py-1"
                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                            onClick={() => setAddShowOffcancas(true)}

                                        >Add Contact Detail</button>

                                    </div>
                                </div>
                            </div>
                            {/* <form
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >
                                    <div className="row">
                                        <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name ? addData?.name : addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                                    </div>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country </label>
                                                    <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_name} placeholder="Country"
                                                    // {...register("country", { required: { value: false, message: "Country is required" } })} 
                                                    />
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Email Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("email_type", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="Home">Home</option>
                                                        <option value="Shop">Shop</option>
                                                        <option value="Office">Office</option>
                                                        <option value="Personal">Personal</option>
                                                        <option value="Company Email Id">Company Email Id</option>
                                                        <option value="Personal Email Id">Personal Email Id</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.email_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("number_type", { required: { value: true, message: "Field is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="Mobile-1">Mobile-1</option>
                                                        <option value="Mobile-2">Mobile-2</option>
                                                        <option value="Home Mobile">Home Mobile</option>
                                                        <option value="Shop">Shop</option>
                                                        <option value="Office">Office</option>
                                                        <option value="Company Mobile No">Company Mobile No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.number_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Country Code </label>

                                                    <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_code} placeholder="City Name" {...register("country_code", { required: { value: false, message: "Field is required" } })} />


                                                    <span className='text-red w-100'>{errors?.country_code?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand"
                                                            placeholder={documentType ? documentType : "Mobile Number"}

                                                            // onInput={documentType ==="Mobile Number" && maxLengthCheck}
                                                            {...register('number', {
                                                                // onChange:maxLengthCheck,
                                                                required: 'Mobile Number is required',
                                                                pattern: {
                                                                    value: regexForDocumentNumber,
                                                                    message: 'Please enter a valid Mobile Number',
                                                                },
                                                            })}
                                                            onChange={handleNumbercode}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.number?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Contact Name & Surname <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Contact Name & Surname"
                                                            {...register('contact_name_and_surname', { required: true, message: 'Contact Name & Surname is required' })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.contact_name_and_surname?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Email Address <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="email" className="form-control" id="exampleInputBrand" placeholder="Email Address"
                                                            {...register('email_address', {
                                                                required: 'Email is required',
                                                                pattern: {
                                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                    message: 'Please enter a valid email',
                                                                },
                                                            })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_address?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Website </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Website"
                                                            {...register('social_media_account', { required: false, message: 'Website is required' })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.social_media_account?.message}</span>
                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Re-Mark </label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Re-Mark"
                                                            {...register('re_mark', { required: false, message: 'Re-Mark is required' })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>




                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                        onClick={handleSubmit(onSubmit)}
                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                    {currentPath === '/addCustomerDetails' ?
                                                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form> */}


                            <Add_Contact_Format_Modal addShowOffcancas={addShowOffcancas} setAddShowOffcancas={setAddShowOffcancas} setAdded={setAdded} setForEditIds={setForEditIds} />

                            <Edit_Contact_Format_Modal showOffcancasEdit={showOffcancasEdit} handleCloseEdit={handleCloseEdit} setShowOffcancasEdit={setShowOffcancasEdit} id={id} setIsEdited={setIsEdited} />

                            <Stepper_Table
                                key={render}
                                apiname={apiname}
                                filterFields={filter}
                                addButtonName={addButtonName}
                                stickyStart={stickyStart}
                                stickyEnd={stickyEnd}
                                TableColumns={Columns_Contact_Format}
                                addButtonPageName={addButtonPageName}
                                titleofheaders={titleofheaders}
                                permissionClass={permissionClass}
                                isMore={false}
                                isAddButton={false}
                                multipleIds={ForEditIds}
                                added={added}
                                setAdded={setAdded}
                                setShowOffcancas={setAddShowOffcancas}
                                localIds={localIds}
                                setGetIdForEdit={setGetIdForEdit}
                                setIsNew={setIsNew}
                                handleCloseEdit={handleCloseEdit}
                                handleShowEdit={handleShowEdit}
                                activePage={activePage}
                            />
                            <div className="row d-flex  ">
                                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className="row d-flex ">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                            <button
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                onClick={handleNext}
                                            >Next</button>
                                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                            {currentPath === '/addCustomerDetails' ?
                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />


            {show && (
                <Mui_Toaster
                    message={`Contact Details Updated Successfully`}
                    severity={"success"}
                    Isopen={true}
                />
            )}
        </div>
    )
}

export default Edit_Contact_Format


