import { useEffect, useRef, useState } from "react";
import { RiPencilLine } from "react-icons/ri";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./addColor.css";
import "react-color-palette/lib/css/styles.css";
import { Alert } from "../../components/Model/Model";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { useCreate } from "../../hooks/Api";
import useFocusOnEnter from "../../components/focus";

import Restricted from "../../Roles/Restricted";

const ntc = require("@yatiac/name-that-color");
const toHex = require("colornames");

const Add_Color = () => {
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);

  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_COLOR_API?.toString();

  const {
    mutate: colorPost,
    data,
    isError,
    isLoading,
    isSuccess,
    error,
  }: any = useCreate(apiname);

  const { register, handleSubmit, setError, formState, reset, setValue }: any =
    useForm({
      mode: "onChange",
    });

  const { errors, isDirty, dirtyFields, touchedFields } = formState;


  const onSubmit = (data: any) => {
    colorPost(data);
  };

  const [color, setColor]: any = useState({ hex: "#ffffff" });
  const [colorname, setColorname]: any = useState("");
  const [colorName, setColorName] = useState("");
  const [cn, setCn]:any = useState("");
  const [displayColorPicker, setdisplayColorPicker] = useState(false);

  useEffect(() => {
    setColorName(ntc(color.hex).colorName);
  }, [color]);

  const style = {
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  const onColorPickerInfoChange = (color: any) => {
    if (color.target.value.includes("#")) {
      // setColorname(ntc(color.target.value).colorName);
      setValue("name", ntc(color.target.value).colorName);
      setValue("hex", color.target.value);
      setCn(ntc(color.target.value).closestColor);
    }

    if (color.target.placeholder.includes("Name")) {
      // setColorname(color.target.value);
      if (toHex(color.target.value)) {
        // setColorname(toHex(color.target.value))
        setValue("hex", toHex(color.target.value));
        setCn(toHex(color.target.value));
      } else {
        color.target.value = color.target.value
          .toLowerCase()
          .split(" ")
          .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
        setColorname("");
      }
    }
    if (color.target.placeholder.includes("Code")) {
      setCn(ntc(color.target.value).closestColor);
      setValue("hex", color.target.value);
    }

    // setError(!errors)
  };

  const handleReset = () => {
    reset();
    setCn(null)
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);

  if (isError) {
  }

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listColor", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);


  const handleUpper=(e:any)=>{
    e.target.value = e.target.value.toLowerCase()
.split(' ')
.map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
.join(' ');
}




  return (
    <div>
      <Restricted
        to={`${apiname?.split("/").at(-1)}.add_${apiname?.split("/").at(-1)}`}
        fallback={`You are not authorized to add ${apiname
          ?.split("/")
          .at(-1)}`}
      >
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>Add Color</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/listColor"
                      className="text-decoration-none text-black"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      Product Details
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/listColor"
                      className="text-decoration-none text-black"
                    >
                      Color
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Add Color</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              {/* <div className="card-header">
                                <h5 className="card-title"><img src={searchLogo} className="mx-1" alt='' /><span className="mx-2"><b>Search</b></span></h5>
                            </div> */}
              <div
                className="card-body"
                style={{ maxHeight: "80vh", overflow: "auto" }}
              >
                {show && showmessage === "danger" ? (
                  <Alert
                    color={showmessage}
                    message={error?.response?.data?.message}
                  />
                ) : (
                  ""
                )}
                {/* {show && showmessage === "danger" && error?.response?.data?.message?.name == "color with this Color Name already exists." ? <Alert color={showmessage} message="Color Already Exist" /> : ""} */}
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <form
                      ref={focus}
                      onKeyUp={(event) => onEnterKey(event)}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="row d-flex justify-content-lg-start justify-content-sm-around justify-content-md-center">
                      <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                          Company Color Name <span className="text-red">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Company Color Name"
                              {...register("company_color_name", {
                                onChange:handleUpper, required: {
                                  value: true,
                                  message: "Company Color Name is required",
                                },
                                
                              })}
                              
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine
                                  color="white"
                                  // style={{backgroundColor:"blue",color:"green"}}
                                />
                              </span>
                            </div>
                          </div>

                          <span className="text-red">
                            {errors?.company_color_name?.message}
                          </span>
                        </div>
                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Color Name <span className="text-red">*</span>
                          </label>
                          <div className="input-group" data-colorpicker-id={2}>
                            {/* <input type="text" className="form-control border-end-0" onClick={handleOpen} placeholder="Add Color Name"
                                                            {...register("colorname", { required: true, maxLength: 30 })}
                                                        /> */}
                            <input
                              type="text"
                              className="form-control border-end-0"
                              placeholder="Color Name"
                              // disabled
                              {...register("name", {
                                required: {
                                  value: true,
                                  message: "Color Name is required",
                                },
                                maxLength: 30,
                              })}
                              onChange={onColorPickerInfoChange}
                            />

                            <div className="input-group-append">
                              <input
                                type="color"
                                id="color1"
                                value={cn}
                                className="input-group-text bg-white border-start-0"
                                onChange={onColorPickerInfoChange}
                              ></input>
                            </div>

                            <span className="text-red w-100">
                              {errors?.name?.message}
                            </span>
                          </div>
                        </div>


                        



                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Color Code<span className="text-red">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              placeholder="Color Code"
                              // disabled
                              {...register("hex", {
                                required: {
                                  value: true,
                                  message: "Color Code is required",
                                },
                                pattern: {
                                  value: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
                                  message: "Color Code is not correct",
                                },
                              })}
                              onChange={onColorPickerInfoChange}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine
                                  color="white"
                                  // style={{backgroundColor:"blue",color:"green"}}
                                />
                              </span>
                            </div>
                          </div>

                          <span className="text-red">
                            {errors?.hex?.message}
                          </span>
                        </div>
                        
                        

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Status <span className="text-red">*</span>
                          </label>

                          <select
                            className={`form-select`}
                            {...register("status", {
                              required: {
                                value: true,
                                message: "Status is required",
                              },
                              maxLength: 30,
                            })}
                          >
                            <option value="" className="option" selected>
                              -Status-
                            </option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className="text-red">
                            {errors?.status?.message}
                          </span>
                        </div>

                        <div></div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row d-flex  ">
                  <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                    <div className="row d-flex ">
                      <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                        <button
                          type="button"
                          disabled={isLoading}
                          className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                          style={{
                            borderColor: "rgb(0, 54, 101)",
                            background: "rgb(0, 54, 101)",
                            color: "white",
                          }}
                          onClick={handleSubmit(onSubmit)}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"
                          style={{
                            borderColor: "blue",
                            background: "blue",
                            color: "white",
                          }}
                          onClick={handleReset}
                        >
                          Reset
                        </button>

                        <button
                          type="button"
                          className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                          style={{
                            borderColor: "red",
                            background: "red",
                            color: "white",
                          }}
                          onClick={() => navigate("/listColor")}
                        >
                          Cancel
                        </button>
                      </div>
                      {/* <div className="col-lg-6">
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <>
                {/* {isSuccess&&    <Model title='Color' body="Color Added Successfully" onclick={handleModelClose} onclose={handleModelClick}/>} */}
              </>
            </div>
          </div>
        </section>
      </div>
      </Restricted>
    </div>
  );
};

export default Add_Color;
