import React, { useEffect, useRef } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import "../../components/Table/table.css"
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { usePanniPost } from "../../hooks/panni";
import { useState } from "react";
import Select from 'react-select'
import axios from 'axios'
import { isConstructorDeclaration } from "typescript";
import { useCreate } from "../../hooks/Api";
import { excel } from "react-export-table-to-excel/lib/lib";
import { useFetchColorDropDown, useFetchBrandDropDown, useFetchHSNDropDown, useFetchMaterialDensityDropDown, useFetchMaterialandHSNDropDown, useFetchPacketPakingDropDown, useFetchUomDropDown, useFetchPanniMaterialUseDropDown, useFetchPanniSealingTypeDropDown, useFetchPanniProductPrintingTypeDropDown, useFetchPanniProductPakingTypeDropDown, useFetchPanniProductByDropDown, useFetchMaterialUseDropDown, useFetchMachineLocationDropDown } from "../../hooks/DropDown_Api";
import Calculation from "../Panni/calculation";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import { Color_Model } from "../../components/Dynamic_Form_Models/Color_Model";
import { Brand_Model } from "../../components/Dynamic_Form_Models/Brand_Model";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import useFocusOnEnter from "../../components/focus";
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'
import { HSN_Model } from "../../components/Dynamic_Form_Models/HSN_Model";
import CreatableSelect from "react-select/creatable";
import { UOM_Model } from "../../components/Dynamic_Form_Models/Uom_Model";
import type { UploadFile } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


const Add_Panni = () => {
    const navigate = useNavigate()
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const apiname = process.env.REACT_APP_PANNI_API?.toString()
    const getRelatedHsn = process.env.REACT_APP_GET_RELATED_HSN_API?.toString()

    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });

    const [openHSN, setOpenHSN] = useState(false)
    const [openUOM, setOpenUOM] = useState(false)
    const [openProductUOM, setOpenProductUOM] = useState(false)

    const [panniPrinting, setpanniPrinting]: any = useState("no")
    const [panniGadget, setpanniGadget] = useState("no")
    const [panniKan, setpanniKan]: any = useState("no")
    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [colorOpen, setColorOpen] = useState(false)
    const [options, setOptions]: any = useState([])
    const [optionsPrinting, setOptionsPrinting]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchPanniTerm, setSearchPanniTerm] = useState('')
    const [ColorTotal, setColorTotal]: any = useState([])
    const [selected, setSelected] = useState([])
    const [brandOpen, setBrandOpen] = useState(false)
    const [printingColorOpen, setPrintingColorOpen] = useState(false)

    const [optionsbrand, setOptionsBrand]: any = useState([])
    const [searchTermBrand, setSearchTermBrand] = useState('')
    const [loadingBrand, setLoadingBrand] = useState(false)
    const [colorNamePanni, setColorNamePanni]: any = useState({})


    const [optionsHSN, setOptionsHSN]: any = useState([])
    const [searchHSN, setSearchHSN] = useState('')
    const [loadingHSN, setLoadingHSN] = useState(false)
    const [gstNumber, setGSTNumber]: any = useState({})

    const [selectedValue, setSelectedValue] = useState([]);
    const [optionsPanniName, setOptionsPanniName] = useState([])
    const [loadingPanniName, setLoadingPanniName] = useState(false)
    const [panniName, setPanniName]: any = useState({})

    const [forValue, setForValue]: any = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [submittedColor, setSubmittedColor] = useState(false)
    const [submittedPrintingColor, setSubmittedPrintingColor] = useState(false)
    const [submittedBrand, setSubmittedBrand] = useState(false)

    const [forColorValue, setForColorValue]: any = useState([])
    const [forColorsValue, setForColorsValue]: any = useState([])
    const [forBrandValue, setForBrandValue]: any = useState([])
    const [forHSNValue, setForHSNValue]: any = useState([])
    const [forUOMValue, setForUOMValue]: any = useState([])
    const [ForProductUOMValue, setForProductUOMValue]: any = useState([])


    const onPrintingChange = (e: any) => {
        setpanniPrinting(e.target.value)
    }
    const onGadgetChange = (e: any) => {
        setpanniGadget(e.target.value)
    }
    const onPanniChange = (e: any) => {
        setpanniKan(e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const { data: colorData, refetch: colorRefetch } = useFetchColorDropDown(searchTerm)

    useEffect(() => {
        setOptions(colorData);
        setOptionsPrinting(colorData)
    }, [colorData])



    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])






    const handleInputChange = (e: any) => {
        setSearchTerm(e)
    }
    const handlePanniInputChange = (e: any) => {
        setSearchPanniTerm(e)
    }
    const handleChange = (e: any) => {

        const color = e.map((value: any) => {
            return value.value
        })
        setValue("color", color)

        setForColorValue(e)

    }




    const handleChangePanni = (e: any) => {

        const printing_color_name = e.map((value: any) => {
            return value.value
        })
        setValue("printing_color_name", printing_color_name)
        setColorTotal(e.length)
        setValue("printing_total_color", e.length)
        setForColorsValue(e)
    }



    const { data: brandData, refetch: brandRefetch } = useFetchBrandDropDown(searchTerm)

    useEffect(() => {
        setOptionsBrand(brandData)
    }, [brandData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            brandRefetch()
        }
    }, [])



    const handleInputChangeBrand = (e: any) => {
        setSearchTermBrand(e)
    }

    const handleChangeBrand = (e: any) => {
        setColorNamePanni(e)
        setValue("brand", `${e.value}`)
        setValue("brand_type", `${e.type}`)
        setForBrandValue({ label: e?.label, value: e?.value, type: e?.type })
    }



    // const handleChangeHSN = (e: any) => {
    //     setHsnCode(e)

    //     setGSTNumber(e)
    //     setValue("hsn_code", `${e.value}`)
    //     setValue("gst", `${e.gst_id}`)
    //     setForHSNValue({ label: e?.label, value: e?.value })

    // }
    const [sutra, setSutra] = useState(0)

    const { data: HSNData, refetch: HSNRefetch } = useFetchHSNDropDown()

    useEffect(() => {
        setOptionsHSN(HSNData)
    }, [HSNData])

    const handleChangeHSN = (e: any) => {
        setValue("hsn_code", `${e.value}`)
        setHsnCode(e)
        setNameAndGrade(e)
        setSutra(Number(e.sutra))
        setValue("polymer_grade", e.polymer)
        setForHSNValue({ label: e?.label, value: e?.value })
    }
    const { data: UomData, refetch: UomRefetch } = useFetchUomDropDown()
    const [optionsUom, setOptionsUom]: any = useState()
    const [optionsProductUom, setOptionsProductUom]: any = useState()
    useEffect(() => {
        setOptionsUom(UomData)
        setOptionsProductUom(UomData)
    }, [UomData])

    const handleChangeUom = (e: any) => {
        setValue("uom", `${e.value}`)
        setForUOMValue(e)
        setForUOMValue({ label: e?.label, value: e?.value })

    }
    const handleChangeProductUom = (e: any) => {
        setValue("product_packing_total_kg_uom", `${e.value}`)
        setForProductUOMValue(e)
        setForProductUOMValue({ label: e?.label, value: e?.value })

    }




    const { data: materialdensityData, refetch: materialdensityRefetch } = useFetchMaterialDensityDropDown(searchTerm)

    useEffect(() => {
        setOptionsPanniName(materialdensityData)
    }, [materialdensityData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            materialdensityRefetch()
        }
    }, [])


    const handleInputChangePanniName = (e: any) => {
        setSearchHSN(e)
    }

    const [nameAndGrade, setNameAndGrade] = useState()
    const [hsnCode, setHsnCode] = useState()



    const { mutate: getrelated, data: relatedData } = useCreate(getRelatedHsn)
    // const handleChangePanniName = async (e: any) => {
    //     setNameAndGrade(e)
    //     setPanniName(e)
    //     setValue("name_and_grade", `${e.value}`)
    //     getrelated({ "grade": e.value })
    //     setSutra(e.sutra)
    //     // setGSTNumber("")
    //     // setValue("gst","")

    //     setForValue({ label: e?.label, value: e?.value, gst: e?.gst })

    // }

    // useEffect(() => {
    //     let temp_results = relatedData?.results.map((e: any) => {
    //         return (
    //             {
    //                 value: e.id,
    //                 label: e.hsn_code,
    //                 gst_id: e.gst.id,
    //                 gst: e.gst.india_GST,
    //             })

    //     })

    //     setOptionsHSN(temp_results)
    // }, [relatedData])



    const apinameForPackingType = process.env.REACT_APP_PANNI_PACKET_PAKING_TYPE_API?.toString()
    const { mutate: mutatePackingType, data: dataPackingType, isError: PackingTypeIsError, isLoading: PackingTypeLoading, isSuccess: PackingTypeSuccess, error: PackingTypeError }: any = useCreate(apinameForPackingType)
    const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valuePackingType, setValuePackingType]: any = useState();
    const [optionsPackingType, setOptionsPackingType]: any = useState([]);
    const [valuePackingTypeCreatable, setValuePackingTypeCreatable] = useState<Option | null>();

    const { data: PackingTypeData, refetch: PackingTypeRefetch } = useFetchPacketPakingDropDown()

    useEffect(() => {
        setOptionsPackingType(PackingTypeData)
    }, [PackingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            PackingTypeRefetch()
        }
    }, [])


    const handleChangePackingType = (e: any) => {

        PackingTypeRefetch()

        setApiName(apinameForPackingType)
        setDeleteApiName(apinameForPackingType)
        setEditId(e?.value)
        setEditData(PackingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValuePackingType(e)
        setValuePackingTypeCreatable(e)
        setValue("packet_packing_type", `${e?.value}`)
        setValuePackingTypeCreatable(null)
    }





    const handleCreatePackingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutatePackingType({ name: newOption.name, status: newOption.status })
            setOptionsPackingType((prev: any) => [...prev, newOption]);
            setValuePackingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (PackingTypeSuccess && optionsPackingType) {
                await PackingTypeRefetch();
            }
            setValue("packet_packing_type", PackingTypeData[0]?.value)
        })();

        return () => {
        };
    }, [PackingTypeSuccess, PackingTypeData]);
    const apinameForMaterialUse = process.env.REACT_APP_PANNI_MATERIAL_USE_API?.toString()
    const { mutate: mutateMaterialUse, data: dataMaterialUse, isError: MaterialUseIsError, isLoading: MaterialUseLoading, isSuccess: MaterialUseSuccess, error: MaterialUseError }: any = useCreate(apinameForMaterialUse)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueMaterialUse, setValueMaterialUse]: any = useState();
    const [optionsMaterialUse, setOptionsMaterialUse]: any = useState([]);
    const [valueMaterialUseCreatable, setValueMaterialUseCreatable] = useState<Option | null>();

    const { data: MaterialUseData, refetch: MaterialUseRefetch } = useFetchPanniMaterialUseDropDown()

    useEffect(() => {
        setOptionsMaterialUse(MaterialUseData)
    }, [MaterialUseData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            MaterialUseRefetch()
        }
    }, [])


    const handleChangeMaterialUse = (e: any) => {

        MaterialUseRefetch()

        setApiName(apinameForMaterialUse)
        setDeleteApiName(apinameForMaterialUse)
        setEditId(e?.value)
        setEditData(MaterialUseData)
        setRenderKey(prevKey => prevKey + 1);

        setValueMaterialUse(e)

        setValue("material_use", `${e?.value}`)
        setValueMaterialUseCreatable(null)
    }





    const handleCreateMaterialUse = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateMaterialUse({ name: newOption.name, status: newOption.status })
            setOptionsMaterialUse((prev: any) => [...prev, newOption]);
            setValueMaterialUseCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (MaterialUseSuccess && optionsMaterialUse) {
                await MaterialUseRefetch();
                setValue("material_use", MaterialUseData[0]?.value)
            }
        })();

        return () => {
        };
    }, [MaterialUseSuccess, MaterialUseData]);
    const apinameForSealingType = process.env.REACT_APP_PANNI_SEALING_TYPE_API?.toString()
    const { mutate: mutateSealingType, data: dataSealingType, isError: SealingTypeIsError, isLoading: SealingTypeLoading, isSuccess: SealingTypeSuccess, error: SealingTypeError }: any = useCreate(apinameForSealingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueSealingType, setValueSealingType]: any = useState();
    const [optionsSealingType, setOptionsSealingType]: any = useState([]);
    const [valueSealingTypeCreatable, setValueSealingTypeCreatable] = useState<Option | null>();

    const { data: SealingTypeData, refetch: SealingTypeRefetch } = useFetchPanniSealingTypeDropDown()

    useEffect(() => {
        setOptionsSealingType(SealingTypeData)
    }, [SealingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            SealingTypeRefetch()
        }
    }, [])


    const handleChangeSealingType = (e: any) => {

        SealingTypeRefetch()

        setApiName(apinameForSealingType)
        setDeleteApiName(apinameForSealingType)
        setEditId(e?.value)
        setEditData(SealingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSealingType(e)

        setValue("sealing_type", `${e?.value}`)
        setValueSealingTypeCreatable(null)
    }





    const handleCreateSealingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateSealingType({ name: newOption.name, status: newOption.status })
            setOptionsSealingType((prev: any) => [...prev, newOption]);
            setValueSealingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SealingTypeSuccess && optionsSealingType) {
                await SealingTypeRefetch();
                setValue("sealing_type", SealingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [SealingTypeSuccess, SealingTypeData]);
    const apinameForProductPrintingType = process.env.REACT_APP_PANNI_PRINTING_TYPE_API?.toString()
    const { mutate: mutateProductPrintingType, data: dataProductPrintingType, isError: ProductPrintingTypeIsError, isLoading: ProductPrintingTypeLoading, isSuccess: ProductPrintingTypeSuccess, error: ProductPrintingTypeError }: any = useCreate(apinameForProductPrintingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPrintingType, setValueProductPrintingType]: any = useState();
    const [optionsProductPrintingType, setOptionsProductPrintingType]: any = useState([]);
    const [valueProductPrintingTypeCreatable, setValueProductPrintingTypeCreatable] = useState<Option | null>();

    const { data: ProductPrintingTypeData, refetch: ProductPrintingTypeRefetch } = useFetchPanniProductPrintingTypeDropDown()

    useEffect(() => {
        setOptionsProductPrintingType(ProductPrintingTypeData)
    }, [ProductPrintingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPrintingTypeRefetch()
        }
    }, [])


    const handleChangeProductPrintingType = (e: any) => {

        ProductPrintingTypeRefetch()

        setApiName(apinameForProductPrintingType)
        setDeleteApiName(apinameForProductPrintingType)
        setEditId(e?.value)
        setEditData(ProductPrintingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPrintingType(e)

        setValue("printing_type", `${e?.value}`)
        setValueProductPrintingTypeCreatable(null)
    }





    const handleCreateProductPrintingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPrintingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPrintingType((prev: any) => [...prev, newOption]);
            setValueProductPrintingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPrintingTypeSuccess && optionsProductPrintingType) {
                await ProductPrintingTypeRefetch();
                setValue("printing_type", ProductPrintingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPrintingTypeSuccess, ProductPrintingTypeData]);
    const apinameForProductPakingType = process.env.REACT_APP_PANNI_PACKING_TYPE_API?.toString()
    const { mutate: mutateProductPakingType, data: dataProductPakingType, isError: ProductPakingTypeIsError, isLoading: ProductPakingTypeLoading, isSuccess: ProductPakingTypeSuccess, error: ProductPakingTypeError }: any = useCreate(apinameForProductPakingType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductPakingType, setValueProductPakingType]: any = useState();
    const [optionsProductPakingType, setOptionsProductPakingType]: any = useState([]);
    const [valueProductPakingTypeCreatable, setValueProductPakingTypeCreatable] = useState<Option | null>();

    const { data: ProductPakingTypeData, refetch: ProductPakingTypeRefetch } = useFetchPanniProductPakingTypeDropDown()

    useEffect(() => {
        setOptionsProductPakingType(ProductPakingTypeData)
    }, [ProductPakingTypeData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductPakingTypeRefetch()
        }
    }, [])


    const handleChangeProductPakingType = (e: any) => {

        ProductPakingTypeRefetch()

        setApiName(apinameForProductPakingType)
        setDeleteApiName(apinameForProductPakingType)
        setEditId(e?.value)
        setEditData(ProductPakingTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductPakingType(e)

        setValue("packing_type", `${e?.value}`)
        setValueProductPakingTypeCreatable(null)
    }





    const handleCreateProductPakingType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductPakingType({ name: newOption.name, status: newOption.status })
            setOptionsProductPakingType((prev: any) => [...prev, newOption]);
            setValueProductPakingTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductPakingTypeSuccess && optionsProductPakingType) {
                await ProductPakingTypeRefetch();
                setValue("packing_type", ProductPakingTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductPakingTypeSuccess, ProductPakingTypeData]);
    const apinameForProductBy = process.env.REACT_APP_PANNI_PRODUCT_BY_API?.toString()
    const { mutate: mutateProductBy, data: dataProductBy, isError: ProductByIsError, isLoading: ProductByLoading, isSuccess: ProductBySuccess, error: ProductByError }: any = useCreate(apinameForProductBy)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductBy, setValueProductBy]: any = useState();
    const [optionsProductBy, setOptionsProductBy]: any = useState([]);
    const [valueProductByCreatable, setValueProductByCreatable] = useState<Option | null>();

    const { data: ProductByData, refetch: ProductByRefetch } = useFetchPanniProductByDropDown()

    useEffect(() => {
        setOptionsProductBy(ProductByData)
    }, [ProductByData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            ProductByRefetch()
        }
    }, [])


    const handleChangeProductBy = (e: any) => {


        ProductByRefetch()

        setApiName(apinameForProductBy)
        setDeleteApiName(apinameForProductBy)
        setEditId(e?.value)
        setEditData(ProductByData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductBy(e)

        setValue("product_by", `${e?.value}`)
        setValueProductByCreatable(null)
    }





    const handleCreateProductBy = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductBy({ name: newOption.name, status: newOption.status })
            setOptionsProductBy((prev: any) => [...prev, newOption]);
            setValueProductByCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductBySuccess && optionsProductBy) {
                await ProductByRefetch();
                setValue("product_by", ProductByData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductBySuccess, ProductByData]);

    const apinameForProductType = process.env.REACT_APP_MATERIAL_USE_API?.toString()
    const { mutate: mutateProductType, data: dataProductType, isError: ProductTypeIsError, isLoading: ProductTypeLoading, isSuccess: ProductTypeSuccess, error: ProductTypeError }: any = useCreate(apinameForProductType)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueProductType, setValueProductType]: any = useState();
    const [optionsProductType, setOptionsProductType]: any = useState([]);
    const [valueProductTypeCreatable, setValueProductTypeCreatable] = useState<Option | null>();

    const { data: ProductTypeData, refetch: ProductTypeRefetch } = useFetchMaterialUseDropDown()

    useEffect(() => {
        setOptionsProductType(ProductTypeData)
    }, [ProductTypeData])





    const handleChangeProductType = (e: any) => {

        ProductTypeRefetch()

        setApiName(apinameForProductType)
        setDeleteApiName(apinameForProductType)
        setEditId(e?.value)
        setEditData(ProductTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueProductType(e)

        setValue("panni_special_running", `${e?.value}`)
        setValueProductTypeCreatable(null)
    }





    const handleCreateProductType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateProductType({ name: newOption.name, status: newOption.status })
            setOptionsProductType((prev: any) => [...prev, newOption]);
            setValueProductTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (ProductTypeSuccess && optionsProductType) {
                await ProductTypeRefetch();
                setValue("panni_special_running", ProductTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [ProductTypeSuccess, ProductTypeData]);
    const apinameForLocation = process.env.REACT_APP_MACHINE_LOCATION_API?.toString()
    const { mutate: mutateLocation, data: dataLocation, isError: LocationIsError, isLoading: LocationLoading, isSuccess: LocationSuccess, error: LocationError }: any = useCreate(apinameForLocation)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueLocation, setValueLocation]: any = useState();
    const [optionsLocation, setOptionsLocation]: any = useState([]);
    const [valueLocationCreatable, setValueLocationCreatable] = useState<Option | null>();

    const { data: LocationData, refetch: LocationRefetch } = useFetchMachineLocationDropDown()

    useEffect(() => {
        setOptionsLocation(LocationData)
    }, [LocationData])


    useEffect(() => {
        if (searchTerm?.length >= 1) {
            LocationRefetch()
        }
    }, [])


    const handleChangeLocation = (e: any) => {


        LocationRefetch()

        setApiName(apinameForLocation)
        setDeleteApiName(apinameForLocation)
        setEditId(e?.value)
        setEditData(LocationData)
        setRenderKey(prevKey => prevKey + 1);

        setValueLocation(e)

        setValue("panni_location", `${e?.value}`)
        setValueLocationCreatable(null)
    }





    const handleCreateLocation = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateLocation({ name: newOption.name, status: newOption.status })
            setOptionsLocation((prev: any) => [...prev, newOption]);
            setValueLocationCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (LocationSuccess && optionsLocation) {
                await LocationRefetch();
            }
            setValue("panni_location", LocationData[0]?.value)
        })();

        return () => {
        };
    }, [LocationSuccess, LocationData]);

    const handleReset = () => {
        reset()
        setForColorValue(null)
        setForColorsValue(null)
        setColorTotal(null)
        setForValue(null)
        setForBrandValue(null)
        setForHSNValue(null)
        setGSTNumber(null)
        setpanniGadget("no")
        setpanniPrinting("no")
        setpanniKan("no")
        setlogo(null)
        setlogoPdf(null)
        setPanniWidth(null) 
        setGauge(null) 
        setValueMaterialUseCreatable(null)
        setValueMaterialUse(null)
        setValueSealingTypeCreatable(null)
        setValueSealingType(null)
        setValuePackingTypeCreatable(null)
        setValuePackingType(null)
        setForUOMValue(null)
        setForProductUOMValue(null)
        setValueProductPakingTypeCreatable(null)
        setValueProductPakingType(null)
        setValueProductByCreatable(null)
        setValueProductBy(null)
        setValueProductTypeCreatable(null)
        setValueProductType(null)
        setValueLocationCreatable(null)
        setValueLocation(null)
        setValueProductPrintingTypeCreatable(null)
        setValueProductPrintingType(null)
        setuploadBoriAndCartonImage([])
        setUploadProductPacketPacking([])
        setUploadProductImage([])
        setUploadProductPDF([])
    }

    const onSubmit = (pannidetail: any) => {
        // delete pannidetail.product_packing_total_kg
        if (boriAndCartonImage.length == 0) {
            delete pannidetail.bori_and_carton_image
        }

        if (productPacketPacking.length == 0) {
            delete pannidetail.product_packet_packing_image
        }
        if (productImage.length == 0) {
            delete pannidetail.product_image
        }
        if (productPDF.length == 0) {
            delete pannidetail.product_pdf_drawing
        }

        if (panniPrinting === "no") {
            delete pannidetail.printing_type
            delete pannidetail.printing_total_color
            delete pannidetail.printing_length
            delete pannidetail.printing_width
            delete pannidetail.printing_color_name
            delete pannidetail.brand
            delete pannidetail.brand_type
        }

        if (panniGadget === "no") {
            delete pannidetail.gadget_size
        }

        if (panniKan === "no") {
            delete pannidetail.kan_length
            delete pannidetail.kan_width
        }

        // if (branddetail.logo?.length == 1 && branddetail.logo_pdf?.length != 1) {
        //             delete branddetail.logo_pdf

        //         } else if (branddetail.logo_pdf?.length == 1 && branddetail.logo?.length != 1) {
        //             delete branddetail.logo
        //         } else if(branddetail.logo?.length == 1 && branddetail.logo_pdf?.length) {
        //         }
        //         else{
        //             delete branddetail.logo
        //             delete branddetail.logo_pdf
        //         }
        const formData = new FormData();



        for (const [key, value] of Object.entries(pannidetail)) {
            const formvalue: any = value
            if (key === "bori_and_carton_image") {
                for (let i = 0; i < boriAndCartonImage.length; i++) {

                    formData.append('bori_and_carton_image', boriAndCartonImage[i])
                }
                continue
            }
            if (key === "product_packet_packing_image") {
                for (let i = 0; i < productPacketPacking.length; i++) {

                    formData.append('product_packet_packing_image', productPacketPacking[i])
                }
                continue
            }

            if (key === "product_image") {
                for (let i = 0; i < productImage.length; i++) {

                    formData.append('product_image', productImage[i])
                }
                continue
            }
            if (key === "product_pdf_drawing") {
                for (let i = 0; i < productPDF.length; i++) {

                    formData.append('product_pdf_drawing', productPDF[i])
                }
                continue
            }

            if (ProductPackingtotal && key === 'product_packing_total_kg') {
                let decimalNumber = Number(ProductPackingtotal);
                const formattedString = decimalNumber.toFixed(3);
                formData.append('product_packing_total_kg', formattedString)
                continue
            }

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (formvalue.hasOwnProperty('size')) {
                formData.append(key, formvalue[0])
            }
            else if (Array.isArray(value) && key === "color") {
                value.forEach((val, index) => formData.append(`color`, val))
            }
            else if (Array.isArray(value) && key === "printing_color_name") {
                value.forEach((val, index) => formData.append(`printing_color_name`, val))
            }


        }

        mutate(formData);
    }





    const [panniType, setPanniType] = useState("")
    const [panniLength, setPanniLength] = useState(1)
    const [panniWidth, setPanniWidth]: any = useState(1)
    const [doubleSideTotalLength, setDoubleSideTotalLength] = useState(0)
    const [totalsquare, setTotalSquare] = useState(0)
    const [squareSutra, setSquareSutra] = useState(645.16)
    const [totalInch, setTotalInch] = useState(0)
    const [gauge, setGauge]:any = useState(0)
    const [oneNosWeight, setOneNosWeight] = useState(0)
    const [oneKgNos, setOneKgNos] = useState(0)
    const [totalLength, setTotalLength] = useState(0)
    const [panniKanLength, setPanniKanLength] = useState(0)
    const [gadgetSize, setGadgetSize] = useState("")
    const [customError, setCustomError] = useState("")
    const gadget = getValues("gadget")

    // console.log("oneNosWeight",oneNosWeight,"oneKgNos",oneKgNos,"sutra",sutra)

    const handleGadgetSize = (e: any) => {

        try {
            const temp = eval(e.target.value)
            setGadgetSize(eval(e.target.value))

        }
        catch {
        }
    }
    // useEffect(() => {
    //     setValue("width", gadgetSize)
    //     if (gadget === "no") {
    //         setValue("width", 0)
    //     }

    //     if (gadget === "yes") {
    //         setPanniWidth(gadgetSize)
    //     }
    // }, [gadgetSize, gadget])


    useEffect(() => {

        const results = Calculation(panniLength, panniWidth, gauge, sutra, panniType, panniGadget, panniKan, panniKanLength)

        setOneNosWeight(results?.onenos)
        setOneKgNos(results?.kg)





        setValue("gauge", gauge)
        setValue("panni_1_nos_weight_gram", oneNosWeight)
        setValue("panni_1KG_in_nos", oneKgNos)

        // console.log(oneNosWeight,oneKgNos)

    }, [panniLength, panniWidth, doubleSideTotalLength, gauge, totalInch, panniType, oneNosWeight, sutra, totalLength, panniGadget, panniKan, panniKanLength, gadget, gadgetSize])



    const handleSetGauge = (e: any) => {
        setGauge(e.target.value * 4)
    }

    const handleSetPanniType = (e: any) => {
        setPanniType(e.target.value)
    }

    const handleSetPanniLength = (e: any) => {
        setPanniLength(Number(e.target.value))
    }
    const handleSetPanniWidth = (e: any) => {
        setPanniWidth(e.target.value)
    }
    const handleSetPanniKanLength = (e: any) => {
        setPanniKanLength(Number(e.target.value))
    }

    // useEffect(() => {

    // }, [panniPrinting])






    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listPanni', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    useEffect(() => {


        if (submittedColor) {

            setForColorValue(options[0])
            setValue("color", options[0]?.value)


        }


    }, [submittedColor, options])
    useEffect(() => {


        if (submittedPrintingColor) {

            setForColorsValue(optionsPrinting[0])
            setValue("printing_color_name", optionsPrinting[0]?.value)

        }


    }, [submittedPrintingColor, optionsPrinting])

    useEffect(() => {

        if (submittedBrand) {

            setValue("brand", optionsbrand[0].value)
            setForBrandValue(optionsbrand[0])
        }

    }, [submittedBrand, optionsbrand])


    const [submittedHSN, setSubmittedHSN] = useState(false)


    useEffect(() => {
        if (submittedHSN) {
            setForHSNValue(optionsHSN[0])

            setValue("hsn_code", optionsHSN[0]?.value)

        }

    }, [submittedHSN, optionsHSN])

    const [submittedUOM, setSubmittedUOM] = useState(false)


    useEffect(() => {
        if (submittedUOM) {
            setForUOMValue(optionsUom[0])

            setValue("uom", optionsUom[0]?.value)

        }

    }, [submittedUOM, optionsUom])
    const [submittedProductUOM, setSubmittedProductUOM] = useState(false)


    useEffect(() => {
        if (submittedProductUOM) {
            setForProductUOMValue(optionsProductUom[0])

            setValue("product_packing_total_kg_uom", optionsProductUom[0]?.value)

        }

    }, [submittedProductUOM, optionsProductUom])


    const [packet_packing_type, setPacket_packing_type]: any = useState()
    const [ProductQty, setProductQty]: any = useState()

    // console.log(packet_packing_type,"packet_packing_type");
    useEffect(() => {
        if (valuePackingTypeCreatable) {
            setPacket_packing_type(valuePackingTypeCreatable?.label)
        } else {
            setPacket_packing_type(valuePackingType?.label)
        }
    }, [valuePackingTypeCreatable, valuePackingType])

    const [ProductPackingtotal, setProductPackingtotal]: any = useState()

    useEffect(() => {
        setProductPackingtotal(Number(packet_packing_type) * Number(ProductQty))
        setValue("product_packing_total_kg", ProductPackingtotal)

    }, [ProductPackingtotal, packet_packing_type, ProductQty])

    const onProductQtyChange = (e: any) => {
        setProductQty(e.target.value)
    }




    useEffect(() => {
        if (panniPrinting === 'no') {
            setValue("printing_type", "")
            setValue("printing_total_color", "")
            setValue("printinig_length", "")
            setValue("printinig_width", "")
            setValue("printing_color_name", "")
            setValue("brand", "")
            setValue("brand_type", "")
            setForColorsValue(null)
            setColorTotal(null)
            setForBrandValue(null)
            setColorNamePanni(null)
        }
        if (panniGadget === 'no') {
            setValue("gadget_size", "")
        }
        if (panniKan === 'no') {
            setValue("kan_length", "")
            setValue("kan_width", "")
        }
    }, [panniPrinting, panniGadget, panniKan])


    const [boriAndCartonImage, setBoriAndCartonImage] = useState([])
    const [uploadBoriAndCartonImage, setuploadBoriAndCartonImage] = useState<UploadFile[]>([])

    const [productPacketPacking, setProductPacketPacking] = useState([])
    const [uploadProductPacketPacking, setUploadProductPacketPacking] = useState<UploadFile[]>([])

    const [productImage, setProductImage]: any = useState([])
    const [uploadProductImage, setUploadProductImage] = useState<UploadFile[]>([])

    const [productPDF, setProductPDF] = useState([])
    const [uploadProductPDF, setUploadProductPDF] = useState<UploadFile[]>([])

    const BoriAndCartonChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setBoriAndCartonImage(tempFiles)
        setuploadBoriAndCartonImage(e.fileList)

    }


    const ProductPacketPackingOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductPacketPacking(tempFiles)
        setUploadProductPacketPacking(e.fileList)

    }

    const ProductImageOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductImage(tempFiles)
        setUploadProductImage(e.fileList)

    }
    const ProductPDFOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setProductPDF(tempFiles)
        setUploadProductPDF(e.fileList)

    }




    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {

        if (apiName === apinameForMaterialUse) {
            MaterialUseRefetch()
        }

        if (apiName === apinameForSealingType) {
            SealingTypeRefetch()
        }

        if (apiName === apinameForPackingType) {
            PackingTypeRefetch()
        }

        if (apiName === apinameForProductPakingType) {
            ProductPakingTypeRefetch()
        }

        if (apiName === apinameForProductType) {
            ProductTypeRefetch()
        }


        if (apiName === apinameForLocation) {
            LocationRefetch()
        }


        if (apiName === apinameForProductPrintingType) {
            ProductPrintingTypeRefetch()
        }


        if (apiName === apinameForProductBy) {
            ProductByRefetch()
        }


    }


    useEffect(() => {

        if (OpenEditDropDownModel && valueMaterialUse && apiName === apinameForMaterialUse) {
            setValueMaterialUse(null)
            setValueMaterialUseCreatable(null)
        }


        if (OpenEditDropDownModel && valueSealingType && apiName === apinameForSealingType) {
            setValueSealingType(null)
            setValueSealingTypeCreatable(null)
        }

        if (OpenEditDropDownModel && valuePackingType && apiName === apinameForPackingType) {
            setValuePackingType(null)
            setValuePackingTypeCreatable(null)
        }

        if (OpenEditDropDownModel && valueProductPakingType && apiName === apinameForProductPakingType) {
            setValueProductPakingType(null)
            setValueProductPakingTypeCreatable(null)
        }

        if (OpenEditDropDownModel && valueProductType && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }

        if (OpenEditDropDownModel && valueLocation && apiName === apinameForLocation) {
            setValueLocation(null)
            setValueLocationCreatable(null)
        }

        if (OpenEditDropDownModel && valueProductPrintingType && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }

        if (OpenEditDropDownModel && valueProductBy && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }








        if (deletesucess && apiName === apinameForMaterialUse) {
            setValueMaterialUse(null)
            setValueMaterialUseCreatable(null)
        }


        if (deletesucess && apiName === apinameForSealingType) {
            setValueSealingType(null)
            setValueSealingTypeCreatable(null)
        }

        if (deletesucess && apiName === apinameForPackingType) {
            setValuePackingType(null)
            setValuePackingTypeCreatable(null)
        }

        if (deletesucess && apiName === apinameForProductPakingType) {
            setValueProductPakingType(null)
            setValueProductPakingTypeCreatable(null)
        }

        if (deletesucess && apiName === apinameForProductType) {
            setValueProductType(null)
            setValueProductTypeCreatable(null)
        }

        if (deletesucess && apiName === apinameForLocation) {
            setValueLocation(null)
            setValueLocationCreatable(null)
        }


        if (deletesucess && apiName === apinameForProductPrintingType) {
            setValueProductPrintingType(null)
            setValueProductPrintingTypeCreatable(null)
        }



        if (deletesucess && apiName === apinameForProductBy) {
            setValueProductBy(null)
            setValueProductByCreatable(null)
        }



    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption

    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };



    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header  px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add Packing Material</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to={"/listColor"} className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to={"#"} className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to={"/listPanni"} className="text-decoration-none text-black">Packing Material</Link></li>
                                    <li className="breadcrumb-item active">Add Packing Material</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message.name} /> : ""}

                                    <div className="row">
                                        <h4 className="mb-0 mt-2">Panni Detail</h4>
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Name & Grade <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                    <Select
                                                        // id="Panni Name & Grade"
                                                        {...register("name_and_grade", { required: { value: true, message: "Panni Name and Grade is required" } })}
                                                        className=""
                                                        placeholder={'Panni Name & Grade'}
                                                        onInputChange={handleInputChangePanniName}
                                                        onChange={handleChangePanniName}
                                                        options={optionsPanniName}
                                                        value={forValue}
                                                        // isOptionDisabled={(options:any)=>
                                                        //     {
                                                        //         if(options.status === "inactive" || options.deleted == true){
                                                        //         return options
                                                        //         }
                                                        //        }}
                                                    />
                                                    <span className='text-red w-100'>{errors?.name_and_grade?.message}</span>
                                                </div>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Name & Type & HSN & GST% <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("hsn_code", { required: { value: true, message: "Material Name & Type & Category & HSN & GST% is required" } })}
                                                            onChange={handleChangeHSN}
                                                            options={optionsHSN}
                                                            components={customIndicator}
                                                            value={forHSNValue}

                                                            // value={selHSN === "" ? optionsHSN?.find((obj: any) => obj.value === selectedHSN) : selHSN}
                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenHSN(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.hsn_code?.message}</span>


                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Material Use <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueMaterialUse(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("material_use", { required: { value: true, message: "Material Use is required" } })}
                                                        placeholder={'Select Material Use'}
                                                        // menuIsOpen={true}

                                                        components={customCreatableIndicator}
                                                        options={optionsMaterialUse}
                                                        onMenuOpen={() => MaterialUseRefetch()}
                                                        onCreateOption={handleCreateMaterialUse}
                                                        onChange={handleChangeMaterialUse}
                                                        value={valueMaterialUseCreatable == null ? valueMaterialUse : valueMaterialUseCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.material_use?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Color Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("color", { required: { value: true, message: "Panni Color Name is required" } })}
                                                            placeholder={'Select Color'}
                                                            onInputChange={handleInputChange}
                                                            onChange={handleChange}
                                                            options={options}
                                                            isMulti

                                                            components={customIndicator}

                                                            // defaultValue={options}
                                                            value={forColorValue}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.color?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Width mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control"
                                                            // disabled={panniType != "" && gadget != "yes" ? false : true}
                                                            id="exampleInputBrand" placeholder="Product Width mm"
                                                            {...register("width", {
                                                                onChange: handleSetPanniWidth, required: { value: true, message: "Product Width mm is required" },
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: "input numbers only"
                                                                }
                                                            })}
                                                        //  onChange={(e:any)=>{
                                                        //     setPanniWidth(e.target.value)
                                                        //  }}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.width?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Length mm <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control"
                                                            // disabled={panniType != "" ? false : true}
                                                            id="exampleInputBrand" placeholder="Product Length mm"
                                                            {...register("length", {
                                                                onChange: handleSetPanniLength, required: { value: true, message: "Product Length mm is required" }
                                                                , pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: "input numbers only"
                                                                }
                                                            })}
                                                        //  onChange={(e:any)=>{
                                                        //     setPanniLength(Number(e.target.value))
                                                        //  }}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.length?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Thickness Micron <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control"
                                                            // disabled={panniType != "" ? false : true}
                                                            id="exampleInputBrand" placeholder="Thickness Micron" {...register("micron", {
                                                                onChange: handleSetGauge,
                                                                 required: { value: true, message: "Micron is required" }, pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: "input numbers only"
                                                                }
                                                            })}
                                                        //   onChange={handleSetGauge} 
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.micron?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Thickness Gauge
                                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                                    </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Add Panni Name"
                                                            disabled
                                                            {...register("gauge", { required: { value: false, message: "Gauge is required" } })}
                                                        // value={gauge}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gauge?.message}</span>
                                                </div>





                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Sealing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSealingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("sealing_type", { required: { value: true, message: "Product Sealing Type is required" } })}
                                                        placeholder={'Select Product Sealing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSealingType}
                                                        onMenuOpen={() => SealingTypeRefetch()}
                                                        onCreateOption={handleCreateSealingType}
                                                        onChange={handleChangeSealingType}
                                                        value={valueSealingTypeCreatable == null ? valueSealingType : valueSealingTypeCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.sealing_type?.message}</span>
                                                </div>









                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product ML</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            placeholder="Product ML" {...register("product_ml", { required: { value: false, message: "Product ML is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_ml?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Actual ML</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            placeholder="Product Actual ML" {...register("product_actual_ml", { required: { value: false, message: "Product Actual ML is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_actual_ml?.message}</span>
                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">HSN Code <span style={{ color: 'red' }}>*</span></label>

                                                    <Select
                                                        placeholder={'Select HSN Code'}
                                                        // onInputChange={handleInputChangeHSN}
                                                        {...register("hsn_code", { required: { value: true, message: "Hsn Code is required" } })}
                                                        onChange={handleChangeHSN}
                                                        options={optionsHSN}
                                                        value={forHSNValue}
                                                    />
                                                    <span className='text-red w-100'>{errors?.hsn_code?.message}</span>
                                                </div> */}


                                                {/* <div className="col-sm-3 mt-2 ">
                                                    <label htmlFor="exampleInputEmail1">Panni Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select"
                                                        // disabled={!nameAndGrade || !hsnCode}
                                                        {...register("type", { onChange: handleSetPanniType, required: { value: true, message: "Type is required" } })}
                                                    // onChange={(e:any)=>{
                                                    //     setPanniType(e.target.value)
                                                    // }}
                                                    >
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"single patto"}>Singal Patto</option>
                                                        <option value={"liner"}>Liner</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div> */}





                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Product Packet Packing Type</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValuePackingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("packet_packing_type", { required: { value: false, message: "Product Packet Packing Type is required" } })}
                                                        placeholder={'Select Product Packet Packing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsPackingType}
                                                        onMenuOpen={() => PackingTypeRefetch()}
                                                        onCreateOption={handleCreatePackingType}
                                                        onChange={handleChangePackingType}
                                                        value={valuePackingTypeCreatable == null ? valuePackingType : valuePackingTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.packet_packing_type?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("uom", { required: { value: true, message: "U.O.M is required" } })}
                                                            placeholder={'Select U.O.M'}
                                                            onChange={handleChangeUom}
                                                            options={optionsUom}
                                                            value={forUOMValue}
                                                            components={customIndicator}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenUOM(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.uom?.message}</span>

                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Packet Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Packet Qty" {...register("packet_qty", { onChange: onProductQtyChange, required: { value: true, message: "Packet Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.packet_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Packing Total Kg.</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" disabled value={ProductPackingtotal} placeholder="Product Packing Total Kg." {...register("product_packing_total_kg", { onChange: handleValue, required: { value: false, message: "Product Packing Total Kg. is required" } })} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_packing_total_kg?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("product_packing_total_kg_uom", { required: { value: true, message: "Product U.O.M is required" } })}
                                                            placeholder={'Select U.O.M'}
                                                            onChange={handleChangeProductUom}
                                                            options={optionsProductUom}
                                                            value={ForProductUOMValue}
                                                            components={customIndicator}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenProductUOM(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_packing_total_kg_uom?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Product Packing Type</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductPakingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("packing_type", { required: { value: false, message: "Product Packing Type is required" } })}
                                                        placeholder={'Select Product Packing Type '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductPakingType}
                                                        onMenuOpen={() => ProductPakingTypeRefetch()}
                                                        onCreateOption={handleCreateProductPakingType}
                                                        onChange={handleChangeProductPakingType}
                                                        value={valueProductPakingTypeCreatable == null ? valueProductPakingType : valueProductPakingTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.packing_type?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Stock Minimum Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Stock Minimum Qty"
                                                            {...register("product_stock_minimum_qty", { onChange: handleValue, required: { value: true, message: "Product Stock Minimum Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_stock_minimum_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Stock Maximum Qty <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Stock Maximum Qty"
                                                            {...register("product_stock_maximum_qty", { onChange: handleValue, required: { value: true, message: "Product Stock Maximum Qty is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_stock_maximum_qty?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Bursting Strength <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Bursting Strength" {...register("bursting_strength", { onChange: handleValue, required: { value: true, message: "Bursting Strength is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.bursting_strength?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Product By</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductBy(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("product_by", { required: { value: false, message: "Product By is required" } })}
                                                        placeholder={'Select Product By '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductBy}
                                                        onMenuOpen={() => ProductByRefetch()}
                                                        onCreateOption={handleCreateProductBy}
                                                        onChange={handleChangeProductBy}
                                                        value={valueProductByCreatable == null ? valueProductBy : valueProductByCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.product_by?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Product Type</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("panni_special_running", { required: { value: false, message: "Product Type is required" } })}
                                                        placeholder={'Select Product Type '}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsProductType}
                                                        onCreateOption={handleCreateProductType}
                                                        onMenuOpen={() => ProductTypeRefetch()}
                                                        onChange={handleChangeProductType}
                                                        value={valueProductTypeCreatable == null ? valueProductType : valueProductTypeCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.panni_special_running?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Maximum Debit Day <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Maximum Debit Day" {...register("product_maximum_debit_day", { onChange: handleValue, required: { value: true, message: "Product Maximum Debit Day is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_maximum_debit_day?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">

                                                    <label htmlFor="exampleInputEmail1">Material Location</label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueLocation(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("panni_location", { required: { value: false, message: "Material Location is required" } })}
                                                        placeholder={'Select Material Location'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsLocation}
                                                        onMenuOpen={() => LocationRefetch()}
                                                        onCreateOption={handleCreateLocation}
                                                        onChange={handleChangeLocation}
                                                        value={valueLocationCreatable == null ? valueLocation : valueLocationCreatable}

                                                    />
                                                    <span className='text-red'>{errors?.panni_location?.message}</span>

                                                </div>
                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Sealing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select"  {...register("sealing_type", { required: { value: true, message: "Sealing Type is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"side"}>Side</option>
                                                        <option value={"vertical"}>Vertical</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.sealing_type?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Special / Running <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("panni_special_running", { required: { value: true, message: "Field is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"special"}>Special</option>
                                                        <option value={"running"}>Running</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.panni_special_running?.message}</span>
                                                    </div>*/}


                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">Panni Printing</h4>
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing <span style={{ color: 'red' }}>*</span></label>


                                                    <select className="form-select" {...register("printing", { onChange: onPrintingChange, required: { value: true, message: "Printing is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.printing?.message}</span>
                                                </div>
                                                {/* {panniPrinting === "yes" ?
                                                 <>  */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Type {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueProductPrintingType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("printing_type", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Printing Type is required" } })}
                                                        placeholder={'Select Product Printing Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        isDisabled={panniPrinting === 'no'}
                                                        options={optionsProductPrintingType}
                                                        onMenuOpen={() => ProductPrintingTypeRefetch()}
                                                        onCreateOption={handleCreateProductPrintingType}
                                                        onChange={handleChangeProductPrintingType}
                                                        value={valueProductPrintingTypeCreatable == null ? valueProductPrintingType : valueProductPrintingTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.printing_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Color Name {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("printing_color_name", { required: { value: panniPrinting === 'no' ? false : true, message: "Color is required" } })}
                                                            placeholder={'Select Color'}
                                                            value={forColorsValue}
                                                            options={optionsPrinting}
                                                            isDisabled={panniPrinting === 'no'}
                                                            onInputChange={handlePanniInputChange}
                                                            components={customIndicator}

                                                            onChange={handleChangePanni}
                                                            isMulti={true}
                                                            // isOptionDisabled={(optionsPanniName:any)=>
                                                            //     {
                                                            //         if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                            //         return optionsPanniName
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" disabled={panniPrinting === 'no'} onClick={() => {
                                                                setPrintingColorOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Total Color</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" value={ColorTotal} disabled className="form-control" id="exampleInputBrand" placeholder=" 0" {...register("printing_total_color", { required: false })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.printing_total_color?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Brand Name {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="d-flex row" >

                                                        <Select
                                                            {...register("brand", { required: { value: panniPrinting === 'no' ? false : true, message: "Product Brand Name is required" } })}
                                                            placeholder={'Select Brand Name'}
                                                            onInputChange={handleInputChangeBrand}
                                                            onChange={handleChangeBrand}
                                                            options={optionsbrand}
                                                            value={forBrandValue}
                                                            components={customIndicator}
                                                            isDisabled={panniPrinting === 'no'}

                                                            // isOptionDisabled={(optionsbrand:any)=>
                                                            //     {
                                                            //         if(optionsbrand.status === "inactive" || optionsbrand.deleted === true){
                                                            //         return optionsbrand
                                                            //         }
                                                            //        }}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }} >
                                                            <Button className="input-group-text border-start-0 bg-primary" disabled={panniPrinting === 'no'} onClick={() => {
                                                                setBrandOpen(true)
                                                            }} ><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.brand?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Our Brand Panni</label>
                                                    <select disabled className="form-select" {...register("brand_type", { required: { value: false, message: "Brand Type is required" } })}>
                                                        <option value={''}>Select Any</option>
                                                        <option selected={colorNamePanni?.type === "our" ? true : false} value="our">Our</option>
                                                        <option selected={colorNamePanni?.type === "other" ? true : false} value="other">Other</option>
                                                    </select>
                                                    {/* <span className='text-red w-100'>{errors?.color?.message}</span> */}
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Width mm {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            disabled={panniPrinting === 'no' || ''}
                                                            placeholder="Product Printing Width mm" {...register("printing_width", { required: { value: panniPrinting === 'no' ? false : true, message: "Width is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.printinig_width?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Printing Length mm {panniPrinting === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            disabled={panniPrinting === 'no' || ''}
                                                            placeholder="Product Printing Length mm" {...register("printing_length", { required: { value: panniPrinting === 'no' ? false : true, message: "Length is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.printinig_length?.message}</span>
                                                </div>

                                                {/* </> : ""} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">Panni Gadget</h4>
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Gadget Yes-No </label>
                                                    <select className="form-select" {...register("gadget", { onChange: onGadgetChange, required: { value: false, message: "Gadget is required" } })} disabled={(panniType == "single patto" ? true : false)}>
                                                        <option value={"no"} selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"} >No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.gadget?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Gadget Size mm {panniGadget === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand"
                                                            placeholder="Add Gadget Size mm" {...register("gadget_size", { required: { value: panniGadget === 'no' ? false : true, message: "Gadget is required" } })}
                                                            onChange={handleGadgetSize}
                                                            disabled={panniGadget === 'no' || ''}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.gadget_size?.message}</span>
                                                </div>
                                                {/* {panniGadget === "yes" ?
                                                    <> */}
                                                {/* </> : ""} */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">Panni Kan</h4>
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Kan </label>

                                                    <select className="form-select" {...register("kan", { onChange: onPanniChange, required: { value: false, message: "Panni Kan is required" } })}
                                                        disabled={(panniType == "single patto" ? true : false)}
                                                    >
                                                        <option value={"no"} selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"} >No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.kan?.message}</span>
                                                </div>
                                                {/* {panniKan === "yes" ?
                                                <> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Kan Width mm {panniKan === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            disabled={panniKan === "no" || ""}
                                                            placeholder="Product Width mm" {...register("kan_width", { required: { value: panniKan === "no" ? false : true, message: "Width is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.kan_width?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Kan Length mm {panniKan === 'yes' ? <span style={{ color: 'red' }}>*</span> : ''}</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand"
                                                            disabled={panniKan === "no" || ""}
                                                            placeholder="Product Length mm" {...register("kan_length", { onChange: handleSetPanniKanLength, required: { value: panniKan === "no" ? false : true, message: "Length is required" } })}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.kan_length?.message}</span>
                                                </div>
                                                {/* </> : ""} */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <h4 className="mb-0 mt-2">Panni Packing</h4>
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row"> */}
                                    {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Packet Packing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Add Panni Packet Packing Type" {...register("packet_packing_type", { onChange: handleValue, required: { value: true, message: "Panni Package Type is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.packet_packing_type?.message}</span>
                                                </div> */}
                                    {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Packing KG <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" className="form-control" id="exampleInputBrand" placeholder="Add Panni Packing KG" {...register("packing_kg", { onChange: handleValue, required: { value: true, message: "KG is required" } })} />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.packing_kg?.message}</span>
                                                </div> */}
                                    {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Panni Packing Type <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("packing_type", { required: { value: true, message: "Type is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"single patto"}>Single Patto</option>
                                                        <option value={"bori"}>Bori</option>
                                                        <option value={"cartoon"}>Carton</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.packing_type?.message}</span>
                                                </div> */}
                                    {/* </div>
                                        </div>
                                    </div> */}


                                    <div className="row mt-3">
                                    </div>
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                       
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Product 1 Nos Weight Gram
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </label>
                                            <div className="input-group" data-colorpicker-id={2}>
                                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product 1 Nos Weight Gram"
                                                    disabled
                                                    value={oneNosWeight}
                                                    {...register("panni_1_nos_weight_gram", { required: { value: false, message: "Weight gram is required" } })}
                                                />
                                            </div>
                                            <span className='text-red w-100'>{errors?.panni_1_nos_weight_gram?.message}</span>
                                        </div>
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Product 1 KG In Nos
                                                {/* <span style={{ color: 'red' }}>*</span> */}
                                            </label>
                                            <div className="input-group" data-colorpicker-id={2}>
                                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product 1 KG in Nos"
                                                    disabled
                                                    value={oneKgNos}
                                                    {...register("panni_1KG_in_nos", { required: { value: false, message: "KG is required" } })}
                                                />
                                            </div>
                                            <span className='text-red w-100'>{errors?.panni_1KG_in_nos?.message}</span>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Remark </label>
                                            <div className="input-group" data-colorpicker-id={2}>
                                                <input type="text" placeholder="Remark" className="form-control" id="exampleInputBrand"
                                                    {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                </div>
                                            </div>
                                            <span className='text-red w-100'>{errors?.remark?.message}</span>
                                        </div>
                                        <div className="col-sm-3 mt-2">
                                            <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                            <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                <option value={""} selected>Select Any</option>

                                                <option value={"active"}>Active</option>
                                                <option value={"inactive"}>Inactive</option>
                                            </select>
                                            <span className='text-red w-100'>{errors?.status?.message}</span>
                                        </div>
                                    </div>

                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Bori & Cartoon Image All </label>
                                                    <Upload
                                                        {...register("bori_and_carton_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={BoriAndCartonChange}
                                                        multiple={true}
                                                        fileList={uploadBoriAndCartonImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Packing Image All </label>
                                                    <Upload
                                                        {...register("product_packet_packing_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductPacketPackingOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductPacketPacking}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product Image All </label>
                                                    <Upload
                                                        {...register("product_image")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={ProductImageOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Product PDF Drawing All</label>
                                                    <Upload
                                                        {...register("product_pdf_drawing")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={ProductPDFOnChange}
                                                        multiple={true}
                                                        fileList={uploadProductPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPanni")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            <Color_Model refetchForm={colorRefetch} open={colorOpen} setOpen={setColorOpen} setSubmitted={setSubmittedColor} />
            <Color_Model refetchForm={colorRefetch} open={printingColorOpen} setOpen={setPrintingColorOpen} setSubmitted={setSubmittedPrintingColor} />
            <Brand_Model refetchForm={brandRefetch} open={brandOpen} setOpen={setBrandOpen} setSubmitted={setSubmittedBrand} />
            <HSN_Model refetchForm={HSNRefetch} open={openHSN} setOpen={setOpenHSN} setSubmitted={setSubmittedHSN} />
            <UOM_Model refetchForm={UomRefetch} open={openUOM} setOpen={setOpenUOM} setSubmitted={setSubmittedUOM} />
            <UOM_Model refetchForm={UomRefetch} open={openProductUOM} setOpen={setOpenProductUOM} setSubmitted={setSubmittedProductUOM} />



            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />

        </div>
    )
}

export default Add_Panni


