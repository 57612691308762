import { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { useCreate } from '../../hooks/Api';
import { Alert } from "../Model/Model";
import { useForm } from 'react-hook-form';
import useFocusOnEnter from '../focus';
import { useNavigate } from 'react-router-dom';
import { useFetchProject } from '../../hooks/DropDown_Api';
import IndicatorsContainer from '../Select Dropdown/Select'
import IndicatorsContainerCreate from '../Select Dropdown/Creatabel'
import ReactSelect from '../HRMS Components/Select/select';
import InputField from '../HRMS Components/Inpute/input';
import { RiPencilLine } from 'react-icons/ri';
const ntc = require('@yatiac/name-that-color');
const toHex = require('colornames')

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});


export const Task_Status_Model = ({ refetchForm, open, setOpen, id }: any) => {
    let pageName = "Task Status"
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_HRMS_TASK_STATUS_API?.toString();

    const { mutate: TaskStatus, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

    useEffect(() => {
        if (isSuccess) {
            setOpen(false)
            reset()
        }
        refetchForm()
    }, [isSuccess, data])

    const handleReset = () => {
        reset()
    }

    const onSubmit = (branddetail: any) => {

        const formData = new FormData();

        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value

            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            formData.append('project', id)
        }
        TaskStatus(formData);

    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    return (
        <>
            <Modal

                show={open} onHide={() => {
                    setShow(false)
                    reset()
                    setOpen(false)
                }} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '16px' }}>{pageName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form
                        ref={focus}
                        onKeyUp={event => onEnterKey(event)}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className="card-body" style={{ maxHeight: "80vh" }}>
                            {show && showmessage === "success" ? <Alert color={showmessage} message={"Successfully Added"} /> : ""}
                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-5 mt-2">
                                            <label>Task Status Name <span style={{ color: "red" }}>*</span></label>
                                            <div className="input-group">
                                                <input className="form-control" type="text" placeholder="Task Status Name"
                                                    {...register("name", { onChange: handleUpper, required: { value: true, message: "Task Status Name is required" } })} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text border-start-0 bg-primary">
                                                        <RiPencilLine
                                                            color="white"
                                                        // style={{backgroundColor:"blue",color:"green"}}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <span className="text-red w-100">
                                                {errors?.Name?.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="info" className="text-white"
                        onClick={handleSubmit(onSubmit)}
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                    >
                        Submit
                    </Button>
                    <Button variant="info" className="text-white" style={{ borderColor: "blue", background: "blue", color: "white" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button variant="secondary" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpen(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}



