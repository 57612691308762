// import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../../components/Cell/Status";
import { useFetch, useUpdateSingle } from "../../../hooks/Api";
import noImage from "../../../images/noImage.png"
import Modal from "react-bootstrap/esm/Modal";



interface Data {
  selection: any



  code: any
  lead_generated_by_sales_executive_code_and_name: any
  lead_source_of_inquiry: any
  requirement_status: any
  company_new_old: any
  company_name_and_city: any
  contact_person: any
  name_of_customer: any
  mobile_number: any
  email: any
  pincode: any
  address: any
  village_code_and_name: any
  reference_name: any
  lead_contacted: any
  country: any
  state: any
  district: any
  taluka: any

  quotation: any






  created_at: any

  created_by: any
  updated_by: any
  updated_at: any


  status: any;
  actions: any;
}



export const titleofheaders = [
  {
    id: `srno`,
    Header: `Sr No`,
  },
  {
    id: 'code',
    Header: 'Sales Inquiry Code',

  },
  {
    id: 'lead_generated_by_sales_executive_code_and_name',
    Header: 'Lead Generated Name',


  },
  {
    id: 'lead_source_of_inquiry',
    Header: 'Lead Source Of Enquiry',


  },
  {
    id: 'requirement_status',
    Header: 'Requirment Status',

  },
  {
    id: 'company_new_old',
    Header: 'Company New-Old',

  },
  {
    id: 'company_name_and_city',
    Header: 'Company Name ',

  },
  {
    id: 'contact_person',
    Header: 'Contact Person Name',

  },
  {
    id: 'name_of_customer',
    Header: 'Name Of Customer',

  },
  {
    id: 'mobile_number',
    Header: 'Mobile Number',

  },
  {
    id: 'email',
    Header: 'E-Mail ID',

  },
  {
    id: 'address',
    Header: 'Address',

  },
  {
    id: 'village_code_and_name',
    Header: 'Village Code & Name',

  },
  {
    id: 'taluka',
    Header: 'Taluka Name',

  },
  {
    id: 'district',
    Header: 'District Name',

  },
  {
    id: 'state',
    Header: 'State Name',

  },
  {
    id: 'country',
    Header: 'Country Name',

  },
  {
    id: 'pincode',
    Header: 'Pin Code Number',


  },
  {
    id: 'reference_name',
    Header: 'Reference Name',

  },
  {
    id: 'lead_contacted',
    Header: 'Lead Contacted Date & Time',


  },

  {
    id: `visiting_card_image`,
    Header: "Visiting Card Image",

  },
  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },
  {
    id: 'status',
    Header: 'Status',
  },
]




export const Columns_Sales: any = [




  {
    id: `srno`,
    Header: () => <div style={{ display: "inline" }}>Sr.<br />No</div>,
    sticky: "left",
  },

  {
    id: 'code',
    Header: () => <div style={{ display: "inline" }}>Sales Inquiry<br />Code</div>,
    // Header: 'Sales Inquiry Code',
    sticky: "left",
    accessor: 'code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.code}
        </div>
      )
    }

  },
  {
    id: 'date',
    Header: () => <div style={{ display: "inline" }}>Date</div>,
    accessor: 'date',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(" ")[0].split("T").join("  ") : "-")
  },
  {
    id: 'company_new_old',
    Header: () => <div style={{ display: "inline" }}>Customer<br />New-Old</div>,
    // Header: 'Company New-Old',
    accessor: 'company_new_old',
    Cell: ({ row }: any) => {
      const convertToUpper = row.original.company_new_old
      if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
  {
    id: 'company_name_and_city',
    Header: () => <div style={{ display: "inline" }}>Customer<br />Name & City</div>,
    // Header: 'Company Name & City',
    accessor: 'company_name_and_city',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.customer_name} {row.original.extra_response?.company_name_and_city?.city[0]?.city_name?row.original.extra_response?.company_name_and_city?.city[0]?.city_name:row.original.extra_response?.company_name_and_city?.city[0]?.village_name}
        </div>
      )
    }

  },
  {
    id: 'lead_contacted',
    Header: () => <div style={{ display: "inline" }}>Requirment<br />Date</div>,
    // Header: 'Lead Contacted Date & Time',
    accessor: 'lead_contacted',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.lead_contacted}
        </div>
      )
    }

  },
  {
    id: 'requirement_status',
    Header: () => <div style={{ display: "inline" }}>Requirment<br />Status</div>,
    // Header: 'Requirment Status',
    accessor: 'requirement_status',

    Cell: ({ row }: any) => {
      const convertToUpper = row.original.requirement_status
      if (!convertToUpper) return "";
      // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
      return convertToUpper.split("_").map((w: any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
    }

  },
 
  {
    id: 'lead_source_of_inquiry',
    Header: () => <div style={{ display: "inline" }}>Lead Source<br />Of Enquiry</div>,
    // Header: 'Lead Source Of Enquiry',
    accessor: 'lead_source_of_inquiry',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.lead_source_of_inquiry?.name}
        </div>
      )
    }

  },
  // {
  //   id: 'lead_generated_by_sales_executive_code_and_name',
  //   Header: () => <div style={{ display: "inline" }}>Lead Generated by Sales<br />Executive Code & Name</div>,
  //   // Header: 'Lead Generated by Sales Executive Code & Name',
  //   accessor: 'lead_generated_by_sales_executive_code_and_name',
  //   Cell: ({ row }: any) => {
  //     return (
  //       <div>
  //         {`${row.original.lead_generated_by_sales_executive_code_and_name?.code}::${row.original.lead_generated_by_sales_executive_code_and_name?.name}`}
  //       </div>
  //     )
  //   }

  // },

  //   {
  //     id: 'length',
  //     Header: 'Product Code & Name & Type',
  //     accessor:'length',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.length}
  //         </div>
  //       )
  // }

  //   },
  //   {
  //     id: 'width',
  //     Header: 'Product Type',
  //     accessor:'width',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.width}
  //         </div>
  //       )
  // }

  //   },
  //   {
  //     id: 'micron',
  //     Header: 'Product Description',
  //     accessor:'micron',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.micron}
  //         </div>
  //       )
  // }

  //   },
  //   {
  //     id: 'gauge',
  //     Header: 'Product Special - Running',
  //     accessor:'gauge',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.gauge}
  //         </div>
  //       )
  // }

  //   },

  //   {
  //     id: 'panni_1_nos_weight_gram',
  //     Header: 'Product Total Qty.',
  //     accessor:'panni_1_nos_weight_gram',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.panni_1_nos_weight_gram}
  //         </div>
  //       )
  // }
  //   },
  //   {
  //     id: 'panni_1KG_in_nos',
  //     Header: 'Requirment Date',
  //     accessor:'panni_1KG_in_nos',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.panni_1KG_in_nos}
  //         </div>
  //       )
  // }

  //   },
  //   {
  //     id: 'material_use',
  //     Header: 'Expected Basic Value',
  //     accessor:'material_use',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.material_use}
  //         </div>
  //       )
  // }

  //   },
  //   {
  //     id: 'bursting_strength',
  //     Header: 'Possible Yes-No',
  //     accessor:'bursting_strength',
  //     Cell: ({ row }: any) => {
  //       return(
  //         <div>
  //           {row.original.bursting_strength}
  //         </div>
  //       )
  // }

  // },
  // {
  //   id: 'contact_person',
  //   Header: () => <div style={{ display: "inline" }}>Contact Person<br />Name</div>,
  //   // Header: 'Contact Person Name',
  //   accessor: 'contact_person',
  //   Cell: ({ row }: any) => {
  //     return (
  //       <div>
  //         {row.original.contact_person}
  //       </div>
  //     )
  //   }

  // },
  // {
  //   id: 'name_of_customer',
  //   Header: () => <div style={{ display: "inline" }}>Name Of<br />Owner</div>,
  //   // Header: 'Name Of Customer',
  //   accessor: 'name_of_customer',
  //   Cell: ({ row }: any) => {


  //     return (
  //       <div>
  //         {row.original.extra_response?.company_name_and_city?.name}
  //       </div>
  //     )
  //   }

  // },
  {
    id: 'customer_grade',
    Header: () => <div style={{ display: "inline" }}>Customer<br />Grade</div>,
    // Header: 'Country Name',
    accessor: 'customer_grade',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.customer_grade?row.original.extra_response?.company_name_and_city?.customer_grade:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.city_and_village.country?.name} */}
        </div>
      )
    }


  },
  {
    id: 'country_code',
    Header: () => <div style={{ display: "inline" }}>Country<br />Code</div>,
    // Header: 'Country Name',
    accessor: 'country_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.country_code_mobile[0]?row.original.extra_response?.company_name_and_city?.country_code_mobile[0]:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.city_and_village.country?.name} */}
        </div>
      )
    }


  },
  {
    id: 'mobile_number',
    Header: () => <div style={{ display: "inline" }}>Mobile<br />Number</div>,
    // Header: 'Mobile Number',
    accessor: 'mobile_number',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.contact_detail_number}
          {/* {row.original.mobile_number} */}
        </div>
      )
    }

  },
  {
    id: 'email',
    Header: () => <div style={{ display: "inline" }}>E-Mail<br />Address</div>,
    // Header: 'E-Mail ID',
    accessor: 'email',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.email_address}
          {/* {row.original.email} */}
        </div>
      )
    }

  },
  {
    id: 'reference_name',
    Header: () => <div style={{ display: "inline" }}>Reference By<br />Name</div>,
    // Header: 'Reference Name',
    accessor: 'reference_name',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.reference_name?row.original.extra_response?.company_name_and_city?.reference_name:row.original.extra_response?.company_name_and_city?.other_reference===null? <span style={{ color: 'red',fontSize:"20px" }}>****</span>:row.original.extra_response?.company_name_and_city?.reference_name?row.original.extra_response?.company_name_and_city?.reference_name:row.original.extra_response?.company_name_and_city?.other_reference}
     

        </div>
      )
    }

  },
  {
    id: 'village_code_and_name',
    Header: () => <div style={{ display: "inline" }}>City<br />Name</div>,
    // Header: 'Village Code & Name',
    accessor: 'village_code_and_name',


    Cell: ({ row }: any) => {

      return (
        <div>
          {/* {row.original.company_new_old === "old"?row.original.company_name_and_city.address[0]?.city_and_village?.city_name:row.original.company_name_and_city.address[0]?.city_and_village?.city_name} */}
          {row.original.extra_response?.company_name_and_city?.city[0]?.city_code} {row.original.extra_response?.company_name_and_city?.city[0]?.city_name?row.original.extra_response?.company_name_and_city?.city[0]?.city_name:row.original.extra_response?.company_name_and_city?.city[0]?.village_name}
        </div>
      )
    }


  },
  {
    id: 'taluka',
    Header: () => <div style={{ display: "inline" }}>Taluka<br />Name</div>,
    // Header: 'Taluka Name',
    accessor: 'taluka',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.city[0]?.taluka?row.original.extra_response?.company_name_and_city?.city[0]?.taluka:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}
        </div>
      )
    }

  },
  {
    id: 'district',
    Header: () => <div style={{ display: "inline" }}>District<br />Name</div>,
    // Header: 'District Name',
    accessor: 'district',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.city[0]?.district?row.original.extra_response?.company_name_and_city?.city[0]?.district:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.city?.district?.name} */}
        </div>
      )
    }

  },
  {
    id: 'state',
    Header: () => <div style={{ display: "inline" }}>State<br />Name</div>,
    // Header: 'State Name',
    accessor: 'state',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.city[0]?.state?row.original.extra_response?.company_name_and_city?.city[0]?.state:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.city_and_village.state?.name} */}
        </div>
      )
    }

  },
  {
    id: 'country',
    Header: () => <div style={{ display: "inline" }}>Country<br />Name</div>,
    // Header: 'Country Name',
    accessor: 'country',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.city[0]?.country?row.original.extra_response?.company_name_and_city?.city[0]?.country:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.city_and_village.country?.name} */}
        </div>
      )
    }


  },
  {
    id: 'pincode',
    Header: () => <div style={{ display: "inline" }}>Pin Code<br />Number</div>,
    // Header: 'Pin Code Number',
    accessor: 'pincode',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.extra_response?.company_name_and_city?.city[0]?.pincode[0]?row.original.extra_response?.company_name_and_city?.city[0]?.pincode[0]:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },
  
  {
    id: 'remark',
    Header: () => <div style={{ display: "inline" }}>Re-mark</div>,
    // Header: 'Pin Code Number',
    accessor: 'remark',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.remark?row.original.remark:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },

  // {
  //   id: 'status',
  //   Header: 'Status',
  //   accessor: 'status',


  // },

  {
    id: 'lead_code',
    Header: () => <div style={{ display: "inline" }}>Lead Generated<br/>Code</div>,
    // Header: 'Pin Code Number',
    accessor: 'lead_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.lead_code?row.original.lead_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },
  {
    id: 'quotation_code',
    Header: () => <div style={{ display: "inline" }}>Quotation<br/>Code</div>,
    // Header: 'Pin Code Number',
    accessor: 'quotation_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.quotation_code?row.original.quotation_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },
  {
    id: 'order_code',
    Header: () => <div style={{ display: "inline" }}>Order<br/>Code</div>,
    // Header: 'Pin Code Number',
    accessor: 'order_code',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.order_code?row.original.order_code:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },
  {
    id: 'code_one',
    Header: () => <div style={{ display: "inline" }}>Code</div>,
    // Header: 'Pin Code Number',
    accessor: 'code_one',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.code_one?row.original.code_one:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },
  {
    id: 'code_two',
    Header: () => <div style={{ display: "inline" }}>Code</div>,
    // Header: 'Pin Code Number',
    accessor: 'code_two',
    Cell: ({ row }: any) => {
      return (
        <div>
          {row.original.code_two?row.original.code_two:<span style={{ color: 'red',fontSize:"20px" }}>****</span>}

          {/* {row.original.company_name_and_city?.address[0]?.pincode?.pincode} */}
        </div>
      )
    }


  },

 
  // {
  //   id: `product_type`,
  //   Header: () => <div style={{display:"inline"}}>Product<br/>Type</div>,
  //   accessor:'product_type',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.product_type?.type
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.join(",")

  // }
  // },
  // {
  //   id: `product_special_running`,
  //   Header: () => <div style={{display:"inline"}}>Special<br/>Running</div>,
  //   accessor:'product_special_running',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.product_special_running
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(",")
  //     // return productType?.split("_")?.map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase())?.join(" ")

  // }
  // },
  // {
  //   id: `product_code_name_type`,
  //   Header: () => <div style={{display:"inline"}}>Code/Name<br/>& Type</div>,
  //   accessor:'product_code_name_type',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.product_code_name_type?.product_code
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.join(",")

  // }
  // },
  // {
  //   id: `product_description`,
  //   Header: () => <div style={{display:"inline"}}>Description</div>,
  //   accessor:'product_description',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.product_description
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.join(",")

  // }
  // },
  // {
  //   id: `product_qty`,
  //   Header: () => <div style={{display:"inline"}}>Qty</div>,
  //   accessor:'product_qty',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.product_qty
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.join(",")

  // }
  // },
  // {
  //   id: `requirement_date`,
  //   Header: () => <div style={{display:"inline"}}>	Requirement<br/>Date</div>,
  //   accessor:'requirement_date',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.requirement_date
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.join(",")

  // }
  // },
  // {
  //   id: `expected_basic_value`,
  //   Header: () => <div style={{display:"inline"}}>Expected Basic<br/>Value</div>,
  //   accessor:'expected_basic_value',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.expected_basic_value
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.join(",")

  // }
  // },
  // {
  //   id: `possible_yes_no`,
  //   Header: () => <div style={{display:"inline"}}>Possible<br/>Yes/No</div>,
  //   accessor:'possible_yes_no',
  //   Cell: ({ row }: any) => {
  //     let productType = row.original?.product?.map((e:any)=>{
  //       return e?.possible_yes_no
  //     })
  //     if (productType?.length === 0) return "";
  //     // <span style={{ color: 'red',fontSize:"20px" }}>****</span>;
  //     return productType?.map((w:any) => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(",")

  // }
  // },






  {
    id: `visiting_card_image`,
    Header: () => <div style={{ display: "inline" }}>Visiting Card<br />Image</div>,

    // accessor: `logo`,
    Cell: ({ row }: any) => {

      const [deleteConfirm, setDeleteConfirm] = useState(false)
      return (
        <>

          <div >

            {!row.original.visiting_card_image ? <>
              <a

                href="#"

              // data-large-src={row.original.logo?row.original.logo:noImage} data-toggle="modal"
              >
                <img src={noImage} height={60} width={100} />
              </a>
            </> : <>
              <a
                onClick={() => setDeleteConfirm(true)}
                href={`#galleryModal${row.original.name}`}

                data-large-src={row.original.visiting_card_image ? row.original.visiting_card_image : noImage} data-toggle="modal">
                <img src={row.original.visiting_card_image ? row.original.visiting_card_image : noImage} height={60} width={100} />
              </a>
            </>}

          </div>



          <Modal show={deleteConfirm} onHide={() => setDeleteConfirm(false)} aria-labelledby="contained-modal-title-vcenter"
            centered    >
            <Modal.Header >
              <Modal.Title>

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img src={row.original.visiting_card_image ? row.original.visiting_card_image : noImage} id={`galleryImage${row.original.name}`} width={500} height={500} className="loaded-image mx-auto img-fluid" />

            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeleteConfirm(false)}>OK</button>

            </Modal.Footer>
          </Modal>
        </>)
    }


  },

  {
    id: 'lead_Connected_date',
    Header: () => <div style={{ display: "inline" }}>Lead Contacted<br />& Code & Date & Time</div>,
    accessor: 'lead_Connected_date',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(" ")[0].split("T").join("  ") : "-")
  },

  // {
  //   id: 'quotation',
  //   Header: 'Quotation',

  // },

 











  {
    id: 'created_at',
    Header: () => <div style={{ display: "inline" }}>Created<br />At</div>,
    accessor: 'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div style={{ display: "inline" }}>Created<br />By</div>,
    accessor: 'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username


  },
  {
    id: 'updated_at',
    Header: () => <div style={{ display: "inline" }}>Updated<br />At</div>,
    accessor: 'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div style={{ display: "inline" }}>Updated<br />By</div>,
    accessor: 'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },


  {
    id: 'Actions',
    Header: 'Actions',


  },
  {
    id: 'Selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_Sales, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows } = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SALES_INQUIRY_API}/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]




