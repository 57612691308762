import { useEffect, useRef, useState } from "react";
import "../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import useFocusOnEnter from "../../components/focus";
import { Deletesinglewithdependancy } from "../../components/modal";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFetchCategoryDropDown, useFetchInquiryProductTypeDropDown } from "../../hooks/DropDown_Api";
import CreatableSelect from "react-select/creatable";
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";


interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Edit_ProductType = () => {
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const { id } = useParams()
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_PRODUCT_TYPE_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    const [openEditModel, setOpenEditModel] = useState(false)
    const [expirationDate, setExpirationDate] = useState()
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const { mutate, isSuccess, isError, error, data: gstUpdated_response }: any = useUpdateSingle()
    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()
    const [optionsCategory, setOptionsCategory]: any = useState([]);
    const [selCategory, setSelCategory]: any = useState('');
    const [selectedCategory, setSelectedCategory]: any = useState('');
    const [selectedInquiryProductType, setSelectedInquiryProductType]: any = useState('');

    const { register, handleSubmit, setError, formState: { errors, isDirty }, reset, setValue, getValues }: any = useForm();


    // const { data: machineNameData, refetch: machineNameRefetch } = useFetchCategoryDropDown()
    // useEffect(() => {
    //     setOptionsCategory(machineNameData)
    // }, [machineNameData])






    useEffect(() => {
        if (data) {
            // setValue("product_type_code", data.product_type_code)
            reset(data)
            setSelectedCategory(data.product_categories?.id)
            setValue("product_categories", data.product_categories?.id)
            setSelectedInquiryProductType(data.inquiry_product_type?.id)
            setValue("inquiry_product_type", data.inquiry_product_type?.id)

        }
    }, [data]);




    const onSubmit = (materialtypeDetails: any) => {


        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value
            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData
        }
        mutate(data);
        setValuesofform(data)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")


    useEffect(() => {
        if (isSuccess || yessuccess || nosuccess) {
            setShow(true)
            setValuesofform(null)
            setshowmessage("success")
            navigate('/listProducttype', { state: { showEditToast: true } })


        }

    }, [gstUpdated_response, data, isSuccess, yessuccess,
        nosuccess])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);
    useEffect(() => {
        if (isError) {
            if (error.response.status === 405) {
                setConfirmmodal(true)
            }
        }
        else if (isError || yeserror || noerror) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isError, yeserror, noerror])

    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {


        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }



    const [valueCategory, setValueCategory]: any = useState();
    const [valueCategoryCreatable, setValueCategoryCreatable] = useState<Option | null>();

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const apinameForCategory = process.env.REACT_APP_PRODUCT_CATEGORY_API?.toString()
    const { mutate: mutateCategory, data: dataCategory, isError: CategoryIsError, isLoading: CategoryLoading, isSuccess: CategorySuccess, error: CategoryError }: any = useCreate(apinameForCategory)


    const { data: CategoryData, refetch: CategoryRefetch } = useFetchCategoryDropDown()

    useEffect(() => {
        setOptionsCategory(CategoryData)
    }, [CategoryData])

    const handleChangeCategory = (e: any) => {
        
        CategoryRefetch()

        setApiName(apinameForCategory)
        setDeleteApiName(apinameForCategory)
        setEditId(e?.value)
        setEditData(CategoryData)
        setRenderKey(prevKey => prevKey + 1);

        setValueCategory(e)

        setValue("product_categories", e?.value)
        setSelCategory(e)
    }

    const handleCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            setIsLoadingValue(false);


            mutateCategory({ name: newOption.name, status: newOption.status })
            setOptionsCategory((prev: any) => [...prev, newOption]);
            setValueCategoryCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (CategorySuccess && optionsCategory && valueCategoryCreatable != null) {
                await CategoryRefetch();

                setValue("product_categories", CategoryData[0].value)
            }
        })();

        return () => {
        };
    }, [CategorySuccess, CategoryData]);
    
    const [valueInquiryProductType, setValueInquiryProductType]:any = useState();
    const [optionsInquiryProductType, setOptionsInquiryProductType]: any = useState([]);
    const [valueInquiryProductTypeCreatable, setValueInquiryProductTypeCreatable] = useState<Option | null>();
   
    const apinameForInquiryProductType = process.env.REACT_APP_INQUIRY_PRODUCT_TYPE_API?.toString()
    const { mutate: mutateInquiryProductType, data: dataInquiryProductType, isError: InquiryProductTypeIsError, isLoading: InquiryProductTypeLoading, isSuccess: InquiryProductTypeSuccess, error: InquiryProductTypeError }: any = useCreate(apinameForInquiryProductType)


    const { data: InquiryProductTypeData, refetch: InquiryProductTypeRefetch } = useFetchInquiryProductTypeDropDown()

    
    useEffect(() => {
        setOptionsInquiryProductType(InquiryProductTypeData)
    }, [InquiryProductTypeData])

    const handleChangeInquiryProductType = (e: any) => {

        InquiryProductTypeRefetch()

        setApiName(apinameForInquiryProductType)
        setDeleteApiName(apinameForInquiryProductType)
        setEditId(e?.value)
        setEditData(InquiryProductTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueInquiryProductType(e)

        setValue("inquiry_product_type", `${e?.value}`)
        setValueInquiryProductTypeCreatable(null)
    }

    const handleCreateInquiryProductType = (inputValue: string) => {
        
        inputValue = inputValue.toLowerCase()
        .split(' ')
        .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
        const newOption = createOption(inputValue);
        setIsLoadingValue(false);
       
        
        mutateInquiryProductType({name:newOption.name,status:newOption.status})
        setOptionsInquiryProductType((prev:any) => [...prev, newOption]);
        setValueInquiryProductTypeCreatable(newOption);
      }, 0);

   
    };


    useEffect(() => {
        (async () => {
            if(InquiryProductTypeSuccess && optionsInquiryProductType){
            await InquiryProductTypeRefetch();
            setValue("inquiry_product_type",InquiryProductTypeData[0].value)
            }
        })();
        
        return () => {
        };
      }, [InquiryProductTypeSuccess,InquiryProductTypeData]);


    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {
        if (apiName === apinameForCategory) {
            CategoryRefetch()
        }
     
        
        // queryClient.invalidateQueries(['Panni Material Use']);



       
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueCategory && apiName === apinameForCategory) {
            setValueCategory(null)
            setValueCategoryCreatable(null)
        }

      






        if (deletesucess && apiName === apinameForCategory) {
            setValueCategory(null)
            setValueCategoryCreatable(null)
        }

       


    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };



    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Edit Product Type</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Product Details</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listProducttype" className="text-decoration-none text-black">Product Type</Link></li>
                                    <li className="breadcrumb-item">Edit Product Type</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Edit Capacity</span></h5>
                            </div> */}
                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" >
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                



                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Type <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Type" {...register("type", { onChange: handleValue, required: { value: true, message: "Type is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Product Category <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >



                                                        <CreatableSelect
                                                            isClearable={() => {
                                                                setValueCategory(null)
                                                                setValue(null)
                                                            }}
                                                            {...register("product_categories", { required: { value: true, message: "Product Category is required" } })}
                                                            placeholder={'Select Product Category'}
                                                            components={ customCreatableIndicator }
                                                            onMenuOpen={()=>CategoryRefetch()}
                                                            // menuIsOpen={true}
                                                            options={optionsCategory}
                                                            onCreateOption={handleCreate}
                                                            onChange={handleChangeCategory}
                                                            // value={valueCategoryCreatable==null?valueCategory:valueCategoryCreatable}
                                                            value={valueCategoryCreatable == null ? valueCategory == null ? optionsCategory?.filter((obj: any) => obj.value === selectedCategory) : valueCategory : valueCategoryCreatable}


                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.product_categories?.message}</span>

                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Inquiry Product Type <span style={{color:'red'}}>*</span></label>
                                                    
                                                    
                                                     <CreatableSelect
                                                    isClearable={()=>{setValueInquiryProductType(null)
                                                        setValue(null)}}
                                                        // classNames="creatable-select"
                                                        {...register("inquiry_product_type", { required: { value: true, message: "Inquiry Product Type is required" } })}
                                                        placeholder={'Select Inquiry Product Type'}
                                                        components={ customCreatableIndicator }
                                                        // menuIsOpen={true}
                                                        options={optionsInquiryProductType}
                                                        onCreateOption={handleCreateInquiryProductType}
                                                        onChange={handleChangeInquiryProductType}
                                                        // value={valueInquiryProductTypeCreatable==null?valueInquiryProductType:valueInquiryProductTypeCreatable}
                                                        value={valueInquiryProductTypeCreatable == null ? valueInquiryProductType == null ? optionsInquiryProductType?.filter((obj: any) => obj.value === selectedInquiryProductType) : valueInquiryProductType : valueInquiryProductTypeCreatable}
                                                        onMenuOpen={()=>InquiryProductTypeRefetch()}

                                                    />
                                                    <span className='text-red w-100'>{errors?.inquiry_product_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select"
                                                        {...register("status", {
                                                            required: { value: true, message: "Status is required" }, maxLength: 30,

                                                        })}
                                                    >
                                                        <option value="" disabled selected style={{ fontSize: "20px", padding: "5px", background: "rgb(170, 170, 170)", color: "white" }}>-Status-</option>
                                                        <option value="active" style={{ fontSize: "20px", padding: "5px" }}>Active</option>
                                                        <option value="inactive" style={{ fontSize: "20px", padding: "5px" }}>Inactive</option>
                                                    </select>



                                                    <span className='text-red'>{errors?.status?.message}</span>




                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Product Type Code</label>
                                                    <div className="input-group" >
                                                        <input type="text" className="form-control"
                                                            disabled
                                                            {...register("product_type_code")} />
                                                        {/* <div className="input-group-append">
                                                            <span className="input-group-text bg-white border-start-0"><RiPencilLine color='green' /></span>
                                                        </div> */}
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}
                                                    // disabled={!isDirty}
                                                    >Submit</button>
                                                    <button type="button" className="border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listProducttype")}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>

 {/* Edit Modal HTML */}
 <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />

                <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered    >
                    <Modal.Header closeButton>
                        <Modal.Title>Select a Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>





                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-12 mt-2">
                                    <label htmlFor="exampleInputEmail1">Choose Date</label>
                                    <input type="date" className="form-control" id="exampleInputBrand"

                                        {...register("expiration_date", { onChange: handleExpirationDate })}
                                    />
                                </div>
                            </div>
                        </div>



                    </Modal.Body>
                    <Modal.Footer>



                        <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>


                        <button
                            type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                            onClick={handleSubmit(handleConfirm)}
                        >Confirm</button>

                    </Modal.Footer>
                </Modal>
                <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                    yesupdatemodal={yesupdatemodal}
                    cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />
            </div>
        </div>
    )
}

export default Edit_ProductType


