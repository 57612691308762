// import { Button, DatePicker, Input, Table } from "antd";
// import dayjs from "dayjs";
// import React,{ useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useFetch } from "../../../hooks/Api";
// import { useForm } from "react-hook-form";
// import { ColumnsType } from 'antd/lib/table';
// import { useDispatch, useSelector } from "react-redux";
// import { updatePayslip } from "../../../redux/payrollSlice";
// // import {
// //   useAddPayrollMutation,
// //   useGetPayslipsQuery,
// // } from "../../redux/rtk/features/payroll/payrollApi";
// // import { updatePayslip } from "../../redux/rtk/features/payroll/payrollSlice";
// // import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
// // import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
// // import PageTitle from "../page-header/PageHeader";


// function CustomTable({ list, loading }: any) {

//     const dispatch = useDispatch();
//     const [columnsToShow, setColumnsToShow] = useState([]);


//     const handleInputChange = (id:any, key:any, event:any) => {
//         const value = event.target.value; // Extract value from the event
//         dispatch(updatePayslip({ id, value, key }));
//       };

//     const columnsTemplate: any = [
//         {
//             title: "ID",
//             dataIndex: "id",
//             key: "id",
//         },
//         {
//             title: "User",
//             key: "username",
//             render: (record: any) => `${record.username}`,
//         },

//         // {
//         //     title: "Salary",
//         //     dataIndex: "salary",
//         //     key: "salary",
//         //     render: (record: any) => 0,

//         // },

//         {
//             title: "Salary Payable",
//             dataIndex: "salaryPayable",
//             key: "salaryPayable",
//             render: (salaryPayable: any, { id }: any) => {
//                 console.log(salaryPayable)
//                 return (
//                     <div>
//                         <Input
//                             placeholder='Salary Payable'
//                             type="number"
//                             size={"small"}
//                             style={{ width: "150px", fontSize: "15px" }}
//                             // onChange={(value) => {
//                             //     dispatch(updatePayslip({ id, value, key: "salaryPayable" }));
//                             // }}
//                             onChange={(e) => handleInputChange(6, 'salaryPayable', e)}
//                             value={salaryPayable}
//                             min={0}
//                         // defaultValue={salaryPayable}
//                         />{" "}
//                     </div>
//                 );
//             },
//         },

//         {
//             title: "Bonus",
//             dataIndex: "bonus",
//             key: "bonus",
//             render: (bonus: any, { id }: any) => {
//                 return (
//                     <div>
//                         <Input
//                             placeholder='Bonus'
//                             type="number"
//                             size={"small"}
//                             style={{ width: "100px", fontSize: "15px" }}
//                             onChange={(value) => {
//                                 // dispatch(updatePayslip({ id, value, key: "bonus" }));
//                             }}
//                             value={bonus}
//                             min={0}
//                         // defaultValue={bonus}
//                         />{" "}
//                     </div>
//                 );
//             },
//         },

//         {
//             title: "B-Comment",
//             dataIndex: "bonusComment",
//             key: "bonusComment",
//             render: (bonusComment: any, { id }: any) => {
//                 return (
//                     <div>
//                         <Input
//                             placeholder='comment'
//                             size={"small"}
//                             style={{ width: "100px", fontSize: "15px" }}
//                             onChange={(e) => {
//                                 // dispatch(
//                                 //   updatePayslip({
//                                 //     id,
//                                 //     value: e.target.value,
//                                 //     key: "bonusComment",
//                                 //   })
//                                 // );
//                             }}
//                             value={bonusComment}

//                         // defaultValue={deductionComment}
//                         />{" "}
//                     </div>
//                 );
//             },
//         },

//         {
//             title: "Deduction",
//             dataIndex: "deduction",
//             key: "deduction",
//             render: (deduction: any, { id }: any) => {
//                 return (
//                     <div>
//                         <Input
//                             placeholder='Deduction'
//                             type="number"
//                             size={"small"}
//                             style={{ width: "100px", fontSize: "15px" }}
//                             onChange={(value) => {
//                                 // dispatch(updatePayslip({ id, value, key: "deduction" }));
//                             }}
//                             value={deduction}
//                             min={0}
//                         // defaultValue={deduction}
//                         />{" "}
//                     </div>
//                 );
//             },
//         },

//         {
//             title: "D-Comment",
//             dataIndex: "deductionComment",
//             key: "deductionComment",
//             render: (deductionComment: any, { id }: any) => {
//                 return (
//                     <div>
//                         <Input
//                             placeholder='comment'
//                             size={"small"}
//                             style={{ width: "100px", fontSize: "15px" }}
//                             onChange={(e) => {
//                                 // dispatch(
//                                 //   updatePayslip({
//                                 //     id,
//                                 //     value: e.target.value,
//                                 //     key: "deductionComment",
//                                 //   })
//                                 // );
//                             }}
//                             value={deductionComment}

//                         // defaultValue={deductionComment}
//                         />{" "}
//                     </div>
//                 );
//             },
//         },

//         {
//             title: "Working Hours",
//             dataIndex: "workingHour",
//             key: "workingHour",
//             render: (workingHour: any) => `${0?.toFixed(2)} hours`,
//         },

//         {
//             title: "Total Payable",
//             dataIndex: "totalPayable",
//             key: "totalPayable",
//             // render: () => `${0}`,

//         },
//     ];

//     // const columnsTemplate:  ColumnsType<RecordType> = [
//     //     {
//     //         title: "ID",
//     //         dataIndex: "id",
//     //         key: "id",
//     //     },
//     //     {
//     //         title: "User",
//     //         key: "username",
//     //         render: (text: string, record: RecordType) => `${record.username}`,
//     //     },
//     //     {
//     //         title: "Salary Payable",
//     //         dataIndex: "salaryPayable",
//     //         key: "salaryPayable",
//     //         render: (salaryPayable: number, {id}) => (
//     //             <Input
//     //                 placeholder='Salary Payable'
//     //                 type="number"
//     //                 size="small"
//     //                 style={{ width: "150px", fontSize: "15px" }}
//     //                 onChange={(value) => {
//     //                     dispatch(updatePayslip({ id, value, key: "salaryPayable" }));
//     //                 }}
//     //                 value={salaryPayable}
//     //                 min={0}
//     //             />
//     //         ),
//     //     },
//     //     {
//     //         title: "Bonus",
//     //         dataIndex: "bonus",
//     //         key: "bonus",
//     //         render: (bonus: number, record: RecordType) => (
//     //             <Input
//     //                 placeholder='Bonus'
//     //                 type="number"
//     //                 size="small"
//     //                 style={{ width: "100px", fontSize: "15px" }}
//     //                 onChange={(e) => {
//     //                     dispatch(updatePayslip({ id: record.id, value: e.target.value, key: "bonus" }));
//     //                 }}
//     //                 value={bonus}
//     //                 min={0}
//     //             />
//     //         ),
//     //     },
//     //     {
//     //         title: "B-Comment",
//     //         dataIndex: "bonusComment",
//     //         key: "bonusComment",
//     //         render: (bonusComment: string, record: RecordType) => (
//     //             <Input
//     //                 placeholder='comment'
//     //                 size="small"
//     //                 style={{ width: "100px", fontSize: "15px" }}
//     //                 onChange={(e) => {
//     //                     dispatch(updatePayslip({
//     //                         id: record.id,
//     //                         value: e.target.value,
//     //                         key: "bonusComment",
//     //                     }));
//     //                 }}
//     //                 value={bonusComment}
//     //             />
//     //         ),
//     //     },
//     //     {
//     //         title: "Deduction",
//     //         dataIndex: "deduction",
//     //         key: "deduction",
//     //         render: (deduction: number, record: RecordType) => (
//     //             <Input
//     //                 placeholder='Deduction'
//     //                 type="number"
//     //                 size="small"
//     //                 style={{ width: "100px", fontSize: "15px" }}
//     //                 onChange={(e) => {
//     //                     dispatch(updatePayslip({ id: record.id, value: e.target.value, key: "deduction" }));
//     //                 }}
//     //                 value={deduction}
//     //                 min={0}
//     //             />
//     //         ),
//     //     },
//     //     {
//     //         title: "D-Comment",
//     //         dataIndex: "deductionComment",
//     //         key: "deductionComment",
//     //         render: (deductionComment: string, record: RecordType) => (
//     //             <Input
//     //                 placeholder='comment'
//     //                 size="small"
//     //                 style={{ width: "100px", fontSize: "15px" }}
//     //                 onChange={(e) => {
//     //                     dispatch(updatePayslip({
//     //                         id: record.id,
//     //                         value: e.target.value,
//     //                         key: "deductionComment",
//     //                     }));
//     //                 }}
//     //                 value={deductionComment}
//     //             />
//     //         ),
//     //     },
//     //     {
//     //         title: "Working Hours",
//     //         dataIndex: "workingHour",
//     //         key: "workingHour",
//     //         render: (workingHour: number) => `${0?.toFixed(2)} hours`,
//     //     },
//     //     {
//     //         title: "Total Payable",
//     //         dataIndex: "totalPayable",
//     //         key: "totalPayable",
//     //     },
//     // ];

//     useEffect(() => {
//         setColumnsToShow(columnsTemplate);

//     }, []);


//     console.log(columnsToShow, "columnsToShow");


//     const addKeys = (arr: any) => arr.map((i: any) => ({ ...i, key: i.id }));

//     return (

//         <div className='mt-3'>

//             <Table
//                 scroll={{ x: true }}
//                 loading={loading}
//                 columns={columnsToShow}
//                 dataSource={list ? addKeys(list) : []}
//                 expandable={{
//                     expandedRowRender: (record: any) => <div>{
//                         <div className="d-flex " style={{ justifyContent: 'start' }}>
//                             <div className="  " style={{ marginRight: '50px' }} >
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">Paid Leave : </div>
//                                     {/* <div>{record.paidLeave ? record.paidLeave : 0}</div> */}
//                                 </div>
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">Unpaid Leave : </div>
//                                     {/* <div className="ml-2">{record.unpaidLeave ? record.unpaidLeave : 0}</div> */}
//                                 </div>
//                             </div>
//                             <div className="  " style={{ marginRight: '50px' }}>
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">M-Holiday : </div>
//                                     {/* <div>{record.monthlyHoliday ? record.monthlyHoliday : 0}</div> */}
//                                 </div>
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">P-Holiday : </div>
//                                     {/* <div>{record.publicHoliday ? record.publicHoliday : 0}</div> */}
//                                 </div>
//                             </div>

//                             <div className="  " style={{ marginRight: '50px' }}>
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">Work Day : </div>
//                                     {/* <div className="ml-2">{record.workDay ? record.workDay : 0}</div> */}
//                                 </div>
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold"> Shift W.H : </div>
//                                     {/* <div>{record.shiftWiseWorkHour ? record.shiftWiseWorkHour : 0}</div> */}
//                                 </div>
//                             </div>
//                             <div className="  " style={{ marginRight: '50px' }} >
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">Month W.H : </div>
//                                     {/* <div className="ml-2">{record.monthlyWorkHour ? record.monthlyWorkHour : 0}</div> */}
//                                 </div>
//                                 <div className="d-flex " style={{ justifyContent: 'space-between' }}>
//                                     <div className="text-bold">H Salary : </div>
//                                     {/* <div className="ml-2">{record.hourlySalary ? record.hourlySalary : 0}</div> */}
//                                 </div>
//                             </div>
//                         </div>
//                     }</div>,
//                     // rowExpandable: (record) => record.name !== "Not Expandable",
//                 }}
//             />
//         </div>
//     );
// }

// const Calculate_Payroll: React.FC = () => {
//     let pageName = 'Calculate Payroll'
//     const [pageConfig, setPageConfig] = useState({
//         salary_month: dayjs().format("M"),
//         salary_year: dayjs().format("YYYY"),
//     });
//     const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

//     const apiname = `${process.env.REACT_APP_LEOUSER_API}`?.toString();
//     // const { mutate: Payslip, data, isSuccess }: any = useCreate(apiname);
//     const { data } = useFetch('', '', 1, 10, '', apiname)

//     console.log(data, "data");




//     //   const { isLoading } = useGetPayslipsQuery(pageConfig);
//     //   const [addPayslip, { isLoading: addLoading }] = useAddPayrollMutation();

//     const onMonthChange = (date: any, dateString: any) => {
//         setPageConfig((prev) => {
//             return { ...prev, month: dateString };
//         });
//     };

//     const onYearChange = (date: any, dateString: any) => {
//         setPageConfig((prev) => {
//             return { ...prev, year: dateString };
//         });
//     };

//     const [payslips, setPayslips] = useState([]);
// //   const list = useSelector((state:any) => state.payroll.list);

//     useEffect(() => {
//         console.log(data?.results,"data?.results");

//         setPayslips(data?.results);
//         // setPayslips(list);
//     }, [data]);

//     const navigate = useNavigate();

//     console.log(payslips, "payslips");
//     const OnSubmit = async () => {
//         const dataArray = payslips.map((i:any) => ({

//         userId: i.id,
//           salaryMonth: parseInt(pageConfig?.salary_month || dayjs().format("M")),
//           salaryYear: parseInt(pageConfig?.salary_year || dayjs().format("YYYY")),
//         //   salary: i.salary,
//         //   paidLeave: i.paidLeave,
//         //   unpaidLeave: i.unpaidLeave,
//         //   monthlyHoliday: i.monthlyHoliday,
//         //   publicHoliday: i.publicHoliday,
//         //   workDay: i.workDay,
//         //   shiftWiseWorkHour: i.shiftWiseWorkHour,
//         //   monthlyWorkHour: i.monthlyWorkHour,
//         //   hourlySalary: i.hourlySalary,
//           bonus: i.bonus,
//           bonusComment: i.bonusComment,
//           deduction: i.deduction,
//           deductionComment: i.deductionComment,
//           totalPayable: i.totalPayable,
//           workingHour: i.workingHour,
//           salaryPayable: i.salaryPayable,
//         }));
// console.log(dataArray,"dataArray");

//         try {
//             //   const resp = await addPayslip(dataArray);
//             //   if (resp) {
//             //     navigate("/admin/payroll/list");
//             //   }
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     return (
//         <div style={{ height: "97%" }}>
//             <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
//                 <section className="content-header py-1 px-3">
//                     <div className="container-fluid">
//                         <div className="row">
//                             <div className="col-sm-6">
//                             </div>
//                             <div className="col-sm-6">
//                                 <ol className="breadcrumb float-sm-right">
//                                     <li className="breadcrumb-item">
//                                         <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
//                                     </li>
//                                     <li className="breadcrumb-item">
//                                         <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
//                                     </li>
//                                     <li className="breadcrumb-item">
//                                         <Link to="/listHrmsTask" className="text-decoration-none text-black">{pageName}</Link>
//                                     </li>
//                                     <li className="breadcrumb-item">Add {pageName}</li>
//                                 </ol>
//                             </div>
//                         </div>
//                     </div>
//                 </section>

//                 <section className="content">
//                     <div className="container-fluid">
//                         <div className="card card-default" >
//                             <div className="card-body" >
//                                 <div className="row d-flex justify-content-md-center justify-content-lg-start">
//                                     <div className="col-md-12">
//                                         <div className="row">
//                                             <div className='d-flex justify-content-space-between gap-3' style={{ justifyContent: 'space-between' }}>
//                                                 <h4>{pageName}</h4>
//                                                 <div className="d-flex justify-content-end gap-3">

//                                                     <p className='text-base text-color-2 items-center'>

//                                                         Select Month :
//                                                     </p>
//                                                     <DatePicker
//                                                         format={"M"}
//                                                         style={{ maxWidth: "200px" }}
//                                                         picker='month'
//                                                         defaultValue={dayjs()}
//                                                         onChange={onMonthChange}
//                                                     />
//                                                     <p className='text-base text-color-2 items-center'>

//                                                         Select Year :
//                                                     </p>
//                                                     <DatePicker
//                                                         format={"YYYY"}
//                                                         picker='year'
//                                                         style={{ maxWidth: "200px" }}
//                                                         onChange={onYearChange}
//                                                         defaultValue={dayjs()}
//                                                     />
//                                                 </div>
//                                             </div>

//                                             <CustomTable list={payslips}
//                                             //   loading={isLoading} 
//                                             />


//                                             <div className='d-flex justify-content-end'>
//                                                 <Button
//                                                     //   loading={isLoading || addLoading}
//                                                     type='primary'
//                                                     size='large'
//                                                     htmlType='submit'
//                                                     onClick={OnSubmit}
//                                                     className='mt-5 text-end'
//                                                 >
//                                                     Generate Payslip
//                                                 </Button>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </div >
//         </div >
//     );
// };

// export default Calculate_Payroll;

import { Button, Card, DatePicker, Input, InputNumber, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useAddPayrollMutation,
  useGetPayslipsQuery,
} from "../../../redux/payrollApi";
import { updatePayslip } from "../../../redux/payrollSlice";
import { useCreate } from "../../../hooks/Api";
// import UserPrivateComponent from "../PrivateRoutes/UserPrivateComponent";
// import ColVisibilityDropdown from "../Shared/ColVisibilityDropdown";
// import PageTitle from "../page-header/PageHeader";

function CustomTable({ list, loading }) {
  const [columnsToShow, setColumnsToShow] = useState([]);
  const dispatch = useDispatch();


  const handleNumberChange = async (id, key, value) => {
    dispatch(updatePayslip({ id, value, key }));
  };

  const handleInputChange = async (id, key, event) => {
    const value = event.target.value;
    dispatch(updatePayslip({ id, value, key }));
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      key: "username",
      render: (record) => `${record.username}`,
    },

    // {
    //   title: "Salary",
    //   dataIndex: "salary",
    //   key: "salary",
    //   render: (record) => '0000',
    // },

    {
      title: "Salary Payable",
      dataIndex: "salaryPayable",
      key: "salaryPayable",
      render: (salaryPayable, { id }) => {
        return (
          <div>
            <InputNumber
            placeholder='Salary Payable'
              size={"small"}
              style={{ width: "150px", fontSize: "15px" }}
              onChange={(value) => {
                dispatch(updatePayslip({ id, value, key: "salaryPayable" }));
              }}

              // onChange={(value) => handleNumberChange(id, "salaryPayable", value)}
              value={salaryPayable}
              min={0}
            // defaultValue={salaryPayable}
            />{" "}
          </div>
        );
      },
    },

    {
      title: "Bonus",
      dataIndex: "bonus",
      key: "bonus",
      render: (bonus, { id }) => {
        return (
          <div>
            <InputNumber
            placeholder='Bonus'
              size={"small"}
              style={{ width: "100px", fontSize: "15px" }}
              onChange={(value) => {
                dispatch(updatePayslip({ id, value, key: "bonus" }));
              }}
              value={bonus}
              min={0}
            // defaultValue={bonus}
            />{" "}
          </div>
        );
      },
    },

    {
      title: "B-Comment",
      dataIndex: "bonusComment",
      key: "bonusComment",
      render: (bonusComment, { id }) => {
        return (
          <div>
            <Input
              placeholder='B-Comment'
              size={"small"}
              style={{ width: "100px", fontSize: "15px" }}
              // onChange={(e) => {
              //   dispatch(
              //     updatePayslip({
              //       id,
              //       value: e.target.value,
              //       key: "bonusComment",
              //     })
              //   );
              // }}
              onChange={(e) => handleInputChange(id, "bonusComment", e)}
              value={bonusComment}

            // defaultValue={deductionComment}
            />{" "}
          </div>
        );
      },
    },

    {
      title: "Deduction",
      dataIndex: "deduction",
      key: "deduction",
      render: (deduction, { id }) => {
        return (
          <div>
            <InputNumber
            placeholder='Deduction'
              size={"small"}
              style={{ width: "100px", fontSize: "15px" }}
              onChange={(value) => {
                dispatch(updatePayslip({ id, value, key: "deduction" }));
              }}
              value={deduction}
              min={0}
            // defaultValue={deduction}
            />{" "}
          </div>
        );
      },
    },

    {
      title: "D-Comment",
      dataIndex: "deductionComment",
      key: "deductionComment",
      render: (deductionComment, { id }) => {
        return (
          <div>
            <Input
              placeholder='D-Comment'
              size={"small"}
              style={{ width: "100px", fontSize: "15px" }}
              onChange={(e) => {
                dispatch(
                  updatePayslip({
                    id,
                    value: e.target.value,
                    key: "deductionComment",
                  })
                );
              }}
              value={deductionComment}

            // defaultValue={deductionComment}
            />
          </div>
        );
      },
    },   

    {
      title: "Working Hours",
      dataIndex: "workingHour",
      key: "workingHour",
      render: (workingHour) => `${0?.toFixed(2)} hours`,
    },

    {
      title: "Total Payable",
      dataIndex: "totalPayable",
      key: "totalPayable",
      // render: (record) => '0000',
    },
  ];

  useEffect(() => {
    setColumnsToShow(columns);

  }, []);

  const columnsToShowHandler = (val) => {
    setColumnsToShow(val);
  };

  const addKeys = (arr) => arr?.results?.map((i) => ({ ...i, key: i.id }));



  return (

    <div className='mt-5'>
      <div className='text-center items-center gap-5 my-2 flex'>

        {/* {list && (
          <div>
            <ColVisibilityDropdown
              options={columns}
              columns={columns}
              columnsToShowHandler={columnsToShowHandler}
            />
          </div>
        )}

        {list && (
          <div>
            <div className="bg-gray-700 hover:bg-gray-500 text-white font-bold py-1 px-3 rounded mr-2">
              <CSVLink
                data={list}
                className="btn btn-dark btn-sm mb-1"
                filename="payslips"
              >
                Download CSV
              </CSVLink>
            </div>
          </div>
        )} */}
      </div>

      <Table
        scroll={{ x: true }}
        loading={loading}
        columns={columnsToShow}
        dataSource={list ? addKeys(list) : []}
        expandable={{
          expandedRowRender: (record) => <div>{
            <div className="d-flex " style={{ justifyContent: 'start' }}>
              <div className="  " style={{ marginRight: '50px' }} >
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">Paid Leave : </div>
                  {/* <div>{record.paidLeave ? record.paidLeave : 0}</div> */}
                </div>
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">Unpaid Leave : </div>
                  {/* <div className="ml-2">{record.unpaidLeave ? record.unpaidLeave : 0}</div> */}
                </div>
              </div>
              <div className="  " style={{ marginRight: '50px' }}>
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">M-Holiday : </div>
                  {/* <div>{record.monthlyHoliday ? record.monthlyHoliday : 0}</div> */}
                </div>
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">P-Holiday : </div>
                  {/* <div>{record.publicHoliday ? record.publicHoliday : 0}</div> */}
                </div>
              </div>

              <div className="  " style={{ marginRight: '50px' }}>
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">Work Day : </div>
                  {/* <div className="ml-2">{record.workDay ? record.workDay : 0}</div> */}
                </div>
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold"> Shift W.H : </div>
                  {/* <div>{record.shiftWiseWorkHour ? record.shiftWiseWorkHour : 0}</div> */}
                </div>
              </div>
              <div className="  " style={{ marginRight: '50px' }} >
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">Month W.H : </div>
                  {/* <div className="ml-2">{record.monthlyWorkHour ? record.monthlyWorkHour : 0}</div> */}
                </div>
                <div className="d-flex " style={{ justifyContent: 'space-between' }}>
                  <div className="text-bold">H Salary : </div>
                  {/* <div className="ml-2">{record.hourlySalary ? record.hourlySalary : 0}</div> */}
                </div>
              </div>
            </div>
          }</div>,
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </div>
  );
}

const Calculate_Payroll = () => {
  let pageName = 'Calculate Payroll'
  const [pageConfig, setPageConfig] = useState({
    month: dayjs().format("M"),
    year: dayjs().format("YYYY"),
  });
  const { isLoading } = useGetPayslipsQuery();
  const [addPayslip, { isLoading: addLoading }] = useAddPayrollMutation();

  const onMonthChange = (date, dateString) => {
    setPageConfig((prev) => {
      return { ...prev, month: dateString };
    });
  };

  const onYearChange = (date, dateString) => {
    setPageConfig((prev) => {
      return { ...prev, year: dateString };
    });
  };

  const [payslips, setPayslips] = useState([]);
  const list = useSelector((state) => state.payroll.list);
  useEffect(() => {
    setPayslips(list);
  }, [list]);

  const navigate = useNavigate();



  const OnSubmit = async () => {
    const dataArray = payslips.results.map((i) => ({
      user: i.id,
      salaryMonth: parseInt(pageConfig?.salaryMonth || dayjs().format("M")),
      salaryYear: parseInt(pageConfig?.salaryYear || dayjs().format("YYYY")),
      salary: i.salary,
      paidLeave: i.paidLeave,
      unpaidLeave: i.unpaidLeave,
      monthlyHoliday: i.monthlyHoliday,
      publicHoliday: i.publicHoliday,
      workDay: i.workDay,
      shiftWiseWorkHour: i.shiftWiseWorkHour,
      monthlyWorkHour: i.monthlyWorkHour,
      hourlySalary: i.hourlySalary,
      bonus: i.bonus? i.bonus : 0,
      bonus_comment: i.bonusComment?i.bonusComment:null,
      deduction: i.deduction? i.deduction:0,
      deduction_comment: i.deductionComment? i.deductionComment: null,
      total_payable: i.totalPayable,
      workingHour: i.workingHour,
      salary_payable: i.salaryPayable,
    }));

    // try {
    //   const resp = await addPayslip(dataArray);
    //   if (resp) {
    //     navigate("/admin/payroll/list");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
    mutate(dataArray)
    // mutateUpdateSalary()
  };



  const apiname_Ganerate_Payslip = process.env.REACT_APP_HRMS_PAYSLIP_API?.toString()
  const { mutate, data, isError, isLoading:isGenerating, error, isSuccess } = useCreate(apiname_Ganerate_Payslip)



  const apiname_Update_Payslip = process.env.REACT_APP_LEOUSER_API?.toString()
  const { mutate:mutateUpdateSalary, data:dataUpdateSalary, isError:isErrorUpdateSalary, isLoading:isUpdating, error:isUpdateError, isSuccess:isUpdateSuccess } = useCreate(apiname_Update_Payslip)

  // useEffect(()=>{
  //   if(isSuccess){
  //     alert('Success')
  //   }
  // },[isSuccess])




  return (
    <div>
      {/* <PageTitle title='Back' /> */}
      {/* <UserPrivateComponent permission={"readAll-payroll"}> */}
      {/* <Card className='mt-5'>
          <div className='flex justify-between'>
            <h1 className='font-bold text-lg'>Calculate Payroll</h1>
            <div className='flex gap-3'>
              <h1 className='text-base text-color-2 items-center'>
                {" "}
                Select Month :{" "}
              </h1> */}
      <div style={{ height: "97%" }}>
        <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
          <section className="content-header py-1 px-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/listHrmsTask" className="text-decoration-none text-black">{pageName}</Link>
                    </li>
                    <li className="breadcrumb-item">Add {pageName}</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="card card-default" >
                <div className="card-body" >
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <div className='d-flex justify-content-space-between gap-3' style={{ justifyContent: 'space-between' }}>
                          <h4>{pageName}</h4>
                          <div className="d-flex justify-content-end gap-3">

                            <p className='text-base text-color-2 items-center'>

                              Select Month :
                            </p>
                            <DatePicker
                              format={"M"}
                              style={{ maxWidth: "200px" }}
                              picker='month'
                              defaultValue={dayjs()}
                              onChange={onMonthChange}
                            />
                            <p className='text-base text-color-2 items-center'>

                              Select Year :
                            </p>
                            <DatePicker
                              format={"YYYY"}
                              picker='year'
                              style={{ maxWidth: "200px" }}
                              onChange={onYearChange}
                              defaultValue={dayjs()}
                            />
                          </div>
                        </div>

                        <CustomTable list={payslips} loading={isLoading} />

                        <div className='d-flex justify-content-end'>
                          <Button
                            //   loading={isLoading || addLoading}
                            type='primary'
                            size='large'
                            htmlType='submit'
                            onClick={OnSubmit}
                            className='mt-5 text-end' 
                            style={{backgroundColor:'rgb(0, 54, 101)'}}
                          >
                            Generate Payslip
                          </Button>
                        </div>
                        {/* </Card> */}
                        {/* </UserPrivateComponent> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Calculate_Payroll;



