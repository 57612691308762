import React, { useEffect, useRef, useState } from "react";
import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useGSTEdit, useUpdateGst } from "../../../hooks/GST";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate, useFetchSingle, useUpdateSingle, useUpdateSingleforcewithno, useUpdateSingleforcewithyes } from "../../../hooks/Api";
import Select from "react-select";
import axios from "axios";
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import CreatableSelect from "react-select/creatable";
import { useFetchAddressTypeDropDown, useFetchCityDropDown, useFetchCityPincodeDropDown, useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";
import Offcanvas from "react-bootstrap/esm/Offcanvas";
import { Columns_Address_Details, titleofheaders } from "./Columns_Address_Details";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Add_Address_Details from "./Add_Address_Details";
import Modal from "react-bootstrap/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Add_Address_Modal from "./Address_Modal";
import Axiosinstance from "../../../hooks/Interceptor";
import { useDelete, useDeletemforce } from "../../../hooks/delete";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { Button } from "react-bootstrap";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOptions = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Edit_Modal = ({ showOffcancasEdit, handleCloseEdit, id, setIsEdited, setShowOffcancasEdit }: any) => {
    let pageName = "Address Details";
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_ADDRESS_API?.toString();
    const { data } = useFetchSingle(id, apiname)
    const [getIdForEdit, setGetIdForEdit]: any = useState()


    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;
    const ids = addData?.address?.map((e: any) => {

        return e.id
    })
    const tempData = addData?.address
    // const data = tempData










    console.log('ids', ids)

    const {
        mutate,
        isSuccess,
        isError,
        error,
        data: gstUpdated_response,
    }: any = useUpdateSingle();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        reset,
        setValue,
    }: any = useForm();


    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()





    // const [showOffcancasEdit, setShowOffcancasEdit] = useState(false);

    // const handleCloseEdit = () => setShowOffcancasEdit(false);
    // const handleShowEdit = () => setShowOffcancasEdit(true);


    const handleReset = () => {
        reset();
    };

    // useEffect(() => {
    //     if (data) {
    //         reset(data);
    //     }
    // }, [data]);

    const onSubmit = (materialtypeDetails: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(materialtypeDetails)) {
            const formvalue: any = value;
            if (typeof value !== "object") {
                formData.append(key, formvalue);
            }
        }
        let data = {
            apiname: apiname,
            id: id,
            data: formData,
        };
        mutate(data);
        setValuesofform(data)
    };

    const [selEmployee, setSelEmployee]: any = useState("")
    const [selectedEmployee, setSelectedEmployee]: any = useState("")
    const [selCity, setSelCity]: any = useState("")
    const [selectedCity, setSelectedCity]: any = useState("")



    const [optionsCity, setOptionsCity]: any = useState()
    const [valueCityCreatable, setValueCityCreatable] = useState<Option | null>();

    const [valueCity, setValueCity]: any = useState()



    const [valueAddressType, setValueAddressType]: any = useState();
    const [selectedAddressType, setSelectedAddressType]: any = useState('');
    const [optionsAddressType, setOptionsAddressType]: any = useState([]);
    const [valueAddressTypeCreatable, setValueAddressTypeCreatable] = useState<Option | null>();

    const apinameForAddressType = process.env.REACT_APP_SALES_ADDRESS_TYPE_API?.toString()
    const { mutate: mutateAddressType, data: dataAddressType, isError: AddressTypeIsError, isLoading: AddressTypeLoading, isSuccess: AddressTypeSuccess, error: AddressTypeError }: any = useCreate(apinameForAddressType)

    const { data: AddressTypeData, refetch: AddressTypeRefetch } = useFetchAddressTypeDropDown()

    useEffect(() => {
        setOptionsAddressType(AddressTypeData)
    }, [AddressTypeData])

    const handleChangeAddressType = (e: any) => {
        AddressTypeRefetch()

        setApiName(apinameForAddressType)
        setDeleteApiName(apinameForAddressType)
        setEditId(e?.value)
        setEditData(AddressTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAddressType(e)
        setValue("address_type", `${e?.value}`)
        setValueAddressTypeCreatable(null)

    }


    const handleAddressTypeCreate = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            // setIsLoadingValue(false);


            mutateAddressType({ name: newOption.name, status: newOption.status })
            setOptionsAddressType((prev: any) => [...prev, newOption]);
            setValueAddressTypeCreatable(newOption);
        }, 0);


    };


    useEffect(() => {
        (async () => {
            if (AddressTypeSuccess && optionsAddressType) {
                await AddressTypeRefetch();
                setValue("address_type", AddressTypeData[0]?.value)
            }
        })();

        return () => {
        };
    }, [AddressTypeSuccess, AddressTypeData]);




    const apinameForCity = process.env.REACT_APP_CITY_API?.toString()

    const { mutate: mutateCity, data: dataCountry, isError: CountryIsError, isLoading: CountryLoading, isSuccess: CountrySuccess, error: CountryError }: any = useCreate(apinameForCity)


    const { data: CityData, refetch: CityRefetch } = useFetchCityDropDown()
    // const [optionsCavityName, setOptionsCavityName] = useCountry(cavityNameData)
    useEffect(() => {
        setOptionsCity(CityData)
    }, [CityData])

    const handleChangeCity = (e: any) => {

        setValue("city", `${e?.value}`)
        setValueCityCreatable(null)
        setValueDistrict(e?.district)
        setValueState(e?.state)
        setValueCountry(e?.country)
        setValueTaluka(e?.taluka)
        // setCountryValue(e?.value)

        setByCity(e?.value)
        setSelCity(e)
    }


    const handleCreateCity = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            // setIsLoadingValue(false);


            mutateCity({ name: newOption.name, status: newOption.status })
            setOptionsCity((prev: any) => [...prev, newOption]);
            setValueCityCreatable(newOption);
        }, 0);


    };

    // const [cityValue,setCityValue]=useState("")
    useEffect(() => {
        (async () => {
            if (CountrySuccess && optionsCity) {
                await CityRefetch();
            }
            setValue("city", CityData[0]?.value)
            // setCityValue(CityData[0]?.value)
        })();

        return () => {
        };
    }, [CountrySuccess, CityData]);






    const [valuePersonType, setValuePersonType]: any = useState();

    const [optionsPersonType, setOptionsPersonType]: any = useState([])

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [selectedPersonType, setSelectedPersonType]: any = useState("")
    const [valuePersonTypeCreatable, setValuePersonTypeCreatable] = useState<Option | null>();

    const apinameForPersonType = process.env.REACT_APP_PERSON_TYPE_API?.toString()

    const { mutate: mutatePersonType, data: dataCavity, isError: cavityIsError, isLoading: cavityLoading, isSuccess: personTypeSuccess, error: cavityError }: any = useCreate(apinameForPersonType)


    const { data: personTypeData, refetch: personTypeRefetch } = useFetchPersonTypeDropDown()

    useEffect(() => {
        setOptionsPersonType(personTypeData)
    }, [personTypeData])





    const handleChangePersonType = (e: any) => {
        setValue("type", e?.value)
        setValuePersonTypeCreatable(null)
        setValuePersonType(e)
    }

    const handleCreatePersonType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOptions(inputValue);
            setIsLoadingValue(false);


            mutatePersonType({ name: newOption.name, status: newOption.status })
            setOptionsPersonType((prev: any) => [...prev, newOption]);
            setValuePersonTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (personTypeSuccess && optionsPersonType) {
                await personTypeRefetch();
                setValue("type", personTypeData[0].value)
            }
        })();

        return () => {
        };
    }, [personTypeSuccess, personTypeData]);


    const [selectedCityPincode, setSelectedCityPincode]: any = useState("")


    const [cityPincodeOptions, setCityPincodeOptions]: any = useState()
    const [forCityPincodeValue, setForCityPincodeValue]: any = useState()
    const [selCityPincode, setSelCityPincode]: any = useState("")
    const [byCity, setByCity] = useState()
    const { data: cityPincodeData, refetch: cityPincodeRefetch } = useFetchCityPincodeDropDown(byCity)


    useEffect(() => {

        setCityPincodeOptions(cityPincodeData)
    }, [cityPincodeData])

    const handleChangePincode = (e: any) => {
        setForCityPincodeValue(e)
        setValue("pincode", e?.value)
        setSelCityPincode(e)
    }




    const [forValue, setForValue]: any = useState()
    const [employeeOptions, setEmployeeOptions]: any = useState()
    const [forEmployeeValue, setForEmployeeValue] = useState(0)


    const { data: employeeData, refetch: employeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        setEmployeeOptions(employeeData)
    }, [employeeData])



    const [valueDistrict, setValueDistrict] = useState()
    const [valueState, setValueState] = useState()
    const [valueCountry, setValueCountry] = useState()
    const [valuePincode, setValuePincode] = useState()
    const [valueTaluka, setValueTaluka] = useState()







    const handleChangeAreaManager = (e: any) => {

        setForEmployeeValue(e)
        setValue("employee_code", e.value)

    }
    const [buildingType, setBuildingType] = useState("")
    const handleBuildingType = (e: any) => {
        setBuildingType(e.target.value)
    }


    // const prevDataRef = useRef();

    // useEffect(() => {
    //     const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
    //     if (data && isDataChanged) {
    //         reset(data);
    //         prevDataRef.current = data;
    //     }
    // }, [data, reset]);


    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedCity(data.city?.id)
            setValue("city", data.city?.id)
            setSelectedCityPincode(data.pincode?.id)
            setValue("pincode", data.pincode?.id)
            setValueState(data.city?.state?.name)
            setValueDistrict(data.city?.district?.name)
            setValueCountry(data.city?.country?.name)
            setValueTaluka(data.city?.taluka?.name)
            setByCity(data?.city?.id)
            setSelectedEmployee(data.employee_code?.id)
            setValue("employee_code", data.employee_code?.id)
            setValue("type", data.type?.id)
            setSelectedPersonType(data.type?.id)
            setSelectedAddressType(data?.address_type?.id)
            setValue('address_type', data?.address_type?.id)
        }
    }, [data])






    // const [show, setShow] = useState(false);
    // const [showmessage, setshowmessage] = useState("");

    // useEffect(() => {
    //     if (isSuccess) {
    //         setShow(true);
    //         setshowmessage("success");
    //         // setCurrentStep(currentStep + 1)
    //         //   navigate("/listAddress", { state: { showEditToast: true } });
    //     } else if (isError) {
    //         setShow(true);
    //         setshowmessage("danger");
    //     }
    // }, [gstUpdated_response, error, isSuccess]);



    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };
    const maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }


    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    const [openEditDateModel, setOpenEditDateModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()


    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()

    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (yessuccess) {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForBasicDetails}/${basicIdTemp}`);
                    // console.log('response', response);
                    localStorage.setItem('Add Data', JSON.stringify(response?.data?.data))
                    setShowOffcancasEdit(false)
                    setIsEdited(true)
                    // Do something with response.data
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle the error appropriately
                }
            }
        };

        fetchData();
    }, [yessuccess]);

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {

            setShow(true);
            setshowmessage("success");






            setShowOffcancasEdit(false)
            setBasicDetailsInitiated(true);

            // let tempId = {
            //                 personal_detail:data?.data?.id
            //             }

            //             let tempData = {
            //                 id: basicIdTemp,
            //                 apiname: apinameForBasicDetails,
            //                 data: tempId
            //             }
            //             BasicDetails(tempData);
            //   navigate("/listCity", { state: { showEditToast: true } });
            // setCurrentStep(currentStep + 1)
        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, gstUpdated_response, error,
        isSuccess,]);

    console.log('yessuccess', yessuccess)

    const [updateCompleted, setUpdateCompleted] = useState(false)

    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
            const currentPath = window.location.pathname;
            // if (currentPath === '/addCustomerDetails') {
            //     setCurrentStep(currentStep + 1)
            // };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, [show]);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditDateModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: basicIdTemp,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditDateModel(false)
    }






    const [ForEditIds, setForEditIds]: any = useState([])

    console.log('ForEditIds', ForEditIds)


    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    const prevDataRef = useRef();

    const [localIds, setLocalIds] = useState([])

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            setMultipleIds(ids)
            // setForEditIds(ids)

            // setForEditIds((oldIds: any) => {
            //     const newIds = [...oldIds, ids];
            //     console.log('New IDs:', newIds);
            //     return newIds;
            // });
            prevDataRef.current = data;
        }
    }, [data, reset]);



    // useEffect(()=>{
    //     if(isNew){
    //         
    //         setForEditIds(ids)
    //         setIsNew(false)
    //     }
    // },[isNew])


    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);


    const filter = {

        name: ""

    };



    const [addShowOffcancas, setAddShowOffcancas] = useState(false)


    // const[render]

    console.log('id in edit Modal', id)




    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForAddressType) {
            AddressTypeRefetch()
        }


    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueAddressType && apiName === apinameForAddressType) {
            setValueAddressType(null)
            setValueAddressTypeCreatable(null)
        }




        if (deletesucess && apiName === apinameForAddressType) {
            setValueAddressType(null)
            setValueAddressTypeCreatable(null)
        }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    return (
        <div>
            <div className="">
                <section className="content-header py-1 px-3">

                </section>
                <section className="">
                    <div className="">
                        <div className="">




                            <Offcanvas show={showOffcancasEdit} onHide={handleCloseEdit} placement={'end'} style={{ width: '178vh', zIndex: '1000' }} >
                                <Offcanvas.Header closeButton>
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className="card card-default">
                                        <h3>Edit</h3>

                                        <form ref={focus}
                                            onKeyUp={event => onEnterKey(event)}
                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body" >
                                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">








                                                            <div className="col-sm-3 mt-2">

                                                                <label >Address Type<span style={{ color: 'red' }}>*</span></label>


                                                                <CreatableSelect
                                                                    isClearable={() => {
                                                                        setValueAddressType(null)
                                                                        setValue(null)
                                                                    }}
                                                                    // classNames="creatable-select"
                                                                    {...register("address_type", { required: { value: true, message: "Address Type is required" } })}
                                                                    placeholder={'Select Address Type'}
                                                                    components={customCreatableIndicator}
                                                                    // menuIsOpen={true}
                                                                    options={optionsAddressType}
                                                                    onCreateOption={handleAddressTypeCreate}
                                                                    onChange={handleChangeAddressType}
                                                                    onMenuOpen={() => AddressTypeRefetch()}
                                                                    // value={valueAddressTypeCreatable == null ? valueAddressType : valueAddressTypeCreatable}
                                                                    value={valueAddressTypeCreatable == null ? valueAddressType == null ? optionsAddressType?.filter((obj: any) => obj.value === selectedAddressType) : valueAddressType : valueAddressTypeCreatable}

                                                                />
                                                                <span className='text-red w-100'>{errors?.address_type?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Premises Type <span style={{ color: 'red' }}>*</span></label>

                                                                <select className="form-select" {...register("premises_type", { onChange: handleBuildingType, required: { value: true, message: "Building Type is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="block no">Block No</option>
                                                                    <option value="shop no">Shop No</option>
                                                                    <option value="godown no">Godown No</option>
                                                                    <option value="flat no">Flat No</option>
                                                                    <option value="survey no">Survey No</option>
                                                                    <option value="factory no">Factory No</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.premises_type?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Premises Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Premises Number" {...register("number", { onChange: handleValue, required: { value: true, message: "Number is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.number?.message}</span>

                                                            </div>
                                                            <div className="col-sm-3 mt-2">

                                                                <label >Premises Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Premises Name"
                                                                        disabled={buildingType != "block no"}
                                                                        {...register("bunglow_name", { onChange: handleValue, required: { value: false, message: "Bunglow Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.bunglow_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Building Name <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Building Name" {...register("building_name", { onChange: handleValue, required: { value: true, message: "Building Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.building_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Society Name</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"
                                                                        disabled={buildingType != "block no"}
                                                                        placeholder="Society Name" {...register("society_name", { onChange: handleValue, required: { value: false, message: "Society Name is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.society_name?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Street Address <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Street Address" {...register("street", { onChange: handleValue, required: { value: true, message: "Street Address is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.street?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Landmark <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Landmark" {...register("landmark", { onChange: handleValue, required: { value: true, message: "Landmark is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.landmark?.message}</span>

                                                            </div>

                                                            <div className="col-sm-3 mt-2">

                                                                <label >Area <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" placeholder="Area" {...register("area", { onChange: handleValue, required: { value: true, message: "Area is required" } })} />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.area?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >City <span style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    isClearable={() => {
                                                                        setValueCity(null)
                                                                        setValue(null)
                                                                    }}
                                                                    {...register("city", { required: { value: true, message: "City Name is required" } })}
                                                                    placeholder={'Select City Name'}
                                                                    options={optionsCity}
                                                                    // onCreateOption={handleCreateCity}
                                                                    onChange={handleChangeCity}
                                                                    value={selCity === "" ? optionsCity?.filter((obj: any) => obj.value === selectedCity) : selCity}
                                                                    components={customIndicator}

                                                                />
                                                                <span className='text-red w-100'>{errors?.city?.message}</span>

                                                            </div>


                                                            <div className="col-sm-3 mt-2">

                                                                <label >Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="number" className="form-control" placeholder="Mobile Number"
                                                                        maxLength={10}
                                                                        minLength={15}
                                                                        onInput={maxLengthCheck}
                                                                        {...register('mobile_number', {
                                                                            required: "Mobile Number is required",

                                                                            pattern: {
                                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                                message: 'Please enter a valid number',
                                                                            },
                                                                        })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.mobile_number?.message}</span>

                                                            </div>





                                                            <div className="col-sm-3 mt-2">
                                                                <label >Remark </label>
                                                                <div className="input-group" >
                                                                    <input type="text" className="form-control"

                                                                        placeholder="Remark"
                                                                        {...register("remark", { onChange: handleValue, required: { value: false, message: "Remark is required" } })}
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                            </div>













                                                            <div className="col-sm-3 mt-2">
                                                                <label >Status <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                                    <option value={""} selected>Select Any</option>
                                                                    <option value="active">Active</option>
                                                                    <option value="inactive">Inactive</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.status?.message}</span>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row d-flex  ">
                                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                        <div className="row d-flex ">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                <button
                                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                                    onClick={handleSubmit(onSubmit)}
                                                                >Submit</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </form>
                                    </div>


                                </Offcanvas.Body>
                            </Offcanvas>




                        </div>
                    </div>
                </section>
            </div>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />

            <Modal show={openEditDateModel} onHide={() => setOpenEditDateModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditDateModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ zIndex: '2000' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
        </div>
    );
};

export default Edit_Modal;
