import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'


interface InputField {
    id?: any,
    name?: any,
    type?: any,
    accept?: any,
    className?: any,
    placeholder?: any,
    htmlFor?: any,
    color?: any
    isPencil?: any
    message?: any
    value?: any,
    label?: Path<any>,
    register?: any,
    isRequired?: boolean,
    errors?: any;
    options?: any;
    isDisabled?: any
    onChange?: (e: any) => void,
    modal?: () => void,
    Cancel?: () => void;
}

const Status = ({ id, name, type, className, label, options, register, isRequired = true, message, errors, value, onChange, modal }: InputField) => {
    
    return (
        <div className={className ? className : "col-sm-3 mt-2"}>
            <label htmlFor="exampleInputEmail1">{label} <span style={{ color: "red" }}>*</span>
            </label>
            <select className={className ? className : "form-select"} {...register(name, { required: { value: isRequired, message: message } })}>
            <option value={""} selected >Select Any</option>
                {
                    options.map((option: any) => {
                        return (
                            <option value={option?.value} >{option?.label}</option>
                        )
                    })
                }
                
            </select>
            {isRequired &&
                <span className={className ? className : "text-red w-100"}>{errors}</span>
            }
        </div>
    )
}

export default Status