import { useEffect, useRef, useState } from "react";
import "../../components/Header/header.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert } from "../../components/Model/Model";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useCreate } from "../../hooks/Api";
import {
    useFetchAccountDropDown, useFetchCavityNameDropDown,
    useFetchDesignationDropDown, useFetchEmployeeTypeDropDown,
    useFetchMouldDropDown, useFetchSalaryTypeDropDown, useFetchCityDropDown,
    useFetchTitleDropDown, useFetchCasteDropDown, useFetchBloodGroupDropDown,
    useFetchWeeklyOffDropDown, useFetchAdministrationTypeDropDown, useFetchEmployeeDropDown
} from "../../hooks/DropDown_Api";
import { RiPencilLine } from "react-icons/ri";
import cameraLogo from "../../images/Export/Camera.png";
import pdfLogo from "../../images/Export/pdf upload.png";
import useFocusOnEnter from "../../components/focus";
import { useFetchDepartmentDropDown, useFetchResponsiblePerson } from "../../hooks/DropDown_Api";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
import { Department_Model } from "../../components/Dynamic_Form_Models/Department_Model";
import DatePicker from "antd/es/date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from 'dayjs';
import moment from "moment";
import { Designation_Model } from "../../components/Dynamic_Form_Models/Designation";
import { Upload } from "antd";
import type { UploadFile } from 'antd/es/upload/interface';
import { City_Model } from "../../components/Dynamic_Form_Models/City_Model";
import { Account_Model } from "../../components/Dynamic_Form_Models/Account_Model";
// import dayjs from 'dayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IndicatorsContainer from '../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../components/Select Dropdown/Creatabel'

import Modal from "react-bootstrap/Modal";
import Actions, { Deleteforce, Deletesingle } from "../../components/Cell/Actions";
import { useDelete, useDeletemforce } from "../../hooks/delete";
import Custom_Actions_Buttons from "../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../hooks/deletemultiple";
import { SimpleInput_Edit } from "../../components/Dynamic_Form_Models/SimpleInput_Edit";

interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active",
});

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}


export const Add_Employee = ({ onNext, setOnNext, setCurrentStep, currentStep }: any) => {
    const navigate = useNavigate()
    const [EmployeeId, setEmployeeId] = useState("no")
    const [Married, setMarried] = useState("Single")
    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const [options, setOptions] = useState([])
    const [optionsEmployee, setOptionsEmployee] = useState([])
    const [iconChange, setIconChange] = useState("password")
    const [confirmiconChange, setconfirmIconChange] = useState("password")
    const [open, setOpen] = useState(false)
    const [openDesignation, setOpenDesignation] = useState(false)

    const [logo, setlogo] = useState(null)
    const [logoPdf, setlogoPdf] = useState(null)
    const [agreement, setAgreement] = useState(null)

    const [isLoadingValue, setIsLoadingValue] = useState(false);
    const [clear, setClear] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [submittedDesignation, setSubmittedDesignation] = useState(false)
    const [optionsDepartment, setOptionsDepartment]: any = useState([])
    const [optionsAccount, setOptionsAccount]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [forDepartmentValue, setForDepartmentValue]: any = useState([])
    const [forAccountValue, setForAccountValue]: any = useState([])
    const [forDesignationValue, setForDesignationValue]: any = useState([])

    const [openCity, setOpenCity]: any = useState(false)
    const [openAccount, setOpenAccount]: any = useState(false)

    const [showOtherRef, setShowOtherRef]: any = useState(false)
    // const apinameForEmployee = process.env.REACT_APP_Employee_API?.toString()

    // const { mutate:mutateCavity, data:dataCavity, isError:cavityIsError, isLoading:cavityLoading, isSuccess:cavitySuccess, error:cavityError }: any = useCreate(apinameForEmployee)

    const onMarriedChange = (e: any) => {
        setMarried(e.target.value)
    }




    const apiname = process.env.REACT_APP_EMPLOYEE_API?.toString()



    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)





    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();
    const onEmployeeChange = (e: any) => {
        setEmployeeId(e.target.value)
        setValue("employee_status", e.target.value)
    }
    const onLogoChange = (e: any) => {
        setlogo(e.target.files[0].name)
    }

    const onLogoPDFChange = (e: any) => {
        setlogoPdf(e.target.files[0].name)
    }

    const onAgreementChange = (e: any) => {
        setAgreement(e.target.files[0].name)
    }





    const { data: departmentData, refetch: departmentRefetch } = useFetchDepartmentDropDown()

    useEffect(() => {
        setOptionsDepartment(departmentData)
    }, [departmentData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            departmentRefetch()
        }
    }, [])


    const [optionsDesignation, setOptionsDesignation]: any = useState([])


    const { data: designationData, refetch: designationRefetch } = useFetchDesignationDropDown()

    useEffect(() => {
        setOptionsDesignation(designationData)
    }, [designationData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            designationRefetch()
        }
    }, [])










    const handleChangeDepartment = (e: any) => {
        setValue("department", e?.value)
        setForDepartmentValue(e)
        // setForDepartmentValue({ label: e?.label, value: e?.value })
    }
    const { data: accountData, refetch: accountRefetch } = useFetchAccountDropDown()

    useEffect(() => {
        setOptionsAccount(accountData)
    }, [accountData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            accountRefetch()
        }
    }, [])










    const handleChangeAccount = (e: any) => {
        setValue("group", e?.value)
        setForAccountValue(e)
    }

    const handleChangeDesignation = (e: any) => {
        setValue("designation", e.value)
        setForDesignationValue(e)
    }

    useEffect(() => {
        if (submitted) {
            setForDepartmentValue(optionsDepartment[0])

            setValue("department", optionsDepartment[0]?.value)

        }

    }, [submitted, optionsDepartment])


    useEffect(() => {
        if (submittedDesignation) {
            setForDesignationValue(optionsDesignation[0])

            setValue("designation", optionsDesignation[0]?.value)

        }

    }, [submittedDesignation, optionsDesignation])






    const [EmployeeValue, setEmployeeValue]: any = useState()

    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()

    useEffect(() => {
        if (EmployeeData) {
            let temp_employee_data: any = EmployeeData?.map((e: any) => e)
            temp_employee_data?.unshift({ label: 'Other', value: 'other' })
            setOptionsEmployee(temp_employee_data)
        }
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        if (e.value === 'other') {
            setEmployeeValue(e)
            setShowOtherRef(true)
            setValue("reference", '')
        }
        else {
            setEmployeeValue(e)
            setShowOtherRef(false)
            setValue('other_reference', '')
            setValue("reference", `${e?.value}`)
        }
    }


    const [mouldUsageValue, setMouldUsageValue]: any = useState()



    const apinameForSalaryType = process.env.REACT_APP_EMPLOYEE_SALARY_TYPE_API?.toString()
    const { mutate: mutateSalaryType, data: dataSalaryType, isError: SalaryTypeIsError, isLoading: SalaryTypeLoading, isSuccess: SalaryTypeSuccess, error: SalaryTypeError }: any = useCreate(apinameForSalaryType)

    const [valueSalaryType, setValueSalaryType]: any = useState();
    const [optionsSalaryType, setOptionsSalaryType]: any = useState([]);
    const [valueSalaryTypeCreatable, setValueSalaryTypeCreatable] = useState<Option | null>();

    const { data: SalaryTypeData, refetch: SalaryTypeRefetch } = useFetchSalaryTypeDropDown()

    useEffect(() => {
        setOptionsSalaryType(SalaryTypeData)
    }, [SalaryTypeData])





    const handleChangeSalaryType = (e: any) => {

        SalaryTypeRefetch()

        setApiName(apinameForSalaryType)
        setDeleteApiName(apinameForSalaryType)
        setEditId(e?.value)
        setEditData(SalaryTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueSalaryType(e)

        setValue("salary_type", `${e?.value}`)
        setValueSalaryTypeCreatable(null)
    }





    const handleCreateSalaryType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateSalaryType({ name: newOption.name, status: newOption.status })
            setOptionsSalaryType((prev: any) => [...prev, newOption]);
            setValueSalaryTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (SalaryTypeSuccess && optionsSalaryType) {
                await SalaryTypeRefetch();
                setValue("salary_type", SalaryTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [SalaryTypeSuccess, SalaryTypeData]);

    const apinameForTitle = process.env.REACT_APP_EMPLOYEE_TITLE_API?.toString()
    const { mutate: mutateTitle, data: dataTitle, isError: TitleIsError, isLoading: TitleLoading, isSuccess: TitleSuccess, error: TitleError }: any = useCreate(apinameForTitle)

    const [valueTitle, setValueTitle]: any = useState();
    const [optionsTitle, setOptionsTitle]: any = useState([]);
    const [valueTitleCreatable, setValueTitleCreatable] = useState<Option | null>();

    const { data: TitleData, refetch: TitleRefetch } = useFetchTitleDropDown()

    useEffect(() => {
        setOptionsTitle(TitleData)
    }, [TitleData])





    const handleChangeTitle = (e: any) => {

        TitleRefetch()

        setApiName(apinameForTitle)
        setDeleteApiName(apinameForTitle)
        setEditId(e?.value)
        setEditData(TitleData)
        setRenderKey(prevKey => prevKey + 1);

        setValueTitle(e)

        setValue("title", `${e?.value}`)
        setValueTitleCreatable(null)
    }





    const handleCreateTitle = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateTitle({ name: newOption.name, status: newOption.status })
            setOptionsTitle((prev: any) => [...prev, newOption]);
            setValueTitleCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (TitleSuccess && optionsTitle) {
                await TitleRefetch();
                setValue("title", TitleData[0]?.value)
            }

        })();

        return () => {
        };
    }, [TitleSuccess, TitleData]);

    const apinameForCaste = process.env.REACT_APP_EMPLOYEE_CASTE_API?.toString()
    const { mutate: mutateCaste, data: dataCaste, isError: CasteIsError, isLoading: CasteLoading, isSuccess: CasteSuccess, error: CasteError }: any = useCreate(apinameForCaste)

    const [valueCaste, setValueCaste]: any = useState();
    const [optionsCaste, setOptionsCaste]: any = useState([]);
    const [valueCasteCreatable, setValueCasteCreatable] = useState<Option | null>();

    const { data: CasteData, refetch: CasteRefetch } = useFetchCasteDropDown()

    useEffect(() => {
        setOptionsCaste(CasteData)
    }, [CasteData])





    const handleChangeCaste = (e: any) => {

        CasteRefetch()

        // setApiName(apinameForCaste)
        // setDeleteApiName(apinameForCaste)
        // setEditId(e?.value)
        // setEditData(CasteData)
        // setRenderKey(prevKey => prevKey + 1);

        setValueCaste(e)

        setValue("caste", `${e?.value}`)
        setValueCasteCreatable(null)
    }





    const handleCreateCaste = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        // setIsLoadingValue(true);
        setTimeout(() => {
            const newOption = createOption(inputValue);
            // setIsLoadingValue(false);


            mutateCaste({ name: newOption.name, status: newOption.status })
            setOptionsCaste((prev: any) => [...prev, newOption]);
            setValueCasteCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (CasteSuccess && optionsCaste) {
                await CasteRefetch();
                setValue("caste", CasteData[0]?.value)
            }

        })();

        return () => {
        };
    }, [CasteSuccess, CasteData]);

    const apinameForBloodGroup = process.env.REACT_APP_EMPLOYEE_BLOODGROUP_API?.toString()
    const { mutate: mutateBloodGroup, data: dataBloodGroup, isError: BloodGroupIsError, isLoading: BloodGroupLoading, isSuccess: BloodGroupSuccess, error: BloodGroupError }: any = useCreate(apinameForBloodGroup)
    // const [isLoadingValue, setIsLoadingValue] = useState(false);

    const [valueBloodGroup, setValueBloodGroup]: any = useState();
    const [optionsBloodGroup, setOptionsBloodGroup]: any = useState([]);
    const [valueBloodGroupCreatable, setValueBloodGroupCreatable] = useState<Option | null>();

    const { data: BloodGroupData, refetch: BloodGroupRefetch } = useFetchBloodGroupDropDown()

    useEffect(() => {
        setOptionsBloodGroup(BloodGroupData)
    }, [BloodGroupData])





    const handleChangeBloodGroup = (e: any) => {

        BloodGroupRefetch()

        // setApiName(apinameForBloodGroup)
        // setDeleteApiName(apinameForBloodGroup)
        // setEditId(e?.value)
        // setEditData(BloodGroupData)
        // setRenderKey(prevKey => prevKey + 1);

        setValueBloodGroup(e)

        setValue("blood_group", `${e?.value}`)
        setValueBloodGroupCreatable(null)
    }





    const handleCreateBloodGroup = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateBloodGroup({ name: newOption.name, status: newOption.status })
            setOptionsBloodGroup((prev: any) => [...prev, newOption]);
            setValueBloodGroupCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (BloodGroupSuccess && optionsBloodGroup) {
                await BloodGroupRefetch();
                setValue("blood_group", BloodGroupData[0]?.value)
            }

        })();

        return () => {
        };
    }, [BloodGroupSuccess, BloodGroupData]);

    const apinameForWeeklyOff = process.env.REACT_APP_EMPLOYEE_WEEKLY_OFF_API?.toString()
    const { mutate: mutateWeeklyOff, data: dataWeeklyOff, isError: WeeklyOffIsError, isLoading: WeeklyOffLoading, isSuccess: WeeklyOffSuccess, error: WeeklyOffError }: any = useCreate(apinameForWeeklyOff)

    const [valueWeeklyOff, setValueWeeklyOff]: any = useState();
    const [optionsWeeklyOff, setOptionsWeeklyOff]: any = useState([]);
    const [valueWeeklyOffCreatable, setValueWeeklyOffCreatable] = useState<Option | null>();

    const { data: WeeklyOffData, refetch: WeeklyOffRefetch } = useFetchWeeklyOffDropDown()

    useEffect(() => {
        setOptionsWeeklyOff(WeeklyOffData)
    }, [WeeklyOffData])





    const handleChangeWeeklyOff = (e: any) => {

        WeeklyOffRefetch()

        setApiName(apinameForWeeklyOff)
        setDeleteApiName(apinameForWeeklyOff)
        setEditId(e?.value)
        setEditData(WeeklyOffData)
        setRenderKey(prevKey => prevKey + 1);

        setValueWeeklyOff(e)

        setValue("weekly_off", `${e?.value}`)
        setValueWeeklyOffCreatable(null)
    }





    const handleCreateWeeklyOff = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateWeeklyOff({ name: newOption.name, status: newOption.status })
            setOptionsWeeklyOff((prev: any) => [...prev, newOption]);
            setValueWeeklyOffCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (WeeklyOffSuccess && optionsWeeklyOff) {
                await WeeklyOffRefetch();
                setValue("weekly_off", WeeklyOffData[0]?.value)
            }

        })();

        return () => {
        };
    }, [WeeklyOffSuccess, WeeklyOffData]);

    const apinameForAdministrationType = process.env.REACT_APP_EMPLOYEE_ADMINISTRATION_API?.toString()
    const { mutate: mutateAdministrationType, data: dataAdministrationType, isError: AdministrationTypeIsError, isLoading: AdministrationTypeLoading, isSuccess: AdministrationTypeSuccess, error: AdministrationTypeError }: any = useCreate(apinameForAdministrationType)

    const [valueAdministrationType, setValueAdministrationType]: any = useState();
    const [optionsAdministrationType, setOptionsAdministrationType]: any = useState([]);
    const [valueAdministrationTypeCreatable, setValueAdministrationTypeCreatable] = useState<Option | null>();

    const { data: AdministrationTypeData, refetch: AdministrationTypeRefetch } = useFetchAdministrationTypeDropDown()

    useEffect(() => {
        setOptionsAdministrationType(AdministrationTypeData)
    }, [AdministrationTypeData])





    const handleChangeAdministrationType = (e: any) => {

        AdministrationTypeRefetch()

        setApiName(apinameForAdministrationType)
        setDeleteApiName(apinameForAdministrationType)
        setEditId(e?.value)
        setEditData(AdministrationTypeData)
        setRenderKey(prevKey => prevKey + 1);

        setValueAdministrationType(e)

        setValue("administration_type", `${e?.value}`)
        setValueAdministrationTypeCreatable(null)
    }





    const handleCreateAdministrationType = (inputValue: string) => {

        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        setTimeout(() => {
            const newOption = createOption(inputValue);


            mutateAdministrationType({ name: newOption.name, status: newOption.status })
            setOptionsAdministrationType((prev: any) => [...prev, newOption]);
            setValueAdministrationTypeCreatable(newOption);
        }, 0);


    };

    useEffect(() => {
        (async () => {
            if (AdministrationTypeSuccess && optionsAdministrationType) {
                await AdministrationTypeRefetch();
                setValue("administration_type", AdministrationTypeData[0]?.value)
            }

        })();

        return () => {
        };
    }, [AdministrationTypeSuccess, AdministrationTypeData]);

    const [forCityValue, setForCityValue]: any = useState([])
    const [cityOptions, setCityOptions]: any = useState([])

    const { data: cityData, refetch: cityRefetch } = useFetchCityDropDown()


    useEffect(() => {
        let temp = cityData?.map((e: any) => {
            return (
                {
                    value: e?.value,
                    label: `${e?.city ? e?.city : e?.village}::${e?.district}`,

                }
            )
        })
        setCityOptions(temp)
    }, [cityData])
    const handleChangeCity = (e: any) => {
        setForCityValue(e)
        setValue("city_name_and_distict_name", e?.value)


    }




    const handleReset = () => {
        reset()
        setValue(null)
        setMouldUsageValue(null)
        setBirthDate(null)
        setJoiningDate(null)
        setAnniversaryDate(null)
        setAge(0)
        setForDepartmentValue(null)
        setForDesignationValue(null)
        setForAccountValue(null)
        setValueSalaryTypeCreatable(null)
        setValueSalaryType(null)
        setValueTitleCreatable(null)
        setValueTitle(null)
        setForCityValue(null)
        setForGradeValue(null)
        setValueWeeklyOffCreatable(null)
        setValueWeeklyOff(null)
        setValueAdministrationTypeCreatable(null)
        setValueAdministrationType(null)
        setEmployeeValue(null)


    }

    const onSubmit = (cavitydetail: any) => {
        if (Image.length == 0) {
            delete cavitydetail.image_all
        }

        if (DocumentPDF.length == 0) {
            delete cavitydetail.document_pdf
        }
        if (AgreementPDF.length == 0) {
            delete cavitydetail.agreement_pdf
        }

        if (!aniversaryDate) {
            delete cavitydetail.anniversary_date
        }
        if (!birthDate) {
            delete cavitydetail.birth_date
        }
        if (!JoiningDate) {
            delete cavitydetail.joining_date
        }
        if (EmployeeValue === undefined) {
            delete cavitydetail.reference
            delete cavitydetail.other_reference
        }

        const formData = new FormData();
        for (const [key, value] of Object.entries(cavitydetail)) {
            const formvalue: any = value

            if (key === "image_all") {
                for (let i = 0; i < Image.length; i++) {

                    formData.append('image_all', Image[i])
                }
                continue
            }
            if (key === "document_pdf") {
                for (let i = 0; i < DocumentPDF.length; i++) {

                    formData.append('document_pdf', DocumentPDF[i])
                }
                continue
            }

            if (key === "agreement_pdf") {
                for (let i = 0; i < AgreementPDF.length; i++) {

                    formData.append('agreement_pdf', AgreementPDF[i])
                }
                continue
            }



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            
        }
        let personType = localStorage.getItem('PersonType');
        let personTypeValue = personType ? JSON.parse(personType) : null;
        formData.append('person_type', personTypeValue.value)
        mutate(formData);


    }





    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")




    useEffect(() => {
        if (isSuccess && data) {
            localStorage.setItem('Add Data', JSON.stringify(data?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                const currentPath = window.location.pathname;
                if (currentPath === '/addCustomerDetails') {
                    setCurrentStep(currentStep + 1)
                }
            }

        }
    }, [data, isSuccess])

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listEmployee', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);



    const handleUpper = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }

    const changeicon = () => {
        if (iconChange == "password") {
            setIconChange("text")
        }
        else {
            setIconChange("password")
        }
    }
    const changeconfirmicon = () => {
        if (confirmiconChange == "password") {
            setconfirmIconChange("text")
        }
        else {
            setconfirmIconChange("password")
        }
    }
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];



    const [birthDate, setBirthDate]: any = useState()

    const handleBirthDate = (e: any) => {


        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let birth_date = `${date}-${month}-${year}`
        setValue("birth_date", birth_date)

        setBirthDate(e)
    }


    const [age, setAge]: any = useState(0)


    useEffect(() => {

        if (birthDate != null || birthDate) {

            // if(birthDate == null){

            // }

            let todayDate = new Date().toISOString().split("T")[0]

            if (birthDate?.$D === 31) {
                var bDate = moment([birthDate?.$y, birthDate?.$M, birthDate?.$D]);
            } else {
                var bDate = moment([birthDate?.$y, birthDate?.$M + 1, birthDate?.$D]);
            }



            var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

            setAge(currentDate.diff(bDate, 'years'))
            setValue("age", age)





        } else {
            setAge(0)
            setValue("age", 0)
        }



    }, [birthDate, age])

    const [JoiningDate, setJoiningDate]: any = useState("")
    const [LeavingDate, setLeavingDate]: any = useState("")


    const handleJoiningDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let joining_date = `${date}-${month}-${year}`
        setValue("joining_date", joining_date)
        setJoiningDate(e)

    }
    const handleLeavingDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let leaving_date = `${date}-${month}-${year}`
        setValue("leaving_date", leaving_date)
        setLeavingDate(e)

    }

    const [aniversaryDate, setAnniversaryDate]: any = useState("")

    const handleAnniversaryDate = (e: any) => {

        let year = e?.$y
        let month = e?.$M + 1
        let date = e?.$D
        let anniversary_date = `${date}-${month}-${year}`
        setValue("anniversary_date", anniversary_date)
        setAnniversaryDate(e)

    }

    const [SubmittedCity, setSubmittedCity]: any = useState(false)
    useEffect(() => {
        if (SubmittedCity) {

            setForCityValue(cityOptions[0])


            setValue("city_name_and_distict_name", cityOptions[0]?.value)

        }

    }, [SubmittedCity, cityOptions])

    const [SubmittedAccount, setSubmittedAccount]: any = useState(false)
    useEffect(() => {
        if (SubmittedAccount) {

            setForAccountValue(optionsAccount[0])


            setValue("group", optionsAccount[0]?.value)

        }

    }, [SubmittedAccount, optionsAccount])


    const [Image, setImage] = useState([])
    const [uploadImage, setUploadImage] = useState<UploadFile[]>([])

    const [DocumentPDF, setDocumentPDF] = useState([])
    const [uploadDocumentPDF, setUploadDocumentPDF] = useState<UploadFile[]>([])

    const [AgreementPDF, setAgreementPDF] = useState([])
    const [uploadAgreementPDF, setUploadAgreementPDF] = useState<UploadFile[]>([])

    const imageChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setImage(tempFiles)
        setUploadImage(e.fileList)

    }



    const DocumentChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setDocumentPDF(tempFiles)
        setUploadDocumentPDF(e.fileList)

    }
    const AgreementChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setAgreementPDF(tempFiles)
        setUploadAgreementPDF(e.fileList)

    }




    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();




    const handleRefetch = () => {
        if (apiName === apinameForAdministrationType) {
            AdministrationTypeRefetch()
        }
        if (apiName === apinameForWeeklyOff) {
            WeeklyOffRefetch()
        }
        if (apiName === apinameForTitle) {
            TitleRefetch()
        }
        if (apiName === apinameForSalaryType) {
            SalaryTypeRefetch()
        }



        // else if(apiName === apinameForTaluka){
        //   TalukaRefetch()
        // }
    }


    useEffect(() => {
        if (OpenEditDropDownModel && valueAdministrationType && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (OpenEditDropDownModel && valueWeeklyOff && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        if (OpenEditDropDownModel && valueTitle && apiName === apinameForTitle) {
            setValueTitle(null)
            setValueTitleCreatable(null)
        }
        if (OpenEditDropDownModel && valueSalaryType && apiName === apinameForSalaryType) {
            setValueSalaryType(null)
            setValueSalaryTypeCreatable(null)
        }






        if (deletesucess && apiName === apinameForAdministrationType) {
            setValueAdministrationType(null)
            setValueAdministrationTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForWeeklyOff) {
            setValueWeeklyOff(null)
            setValueWeeklyOffCreatable(null)
        }
        if (deletesucess && apiName === apinameForTitle) {
            setValueTitle(null)
            setValueTitleCreatable(null)
        }
        if (deletesucess && apiName === apinameForSalaryType) {
            setValueSalaryType(null)
            setValueSalaryTypeCreatable(null)
        }

    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



                {isExist ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };


    const optionsGrade = [
        {
            value: 1,
            label: "A"
        },
        {
            value: 2,
            label: "B"
        },
        {
            value: 3,
            label: "C"
        },
        {
            value: 4,
            label: "D"
        },
        {
            value: 5,
            label: "E"
        },

    ]
    const [forGradeValue, setForGradeValue]: any = useState([])

    const handleChangeGrade = (e: any) => {
        setForGradeValue(e)
        setValue("customer_grade", e?.label)
    }




    return (
        <div>
            <div className="" >
                <section className="content-header px-3 py-1">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Employee</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Users</Link></li>
                                    <li className="breadcrumb-item"><Link to="/listEmployee" className="text-decoration-none text-black">Employee</Link></li>
                                    <li className="breadcrumb-item active">Add Employee</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="">

                            <form ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="" >
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Type <span style={{ color: "red" }}>*</span></label>
                                                    //  <Select 
                                                    //         {...register("type", {  required: { value: true, message: "Employee Type is required" } })}
                                                    //         placeholder={'Employee Type'}
                                                    //         onChange={handleChangeAccount}
                                                    //         value={forAccountValue}
                                                    //         options={optionsAccount}
                                                    //     /> 
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueEmployeeType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("type", { required: { value: true, message: "Employee Type is required" } })}
                                                        placeholder={'Employee Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsEmployeeType}
                                                        onMenuOpen={() => EmployeeTypeRefetch()}
                                                        onCreateOption={handleCreateEmployeeType}
                                                        onChange={handleChangeEmployeeType}
                                                        value={valueEmployeeTypeCreatable == null ? valueEmployeeType : valueEmployeeTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.type?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("group", { required: { value: true, message: "Account Type is required" } })}
                                                            placeholder={'Account Type'}
                                                            onChange={handleChangeAccount}
                                                            value={forAccountValue}
                                                            options={optionsAccount}
                                                            maxMenuHeight={150}
                                                            components={customIndicator}
                                                            className="dropdown-select"

                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenAccount(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.group?.message}</span>
                                                </div>


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Salary Type <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSalaryType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("salary_type", { required: { value: true, message: "Salary Type is required" } })}
                                                        placeholder={'Salary Type'}
                                                        components={customCreatableIndicator}
                                                        options={optionsSalaryType}
                                                        onMenuOpen={() => SalaryTypeRefetch()}
                                                        onCreateOption={handleCreateSalaryType}
                                                        onChange={handleChangeSalaryType}
                                                        value={valueSalaryTypeCreatable == null ? valueSalaryType : valueSalaryTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.salary_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Gender <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("gender", { required: { value: true, message: "Gender is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Male" >Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.gender?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Title <span style={{ color: "red" }}>*</span></label>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueTitle(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("title", { required: { value: true, message: "Title is required" } })}
                                                        placeholder={'Title'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsTitle}
                                                        onMenuOpen={() => TitleRefetch()}
                                                        onCreateOption={handleCreateTitle}
                                                        onChange={handleChangeTitle}
                                                        value={valueTitleCreatable == null ? valueTitle : valueTitleCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.title?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee First Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee First Name" {...register("name", { onChange: handleUpper, required: { value: true, message: "Employee First Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Middle Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Middle Name" {...register("father_name", { onChange: handleUpper, required: { value: true, message: "Employee Middle Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.father_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee Last Name <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Employee Last Name" {...register("surname", { onChange: handleUpper, required: { value: true, message: "Employee Last Name is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.surname?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Name & District Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city_name_and_distict_name", { required: { value: true, message: "City Name & District Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={forCityValue}
                                                            maxMenuHeight={150}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city_name_and_distict_name?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Department <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("department", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChangeDepartment}
                                                            value={forDepartmentValue}
                                                            options={optionsDepartment}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            className="dropdown-select"
                                                        
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department?.message}</span>

                                                </div>



                                                <div className="col-sm-3 mt-2">
                                                    <label >Designation <span style={{ color: "red" }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("designation", { required: { value: true, message: "Designation is required" } })}
                                                            placeholder={'Designation'}
                                                            onChange={handleChangeDesignation}
                                                            value={forDesignationValue}
                                                            options={optionsDesignation}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                            className="dropdown-select"
                                                        
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenDesignation(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Grade </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Grade is required" } })}
                                                            placeholder={'Grade'}
                                                            onChange={handleChangeGrade}
                                                            value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>
                                                    {/*<div className="d-flex row">
                                                        <Select
                                                            {...register("customer_grade", { required: { value: false, message: "Customer Grade is required" } })}
                                                            placeholder={'Customer Grade'}
                                                            onChange={handleChangeGrade}
                                                            value={forGradeValue}
                                                            options={optionsGrade}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>*/}
                                                    <span className='text-red w-100'>{errors?.customer_grade?.message}</span>

                                                </div>

                                                {/* 
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Designation <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("designation", {  required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Yearly_salary" >Supervisor</option>
                                                        <option value="Monthly_salary">Sales Executive</option>
                                                        <option value="contract">Operator</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>
                                                </div> */}


                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Qualification <span style={{ color: "red" }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Qualification" {...register("qualification", { onChange: handleUpper, required: { value: true, message: "Qualification is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.qualification?.message}</span>
                                                </div>


                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Weekly Off</label>
                                                    <select className="form-select"  {...register("weekly_off", { required: { value: false, message: "Field is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.weekly_off?.message}</span>
                                                </div> 
                                                 <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Joining Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            // value={forPointStartDateValue}
                                                            // disabledDate={disabledDate}
                                                            value={JoiningDate}
                                                            {...register("joining_date", { required: { value: true, message: "Joining Date is required" } })}

                                                            onChange={handleJoiningDate}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.joining_date?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Leaving Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            // value={forPointStartDateValue}
                                                            // disabledDate={disabledDate}
                                                            value={LeavingDate}
                                                            {...register("leaving_date", { required: { value: true, message: "Leaving Date is required" } })}

                                                            onChange={handleLeavingDate}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.leaving_date?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Birth Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            // value={forPointStartDateValue}
                                                            // disabledDate={disabledDate}
                                                            value={birthDate}
                                                            {...register("birth_date", { required: { value: true, message: "Birth Date is required" } })}
                                                            picker="date"
                                                            onChange={handleBirthDate}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.birth_date?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Age</label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="number" disabled className="form-control"
                                                            value={age}
                                                            placeholder="Age" {...register("age", { required: { value: false, message: "Field is required" } })} />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.age?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Marital Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("marital_status", { onChange: onMarriedChange, required: { value: true, message: "Marital Status is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Single" >Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="UnMarried">UnMarried</option>
                                                        <option value="Divorced">Divorced</option>
                                                    </select>
                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueSalaryType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("marital_status", { required: { value: true, message: "Marital Status is required" } })}
                                                        placeholder={'Marital Status'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsSalaryType}
                                                        onMenuOpen={() => SalaryTypeRefetch()}
                                                        onCreateOption={handleCreateSalaryType}
                                                        onChange={handleChangeSalaryType}
                                                        value={valueSalaryTypeCreatable == null ? valueSalaryType : valueSalaryTypeCreatable}
                                                    />
                                                    <span className='text-red w-100'>{errors?.marital_status?.message}</span>
                                                </div>
                                                {Married === "Married" && (<>
                                                    <div className="col-sm-3 mt-2">
                                                        <label htmlFor="manualcode_Disc_1">Anniversary Date </label>
                                                        <div className="input-group" >
                                                            <DatePicker
                                                                className="form-control"
                                                                format={dateFormatList}
                                                                value={aniversaryDate}
                                                                // value={forPointStartDateValue}
                                                                // disabledDate={disabledDate}
                                                                {...register("anniversary_date", { required: { value: false, message: "Point Start Date is required" } })}
                                                                onChange={handleAnniversaryDate}
                                                            />
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.anniversary_date?.message}</span>
                                                    </div>
                                                </>)}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Nationality <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("nationality", { required: { value: true, message: "Nationality is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Indian" >Indian</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.nationality?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Religion <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("religion", { required: { value: true, message: "Religion is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="Hindu" >Hindu</option>
                                                        <option value="Muslim">Muslim</option>
                                                        <option value="Christian">Christian</option>
                                                        <option value="Sikh">Sikh</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.religion?.message}</span>
                                                </div> 
                                                 <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Caste <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("caste", { required: { value: true, message: "Caste is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="General" >General</option>
                                                        <option value="ST">ST</option>
                                                        <option value="SC">SC</option>
                                                        <option value="OBC">OBC</option>
                                                        <option value="Other">Other</option>
                                                    </select>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueCaste(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("caste", { required: { value: true, message: "Caste is required" } })}
                                                        placeholder={'Caste'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsCaste}
                                                        onMenuOpen={() => CasteRefetch()}
                                                        onCreateOption={handleCreateCaste}
                                                        onChange={handleChangeCaste}
                                                        value={valueCasteCreatable == null ? valueCaste : valueCasteCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.caste?.message}</span>
                                                </div> 
                                                 <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Blood Group <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select"  {...register("blood_group", { required: { value: true, message: "Blood Group is required" } })}>
                                                        <option value={""} selected >Select Any</option>
                                                        <option value="O+" >O+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="A+">A+</option>
                                                        <option value="A-">A-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="AB-">AB-</option>
                                                    </select>
                                                     <CreatableSelect
                                                        isClearable={() => {
                                                            setValueBloodGroup(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("blood_group", { required: { value: true, message: "Blood Group is required" } })}
                                                        placeholder={'Blood Group'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsBloodGroup}
                                                        onMenuOpen={() => BloodGroupRefetch()}
                                                        onCreateOption={handleCreateBloodGroup}
                                                        onChange={handleChangeBloodGroup}
                                                        value={valueBloodGroupCreatable == null ? valueBloodGroup : valueBloodGroupCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.blood_group?.message}</span>
                                                </div> */}

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Weekly Off <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueWeeklyOff(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("weekly_off", { required: { value: true, message: "Weekly Off is required" } })}
                                                        placeholder={'Weekly Off'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsWeeklyOff}
                                                        onMenuOpen={() => WeeklyOffRefetch()}
                                                        onCreateOption={handleCreateWeeklyOff}
                                                        onChange={handleChangeWeeklyOff}
                                                        value={valueWeeklyOffCreatable == null ? valueWeeklyOff : valueWeeklyOffCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.weekly_off?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Administration Type <span style={{ color: "red" }}>*</span></label>

                                                    <CreatableSelect
                                                        isClearable={() => {
                                                            setValueAdministrationType(null)
                                                            setValue(null)
                                                        }}
                                                        {...register("administration_type", { required: { value: true, message: "Administration Type is required" } })}
                                                        placeholder={'Administration Type'}
                                                        // menuIsOpen={true}
                                                        components={customCreatableIndicator}
                                                        options={optionsAdministrationType}
                                                        onMenuOpen={() => AdministrationTypeRefetch()}
                                                        onCreateOption={handleCreateAdministrationType}
                                                        onChange={handleChangeAdministrationType}
                                                        value={valueAdministrationTypeCreatable == null ? valueAdministrationType : valueAdministrationTypeCreatable}

                                                    />
                                                    <span className='text-red w-100'>{errors?.administration_type?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="name_and_grade">Reference By </label>
                                                    <div className="d-flex row">
                                                        <Select
                                                            {...register("reference", { required: { value: false, message: "Reference By is required" } })}
                                                            placeholder={'Reference By'}
                                                            onChange={handleChangeEmployee}
                                                            value={EmployeeValue}
                                                            options={optionsEmployee}
                                                            components={customIndicator}
                                                            maxMenuHeight={150}
                                                        />
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.employee?.message}</span>

                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Other Reference By </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Other Reference By"
                                                            disabled={!showOtherRef}
                                                            {...register("other_reference", { onChange: handleUpper, required: { value: false, message: "Other Reference By is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.other_reference?.message}</span>
                                                </div>
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Remark </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Remark" {...register("re_mark", { onChange: handleUpper, required: { value: false, message: "Remark is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                </div>

                                                {/* <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Employee  <span style={{ color: 'red' }}>*</span></label>


                                                    <select className="form-select" {...register("employee_status", { onChange: onEmployeeChange, required: { value: true, message: "Employee is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.employee_status?.message}</span>
                                                </div> */}

                                                {/*{EmployeeId === "yes" ?
                                                    <>
                                                    <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User ID <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="User ID"  {...register("username",{ required: { value: true, message: "User ID is required" }, maxLength: 30,})} 
                                                        onChange={handleUpper}
                                                        />
                                                         <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                    </div>
                                                        <span className='text-red w-100'>{errors?.username?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Password <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group-form" style={{width:"100%"}}>
                                                    <input
                                                        type={iconChange?iconChange:"password"}
                                                        className="form-control rounded-3"
                                                        placeholder="Password"
                                                        {...register("password", { required: {value: true, message: "Password is required"} })}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeicon} icon={iconChange == "password" ? faEye : faEyeSlash} /></span>
                                                    </div>
                                                </div>
                                                        <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>

                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">User Confirm Password <span style={{color:'red'}}>*</span></label>
                                                    <div className="input-group-form" style={{width:"100%"}}>
                                                    <input
                                                        type={confirmiconChange?confirmiconChange:"password"}
                                                        className="form-control rounded-3"
                                                        placeholder="Password"
                                                        {...register("password", { required: {value: true, message: "Password is required"} })}
                                                    />
                                                    <div className="input-group-append" >
                                                    <span className="iconset" id="basic-addon1"><FontAwesomeIcon onClick={changeconfirmicon} icon={confirmiconChange == "confirmpassword" ? faEye : faEyeSlash} /></span>
                                                    </div>
                                                </div>
                                                        <span className='text-red w-100'>{errors?.password?.message}</span>
                                                </div>
                                                    </>:""} */}
                                                <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">Status <span style={{ color: "red" }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Image All</label>
                                                    <Upload
                                                        {...register("image_all")}
                                                        accept="image/png, image/jpeg"
                                                        listType="picture"
                                                        onChange={imageChange}
                                                        multiple={true}
                                                        defaultFileList={uploadImage}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"image"}
                                                    >
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>

                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF All</label>
                                                    <Upload
                                                        {...register("document_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={DocumentChange}
                                                        multiple={true}
                                                        defaultFileList={uploadDocumentPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Agreement PDF All</label>
                                                    <Upload
                                                        {...register("agreement_pdf")}
                                                        accept=".doc,.docx,.xml,.pdf"
                                                        listType="text"
                                                        onChange={AgreementChange}
                                                        multiple={true}
                                                        defaultFileList={uploadAgreementPDF}
                                                        onPreview={(e: any) => { return "" }}
                                                        key={"pdf"}
                                                    >
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                    </Upload>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)}

                                                    // disabled={isLoading}

                                                    >Submit</button>
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
            <Account_Model refetchForm={accountRefetch} open={openAccount} setOpen={setOpenAccount} setSubmitted={setSubmittedAccount} />
            <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />
            <Department_Model refetchForm={departmentRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
            <Designation_Model refetchForm={designationRefetch} open={openDesignation} setOpen={setOpenDesignation} setSubmitted={setSubmittedDesignation} />


            {/* Edit Modal HTML */}
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />


        </div>
    )
}

// export default Add_Cavity

