import React from 'react'
import { Path, UseFormRegister, useForm, SubmitHandler } from 'react-hook-form'
import Select from 'react-select'
import IndicatorsContainer from '../../Select Dropdown/Select'
import Button from 'react-bootstrap/esm/Button'
import { AiOutlinePlus } from 'react-icons/ai'
const customIndicator = {
  IndicatorsContainer: IndicatorsContainer
}


interface InputField {
  id?: any,
  name?: any,
  type?: any,
  accept?: any,
  className?: any,
  placeholder?: any,
  htmlFor?: any,
  color?: any
  isPencil?: any
  message?: any
  Selvalue?: any,
  label?: Path<any>,
  register?: any,
  isRequired?: boolean,
  errors?: any;
  options?: any;
  isDisabled?: any
  isModal?: boolean
  onChange?: (e: any) => void,
  modal?: () => void,
  Cancel?: () => void;
  editValue?:any;
  isEdit?:boolean;
  isMulti?:boolean;
  OptionDisabled?:(e: any) => void;
}

const ReactSelect = ({ name, className, label, options, register, isRequired = true, message, errors, Selvalue,editValue,isEdit = false, onChange, modal, isModal = false,isMulti=false,isDisabled,OptionDisabled }: InputField) => {
 
  
  
  return (
    <div className={className ? className : "col-sm-3 mt-2"}>
      <label htmlFor="exampleInputEmail1"> {label} {isRequired && <span style={{ color: 'red' }}>*</span>} </label>
      <div className={className ? className : "d-flex row"} >
        <Select
          {...register(name, { required: { value: isRequired, message: message } })}
          placeholder={label}
          onChange={onChange}
          components={customIndicator}
          options={options}
          isMulti={isMulti}
          isDisabled={isDisabled}
          isOptionDisabled={OptionDisabled}
          value={
            isEdit && isMulti ? 
            Selvalue?.length === 0 ? options?.filter((obj: any) => editValue?.includes(obj?.value)) : Selvalue : 
            isEdit ?
            Selvalue === '' || Selvalue === null || Selvalue === undefined?options?.find((obj: any) => obj.value === editValue):Selvalue : 
            // Selvalue :
            // Selvalue : 
            Selvalue }
          
          className=  {isModal && "dropdown-select"} 
        />
        {isModal &&
          <div className={className ? className : "input-group-append"} style={{ width: 0, padding: 0 }}>
            <Button className={className ? className : "input-group-text border-start-0 bg-primary"} onClick={modal}><AiOutlinePlus /></Button>
          </div>
        }
      </div>

      {isRequired &&
        <span className={className ? className : "text-red w-100"}>{errors}</span>
      }
    </div>
  )
}

export default ReactSelect