import React from 'react';
import Select, { components, OptionProps } from 'react-select';
import { Tooltip } from '@mui/material';

// Define the type for your option
interface OptionType {
  value: string;
  label: string;
  tooltip: string; // Tooltip text for the option
}

// Custom option component
const CustomOptionDropDown: React.FC<OptionProps<OptionType>> = (props) => {
  const { data, innerRef, innerProps } = props;

  return (
    <Tooltip title={<span style={{fontSize:'13px'}}>{data.tooltip}</span>} placement="top">
      <div ref={innerRef} {...innerProps}>
        {data.label}
      </div>
    </Tooltip>
  
  );
};

export default CustomOptionDropDown;
