import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { jsPDF } from "jspdf";

function Qr() {

    const [src, setSrc] = useState("");
    const [src1, setSrc1] = useState("");
    const [text, setText] = useState("SMV070320240000");
    useEffect(() => {

        QRCode.toDataURL("https://quantumbot.in/")
            .then((dataURL) => {

                setSrc(dataURL);
            })
            .catch((error) => {

                console.error(error);
            });
        QRCode.toDataURL("https://lee10.quantumbot.in/")
            .then((dataURL) => {

                setSrc1(dataURL);
            })
            .catch((error) => {

                console.error(error);
            });
    }, []);


    const printPDF = () => {

        // const doc = new jsPDF("l", "mm", [100, 70], true);
        // doc.setProperties({ title: 'QR CODE' })
        // doc.addImage(src, "PNG", 0.8, 0, 70, 70);
        // doc.setFontSize(15);
        // doc.text(text, 12, 67);
        //other

        // const doc = new jsPDF("l", "mm", [100, 70], true);
        // doc.setProperties({ title: 'QR CODE' })
        // doc.addImage(src, "PNG", 12.8, 0, 70, 70);
        // doc.setFontSize(15);
        // doc.text(text, 24, 69);
        //other

        // const doc = new jsPDF("p", "mm", [80, 70], true);
        // doc.setProperties({ title: 'QR CODE' })
        // doc.addImage(src, "PNG", 0.8, 0, 70, 70);
        // doc.setFontSize(15);
        // doc.text(text, 12, 70);

        //other 
        const doc = new jsPDF("l", "mm", [100, 70], true);
        doc.setProperties({ title: 'QR CODE' })
        doc.addImage(src, "PNG", 15.8, 0, 35, 35);
        doc.setFont('normal');
        doc.setFontSize(8);
        doc.text(text, 21.5, 35);
        doc.addImage(src1, "PNG", 50.8, 0, 35, 35);
        doc.text(text, 57, 35);
        doc.addPage();
        doc.addImage(src, "PNG", 15.8, 0, 35, 35);
        doc.setFontSize(8);
        doc.text(text, 21.5, 35);
        doc.addImage(src1, "PNG", 50.8, 0, 35, 35);
        doc.text(text, 57, 35);

        doc.autoPrint();
        doc.output("pdfobjectnewwindow", { filename: "QR CODE" });



    };






    return (
        <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <form>
                                    <div className="row d-flex justify-content-lg-start justify-content-sm-around justify-content-md-center">
                                        <div>
                                            {/* <img src={src} alt="QR code" /> */}
                                            <p>{text}</p>
                                            <button onClick={printPDF}>Print</button>
                                        </div> </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Qr;