import { useEffect, useRef } from "react";
import "../../components/Table/table.css"
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useCreate } from "../../hooks/Api";
import { Alert } from "../../components/Model/Model";
import { RiPencilLine } from "react-icons/ri";
import useFocusOnEnter from "../../components/focus";
import Select from 'react-select'
import { useFetchDepartmentDropDown, useFetchResponsiblePerson } from "../../hooks/DropDown_Api";
// import TimePicker from "@semcore/ui/time-picker";

import { AiOutlinePlus } from "react-icons/ai";

import { DatePicker, Space, TimePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from "moment";
import dayjs from 'dayjs';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import { Department_Model } from "../../components/Dynamic_Form_Models/Department_Model";
import "./checkList.css"


const Add_CheckList = () => {
    const [open, setOpen] = useState(false)



    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);

    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_CHECKLIST_API?.toString()
    const [optionsDepartment, setOptionsDepartment]: any = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues }: any = useForm();


    const [forDepartmentValue, setForDepartmentValue]: any = useState([])
    const [forOpenTimeValue, setForOpenTimeValue]: any = useState()
    const [forCloseTimeValue, setForCloseTimeValue]: any = useState()

    const [forPointStartDateValue, setForPointStartDateValue]: any = useState()
    const [forPointEndDateValue, setForPointEndDateValue]: any = useState()
    const [forEmergencyOnDateValue, setForEmergencyOnDateValue]: any = useState()
    const [forEmergencyOffDateValue, setForEmergencyOffDateValue]: any = useState()








    const { data: colorData, refetch: colorRefetch } = useFetchDepartmentDropDown()

    useEffect(() => {
        setOptionsDepartment(colorData)
    }, [colorData])


    useEffect(() => {
        if (searchTerm.length >= 1) {
            colorRefetch()
        }
    }, [])








    const handleChange = (e: any) => {



        const department = e?.map((value: any) => {
            return value?.value
        })
        setValue("department_name", department)

        // setForDepartmentValue(e?.map((e:any)=>{
        //     return(
        //         {label:e?.label,value:e?.value}
        //     )
        // }))

        setForDepartmentValue(e)


    }

    const onSubmit = (pannidetail: any) => {

        if (!emergencyOn) {
            delete pannidetail.emergency_on_date
            delete pannidetail.emergency_close_date
            delete pannidetail.emergency_on_reason
            delete pannidetail.emergency_off_reason
            delete pannidetail.emergency_on_off_person_name
        }

        if (!forEmergencyOnDateValue) {
            delete pannidetail.emergency_on_date
        }
        if (!forEmergencyOffDateValue) {
            delete pannidetail.emergency_close_date
        }


        if (!forPointStartDateValue) {
            delete pannidetail.point_start_date
        }
        if (!forPointEndDateValue) {
            delete pannidetail.point_end_date
            delete pannidetail.total_running_days
            delete pannidetail.pending_running_days
        }


        if (!forOpenTimeValue) {
            delete pannidetail.point_open_time
        }
        if (!forCloseTimeValue) {
            delete pannidetail.point_close_time
        }


        const formData = new FormData();
        for (const [key, value] of Object.entries(pannidetail)) {

            const formvalue: any = value

            if (key === "point_start_date") {
                const renewdate = moment(pannidetail.point_start_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (key === "point_end_date") {
                const renewdate = moment(pannidetail.point_end_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (key === "emergency_on_date") {
                const renewdate = moment(pannidetail.emergency_on_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (key === "emergency_close_date") {
                const renewdate = moment(pannidetail.emergency_close_date, 'YYYY-MM-DD').format("DD-MM-YYYY")
                formData.append(key, renewdate)
            }
            else if (Array.isArray(value) && key === "name_and_surname") {
                value.forEach((val, index) => formData.append(`name_and_surname`, val))
            }
            else if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

            else if (Array.isArray(value) && key === "department_name") {
                value.forEach((val, index) => formData.append(`department_name`, val))
            }

            // else if (formvalue.hasOwnProperty('size')) {
            //     formData.append(key, formvalue[0])
            // }



        }
        mutate(formData);
    }










    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listChecklist', { state: { showToast: true } })
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const handleValue = (e: any) => {
        e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    }


    const [openTime, setOpenTime]: any = useState()
    const [closeTime, setCloseTime]: any = useState()
    const [minutes, setMinutes]: any = useState()


    useEffect(() => {
        if (openTime && closeTime) {

            const TimeOne = moment()
                .set('hour', openTime?.$H)
                .set('minute', openTime?.$m)
            const TimeTwo = moment()
                .set('hour', closeTime?.$H)
                .set('minute', closeTime?.$m)

            setMinutes(TimeTwo.diff(TimeOne, 'minutes'))

            setValue("point_open_time", `${openTime?.$H}:${openTime?.$m}`)
            setValue("point_close_time", `${closeTime?.$H}:${closeTime?.$m}`)

            setValue("total_min", minutes)

        }
    }, [openTime, closeTime, minutes])




    const [forDisableDate, setForDisableDate]: any = useState()



    const [startDate, setStartDate]: any = useState()
    const [endDate, setEndDate]: any = useState()
    const [totalDays, setTotalDays]: any = useState()
    const [remainingDays, setRemainingDays]: any = useState()

    const handleStartDate = (e: any) => {
        setForDisableDate(e)
        setStartDate(e)
        // setValue("start_date", e)
        setForPointStartDateValue(e)

    }

    const handleEndDate = (e: any) => {
        setEndDate(e)
        // setValue("close_date", e)
        setForPointEndDateValue(e)

    }

    let date1 = getValues("start_date")
    let date2 = getValues("close_date")









    useEffect(() => {


        if (startDate && endDate) {

            let todayDate = new Date().toISOString().split("T")[0]
            var DateOne = moment([startDate.$y, startDate.$M + 1, startDate.$D]);
            var DateTwo = moment([endDate.$y, endDate.$M + 1, endDate.$D]);
            var currentDate = moment([todayDate.split("-")[0], todayDate.split("-")[1], todayDate.split("-")[2]]);

            setTotalDays(DateTwo.diff(DateOne, 'days') + 1)
            setRemainingDays(DateTwo.diff(currentDate, 'days') + 1)
            setValue("point_start_date", `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`)
            setValue("point_end_date", `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}`)

            setValue("total_running_days", totalDays)
            setValue("pending_running_days", remainingDays)
        }
    }, [startDate, endDate, totalDays, remainingDays])


    // useEffect(()=>{

    // },[totalDays,minutes])


    const [emergencyOnDate, setEmergencyOnDate]: any = useState()
    const [emergencyOffDate, setEmergencyOffDate]: any = useState()

    const handleEmegencyOnDate = (e: any) => {
        setEmergencyOnDate(e)
        setForEmergencyOnDateValue(e)

    }

    const handleEmergencyOffDate = (e: any) => {
        setEmergencyOffDate(e)
        setForEmergencyOffDateValue(e)



    }

    useEffect(() => {

        if (emergencyOnDate && emergencyOffDate) {
            setValue("emergency_on_date", `${emergencyOnDate.$y}-${emergencyOnDate.$M + 1}-${emergencyOnDate.$D}`)
            setValue("emergency_close_date", `${emergencyOffDate.$y}-${emergencyOffDate.$M + 1}-${emergencyOffDate.$D}`)
        }

    }, [emergencyOnDate, emergencyOffDate])




    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {





        return current && current < dayjs().startOf('day');
    };





    const format = 'HH:mm';


    const handleReset = () => {
        reset()
        setMinutes("")
        setTotalDays("")
        setRemainingDays("")
        setForDepartmentValue(null)
        setForOpenTimeValue(null)
        setForCloseTimeValue(null)
        setForPointStartDateValue(null)
        setForPointEndDateValue(null)
        setForEmergencyOnDateValue(null)
        setForEmergencyOffDateValue(null)
        setForResponsiblePersonValue(null)
        setTotalDays('')
        setStartDate('')
        setEndDate('')
        setRemainingDays('')
    }





    const [emergencyOn, setEmergencyOn] = useState(false)

    //    const handleNormal = ()=>{

    //    }

    let ok = getValues("normal")


    const [submitted, setSubmitted] = useState(false)


    useEffect(() => {
        if (submitted) {
            setForDepartmentValue(optionsDepartment[0])

            setValue("department_name", optionsDepartment[0].value)

        }

    }, [submitted, optionsDepartment])



    const disabledTime = (selectedHour: any) => {
        const hours: any = [];
        const minutes: any = []



        const currentHour = openTime?.$H
        const currentMinute = openTime?.$m;


        for (let i = currentHour - 1; i <= 24; i--) {

            hours.push(i);
            if (i == -1) {
                break
            }
        }


        // for (let i = currentMinute + 0; i <= 60; i--) {
        //     minutes.push(i);
        //     if (i == -1) {
        //         break
        //     }
        // }


        return {
            disabledHours: () => hours,
            // disabledMinutes: () => minutes,
        }



    };


    const [optionsResponsiblePerson, setOptionsResponsiblePerson]: any = useState([])
    const [forResponsiblePersonValue, setForResponsiblePersonValue]: any = useState([])



    const { data: personData, refetch: personRefetch } = useFetchResponsiblePerson(searchTerm)

    useEffect(() => {
        setOptionsResponsiblePerson(personData);
    }, [personData])


    const handleResponsiblePerson = (e: any) => {

        const printing_color_name = e.map((value: any) => {
            return value.value
        })
        setValue("name_and_surname", printing_color_name)
        setForResponsiblePersonValue(e)
    }






    return (
        <div>

            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header py-1 px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Check List</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="/listColor" className="text-decoration-none text-black">Home</Link></li>
                                    {/* <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Check</Link></li> */}
                                    <li className="breadcrumb-item"><Link to="/listCheckList" className="text-decoration-none text-black">Check List</Link></li>
                                    <li className="breadcrumb-item">Add Check List</li>

                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            <form
                                ref={focus}
                                onKeyUp={event => onEnterKey(event)}
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                    {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">



                                                <div className="">
                                                    <div className="title"></div>
                                                    <div className="form">
                                                        <label><input type="radio" className="input-radio off" value={"normal"} {...register("normal")} checked={emergencyOn === false} name="emergency"
                                                            // onChange={handleNormal}
                                                            onClick={() => setEmergencyOn(false)}
                                                        />Normal</label>
                                                        <label><input type="radio" className="input-radio on" checked={emergencyOn === true} value={"emergency"} {...register("emergency")} name="emergency"
                                                            onClick={() => setEmergencyOn(true)}
                                                        />Emergency</label>
                                                    </div>
                                                </div>



                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="table_sr_no">Point <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" placeholder="Point" className="form-control" id="table_sr_no"
                                                            {...register("name", { onChange: handleValue, required: { value: true, message: "Point is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.name?.message}</span>
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="brand_type">Yes-No <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("choice_yes_no", { required: { value: true, message: "Field is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"yes"}>Yes</option>
                                                        <option value={"no"}>No</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.choice_yes_no?.message}</span>
                                                </div>




                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="name_and_grade">Department <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <div className="input-group" ></div> */}
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("department_name", { required: { value: true, message: "Department is required" } })}
                                                            placeholder={'Department'}
                                                            onChange={handleChange}
                                                            value={forDepartmentValue}
                                                            options={optionsDepartment}
                                                            className="dropdown-select"
                                                            isMulti
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpen(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.department_name?.message}</span>

                                                </div>




                                                {/* <div className="col-sm-4 mt-2">
                                                <label htmlFor="sub_name">Responsible Person <span style={{ color: 'red' }}>*</span></label>
                                                <div className="input-group" >
                                                    <input type="text" placeholder="Responsible Person" className="form-control"   
                                                    {...register("name_and_surname", { onChange: handleValue, required: { value: true, message: "Responsible Person is required" } } )} />
                                                    <div className="input-group-append" >
                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                        /></span>
                                                    </div>
                                                </div>
                                                <span className='text-red w-100'>{errors?.name_and_surname?.message}</span>
                                            </div> */}

                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="sub_name">Responsible Person <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("name_and_surname", { required: { value: false, message: "Responsible Person is required" } })}
                                                            placeholder={'Select'}
                                                            value={forResponsiblePersonValue}
                                                            options={optionsResponsiblePerson}
                                                            // onInputChange={handlePanniInputChange}
                                                            onChange={handleResponsiblePerson}
                                                            isMulti={true}
                                                        // isOptionDisabled={(optionsPanniName:any)=>
                                                        //     {
                                                        //         if(optionsPanniName.status === "inactive" || optionsPanniName.deleted == true){
                                                        //         return optionsPanniName
                                                        //         }
                                                        //        }}

                                                        />

                                                    </div>
                                                </div>






                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="full_code"> Point Open Time <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" style={{ justifyContent: "center" }}>


                                                        {/* <TimePicker size="l" state="normal" 
                                                onChange={(e:any)=>setOpenTime(e)}
                                                 /> */}


                                                        <TimePicker format={format} className="form-control"
                                                            showNow={false}
                                                            {...register("point_open_time", { required: { value: true, message: "Point Open Time is required" } })}
                                                            value={forOpenTimeValue}


                                                            onChange={(e: any) => {

                                                                setOpenTime(e)
                                                                setForOpenTimeValue(e)
                                                            }} />


                                                    </div>
                                                    <span className='text-red w-100'>{errors?.point_open_time?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="default_no">Point Close Time <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" style={{ justifyContent: "center" }}>
                                                        {/* <TimePicker size="l" state="normal" 
                                                 onChange={(e:any)=>setCloseTime(e)} 
                                                /> */}

                                                        <TimePicker format={format} className="form-control"
                                                            showNow={false}
                                                            {...register("point_close_time", { required: { value: true, message: "Point Close Time is required" } })}
                                                            value={forCloseTimeValue}
                                                            disabledTime={disabledTime}


                                                            onChange={(e: any) => {
                                                                setCloseTime(e)
                                                                setForCloseTimeValue(e)

                                                            }} />




                                                    </div>
                                                    <span className='text-red w-100'>{errors?.point_close_time?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="manualcode_1">Total Minutes</label>
                                                    <div className="input-group" >

                                                        <input type="text" disabled placeholder="Total Minutes" className="form-control" id="total_time"
                                                            value={minutes}
                                                            {...register("total_min", { onChange: handleValue })} />

                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>

                                                    {/* <span className='text-red w-100'>{errors?.total_time?.message}</span> */}

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="manualcode_Disc_1">Point Start Date <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >


                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={forPointStartDateValue}
                                                            disabledDate={disabledDate}
                                                            {...register("point_start_date", { required: { value: true, message: "Point Start Date is required" } })}

                                                            onChange={handleStartDate}
                                                        />



                                                    </div>
                                                    <span className='text-red w-100'>{errors?.point_start_date?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="manualcode_2">Point Close Date</label>
                                                    <div className="input-group" data-colorpicker-id={2}>

                                                        <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={forPointEndDateValue}

                                                            disabledDate={disabledDate}
                                                            {...register("point_end_date", { required: { value: false, message: "Field is required" } })}

                                                            onChange={handleEndDate}
                                                        />

                                                    </div>
                                                    <span className='text-red w-100'>{errors?.point_end_date?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="manualcode_Disc_2">Total Running Day </label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" disabled placeholder="Total Running Day" className="form-control" id="manualcode_Disc_2"
                                                            value={totalDays}
                                                            {...register("total_running_days", { onChange: handleValue })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.total_day?.message}</span> */}

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="serial_no">Remaining Running Day </label>
                                                    <div className="input-group" >
                                                        <input type="text" disabled placeholder="Running Day" className="form-control" id="serial_no"
                                                            value={remainingDays}
                                                            {...register("pending_running_days", { onChange: handleValue })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    {/* <span className='text-red w-100'>{errors?.pending_running_days?.message}</span> */}

                                                </div>

                                                {emergencyOn && <>

                                                    <div className="col-sm-4 mt-2">
                                                        <label htmlFor="manualcode_Disc_1">Emergency On Date <span style={{ color: 'red' }}>*</span></label>
                                                        <div className="input-group" >

                                                            {/* <input type="date" placeholder="Manualcode Discription-1" className="form-control" id="start_date"  {...register("start_date", { onChange: handleValue })} /> */}
                                                            <DatePicker
                                                                className="form-control"
                                                                format={dateFormatList}
                                                                value={forEmergencyOnDateValue}
                                                                disabledDate={disabledDate}
                                                                {...register("emergency_on_date", { required: { value: emergencyOn ? true : false, message: "Emergency On Date is required" } })}
                                                                onChange={handleEmegencyOnDate}


                                                            />

                                                        </div>
                                                        <span className='text-red w-100'>{errors?.emergency_on_date?.message}</span>

                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <label htmlFor="manualcode_2">Emergency On Reason <span style={{ color: 'red' }}>*</span></label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" placeholder="Emergency On Reason" className="form-control" id="manualcode_2"
                                                                {...register("emergency_on_reason", { onChange: handleValue, required: { value: emergencyOn ? true : false, message: "Emergency On Reason is required" } })} />
                                                            <div className="input-group-append" >
                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                /></span>
                                                            </div>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.emergency_on_reason?.message}</span>

                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <label htmlFor="manualcode_Disc_1">Emergency Off Date <span style={{ color: 'red' }}>*</span></label>
                                                        <div className="input-group" >
                                                            {/* <input type="date" placeholder="Manualcode Discription-1" className="form-control" id="start_date"  {...register("start_date", { onChange: handleValue })} /> */}
                                                            <DatePicker
                                                                className="form-control"
                                                                format={dateFormatList}
                                                                value={forEmergencyOffDateValue}

                                                                disabledDate={disabledDate}

                                                                {...register("emergency_close_date", { required: { value: emergencyOn ? true : false, message: "Emergency Off Date is required" } })}
                                                                onChange={handleEmergencyOffDate}


                                                            />



                                                        </div>
                                                        <span className='text-red w-100'>{errors?.emergency_close_date?.message}</span>

                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <label htmlFor="manualcode_2">Emergency Off Reason <span style={{ color: 'red' }}>*</span></label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" placeholder="Emergency Off Reason" className="form-control"
                                                                {...register("emergency_off_reason", { onChange: handleValue, required: { value: emergencyOn ? true : false, message: "Emergency Off Reason is required" } })} />
                                                            <div className="input-group-append" >
                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                /></span>
                                                            </div>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.emergency_off_reason?.message}</span>

                                                    </div>
                                                    <div className="col-sm-4 mt-2">
                                                        <label htmlFor="manualcode_2">Emergency On-Off Person Name <span style={{ color: 'red' }}>*</span></label>
                                                        <div className="input-group" data-colorpicker-id={2}>
                                                            <input type="text" placeholder="Emergency On-Off Person Name" className="form-control"
                                                                {...register("emergency_on_off_person_name", { onChange: handleValue, required: { value: emergencyOn ? true : false, message: "Emergency On-Off Person Name is required" } })} />
                                                            <div className="input-group-append" >
                                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                /></span>
                                                            </div>
                                                        </div>
                                                        <span className='text-red w-100'>{errors?.emergency_on_off_person_name?.message}</span>

                                                    </div>
                                                </>
                                                }

                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="brand_type">On-Off <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select"
                                                        {...register("point_on_off", { required: { value: true, message: "Field is required" } })}>
                                                        <option value={""} selected>Select Any</option>
                                                        <option value={"on"}>On</option>
                                                        <option value={"off"}>Off</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.point_on_off?.message}</span>
                                                </div>


                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="remarks">Suggetion Person <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <input type="text" placeholder="Suggetion Person" className="form-control" id="remarks"
                                                            {...register("suggested_by", { onChange: handleValue, required: { value: true, message: "Suggetion Person is required" } })} />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            /></span>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.suggested_by?.message}</span>

                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label htmlFor="remarks">Status <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" data-colorpicker-id={2}>
                                                        <select className="form-select" {...register("status",
                                                            {
                                                                required: { value: true, message: "Status is required" }, maxLength: 30,

                                                            })}>
                                                            <option value="" selected>Select Any </option>
                                                            <option value={"active"}>Active</option>
                                                            <option value={"inactive"}>Inactive</option>
                                                        </select>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                    <button
                                                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                        onClick={handleSubmit(onSubmit)} disabled={isLoading}>Submit</button>

                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listCheckList")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
            {/* {openModel&& */}
            <Department_Model refetchForm={colorRefetch} open={open} setOpen={setOpen} setSubmitted={setSubmitted} />
            {/* } */}

            {/* <Footer /> */}
        </div>
    )
}

export default Add_CheckList


