import React, { useEffect, useRef, useState } from "react";
// import SearchLogo from "../../images/SearchLogo.png";
import cameraLogo from "../../../images/Export/Camera.png";
import pdfLogo from "../../../images/Export/pdf upload.png";
import "../../../components/Header/header.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import axios from 'axios'
import { useCreate, useUpdateSingle } from "../../../hooks/Api";
import InputField from "../../../components/Form Component/inputfield";
import Error from "../../../components/Form Component/error";
import { green } from "@mui/material/colors";
import useFocusOnEnter from "../../../components/focus";
import { RiPencilLine } from "react-icons/ri";
import CreatableSelect from "react-select/creatable";
import { useFetchEmployeeDropDown, useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import { Upload, UploadFile } from "antd";
import { Employee_Model } from "../../../components/Dynamic_Form_Models/Employee_Model";
import Button from "react-bootstrap/esm/Button";
import { AiOutlinePlus } from "react-icons/ai";
// import { useFetchPersonTypeDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import "./Multiple.css"
import Offcanvas from 'react-bootstrap/Offcanvas';
import Axiosinstance from "../../../hooks/Interceptor";
import Table from "../../../components/Dynamic_Table/Table/Table";
import { Columns_Sales_Document, titleofheaders } from "./Columns_Sales_Document"
import Stepper_Table from "../../../components/Dynamic_Table/Table/Stepper Table";

const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
interface Option {
    readonly label: string;
    //   readonly value: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    // value: label,
    name: label,
    status: "active"
});

const Add_Sales_Document = ({ setCurrentStep, currentStep }: any) => {


    let pageName = "Document Detail"

    const focus: any = useRef();
    const { onEnterKey } = useFocusOnEnter(focus);
    const navigate = useNavigate()

    const [logo, setlogo]: any = useState([])
    const [logoPdf, setlogoPdf]: any = useState([])
    const [openEmployee, setOpenEmployee]: any = useState(false)

    const [File, setFile]: any = useState()
    const apiname = process.env.REACT_APP_DOCUMENT_DETAIL_API?.toString()


    const { mutate, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname)


    const { register, handleSubmit, setError, formState: { errors }, reset, setValue, getValues, control }: any = useForm();

    useEffect(() => {
        if (data) {
            reset(data)
        }
    }, [data]);

    const handleReset = () => {
        reset()
        setUploadFile([])
        setUploadFileDoc([])
    }

    const onSubmit = (details: any) => {


        if (logo.length == 0) {
            delete details?.image
            delete details?.images
        }

        if (logoPdf.length == 0) {
            delete details?.pdf
            delete details?.pdfs
        }



        const formData = new FormData();


        for (const [key, value] of Object.entries(details)) {
            const formvalue: any = value

            if (key === "images") {
                for (let i = 0; i < logo.length; i++) {

                    formData.append('images', logo[i])
                }
                continue
            }
            if (key === "pdfs") {
                for (let i = 0; i < logoPdf.length; i++) {

                    formData.append('pdfs', logoPdf[i])
                }
                continue
            }



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        mutate(formData);
        setAdded(true)
    }

    const [employeeOptions, setEmployeeOptions]: any = useState([])
    const [forEmployeeValue, setForEmployeeValue]: any = useState("")
    const { data: EmployeeData, refetch: EmployeeRefetch } = useFetchEmployeeDropDown()


    useEffect(() => {
        console.log('EmployeeData', EmployeeData)
        setEmployeeOptions(EmployeeData)
    }, [EmployeeData])


    const handleChangeEmployee = (e: any) => {
        setForEmployeeValue(e)
        setValue("employee_code", e.value)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")
    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            
            // setValue("document_type", "")
            // setValue("in_print_name", "")
            // setValue("document_number", "")
            // setValue("remark", "")
            // setValue("status", "")
            reset()
            setUploadFile([])
            setUploadFileDoc([])
            setDocumentType(null)
            setRegexForDocumentNumber(null)
            setlogo(null)
            setlogoPdf(null)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);


    const [ids, setIds]: any = useState([])
    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setIds((oldIds: any) => ([...oldIds, data?.results?.id]));

                setAdded(false)

            }
        }
    }, [isSuccess, data]);




    useEffect(() => {


        if (isSuccess && data) {

            let tempId = {
                document: ids
            }


            let tempData = {
                id: basicId,
                apiname: apinameForBasicDetails,
                data: tempId
            }
            BasicDetails(tempData);
        }

    }, [data, isSuccess, ids])

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()


    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [isSuccess, data, error])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);


    const [documentType, setDocumentType]: any = useState("")
    const [regexForDocumentNumber, setRegexForDocumentNumber]: any = useState()

    const handleDocumentType = (e: any) => {


        const type = e.target.value
        if (type === "Aadhar") {
            setDocumentType("Aadhar Card")
            setRegexForDocumentNumber(/^\d{4}\s\d{4}\s\d{4}$/)
        } else if (type === "Pan") {
            setDocumentType("Pan Card")
            setRegexForDocumentNumber(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
        } else if (type === "Election") {
            setDocumentType("Election Card")
            setRegexForDocumentNumber(/^[A-Z]{3}[0-9]{7}$/)
        } else if (type === "Passport") {
            setDocumentType("Passport")
            setRegexForDocumentNumber(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/)
        } else if (type === "Driving") {
            setDocumentType("Driving License")
            setRegexForDocumentNumber(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/)
        } else if (type === "GST") {
            setDocumentType("GST Certificate")
            setRegexForDocumentNumber(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)

        }

    }



    const [uploadFile, setUploadFile] = useState<UploadFile[]>([])
    const [uploadFileDoc, setUploadFileDoc] = useState<UploadFile[]>([])

    const FileOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogo(tempFiles)
        setUploadFile(e.fileList)

    }
    const DocOnChange = (e: any) => {
        e.file.status = "done"

        const tempFiles = e.fileList.map((file: any) => {
            return file.originFileObj
        })
        setlogoPdf(tempFiles)
        setUploadFileDoc(e.fileList)

    }


    const [SubmittedEmployee, setSubmittedEmployee]: any = useState(false)

    useEffect(() => {
        if (SubmittedEmployee) {

            setForEmployeeValue(employeeOptions[0])


            setValue("employee_code", employeeOptions[0]?.value)

        }

    }, [SubmittedEmployee, employeeOptions])


    const [showOffcancas, setShowOffcancas] = useState(false);

    const handleClose = () => setShowOffcancas(false);
    const handleShow = () => setShowOffcancas(true);

    const filter = {

        name: ""

    };

    const addButtonName = "Document Detail";
    const addButtonPageName = "salesDocument"
    const permissionClass = {
        front: "document",
        back: "documentdetail"
    }
    const stickyStart = 1;
    const stickyEnd = 2;

    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    let basicId = addData ? addData.id : null;
    const handleNext = () => {
        const currentPath = window.location.pathname;
        if (currentPath === '/addCustomerDetails') {
            setCurrentStep(currentStep + 1)
        };
        // BasicDetails({})
    }


    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            const currentPath = window.location.pathname;
            if (currentPath === '/addCustomerDetails') {
                setCurrentStep(currentStep + 1)
            };
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    const [isNew, setIsNew] = useState(false)





    const [activePage, setActivePage] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setActivePage('Add Sales')
        }, 500)

    }, [activePage]);


    const currentPath = window.location.pathname;



    return (
        <div>
            <div className="" >
                <section className="content-header px-3 py-1">
                    {/* <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>{pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">Sales</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-decoration-none text-black">{pageName}</a></li>
                                    <li className="breadcrumb-item active">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div> */}
                </section>
                <section className="">
                    <div className="">
                        <div className="card card-default">
                            <div className="row">
                                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name ? addData?.name : addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                            </div>
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add Document</span></h5>
                            </div> */}


                            <div className="row d-flex  ">
                                {/* <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 "> */}
                                <div className="row d-flex ">
                                    <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-end text-sm-end ">
                                        <button
                                            type="button" className="border-1 rounded text-white px-4 py-1"
                                            style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                            onClick={handleShow}

                                        >Add Document Details</button>

                                    </div>
                                </div>
                                {/* </div> */}
                            </div>




                            <Offcanvas show={showOffcancas} onHide={handleClose} placement={'end'} style={{ width: '178vh', zIndex: '1000', top: '71px' }} >
                                <Offcanvas.Header closeButton>
                                    {/* <Offcanvas.Title>Add Address</Offcanvas.Title> */}
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className="card card-default">


                                        <form
                                            ref={focus}
                                            onKeyUp={event => onEnterKey(event)}

                                            onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body" style={{ maxHeight: "80vh", overflow: "auto" }}>
                                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}
                                                <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            {/* <div className="col-sm-3 mt-2">

                                                    <label >Code & Full Name & City <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("customer_code", { required: { value: true, message: "Field is required" } })}
                                                            value={forEmployeeValue}
                                                            options={employeeOptions}
                                                            onChange={handleChangeEmployee}
                                                            components={customIndicator}
                                                           

                                                            className="dropdown-select"
                                                        />
                                                        <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenEmployee(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.customer_code?.message}</span>

                                                </div> */}


                                                            <div className="col-sm-3 mt-2">
                                                                <label htmlFor="exampleInputEmail1">Document Name <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("document_type",
                                                                    {
                                                                        required: { value: true, message: "Type of File is required" },

                                                                    })}
                                                                    onChange={handleDocumentType}
                                                                >
                                                                    <option value="" selected>Select Any</option>
                                                                    <option value="Aadhar">Aadhar Card</option>
                                                                    <option value="Pan">Pan Card</option>
                                                                    <option value="Election">Election Id Card</option>
                                                                    <option value="Passport">Passport</option>
                                                                    <option value="Driving">Driving License</option>
                                                                    <option value="GST">GST Certificate</option>
                                                                </select>
                                                                {errors.document_type && <Error>{errors?.document_type?.message}</Error>}
                                                            </div>


                                                            <div className="col-sm-3 mt-2">


                                                                <label >Document In Print Name<span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"

                                                                        placeholder={'Document In Print Name'}


                                                                        {...register('in_print_name', { required: { value: true, message: "Field is required" } })}
                                                                    //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.in_print_name?.message}</span>
                                                            </div>


                                                            <div className="col-sm-3 mt-2">


                                                                <label >Document Number <span style={{ color: 'red' }}>*</span></label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"

                                                                        placeholder={documentType ? documentType : "Document Number"}


                                                                        {...register('document_number', {
                                                                            required: 'Field is required',
                                                                            pattern: {
                                                                                value: regexForDocumentNumber,
                                                                                message: 'Please enter a valid document',
                                                                            },
                                                                        })}
                                                                    //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.document_number?.message}</span>
                                                            </div>





                                                            <div className="col-sm-3 mt-2">


                                                                <label >Re-Mark</label>
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control"

                                                                        placeholder={'Re-Mark'}


                                                                        {...register('remark', { required: { value: false, message: "Field is required" } })}
                                                                    //  {...register("document_number", { required: { value: true, message: "Field is required" } })} 
                                                                    />
                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                                        /></span>

                                                                    </div>
                                                                </div>
                                                                <span className='text-red w-100'>{errors?.remark?.message}</span>
                                                            </div>

                                                            <div className="col-sm-3 mt-2">
                                                                <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                                <select className="form-select" {...register("status",
                                                                    {
                                                                        required: { value: true, message: " Status is required" }

                                                                    }
                                                                )}>
                                                                    <option value="" selected >Select Any</option>
                                                                    <option value={"active"}>Active</option>
                                                                    <option value={"inactive"}>Inactive</option>
                                                                </select>
                                                                <span className='text-red w-100'>{errors?.status?.message}</span>


                                                            </div>



                                                            {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="application/pdf" className="image-input" data-traget-resolution="image_resolution"  {...register("pdf",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    {documentPDF&&<p style={{display:"inline-flex"}}>{documentPDF} 
                                                    </p>}
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div> */}

                                                        </div>
                                                    </div>



                                                    <div className="row d-flex">
                                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                                            <div className="row d-flex ">
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document Image All</label>
                                                                    <Upload
                                                                        {...register("images")}
                                                                        accept="image/png, image/jpeg"
                                                                        listType="picture"
                                                                        onChange={FileOnChange}
                                                                        multiple={true}
                                                                        fileList={uploadFile}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"images"}
                                                                    >
                                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                                    </Upload>

                                                                </div>
                                                                <div className="col-sm-3 mt-2 image-upload">
                                                                    <label htmlFor="exampleInputEmail1" className="col-12">Document PDF All</label>
                                                                    <label style={{ cursor: "pointer" }}>
                                                                    </label>
                                                                    <Upload
                                                                        {...register("pdfs")}
                                                                        accept=".doc,.docx,.xml,.pdf"
                                                                        listType="text"
                                                                        onChange={DocOnChange}
                                                                        multiple={true}
                                                                        fileList={uploadFileDoc}
                                                                        onPreview={(e: any) => { return "" }}
                                                                        key={"pdfs"}
                                                                    >
                                                                        <img src={pdfLogo} alt='' className="uploaded-image" />
                                                                    </Upload>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row d-flex  ">
                                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                            <div className="row d-flex ">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                                    <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

                                                                    <button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

                                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                </Offcanvas.Body>
                            </Offcanvas>




                            <Stepper_Table
                                apiname={apiname}
                                filterFields={filter}
                                addButtonName={addButtonName}
                                stickyStart={stickyStart}
                                stickyEnd={stickyEnd}
                                TableColumns={Columns_Sales_Document}
                                addButtonPageName={addButtonPageName}
                                titleofheaders={titleofheaders}
                                permissionClass={permissionClass}
                                isMore={false}
                                isAddButton={false}
                                // handleMultipleData={multipleData}
                                multipleIds={multipleIds}
                                added={added}
                                setAdded={setAdded}
                                setShowOffcancas={setShowOffcancas}
                                setMultipleIds={setMultipleIds}
                                setIsNew={setIsNew}
                                activePage={activePage}
                            />




                            {/* <div className="col-sm-3 mt-2">
                                                    <label >Person Type <span style={{ color: 'red' }}>*</span></label>
                                                    <CreatableSelect
                                                        {...register("person_type", { required: { value: true, message: "Field is required" } })}
                                                        isClearable={() => {
                                                            setValuePersonType(null)
                                                            setValue(null)
                                                        }}

                                                        placeholder={'Person Type'}
                                                        options={optionsPersonType}
                                                        onCreateOption={handleCreatePersonType}
                                                        onChange={handleChangePersonType}
                                                        components={customCreatableIndicator}
                                                        value={valuePersonTypeCreatable == null ? valuePersonType : valuePersonTypeCreatable}


                                                    />
                                                    <span className='text-red w-100'>{errors?.person_type?.message}</span>
                                                </div> */}





                            <div className="row d-flex  ">
                                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className="row d-flex ">
                                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center " style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                {currentPath === '/addCustomerDetails' ?
                                                    <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "#fd7e14", background: "white", color: "#fd7e14" }} onClick={() => setCurrentStep(currentStep + 1)}>Skip</button>
                                                    : null}
                                                {/* <button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button> */}

                                                {currentPath === '/addCustomerDetails' ?
                                                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => setCurrentStep(currentStep - 1)}>Back</button>
                                                    : null}
                                            </div>
                                            <div>

                                                <button
                                                    type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                    style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                    onClick={handleNext}
                                                >Submit</button>
                                                <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </section>
            </div>
            <Employee_Model refetchForm={EmployeeRefetch} open={openEmployee} setOpen={setOpenEmployee} setSubmitted={setSubmittedEmployee} />
        </div>
    )
}

export default Add_Sales_Document