import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { jsPDF } from "jspdf";

function Qr() {

    const [src, setSrc] = useState("");
    const [src1, setSrc1] = useState("");
    const [text, setText]:any = useState('QB-Mouse');
    
    // useEffect(() => {
    //     QRCode.toDataURL("https://quantumbot.in/")
    //         .then((dataURL:any) => {

    //             setSrc(dataURL);
    //         })
    //         .catch((error:any) => {

    //             console.error(error);
    //         });
    //     QRCode.toDataURL("https://lee10.quantumbot.in/")
    //         .then((dataURL:any) => {

    //             setSrc1(dataURL);
    //         })
    //         .catch((error:any) => {

    //             console.error(error);
    //         });
    // }, [text]);

    const handleChange = (e:any) => {
       

        // setText(`QB-${e.target.value}`)
        
    }

    const printPDF = () => {
        const doc = new jsPDF("l", "mm", [100, 70], true);
      let qrCount = 1
      let urlValue = '';  
      for (let i = 0; i < 100; i++) {

        
        
          let pageNumber = Math.floor(i / 4) + 1;
          doc.setProperties({ title: `QR CODE - Page ${pageNumber}` });

          QRCode.toDataURL(`${text}-${qrCount}`, function (err, url) {
            urlValue = url
          })
        
      
          doc.addImage(urlValue, "PNG", 5.8, (i % 4) * 35, 34, 34);
          doc.setFont('bold');
          doc.setFontSize(8.5);
          doc.text(`${text}-${qrCount}`, 15.5, (i % 4) * 35 + 33);

          QRCode.toDataURL(`${text}-${qrCount+1}`, function (err, url) {
            urlValue = url
          })
      
          doc.addImage(urlValue, "PNG", 60.8, (i % 4) * 35, 34, 34);
          doc.text(`${text}-${qrCount+1}`, 70, (i % 4) * 35 + 33);

          
          qrCount+=2
          if ((i + 1) % 4 === 0 && i !== 99) {
            doc.addPage();
            qrCount = i+2;

          }
        }
      
        doc.autoPrint();
        doc.output("pdfobjectnewwindow", { filename: "QR CODE" });
      };

    

    // const printPDF = () => {
    //     const doc = new jsPDF("l", "mm", [100, 70], true);

    //     // let data = []
    //     for(let i=0; i<8;i++){
    //         let pageNumber = 1;
    //         let nextBreak = i+4
    //         if(i === nextBreak){
    //             doc.addPage();
    //             doc.setProperties({ title: `QR CODE - Page ${pageNumber+1}` });
    //             doc.addImage(src, "PNG", 15.8, 0, 32, 32);
    //             doc.setFont('bold');
    //             doc.setFontSize(8.5);
    //             doc.text(text, 19.5, 32);
    //             doc.addImage(src1, "PNG", 50.8, 0, 32, 32);
    //             doc.text(text, 55, 32);
    //             doc.addImage(src1, "PNG", 50.8, 35, 32, 32);
    //             doc.text(text, 55, 67);
    //             doc.addImage(src1, "PNG", 15.8, 35, 32, 32);
    //             doc.text(text, 19, 67);
    //         }else{
    //             doc.setProperties({ title: `QR CODE - Page ${pageNumber}` });
    //             doc.addImage(src, "PNG", 15.8, 0, 32, 32);
    //             doc.setFont('bold');
    //             doc.setFontSize(8.5);
    //             doc.text(text, 19.5, 32);
    //             doc.addImage(src1, "PNG", 50.8, 0, 32, 32);
    //             doc.text(text, 55, 32);
    //             doc.addImage(src1, "PNG", 50.8, 35, 32, 32);
    //             doc.text(text, 55, 67);
    //             doc.addImage(src1, "PNG", 15.8, 35, 32, 32);
    //             doc.text(text, 19, 67);
    //         }
    //     }
      
    //     // // Page 1
    //     // doc.setProperties({ title: 'QR CODE - Page 1' });
    //     // doc.addImage(src, "PNG", 15.8, 0, 32, 32);
    //     // doc.setFont('bold');
    //     // doc.setFontSize(8.5);
    //     // doc.text(text, 19.5, 32);
    //     // doc.addImage(src1, "PNG", 50.8, 0, 32, 32);
    //     // doc.text(text, 55, 32);
    //     // doc.addImage(src1, "PNG", 50.8, 35, 32, 32);
    //     // doc.text(text, 55, 67);
    //     // doc.addImage(src1, "PNG", 15.8, 35, 32, 32);
    //     // doc.text(text, 19, 67);
      
    //     // // Add a new page
    //     // doc.addPage();
      
    //     // Page 2
    //     // doc.setProperties({ title: 'QR CODE - Page 2' });
    //     // doc.addImage(src, "PNG", 15.8, 0, 32, 32);
    //     // doc.setFont('bold');
    //     // doc.setFontSize(8.5);
    //     // doc.text(text, 19.5, 32);
    //     // doc.addImage(src1, "PNG", 50.8, 0, 32, 32);
    //     // doc.text(text, 55, 32);
    //     // doc.addImage(src1, "PNG", 50.8, 35, 32, 32);
    //     // doc.text(text, 55, 67);
    //     // doc.addImage(src1, "PNG", 15.8, 35, 32, 32);
    //     // doc.text(text, 19, 67);
      
        // doc.autoPrint();
    //     doc.output("pdfobjectnewwindow", { filename: "QR CODE" });
    //   };



    return (
        <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <form>
                                    <div className="row d-flex justify-content-lg-start justify-content-sm-around justify-content-md-center">
                                        <div>
                                             {/* <img src={src} alt="QR code" /> */}
                                            <select onChange={handleChange}>
                                                <option selected value={''}>Select Any</option>
                                                <option value='Mouse' selected>Mouse</option>
                                                <option value='Keyboard'>Keyboard</option>
                                                <option value='Monitor'>Monitor</option>
                                                <option value='CPU'>CPU</option>
                                                <option value='Laptop'>Laptop</option>
                                                <option value='Head Phone'>Head Phone</option>
                                                <option value={'Charger'}>Charger</option>
                                                {/* <option></option> */}
                                            </select>
                                            <p>{text}</p>
                                            <button onClick={printPDF}>Print</button>
                                        </div> </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Qr;

