import React, { useEffect, useState } from "react";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../../hooks/Api";
import { Alert } from "../../../components/Model/Model";
import Select from 'react-select';
import { useFetchEmployeeDropDown } from "../../../hooks/DropDown_Api";
// import { Alert } from "../Model/Model";
// import Select from 'react-select/async';

const Add_Sales_Designation = () => {
    let pageName = "Designation"
    const navigate = useNavigate()
    const apiname = process.env.REACT_APP_DESIGNATION_API?.toString()


    const  { mutate: gst, data, isError, isLoading, isSuccess, error }:any = useCreate(apiname)
    const { register, handleSubmit, setError, formState: { errors }, reset, setValue }:any = useForm();
  



   
    // useEffect(() => {
    //     if (data) {

    //         reset(data)
    //     }
    // }, [data]);
 
const handleReset = () => {
        reset()
        setForDesignationValue(null)
    }

    const onSubmit = (branddetail: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(branddetail)) {
            const formvalue: any = value
         


            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }
            else if (Array.isArray(value) && key === "use_employee_code_and_surname_and_name") {
                value.forEach((val, index) => formData.append(`use_employee_code_and_surname_and_name`, val))
            }
            
           











        }
        gst(formData);
    }

const[forDesignationValue,setForDesignationValue]:any = useState()
const[designationOptions,setDesignationOptions] = useState()
const[employeeQty,setEmployeeQty] = useState(0)


    const {data:designationData, refetch:designationRefetch} = useFetchEmployeeDropDown()


    useEffect(()=>{
        setDesignationOptions(designationData)
    },[designationData])


    const handleChangeDesignation=(e:any)=>{
        let employees = e?.map((e:any)=>{
            return e.value
        })
        setForDesignationValue(e)
        setValue("use_employee_code_and_surname_and_name",employees)
        setEmployeeQty(employees?.length)
        setValue("employee_qty",employees?.length)
    }








    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            navigate('/listDesignation', { state: { showToast: true }})

        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error])

    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 3000)

    }, [show]);

    const handleValue=(e:any)=>{
        e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    }
    return (
        <div>
            <div className="content-wrapper" style={{ minHeight: '1345.31px' }}>
                <section className="content-header px-3 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Add {pageName}</h4>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">HR</Link></li>
                                    <li className="breadcrumb-item"><Link to="#" className="text-decoration-none text-black">{pageName}</Link></li>
                                    <li className="breadcrumb-item">Add {pageName}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-default">
                            {/* <div className="card-header">
                                <h5 className="card-title mt-2"><img src={SearchLogo} className="mx-1" alt='' /><span className="mx-2">Add GST</span></h5>
                            </div> */}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body" style={{maxHeight:"80vh",overflow:"auto"}}>                                
                                {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message} /> : ""}

                                    <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-3 mt-2">
                                                    <label >Designation Name  <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" id="exampleInputBrand" placeholder="Designation Name" {...register("designation",{onChange:handleValue, required: { value: true, message: "Designation Name is required" }})} />
                                                    <div className="input-group-append">
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>
                                                        </div>
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.designation?.message}</span>
                                                </div>
                                                {/* <div className="col-sm-4 mt-2">
                                                    <label >Use Employee Code,Surname & Name  <span style={{ color: 'red' }}>*</span></label>
                                                        <Select 
                                                            {...register("use_employee_code_and_surname_and_name",{required: { value: true, message: "Field is required" }})}
                                                            value={forDesignationValue}
                                                            options={designationOptions}
                                                            onChange={handleChangeDesignation}
                                                            isMulti
                                                          
                                                            className="dropdown-select"

                                                        />

                                                <span className='text-red w-100'>{errors?.use_employee_code_and_surname_and_name?.message}</span>
                                                       
                                                </div>
                                                <div className="col-sm-4 mt-2">
                                                    <label >Employee Qty</label>
                                                    <div className="input-group" >
                                                    <input type="text" className="form-control" disabled value={employeeQty}  placeholder="Designation Name" {...register("employee_qty",{ required: { value: false, message: "From Date is required" }})} />
                                                        </div>
                                                    <span className='text-red w-100'>{errors?.employee_qty?.message}</span>
                                                </div> */}
                                                <div className="col-sm-3 mt-2">
                                                    <label >Status  <span style={{ color: 'red' }}>*</span></label>
                                                    <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>
                                                    <option value={""}  selected>Select Any</option>
                                                        <option value={"active"}>Active</option>
                                                        <option value={"inactive"}>Inactive</option>
                                                    </select>
                                                    <span className='text-red w-100'>{errors?.status?.message}</span>
                                                </div>


                                               
                                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex  ">
                                        <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                <a type="button" className="btn border rounded text-white px-4 mt-2 mx-3" onClick={handleSubmit(onSubmit)}>Submit</a>

<button type="button" className="btn border-1 rounded  border-1 px-4 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className="btn border-1 rounded  px-4 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listDesignation")}>Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Add_Sales_Designation