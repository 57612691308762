import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../components/Dynamic_Table/Table/Table";
// import { Columns_SingleCode_Disposable,titleofheaders_Disposable  } from "./Columns_SingleCode_Disposable";
// import { Columns_SingleCode_LLDP,titleofheaders_LLDP } from "./Columns_SingleCode_LLDP";
import { Columns_SingleCode_Other,titleofheaders_Other } from "./Columns_SingleCode_Other";
import { fetchProductType, useFetch } from "../../hooks/Api";
import Axiosinstance from "../../hooks/Interceptor";

const List_SingleCode_Other = () => {
  const navigate = useNavigate()
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Product Details";

  const Breadcrumb_Title_Name = "Single Code";

  const apiname = process.env.REACT_APP_SINGLE_CODE_API?.toString();

  // const[productType,setProuctType] = useState("Disposable")

  // let page = 1
  // let pagesize = 10

  // let search:any = ""

  // const handleSearch = (e: any) => {
  //   setSearch(e.target.value);
  // };


  // const {
  //   isLoading: fetchLoading,
  //   isError,
  //   data: fetchAllData,
  //   error,
  //   isFetching,
  //   refetch,
  //   isSuccess,
  //   isPreviousData,
  //   isRefetching,
  // } = useFetch(search,page,pagesize,apiname);

  // useEffect(()=>{

  //   if(apiname === "singleCode" ){
  //     if(isSuccess){
  //       setProuctType(fetchAllData?.results[0]?.product_type?.type)
  //       // refetch()
  //     }
  //   }

   


  // },[apiname,isSuccess,fetchAllData,productType])






  // const [filter,setFilter] = useState({})

  // const filter_Disposable = {
  //   product_type: "Disposable",
  //   product_grade_name: "",
  //   cavity_to_product: "",
  //   HSN_code: "",
  //   color: "",
  //   price_list_1_pcs_weight: "",
  //   product_1_pcs_weight: "",
  //   use_mould_code_and_cavity: "",
  //   cavity_qty:"",
  //   product_ml_price_list: "",
  //   product_actual_ml: "",

  //   product_final_height_mm: "",
  //   product_current_height_mm: "",
  //   product_lip: "",

   

    
  //   created_by: "",
  //   updated_by: "",
  //   status: "",
  // };

  // const filter_LLDP = {
  //   product_type: "LLDP",
  //   product_grade_name: "",
  //   HSN_code: "",
  //   color: "",
  //   panni: "",
  //   panni_balloon: "",
  //   created_by: "",
  //   updated_by: "",
  //   status: "",
  // };

  const filter_LD = {
    // product_type: "LD",
    product_grade_name: "",
    HSN_code: "",
    color: "",
    panni: "",
    panni_balloon: "",
    created_by: "",
    updated_by: "",
    status: "",
  };

  const addButtonName = "Single Code";

  const stickyStart = 1;
  const stickyEnd = 2;

  const addButtonPageName="SingleCode"
  const permissionClass = {
    front: "single_code",
    back: "singlecode"
  }
  // const [titleofheaders,setTitleOfHeaders]:any = useState([])
  // const [columnsof,setColumns]:any = useState([])


  // useEffect(()=>{

    

  //   if(productType === "Disposable"){
  //     setTitleOfHeaders(titleofheaders_Disposable)
  //     setColumns(Columns_SingleCode_Disposable)
  //     setFilter(filter_Disposable)
      
  //   }
  //   else if(productType === "LLDP"){
  //     setTitleOfHeaders(titleofheaders_LLDP)
  //     setColumns(Columns_SingleCode_LLDP)
  //     setFilter(filter_LLDP)
  //   }
  //   else if(productType === "LD"){
  //     setTitleOfHeaders(titleofheaders_LD)
  //     setColumns(Columns_SingleCode_LD)
  //     setFilter(filter_LD)
  //   }

  // },[productType])





  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter_LD}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_SingleCode_Other}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders_Other}
                  // setProuctType={setProuctType}
                  // productType={productType}
                  permissionClass={permissionClass}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_SingleCode_Other;
