
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Column, useTable } from "react-table"
import { Checkbox } from "../../components/inputcomponent/Checkbox"
import { useEffect, useMemo, useState } from "react";
import Columnlevel from "../../components/Table/column";
import axios from "axios";
import { useMutation } from "react-query";
import { Status } from "../../components/Cell/Status";
import Actions from "../../components/Cell/Actions";
// import { updateBrand, useFetchBrands } from "../../hooks/brand";
// import noImage from "../../images/noImage.png"
import { useFetchGst, useFetchProductType, useUpdateGst, useUpdateProductType } from "../../hooks/GST";
import { Update, useFetch } from "../../hooks/Api";


interface Data {
  selection: any
  product_type_code:any
  type:any
  product_categories:any
  created_at:any

  created_by:any
  updated_by:any
  updated_at:any
  status: any;
  actions: any;
}



export const titleofheadersOther=[
  {
    id: `srno`,
    Header: `Sr No`,
  },
  
  {
    id: `product_type_code`,
    Header: `Product Type Code`,
  },

  {
    id: `type`,
    Header: `Product Type`,
  },
  {
    id: `product_categories`,
    Header: `Product Category`,
  },




  {
    id: 'status',
    Header: 'Status',
  },


  {
    id: 'created_at',
    Header: "Created At",
  },
  {
    id: 'created_by',
    Header: "Created By",
  },
  {
    id: 'updated_at',
    Header: "Updated At",
  },
  {
    id: 'updated_by',
    Header: "Updated By",
  },

  
]





export const Columns_ProductTypeOther: any = [



  
  {
    id: `srno`,
    Header: () => <div>Sr<br/>No</div>,
    sticky:"left",
  },
  {
    id: `product_type_code`,
    Header: () => <div>Product Type<br/>Code</div>,
    accessor:'product_type_code',
     sticky:"left",
    
   

  },

  {
    id: `type`,
    Header: () => <div>Product<br/>Type</div>,
    accessor:'type',
    sticky:"left",
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.type}
        </div>
    )}

  },
  {
    id: `product_categories`,
    Header: () => <div>Product<br/>Category</div>,
    accessor:'product_categories',
    // sticky:"left",
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.product_categories}
        </div>
    )}

  },
  {
    id: `inquiry_product_type`,
    Header: () => <div>Inquire Product<br/>Type</div>,
    accessor:'inquiry_product_type',
    // sticky:"left",
    
    Cell: ({ row }: any) =>{
        return(
        <div>
            {row.original.extra_response?.inquiry_product_type?row.original.extra_response?.inquiry_product_type:<span style={{ color: 'red', fontSize: "20px" }}>****</span>}
        </div>
    )}

  },
 
  {
    id: 'status',
    Header: 'Status',
    accessor:'status',
  
  },
 


  

  
  


  {
    id: 'created_at',
    Header: () => <div>Created<br/>At</div>,
    accessor:'created_at',
    Cell: ({ row }: any) => (row.original.created_by?.username ? row.original.created_at?.split(".")[0].split("T").join("  ") : "-")
  },


  {
    id: 'created_by',
    Header: () => <div>Created<br/>By</div>,
    accessor:'created_by',
    Cell: ({ row }: any) => row.original.created_by?.username

 
  },
  {
    id: 'updated_at',
    Header: () => <div>Updated<br/>At</div>,
    accessor:'updated_at',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_at?.split(".")[0].split("T").join("  ") : "-")
  },
  {
    id: 'updated_by',
    Header: () => <div>Updated<br/>By</div>,
    accessor:'updated_by',
    Cell: ({ row }: any) => (row.original.updated_by?.username ? row.original.updated_by?.username : "-")
  },



  {
    id: 'Actions',
    Header: 'Actions',

  
  },
  {
    id: 'Selection',
    // sticky:"left",
    Header: ({ getToggleAllRowsSelectedProps }: any) => {
      const [data, setData] = useState([])

      const columns: any = useMemo(() => Columns_ProductTypeOther, [])
      const defaultColumn: any = useMemo(() => {
        return {
          Filter: Columnlevel
        }
      }
        , [])

      const tableInstance: any = useTable({
        columns,
        data: data,
        defaultColumn,

      },


      )
      const [deleteMultipleid, setDeleteMultipleid]: any = useState([])
      let { selectedFlatRows} = tableInstance
      const { } = useMutation((deleteid: any) => {
        const temp_data = {
          id: deleteMultipleid
        }
        return axios.post(`${process.env.REACT_APP_BASE_URL}producttype/deletelist/`, temp_data,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
      });
      return (
        <>
          <div>
            <Checkbox

              onClick={() => {
                setDeleteMultipleid(() => {
                  const temp = selectedFlatRows?.map((e: any) => {
                    return e.original.id;
                  });
                  return temp;
                });
              }}

              {...getToggleAllRowsSelectedProps()} />
          </div>
        </>
      );
    },

    Cell: ({ row }: any) => <Checkbox   {...row.getToggleRowSelectedProps()} onClick={() => {
      // Add your onClick function here
    }} />
  },
]



