import { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useFetchSingle, useUpdateSingle, useUpdateSingleforcewithyes, useUpdateSingleforcewithno, useCreate } from "../../../hooks/Api";
import { Alert } from "../../../components/Model/Model";
import Modal from "react-bootstrap/esm/Modal";
import { Deletesinglewithdependancy } from "../../../components/modal";
import Axiosinstance from "../../../hooks/Interceptor";
import Mui_Toaster from "../../../components/Mui_Toaster/Mui_Toaster";
import { Button, Offcanvas } from "react-bootstrap";
import { useFetchEmailTypeDropDown, useFetchNumberTypeDropDown, useFetchSocialMediaDropDown } from "../../../hooks/DropDown_Api";
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import CreatableSelect from "react-select/creatable";
import { Deleteforce, Deletesingle } from "../../../components/Cell/Actions";
import { SimpleInput_Edit } from "../../../components/Dynamic_Form_Models/SimpleInput_Edit";

import { useDelete, useDeletemforce } from "../../../hooks/delete";
import Custom_Actions_Buttons from "../../../components/Cell/Custom_Actions";
import { useDeletemultiple, useDeletemultipleforce } from "../../../hooks/deletemultiple";
interface Option {
    readonly label: string;
    readonly name: string;
}
const createOption = (label: string) => ({
    label,
    name: label,
    status: "active"
});

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}
const Edit_Contact_Format_Modal = ({ showOffcancasEdit, handleCloseEdit, id, setIsEdited, setShowOffcancasEdit }: any) => {
    let pageName = "Contact Detail"


    const apiname = process.env.REACT_APP_CONTACT_DETAIL_API?.toString()
    const { data } = useFetchSingle(id, apiname)
    let rawData = localStorage.getItem('Add Data');
    let addData = rawData ? JSON.parse(rawData) : null;

    const tempData = addData?.contact_detail
    const ids = addData?.contact_detail?.map((e: any) => {

        return e.id
    })
    const navigate = useNavigate();
    const [openEditModel, setOpenEditModel] = useState(false)
    const [confirmmodal, setConfirmmodal] = useState(false)
    const [valuesofform, setValuesofform] = useState<any>()
    const [expirationDate, setExpirationDate] = useState()

    const { mutate: mutateyes, isSuccess: yessuccess, isError: yeserror, error: yeserrordetail, data: yesupdated_response }: any = useUpdateSingleforcewithyes()
    const { mutate: mutateno, isSuccess: nosuccess, isError: noerror, error: noerrordetail, data: noupdated_response }: any = useUpdateSingleforcewithno()


    const { mutate, isSuccess, isError, error, data: ContactUpdated_response }: any = useUpdateSingle()

    const { register, handleSubmit, setError, formState, reset, setValue, getValues }: any = useForm({
        mode: "onChange"
    });
    const { errors } = formState;
    const prevDataRef = useRef();

    useEffect(() => {
        const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
        if (data && isDataChanged) {
            reset(data);
            setMultipleIds(ids)

            prevDataRef.current = data;
        }
    }, [data, reset]);

    useEffect(() => {
        if (data) {
            reset(data)
            setSelectedAccountType(data?.email_type?.id)
            setValue('email_type',data?.email_type?.id)
            setSelectedNumberType(data?.number_type?.id)
            setValue('number_type',data?.number_type?.id)
            setSelectedSocialProfile(data?.social_media?.id)
            setValue('social_media',data?.social_media?.id)
        }
    }, [data])

    const onSubmit = (dataContact: any) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(dataContact)) {
            const formvalue: any = value



            if (typeof value !== "object") {
                formData.append(key, formvalue)
            }

        }
        let data = {
            id: id,
            apiname: apiname,
            data: formData
        }
        mutate(data)
        setValuesofform(data)
    }

    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    const apinameForBasicDetails = process.env.REACT_APP_EMPLOYEE_API?.toString()
    const { mutate: BasicDetails, isSuccess: basicDetailsIsSuccess, isError: basicDetailsIsError, error: basicDetailsError, data: basicDetailsData }: any = useUpdateSingle()


    useEffect(() => {
        const fetchData = async () => {
            if (yessuccess) {
                try {
                    const response = await Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}${apinameForBasicDetails}/${basicIdTemp}`);
                    localStorage.setItem('Add Data', JSON.stringify(response?.data?.data))
                    setShowOffcancasEdit(false)
                    setIsEdited(true)
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();
    }, [yessuccess]);


    let rawDataTemp = localStorage.getItem('Add Data');
    let addDataTemp = rawDataTemp ? JSON.parse(rawDataTemp) : null;

    let basicIdTemp = addDataTemp ? addDataTemp.id : null;

    const [basicDetailsInitiated, setBasicDetailsInitiated] = useState(false)

    useEffect(() => {
        if ((isSuccess || yessuccess || nosuccess) && !basicDetailsInitiated) {
            setShow(true);
            setshowmessage("success");
            setShowOffcancasEdit(false)
            setBasicDetailsInitiated(true);

        } else if (isError) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [data, isSuccess, yessuccess, nosuccess, ContactUpdated_response, error,
        isSuccess,]);



    const [updateCompleted, setUpdateCompleted] = useState(false)


    useEffect(() => {
        if (basicDetailsIsSuccess && basicDetailsData && !updateCompleted) {

            localStorage.setItem('Add Data', JSON.stringify(basicDetailsData?.data))
            setUpdateCompleted(true);
        }
    }, [basicDetailsIsSuccess, basicDetailsData])

    useEffect(() => {
        if (isError) {
            if (error.response?.status === 405) {
                setConfirmmodal(true);
            }
        } else if (isError || yeserror || noerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [isError, yeserror, noerror]);
    useEffect(() => {
        setTimeout(() => {
            setShow(false);
        }, 3000);
    }, []);
    const yesupdatemodal = () => {
        setConfirmmodal(false);
        mutateyes(valuesofform)
    }
    const cancelupdatemodal = () => {
        setConfirmmodal(false);
    }
    const noupdatemodal = () => {
        setOpenEditModel(true)
    }


    const handleExpirationDate = (e: any) => {
        setExpirationDate(e.target.value)
        setValue("expiration_date", e.target.value)
    }

    const handleConfirm = (data: any) => {

        delete data.created_at
        delete data.created_by
        let ok = {
            id: id,
            apiname: apiname,
            data: data,
        }

        mutateno(ok)
        setConfirmmodal(false);
        setOpenEditModel(false)
    }


    const [regexForDocumentNumber, setRegexForDocumentNumber]: any = useState()



    const handleNumbercode = (e: any) => {
        let temp = e.target.value.replace(/\D/g, '');
        if (temp.length > 10) temp = temp.substring(0, 10);
        if (temp.length > 6) {
            temp = temp.slice(0, 5) + ' ' + temp.slice(5);
        }
        e.target.value = temp;

        setValue('service_mobile_no', temp)
    }


    const [ForEditIds, setForEditIds]: any = useState([])


    const [multipleIds, setMultipleIds]: any = useState([])

    const [added, setAdded] = useState(false)

    // const prevDataRef = useRef();

    // useEffect(() => {
    //     const isDataChanged = JSON.stringify(data) !== JSON.stringify(prevDataRef.current);
    //     if (data && isDataChanged) {
    //         reset(data);
    //         setMultipleIds(ids)
    //         setForEditIds(ids)
    //         prevDataRef.current = data;
    //     }
    // }, [data, reset]);

    useEffect(() => {
        if (isSuccess) {
            if (data) {
                setMultipleIds((oldIds: any) => {
                    const newIds = [...oldIds, data.data.id];
                    console.log('New IDs:', newIds);
                    return newIds;
                });

                setAdded(false)
            }
        }
    }, [isSuccess, data]);

    const [optionsAccountType, setOptionsAccountType]: any = useState([]);
    const [accountType, setAccountType]: any = useState();
    const [selectedAccountType, setSelectedAccountType]: any = useState();
    const [accountTypeCreatable, setAccountTypeCreatable] = useState<Option | null>();
    const apinameForAccountType = process.env.REACT_APP_PERSON_EMAIL_TYPE_API?.toString()
    const { mutate: mutateAccountType, data: dataAccountType, isError: AccountTypeIsError, isLoading: AccountTypeLoading, isSuccess: AccountTypeSuccess, error: AccountTypeError }: any = useCreate(apinameForAccountType)

    const { data: accountTypeData, refetch: accountTypeRefetch } = useFetchEmailTypeDropDown()

    useEffect(() => {
        setOptionsAccountType(accountTypeData)
    }, [accountTypeData])

    const handleChangeAccountType = (e: any) => {
        accountTypeRefetch()
        setApiName(apinameForAccountType)
        setDeleteApiName(apinameForAccountType)
        setEditId(e?.value)
        setEditData(accountTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setAccountType(e)
        setValue("email_type", `${e?.value}`)
        setAccountTypeCreatable(null)
    }

    const handleCreateAccountType = async (inputValue: string) => {
        // console.log(inputValue, "inputValue")
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setTimeout(() => {
            const newOption = createOption(inputValue);
            mutateAccountType({ name: newOption.name, status: newOption.status })
            setOptionsAccountType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setAccountTypeCreatable(newOption);
        }, 0);
    };




    useEffect(() => {
        (async () => {
            if (AccountTypeSuccess && optionsAccountType) {
                await accountTypeRefetch();
            }
            setValue("email_type", accountTypeData[0].value)
        })();

        return () => {
        };
    }, [AccountTypeSuccess, optionsAccountType]);


    //
    const [optionsNumberType, setOptionsNumberType]: any = useState([]);
    const [NumberType, setNumberType]: any = useState();
    const [selectedNumberType, setSelectedNumberType]: any = useState();
    const [NumberTypeCreatable, setNumberTypeCreatable] = useState<Option | null>();
    const apinameForNumberType = process.env.REACT_APP_PERSON_NUMBER_TYPE_API?.toString()
    const { mutate: mutateNumberType, data: dataNumberType, isError: NumberTypeIsError, isLoading: NumberTypeLoading, isSuccess: NumberTypeSuccess, error: NumberTypeError }: any = useCreate(apinameForNumberType)

    const { data: NumberTypeData, refetch: NumberTypeRefetch } = useFetchNumberTypeDropDown()

    useEffect(() => {
        setOptionsNumberType(NumberTypeData)
    }, [NumberTypeData])

    const handleChangeNumberType = (e: any) => {
        NumberTypeRefetch()
        setApiName(apinameForNumberType)
        setDeleteApiName(apinameForNumberType)
        setEditId(e?.value)
        setEditData(NumberTypeData)
        setRenderKey(prevKey => prevKey + 1);
        setNumberType(e)
        setValue("number_type", `${e?.value}`)
        setNumberTypeCreatable(null)
    }

    const handleCreateNumberType = async (inputValue: string) => {
        // console.log(inputValue, "inputValue")
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setTimeout(() => {
            const newOption = createOption(inputValue);
            mutateNumberType({ name: newOption.name, status: newOption.status })
            setOptionsNumberType((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setNumberTypeCreatable(newOption);
        }, 0);
    };




    useEffect(() => {
        (async () => {
            if (NumberTypeSuccess && optionsNumberType) {
                await NumberTypeRefetch();
            }
            setValue("number_type", NumberTypeData[0].value)
        })();

        return () => {
        };
    }, [NumberTypeSuccess, optionsNumberType]);

    //

    const [optionsSocialProfile, setOptionsSocialProfile]: any = useState([]);
    const [SocialProfile, setSocialProfile]: any = useState();
    const [selectedSocialProfile, setSelectedSocialProfile]: any = useState();
    const [SocialProfileCreatable, setSocialProfileCreatable] = useState<Option | null>();
    const apinameForSocialProfile = process.env.REACT_APP_PERSON_SOCIAL_MEDIA_API?.toString()
    const { mutate: mutateSocialProfile, data: dataSocialProfile, isError: SocialProfileIsError, isLoading: SocialProfileLoading, isSuccess: SocialProfileSuccess, error: SocialProfileError }: any = useCreate(apinameForSocialProfile)

    const { data: SocialProfileData, refetch: SocialProfileRefetch } = useFetchSocialMediaDropDown()

    useEffect(() => {
        setOptionsSocialProfile(SocialProfileData)
    }, [SocialProfileData])

    const handleChangeSocialProfile = (e: any) => {
        SocialProfileRefetch()
        setApiName(apinameForSocialProfile)
        setDeleteApiName(apinameForSocialProfile)
        setEditId(e?.value)
        setEditData(SocialProfileData)
        setRenderKey(prevKey => prevKey + 1);
        setSocialProfile(e)
        setValue("social_media", `${e?.value}`)
        setSocialProfileCreatable(null)
    }

    const handleCreateSocialProfile = async (inputValue: string) => {
        // console.log(inputValue, "inputValue")
        inputValue = inputValue.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');

        setTimeout(() => {
            const newOption = createOption(inputValue);
            mutateSocialProfile({ name: newOption.name, status: newOption.status })
            setOptionsSocialProfile((prev: any) => prev ? [...prev, newOption] : [newOption]);
            setSocialProfileCreatable(newOption);
        }, 0);
    };




    useEffect(() => {
        (async () => {
            if (SocialProfileSuccess && optionsSocialProfile) {
                await SocialProfileRefetch();
            }
            setValue("social_media", SocialProfileData[0].value)
        })();

        return () => {
        };
    }, [SocialProfileSuccess, optionsSocialProfile]);

    
    const [renderKey, setRenderKey] = useState(0);
    const [OpenEditDropDownModel, setOpenEditDropDownModel] = useState(false)
    const [deleteApiName, setDeleteApiName]: any = useState("")
    const [deleteid, setDeleteid] = useState();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    var [deleteMultipleid, setDeleteMultipleid]: any = useState([]);
    const [editData, setEditData]: any = useState()
    const [editId, setEditId]: any = useState()
    const [apiName, setApiName]: any = useState()


    const [deleteConfirmsingle, setDeleteConfirmsingle] = useState(false);
    const [confirmforcemodal, setConfirmforcemodal] = useState(false);

    const {
        mutate: singleDelete,
        data: deletedata,
        isError: deletecoloriserror,
        isSuccess: deletesucess,
    } = useDelete();
    const {
        mutate: deleteMultipleMutet,
        data: deleteMultiple,
        isError: deleteMultipleIsError,
        isSuccess: deleteMultipleSuccess,
    } = useDeletemultiple();
    const {
        mutate: deleteMultipleMutetforce,
        isSuccess: forcefullysuccess,
        isError: forcefullyerror,
    } = useDeletemultipleforce();
    const {
        mutate: deleteforce,
        isSuccess: forcefullysingledeletesuccess,
        isError: forcefullydeleteerror,
    } = useDeletemforce();



    // const queryClient = useQueryClient();
    const handleRefetch = () => {

        if (apiName === apinameForAccountType) {
            accountTypeRefetch()
        }
        if (apiName === apinameForNumberType) {
            NumberTypeRefetch()
        }
        if (apiName === apinameForSocialProfile) {
            SocialProfileRefetch()
        }

        // queryClient.invalidateQueries(['Panni Material Use']);




    }


    useEffect(() => {
        if (OpenEditDropDownModel && accountType && apiName === apinameForAccountType) {
            setAccountType(null)
            setAccountTypeCreatable(null)
        }
        if (OpenEditDropDownModel && NumberType && apiName === apinameForNumberType) {
            setNumberType(null)
            setNumberTypeCreatable(null)
        }
        if (OpenEditDropDownModel && SocialProfile && apiName === apinameForSocialProfile) {
            setSocialProfile(null)
            setSocialProfileCreatable(null)
        }







        if (deletesucess && apiName === apinameForAccountType) {
            setAccountType(null)
            setAccountTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForNumberType) {
            setNumberType(null)
            setNumberTypeCreatable(null)
        }
        if (deletesucess && apiName === apinameForSocialProfile) {
            setSocialProfile(null)
            setSocialProfileCreatable(null)
        }



    }, [OpenEditDropDownModel, apiName, deletesucess])


    const [isExist, setIsExist] = useState(true)


    const CustomOption = ({ innerProps, label, value }: any) => (


        <div {...innerProps} style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
            <span>{label}</span>
            <div style={{ float: "right", justifyContent: "end" }}>



            {label !== 'Square' && label !== 'Round' && label !== 'Triangle' && label !== 'Rectangles' ?

                    <Custom_Actions_Buttons value={value}
                        apiname={deleteApiName}
                        mutate={singleDelete}
                        // masterName={addButtonName}
                        deleteid={deleteid}
                        handleDeletedforce={handleDeletedforce}
                        closereconfirmdelete={closereconfirmdelete}
                        deletemodalask={deletemodalask}
                        setEditOpen={setOpenEditDropDownModel}
                    // permissionClass={permissionClass}
                    />
                    : ""}

            </div>
        </div>


    )


    const customCreatableIndicator = {
        IndicatorsContainer: IndicatorsContainerCreate,
        Option: CustomOption
    }



    useEffect(() => {
        if (deletesucess === true) { }
        // CountryRefetch();
    }, [deletesucess, editData?.results]);
    useEffect(() => {
        return () => {
            setDeleteid(undefined);
        };
    }, []);
    const handleConfirmDelete = () => {
        setDeleteConfirm(true);
    };
    const closereconfirmdelete = () => {
        setConfirmforcemodal(false);
        setDeleteMultipleid([]);
    };

    const confirmmodalclose = () => {
        setDeleteConfirmsingle(false);
        setDeleteid(undefined);
    };
    const confirmmodalsubmit = () => {
        setDeleteConfirmsingle(false);
        singleDelete({ deleteid, apiname: deleteApiName });
    };
    const deletemodalask = (deleteidselect: any) => {
        setDeleteConfirmsingle(true);
        setDeleteid(deleteidselect);
    };
    const handleDeletedforce = () => {
        setConfirmforcemodal(false);
        if (deletesucess) deleteforce({ deleteid, apiname: deleteApiName });
        else if (deleteMultipleSuccess) {
            deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
        }
    };
    useEffect(() => {
        if (forcefullysuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullyerror) {
            setShow(true);
            setshowmessage("danger");
        } else if (forcefullysingledeletesuccess) {
            setShow(true);
            setshowmessage("success");
        } else if (forcefullydeleteerror) {
            setShow(true);
            setshowmessage("danger");
        }
    }, [
        forcefullysuccess,
        forcefullyerror,
        forcefullysingledeletesuccess,
        forcefullydeleteerror,
    ]);

    useEffect(() => {
        if (deleteMultipleSuccess) {
            const showdepenedancymsg = deleteMultiple?.data?.data;
            const dependacyfound = showdepenedancymsg?.filter((color: any) => {
                if (
                    color.dependency === `${process.env.REACT_APP_DEPENDACY_FOUND_NOT}`
                ) {
                    return color;
                }
            });
            if (dependacyfound?.length === deleteMultipleid?.length) {
                deleteMultipleMutetforce({ deleteMultipleid, apiname: deleteApiName });
            } else {
                setConfirmforcemodal(true);
            }
        } else if (deleteMultipleIsError) {
            setShow(true);
            setshowmessage("danger");
        } else if (deletesucess) {
            if (deletedata.data?.data?.length > 0) {
                setConfirmforcemodal(true);
            } else if (deleteid) {
                setShow(true);
                setshowmessage("success");
                deleteforce({ deleteid, apiname: deleteApiName });
            }
        }
    }, [deleteMultipleSuccess, deleteMultipleIsError, deletesucess, deleteApiName]);

    const handleMultipleDeleted = () => {
        deleteMultipleMutet({ deleteMultipleid, apiname: deleteApiName });
        setDeleteConfirm(false);
    };

    return (
        <div>
            <div >
                <section className="">
                    <div className="">
                        <div className="">


                            <Offcanvas show={showOffcancasEdit} onHide={handleCloseEdit} placement={'end'} style={{ width: '178vh', zIndex: '1000', top: '71px' }} >
                                <Offcanvas.Header closeButton>
                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                <div className="card card-default">


                                    <form
                                        onSubmit={handleSubmit(onSubmit)}>
                                        <div className="card-body" >
                                            <div className="row">
                                                <p className="mb-0 " style={{ fontSize: '14px' }}>{`${addData?.person_type?.name}-${addData?.name ? addData?.name : addData?.customer_name}-${addData?.extra_response?.city_name_and_distict_name?.village_name ? addData?.extra_response?.city_name_and_distict_name?.village_name : addData?.extra_response?.city_name_and_distict_name?.city_name}`}</p>
                                            </div>
                                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response.data?.message?.name} /> : ""}
                                            <div className="row d-flex justify-content-md-center justify-content-lg-start">
                                                <div className="col-md-12">
                                                    <div className="row">


                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Country </label>
                                                            <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_name} placeholder="Country"
                                                            // {...register("country", { required: { value: false, message: "Country is required" } })} 
                                                            />
                                                            <span className='text-red w-100'>{errors?.country?.message}</span>
                                                        </div>

                                                        {/* <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Email Type <span style={{ color: 'red' }}>*</span></label>
                                                            <select className="form-select" {...register("email_type", { required: { value: true, message: "Field is required" } })}>

                                                                <option value={""} selected>Select Any</option>
                                                                <option value="Home">Home</option>
                                                                <option value="Shop">Shop</option>
                                                                <option value="Office">Office</option>
                                                                <option value="Personal">Personal</option>
                                                                <option value="Company Email Id">Company Email Id</option>
                                                                <option value="Personal Email Id">Personal Email Id</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.email_type?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: 'red' }}>*</span></label>
                                                            <select className="form-select" {...register("number_type", { required: { value: true, message: "Field is required" } })}>

                                                                <option value={""} selected>Select Any</option>
                                                                <option value="Mobile-1">Mobile-1</option>
                                                                <option value="Mobile-2">Mobile-2</option>
                                                                <option value="Home Mobile">Home Mobile</option>
                                                                <option value="Shop">Shop</option>
                                                                <option value="Office">Office</option>
                                                                <option value="Company Mobile No">Company Mobile No</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.number_type?.message}</span>
                                                        </div> */}

                                                        <div className="col-sm-3 mt-2" >
                                                            <label htmlFor="exampleInputEmail1">Account Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect

                                                                {...register("email_type", { required: { value: true, message: "Account Type is required" } })}
                                                                placeholder={'Select Account Type'}
                                                                options={optionsAccountType}
                                                                onCreateOption={handleCreateAccountType}
                                                                onChange={handleChangeAccountType}
                                                                // value={accountTypeCreatable == null ? accountType : accountTypeCreatable}
                                                                components={customCreatableIndicator}
                                                                maxMenuHeight={130}
                                                                onMenuOpen={() => accountTypeRefetch()}
                                                                value={accountTypeCreatable == null ? accountType == null ? optionsAccountType?.filter((obj: any) => obj.value === selectedAccountType) : accountType : accountTypeCreatable}


                                                            />
                                                            <span className='text-red w-100'>{errors?.email_type?.message}</span>

                                                        </div>
                                                        <div className="col-sm-3 mt-2" >
                                                            <label htmlFor="exampleInputEmail1">Number Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect

                                                                {...register("number_type", { required: { value: true, message: "Number Type is required" } })}
                                                                placeholder={'Select Number Type'}
                                                                options={optionsNumberType}
                                                                onCreateOption={handleCreateNumberType}
                                                                onChange={handleChangeNumberType}
                                                                // value={NumberTypeCreatable == null ? NumberType : NumberTypeCreatable}
                                                                components={customCreatableIndicator}
                                                                maxMenuHeight={130}
                                                                onMenuOpen={() => NumberTypeRefetch()}
                                                                value={NumberTypeCreatable == null ? NumberType == null ? optionsNumberType?.filter((obj: any) => obj.value === selectedNumberType) : NumberType : NumberTypeCreatable}


                                                            />
                                                            <span className='text-red w-100'>{errors?.number_type?.message}</span>

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Country Code </label>

                                                            <input type="text" className="form-control" id="exampleInputBrand" disabled value={addData?.extra_response?.city_name_and_distict_name?.country_code} placeholder="City Name" {...register("country_code", { required: { value: false, message: "Field is required" } })} />


                                                            <span className='text-red w-100'>{errors?.country_code?.message}</span>
                                                        </div>

                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="exampleInputBrand"
                                                                    // placeholder={documentType ? documentType : "Mobile Number"}
                                                                    {...register('number', {
                                                                        required: 'Mobile Number is required',
                                                                        pattern: {
                                                                            value: regexForDocumentNumber,
                                                                            message: 'Please enter a valid Mobile Number',
                                                                        },
                                                                    })}
                                                                    onChange={handleNumbercode}
                                                                />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.number?.message}</span>
                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Contact Name & Surname <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Contact Name & Surname"
                                                                    {...register('contact_name_and_surname', { required: true, message: 'Contact Name & Surname is required' })} />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.contact_name_and_surname?.message}</span>
                                                        </div>


                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Email Address <span style={{ color: 'red' }}>*</span></label>
                                                            <div className="input-group">
                                                                <input type="email" className="form-control" id="exampleInputBrand" placeholder="Email Address"
                                                                    {...register('email_address', {
                                                                        required: 'Email is required',
                                                                        pattern: {
                                                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                                            message: 'Please enter a valid email',
                                                                        },
                                                                    })}
                                                                />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.email_address?.message}</span>
                                                        </div>
                                                        <div className="col-sm-3 mt-2" >
                                                            <label htmlFor="exampleInputEmail1">Social Media Type <span style={{ color: "red" }}>*</span></label>
                                                            <CreatableSelect

                                                                {...register("social_media", { required: { value: true, message: "Social Media Type is required" } })}
                                                                placeholder={'Select Social Media Type'}
                                                                options={optionsSocialProfile}
                                                                onCreateOption={handleCreateSocialProfile}
                                                                onChange={handleChangeSocialProfile}
                                                                // value={SocialProfileCreatable == null ? SocialProfile : SocialProfileCreatable}
                                                                components={customCreatableIndicator}
                                                                maxMenuHeight={130}
                                                                onMenuOpen={() => SocialProfileRefetch()}
                                                                value={SocialProfileCreatable == null ? SocialProfile == null ? optionsSocialProfile?.filter((obj: any) => obj.value === selectedSocialProfile) : SocialProfile : SocialProfileCreatable}


                                                            />
                                                            <span className='text-red w-100'>{errors?.social_media?.message}</span>

                                                        </div>
                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Social Media Account </label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Social Media Account"
                                                                    {...register('social_media_account', { required: false, message: 'Social Media Account is required' })} />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.social_media_account?.message}</span>
                                                        </div>



                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Re-Mark </label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" id="exampleInputBrand" placeholder="Re-Mark"
                                                                    {...register('re_mark', { required: false, message: 'Re-Mark is required' })} />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white' /></span>

                                                                </div>
                                                            </div>
                                                            <span className='text-red w-100'>{errors?.re_mark?.message}</span>
                                                        </div>




                                                        <div className="col-sm-3 mt-2">
                                                            <label htmlFor="exampleInputEmail1">Status <span style={{ color: 'red' }}>*</span></label>
                                                            <select className="form-select" {...register("status", { required: { value: true, message: "Status is required" } })}>

                                                                <option value={""} selected>Select Any</option>
                                                                <option value="active">Active</option>
                                                                <option value="inactive">Inactive</option>
                                                            </select>
                                                            <span className='text-red w-100'>{errors?.status?.message}</span>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row d-flex  ">
                                                <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                                    <div className="row d-flex ">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                                                            <button
                                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}

                                                                onClick={handleSubmit(onSubmit)}
                                                            >Submit</button>
                                                            <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/listPersonDetail")}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>


                        </div>
                    </div>
                </section>
            </div>
            <Modal
                show={deleteConfirm}
                onHide={() => setDeleteConfirm(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete {""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Sure you want to delete it?</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setDeleteConfirm(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={handleMultipleDeleted}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Deleteforce
                key={renderKey}
                handleDeletedforce={handleDeletedforce}
                reconfirmdelete={confirmforcemodal}
                masterName={""}
                closereconfirmdelete={closereconfirmdelete}
                referance={
                    deletesucess
                        ? deletedata
                        : ""
                }
                apiname={apiName}
            />
            <Deletesingle
                key={renderKey}
                confirmmodal={deleteConfirmsingle}
                confirmmodalclose={confirmmodalclose}
                masterName={""}
                confirmmodalsubmit={confirmmodalsubmit}
                apiname={apiName}
            />

            <SimpleInput_Edit key={renderKey}
                refetchForm={handleRefetch}
                open={OpenEditDropDownModel}
                setOpen={setOpenEditDropDownModel}
                id={editId}
                // superAccountId={superAccountId} 
                // optionsSuperAccount={optionsSuperAccount}
                // isSuperAccount={isSuperAccount}
                apiname={apiName} />
            <Modal show={openEditModel} onHide={() => setOpenEditModel(false)} aria-labelledby="contained-modal-title-vcenter"
                centered    >
                <Modal.Header closeButton>
                    <Modal.Title>Select a Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-sm-12 mt-2">
                                <label htmlFor="exampleInputEmail1">Choose Date</label>
                                <input type="date" className="form-control" style={{ fontSize: "13px" }} {...register("expiration_date", { onChange: handleExpirationDate })} />
                                {/* <input type="date" className="form-control" id="exampleInputBrand"

                                    {...register("expiration_date", { onChange: handleExpirationDate })}
                                /> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => { setOpenEditModel(false) }}>Close</button>
                    <button
                        type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                        style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                        onClick={handleSubmit(handleConfirm)}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
            <Deletesinglewithdependancy noupdatemodal={noupdatemodal}
                yesupdatemodal={yesupdatemodal}
                cancelupdatemodal={cancelupdatemodal} masterName={apiname} confirmmodal={confirmmodal} foundependacy={error?.response?.data} />


            {show && (
                <Mui_Toaster
                    message={`Contact Details Updated Successfully`}
                    severity={"success"}
                    Isopen={true}
                />
            )}
        </div>
    )
}

export default Edit_Contact_Format_Modal


