import React, { useEffect, useRef, useState } from 'react'
import './ContactForm.css'
import { FaLocationArrow } from 'react-icons/fa';
import { GrLocation } from 'react-icons/gr';

import { BsTelephone } from 'react-icons/bs';

import { AiOutlineMail, AiOutlinePlus } from 'react-icons/ai';

import { CgWebsite } from 'react-icons/cg';
import Select from "react-select";

import { RiPencilLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import useFocusOnEnter from '../../../components/focus';
import cameraLogo from "../../../images/Camera.png"
import logo from "../../../images/logo.png"
import { useForm } from 'react-hook-form';
import { useFetchCityDropDown, useFetchSalesProductTypeDropDown, useFetchUOMDropDown } from '../../../hooks/DropDown_Api';
import { useCreate } from '../../../hooks/Api';
import {  DatePicker } from 'antd';
import Button from "react-bootstrap/esm/Button";

import moment from "moment";
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { Alert } from "../../../components/Model/Model";
import { RxCross2 } from 'react-icons/rx';
import { nanoid } from 'nanoid';
import { City_Model } from '../../../components/Dynamic_Form_Models/City_Model';
import IndicatorsContainer from '../../../components/Select Dropdown/Select'
import IndicatorsContainerCreate from '../../../components/Select Dropdown/Creatabel'
import Axiosinstance from '../../../hooks/Interceptor';
import Modal from "react-bootstrap/Modal";
const customIndicator = {
    IndicatorsContainer: IndicatorsContainer
}

const customCreatableIndicator = {
    IndicatorsContainer: IndicatorsContainerCreate
}

const OnlineInquiry = () => {
    const focus:any = useRef();
    const {onEnterKey} = useFocusOnEnter(focus);
    const navigate = useNavigate()
    const [visitingCard,setVisitingCard] = useState(null)
    const [optionsProductType, setOptionsProductType]:any = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [productType, setProductType]:any = useState("");
    const [prductTypeValue, setProductTypeValue]: any = useState();

    const[inquiryModal,setInquiryModal] = useState(false)

    const [openCity, setOpenCity]: any = useState(false)




    const apinameAddProductInOnlineInquiry = process.env.REACT_APP_SALES_ADD_PRODUCT_ONLINE_INQUIRY_API?.toString();

      const {
        mutate: addProduct,
        data: addProductData,
        isError: addProductIsError,
        isLoading: addProductIsLoading,
        isSuccess: addProductIsSuccess,
        error: addProductError,
      }: any = useCreate(apinameAddProductInOnlineInquiry);
    
    
      const apinameGetMultipleProductInOnlineInquiry = process.env.REACT_APP_SALES_GET_MULTIPLE_PRODUCTS_ONLINE_INQUIRY_API?.toString();
    
    
    
    
      const {
        mutate: getProduct,
        data: getProductData,
        isError: getProductIsError,
        isLoading: getProductIsLoading,
        isSuccess: getProductIsSuccess,
        error: getProductError,
      }: any = useCreate(apinameGetMultipleProductInOnlineInquiry);


    const onLogoChange = (e:any) => {
        setVisitingCard(e.target.files[0].name)
    }
    
    
    const apiname = process.env.REACT_APP_SALES_ONLINE_INQUIRY_API?.toString();

    const {
        mutate,
        data,
        isError,
        isLoading,
        isSuccess,
        error,
      }: any = useCreate(apiname);

    const { register, handleSubmit, setError, formState, reset,setValue, getValues  }:any = useForm({
        mode:"onChange"
    });
    const { errors } = formState;
    const { data: productTypeData, refetch: productTypeRefetch } = useFetchSalesProductTypeDropDown();

  useEffect(() => {
    setOptionsProductType(productTypeData);
  }, [productTypeData]);


//   const handleChangeProductType = (e:any)=>{
//     setProductTypeValue(e)
//     setValue("product_type",e.value)
//   }


const [optionsUOM,setOptionsUOM] = useState()
const [forUOM,setForUOM]:any = useState()

  const { data: uomData, refetch: uomRefetch } = useFetchUOMDropDown();

  useEffect(() => {
    // let temp = uomData?.map((e:any)=>{
    //     return{
    //         value:e?.id,
    //         label:e?.baseUnit
    //     }
    // })
    // console.log(temp,'uom')
    setOptionsUOM(uomData);
  }, [uomData]);


//   const handleChangeUOM = (e:any)=>{
//     setForUOM(e)
//     setValue("uom",e.value)
//   }


  const[forCityValue,setForCityValue]:any = useState()
  const [cityOptions,setCityOptions]:any = useState()
  const[totalCity,setTotalCity]:any = useState(0)

  const {data:cityData, refetch:cityRefetch} = useFetchCityDropDown()


  useEffect(()=>{
      setCityOptions(cityData)
  },[cityData])


  const [forState,setForState]:any = useState("")
  const [forCountry,setForCountry]:any = useState("")


//   const handleChangeCity=(e:any)=>{

      
  

//       setForCityValue(e)
//       setValue("village_code_and_name",e.value)



//       setForState(e?.state)
//       setForCountry(e?.country)
//       setValue("state",e?.stateID)
//       setValue("country",e?.countryID)
//   }






const [requirementDate,setRequirementDate] = useState(null)

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {

        

        

        return  current && current  < dayjs().startOf('day');
      };


      const handleRequirementDate=(e:any)=>{
setRequirementDate(e)
setValue("requirement_date",`${e?.$D}-${e?.$M+1}-${e?.$y}`)
      }

 
    
    
  const onSubmit = async(details: any) => {
    const formData = new FormData();


    if(!requirementDate){
        delete details.requirement_date
    }

    for (const [key, value] of Object.entries(details)) {
        const formvalue: any = value
     


        if (typeof value !== "object") {
            formData.append(key, formvalue)
        }
        else if (Array.isArray(value) && key === "village_code_and_name") {
            value.forEach((val, index) => formData.append(`village_code_and_name`, val))
        }
      
        else if (key === "visiting_card_image"){
            if (typeof value === 'object' && value !== null && formvalue.length > 0) {
                formData.append(key, formvalue[0])
            }
            else {
                formData.append(key, "")
            }
        }


       
        
        
       
    }

    if(productIds){
        formData.append('product', JSON.stringify(productIds))
       
      }

    if (displayData?.length === 0) {
        setNoProductError(true)
      } else {
        // await sales(formData);
        await mutate(formData);
      }
}
    
    // useEffect(()=>{
    //     if(isSuccess){
    //         reset()
    //         setProductTypeValue(null)
    //         setRequirementDate(null)
    //         setForUOM(null)
    //         setForCityValue(null)
    //         setRequirementDate(null)
    //         setForState(null)
    //         setForCountry(null)
    //         setVisitingCard(null)
    //     }
    // },[isSuccess])
    
    
    const handleReset = () => {
        reset()
        setProductTypeValue(null)
        setRequirementDate(null)
        setForUOM(null)
        setForCityValue(null)
        setRequirementDate(null)
        setForState(null)
        setForCountry(null)
    }



    const [show, setShow] = useState(false)
    const [showmessage, setshowmessage] = useState("")

    useEffect(() => {
        if (isSuccess) {
            setShow(true)
            setshowmessage("success")
            // navigate('/listBrand', { state: { showToast: true }})
        }
        else if (isError) {
            setShow(true)
            setshowmessage("danger")
        }
    }, [data, error,isSuccess])



    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 5000)

    }, [show]);

    const maxLengthCheck = (object:any) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }


    
        // useEffect(() => {
        //     setCityOptions(cityData)
        // }, [cityData])
    
        useEffect(() => {
            let temp = cityData?.map((e: any) => {
                console.log(e)
                return (
                    {
                        value: e?.value,
                        label: `${e?.city?e?.city:e?.village}::${e?.district}`,
                        country:e?.country
    
                    }
                )
            })
            setCityOptions(temp)
        }, [cityData])
        const handleChangeCity = (e: any) => {
            setForCityValue(e)
            setValue("city_name_and_distict_name", e?.value)
            setForCountry(e?.country)

      
    
    
        }


        const [SubmittedCity, setSubmittedCity]: any = useState(false)
        useEffect(() => {
            if (SubmittedCity) {
    
                setForCityValue(cityOptions[0])
    
    
                setValue("city_name_and_distict_name", cityOptions[0]?.value)
    
            }
    
        }, [SubmittedCity, cityOptions])
    
    
    



        const [additionalFields, setAdditionalFields]:any = useState([]);
        const [fieldErrors, setFieldErrors]:any = useState(Array(additionalFields.length).fill({}));

        const handleAddProduct = () => {
            const newProduct = {
              id: nanoid(),
              product_type: null,
              product_description: '',
              product_qty: '',
              uom: null,
              remark:''
            };
        
            setAdditionalFields([newProduct]);
            // setAdditionalFields([...additionalFields, newProduct]);
      
          };
      
          
          const handleDeleteProduct = (id:any) => {
            const updatedFields = additionalFields.filter((field:any) => field.id !== id);
            setAdditionalFields(updatedFields);
            // setRerenderKey(prevKey => prevKey + 1);
          };


          useEffect(() => {
            if (additionalFields.length === 0) {
              setFieldErrors([]);
            }
          }, [additionalFields]);



          const handleAddItems = ()=>{

            const newFieldErrors = additionalFields?.map((field:any) => {
              const errors:any = {};
        
              if (!field.product_type) {
                errors.product_type = 'Product Type is required';
              }
      
              // if (!field.uom) {
              //   errors.uom = 'UOM is required';
              // }
        
            
              if (!field.product_qty) {
                errors.product_qty = 'Product Qty is required';
              }
      
              if (!field.product_description) {
                errors.product_description = 'Product Description is required';
              }
      
            //   if (!field.remark) {
            //     errors.remark = 'Product Discount is required';
            //   }
      
            
        
              return errors;
            });
        
            setFieldErrors(newFieldErrors);
        
            // Check if there are any errors
            const hasErrors = newFieldErrors.some((errors:any) => Object.keys(errors).length > 0);
        
            if (!hasErrors && additionalFields) {
              delete additionalFields[0]?.id
              addProduct(additionalFields[0])
                    setAdditionalFields([]);

                    setAdded(true)
      
            } else {
            }
          }

            const [newProductID,setNewProductID] = useState("")

    const handleChangeProductType = (selectedOption:any, index:any) => {
      const updatedFields = [...additionalFields];

     
      updatedFields[index].product_type = selectedOption?.value;
      setAdditionalFields(updatedFields);

      setNewProductID(selectedOption?.value)


      

    };


    
    const handleChangeUOM = (selectedOption:any, index:any) => {
        const updatedFields = [...additionalFields];
  
       
        updatedFields[index].uom = selectedOption?.value;
        setAdditionalFields(updatedFields);
  
  
      };

      const [newProductPricelistId,setNewProductPricelistId] = useState()
      
    const [salesID,setSalesID] = useState()
    const [leadID,setLeadID] = useState()

      const handleChangeProductQty = (e:any, index:any) => {
        const updatedFields = [...additionalFields];
        updatedFields[index].product_qty = e.target.value;
        updatedFields[index].pricelist = newProductPricelistId;
        updatedFields[index].sales_id = salesID
        updatedFields[index].lead_generate = leadID
        setAdditionalFields(updatedFields);
  
  
  
      };


      const handleChangeProductDiscription = (e:any, index:any) => {
        const updatedFields = [...additionalFields];

        e.target.value = e.target.value.toLowerCase()
        .split(' ')
        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

        updatedFields[index].product_description = e.target.value;
        setAdditionalFields(updatedFields);
       
  
      };

      const handleChangeProductRemark = (e:any, index:any) => {
        const updatedFields = [...additionalFields];

        e.target.value = e.target.value.toLowerCase()
        .split(' ')
        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

        updatedFields[index].remark = e.target.value;
        setAdditionalFields(updatedFields);
  
  
      };



      const [added, setAdded] = useState(false)
      const [displayData, setDisplayData] = useState([])
      const [productIds, setProductIds]: any = useState([])
      const [noProductError, setNoProductError] = useState(false)
    
      useEffect(() => {
        if (displayData?.length > 0) {
          setNoProductError(false)
        }
      }, [displayData])



      
    useEffect(() => {
        if (getProductData) {
          setDisplayData(getProductData?.data)
        }
      }, [getProductData])
    
      useEffect(() => {
        if (productIds) {
          getProduct({ ids: productIds })
        }
      }, [productIds])
    
    
    
      useEffect(() => {
        if (added && addProductIsSuccess) {
          if (addProductData) {
            setProductIds((oldIds: any) => [...oldIds, addProductData?.data?.id]);
    
    
    
            setAdded(false)
          }
    
    
        }
    
      }, [addProductData, addProductIsSuccess, added])
    
      console.log('productIds', productIds)
    
    
      console.log('displayData', displayData, 'addProductData', addProductData)
    
    
      const [renderKey, setRenderKey]: any = useState(false)



      const handleDeleteProductInTable = async (id: any) => {
        // const updatedFields = additionalFields.filter((field: any) => field.id !== id);
        // setAdditionalFields(updatedFields);
        await Axiosinstance.delete(`${process.env.REACT_APP_BASE_URL}${apinameAddProductInOnlineInquiry}/${id}/`)
    
        setProductIds((currentIds: any) => currentIds.filter((currentId: any) => currentId !== id));
    
        getProduct({ ids: productIds })
        setRenderKey((key: any) => key + 1)
    
    
      };

          console.log('product Ids',productIds)


          useEffect(()=>{
            if(isSuccess){
                setInquiryModal(true)
                setDisplayData([])
                setProductIds([])
            }
          },[isSuccess])


        const handleInquiryModal=()=>{

        }

        const handleValue = (e: any) => {
          e.target.value = e.target.value.toLowerCase()
            .split(' ')
            .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        }

    return (
        <>
            <div className='container-fluid scrollbar'>
            <div className='row justify-content-center '>
           
           <img src={logo} className='img-fluid' style={{width:"190px", height:"150px"  }}  />
           {/* <img src={logo} className='pb-5 m-2 h-auto' alt="" width={350} height={160} /> */}

            </div>
                <section id="contact">
                    <div className="contact-box">
                        <div className="contact-form-wrapper">
                          <h3 style={{display:'flex',justifyContent:"center",color:'rgb(0, 54, 101)'}}>Product Inquiry</h3>
                            <form ref={focus}
                             onKeyUp={event => onEnterKey(event)}>

                            {/* {show && showmessage === "success" ? <Alert color={showmessage} message={"Inquiry Sent Successfully, We will contact you shortly."}/>:""}  */}
                            {show && showmessage === "danger" ? <Alert color={showmessage} message={error?.response?.data?.message} /> : ""}
                                {/* <h5 className='text-center text-md text-success'>Inquiry Sent Successfully, We will contact you shortly.</h5> */}
                                <div className='row'>
                                    <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Company Name <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Compony Name" 
                                            {...register("company_name",{ onChange:handleValue, required: { value: true, message: "Field is required" }, maxLength: 30,})}
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                // style={{backgroundColor:"blue",color:"green"}} 
                                                /></span>
                                            </div>
                                           
                                        </div>
                                        <span className='text-red w-100'>{errors?.company_name?.message}</span>
                                    </div>
                                    <div className="col-sm-3 mt-2">
                                                    <label htmlFor="exampleInputEmail1">City Name & District Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="d-flex row" >
                                                        <Select
                                                            {...register("city_name_and_distict_name", { required: { value: true, message: "City Name & District Name is required" } })}
                                                            onChange={handleChangeCity}
                                                            options={cityOptions}
                                                            components={customIndicator}
                                                            value={forCityValue}
                                                            maxMenuHeight={150}
                                                            // isOptionDisabled={(options:any)=>
                                                            //     {
                                                            //         if(options.status === "inactive" || options.deleted == true){
                                                            //         return options
                                                            //         }
                                                            //        }}

                                                            // className="dropdown-select"
                                                        />
                                                        {/* <div className="input-group-append" style={{ width: 0, padding: 0 }}>
                                                            <Button className="input-group-text border-start-0 bg-primary" onClick={() => {
                                                                setOpenCity(true)
                                                            }}><AiOutlinePlus /></Button>
                                                        </div> */}
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.city_name_and_distict_name?.message}</span>
                                                </div>

                                    <div className='col-lg-3 mt-2' >
                                    <label htmlFor="exampleInputEmail1">Mobile Number <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Mobile Number" 
                                                        maxLength={10}
                                                        minLength={15}
                                                        onInput={maxLengthCheck}
                                                          {...register('mobile_number', {
                                                            required: 'Mobile Number is required',
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/g,
                                                                message: 'Please enter a valid number',
                                                            },
                                                        })}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.mobile_number?.message}</span>
                                    </div>

                                    {/* <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Product Description <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="exampleInputBrand" placeholder="Product Description" 
                                             {...register("product_description",{ required: { value: true, message: "Field is required" }, maxLength: 30,})}
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                /></span>
                                            </div>
                                         
                                        </div>
                                        <span className='text-red w-100'>{errors?.product_description?.message}</span>
                                    </div> */}


                                   
                                  
                                    <div className='col-lg-3 mt-2' >
                                    <label htmlFor="exampleInputEmail1">Email Address</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="exampleInputBrand" placeholder="Email Address" 
                                                         {...register('email_address', 
                                                        //  {
                                                        //     required: 'Email is required',
                                                        //     pattern: {
                                                        //         value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                                        //         message: 'Please enter a valid email',
                                                        //     },
                                                        // }
                                                        )}
                                                        />
                                                        <div className="input-group-append" >
                                                            <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                            // style={{backgroundColor:"blue",color:"green"}} 
                                                            /></span>

                                                        </div>
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.email_address?.message}</span>
                                    </div>
                                    <div className='col-lg-3 mt-2' >
                                    <label >Requirement Date</label>
                                                    <div className="input-group" >

                                                    <DatePicker
                                                            className="form-control"
                                                            format={dateFormatList}
                                                            value={requirementDate}
                                                            disabledDate={disabledDate}
                                                        
                                                            {...register("requirement_date", { required: { value: false, message: "Field is required" } })}

                                                            onChange={handleRequirementDate}
                                                        />

                                                        
                                                    </div>
                                                    <span className='text-red'>{errors?.requirement_date?.message}</span>
                                    </div>

                                    <div className='col-lg-3 mt-2' >
                                    <label >Country Name</label>
                                                    <div className="input-group">
                                                    <input disabled type="text" className="form-control" value={forCountry} placeholder="Country Name" 
                                                    // {...register("country", { required: { value: true, message: "Field is required" } })} 
                                                    />
                                                    
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.country?.message}</span>
                                    </div>


                                    {/* <div className='col-lg-3 mt-2' > */}
                                    <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" width={80} height={60}/>
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("visiting_card_image",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    {visitingCard&&<p style={{display:"inline-flex"}}>{visitingCard} 
                                                    {/* <button style={{border:"none"}} onClick={()=>setVisitingCard(null)}><RxCross2 color='red' size={20}/></button> */}
                                                    </p>}
                                                </div>
                                    {/* </div> */}

                                    {/* <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">Product Quantity <span style={{ color: 'red' }}>*</span></label>
                                        <div className="input-group">
                                            <input type="number" className="form-control" id="exampleInputBrand" placeholder="Product Quantity" 
                                             {...register("product_qty",{ required: { value: true, message: "Field is required" }, maxLength: 30,})}
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text border-start-0 bg-primary" ><RiPencilLine color='white'
                                                /></span>

                                            </div>
                                        </div>
                                        <span className='text-red w-100'>{errors?.product_qty?.message}</span>
                                    </div> */}
                                    {/* <div className='col-lg-3 mt-2' >
                                        <label htmlFor="exampleInputEmail1">U.O.M <span style={{ color: 'red' }}>*</span></label>
                                        <Select
                                            {...register("uom", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeUOM}
                                            options={optionsUOM}
                                            value={forUOM}
                                    
                                        />
                                        <span className='text-red w-100'>{errors?.uom?.message}</span>

                                    </div> */}

                                   
                                 
                                    {/* <div className='col-lg-3 mt-2' >
                                    <label htmlFor="exampleInputEmail1">Village Code & Name <span style={{ color: 'red' }}>*</span></label>
                                    <Select
                                            {...register("village_code_and_name", {
                                            required: {
                                                value: true,
                                                message: "Field is required",
                                            },
                                            })}
                                            onChange={handleChangeCity}
                                            options={cityOptions}
                                            value={forCityValue}
                                     
                                        />
                                        <span className='text-red w-100'>{errors?.village_code_and_name?.message}</span>
                                    </div> */}
                                    {/* <div className='col-lg-3 mt-2' >
                                    <label >State Name <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="input-group">
                                                    <input disabled type="text" className="form-control" value={forState}  placeholder="State Name" 
                                                    />
                                                    
                                                    </div>
                                                    <span className='text-red w-100'>{errors?.state?.message}</span>
                                    </div> */}
                                   

                                    {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-12 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" />
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" hidden className="image-input" data-traget-resolution="image_resolution" />
                                                    </label>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div> */}




                                    {/* <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                    <label htmlFor="exampleInputEmail1" className="col-12">Visiting Card Image</label>
                                                    <label style={{cursor:"pointer"}}>
                                                        <img src={cameraLogo} alt='' className="uploaded-image" width={80} height={60}/>
                                                        <input data-required="image" type="file"  id="file_upload" accept="image/*" className="image-input" data-traget-resolution="image_resolution"  {...register("visiting_card_image",{onChange:onLogoChange, required:false})} hidden />
                                                    </label>
                                                    {visitingCard&&<p style={{display:"inline-flex"}}>{visitingCard} 
                                                    </p>}
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="row d-flex">
                                        <div className="col-md-12 col-lg-12  col-sm-12 mt-2">
                                            <div className="row d-flex ">
                                                <div className="col-sm-3 mt-2 image-upload">
                                                <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 " 
                                                style={additionalFields.length > 0?{borderColor: "rgb(0, 54, 101)", background: "rgb(117 143 166)", color: "white" }:{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                disabled={additionalFields.length > 0}
                                                onClick={handleAddProduct}
                                                // onClick={handleSubmit(onSubmit)}
                                                >Add Product</button>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>



                                    {additionalFields.map((field:any, index:any) => (


<>

<div className="row" key={index}>
<div className="col-md-2  mt-4">
    <div className="mb-3">
      <label htmlFor="inputField" className="form-label">Product Type <span style={{ color: 'red' }}>*</span></label>
      <Select
             options={optionsProductType}
            //  value={field.product_type}
             onChange={(selectedOption) =>
               handleChangeProductType(selectedOption, index)
             }
             
           />
          {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].product_type}</div>
       )}
    </div>
  </div>

  <div className="col-md-2  mt-4">
    <div className="mb-3">
      <label htmlFor="inputField" className="form-label">Product Description <span style={{ color: 'red' }}>*</span></label>
      <input
           className="form-control"
             type="text"
             placeholder="Product Description"
            //  value={field.product_discription}
             onChange={(e) => handleChangeProductDiscription(e, index)}
           />
           {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].product_description}</div>
       )}
    </div>
  </div>

  <div className="col-md-2  mt-4">
    <div className="mb-3">
      <label htmlFor="inputField" className="form-label">Product Quantity <span style={{ color: 'red' }}>*</span></label>
      <input
           className="form-control"
             type="number"
             placeholder="Product Total Qty."
            //  value={field.product_qty}
             onChange={(e) => handleChangeProductQty(e, index)}
           />
           {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].product_qty}</div>
       )}
    </div>
  </div>

  <div className="col-md-2  mt-4">
    <div className="mb-3">
      <label htmlFor="inputField" className="form-label">Unit Of Measurment</label>
      <Select
             options={optionsUOM}
             onChange={(selectedOption) =>
               handleChangeUOM(selectedOption, index)
             }
             
           />
           {/* {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].uom}</div>
       )} */}
    </div>
  </div>


  <div className="col-md-2  mt-4">
    <div className="mb-3">
      <label htmlFor="inputField" className="form-label">Re-Mark</label>
      <input
           className="form-control"
             type="text"
             placeholder="Re-Mark"
            //  value={field.remark}
             onChange={(e) => handleChangeProductRemark(e, index)}
           />
           {fieldErrors[index] && (
         <div className="error-message text-red">{fieldErrors[index].remark}</div>
       )}
    </div>
  </div>

 

  



  <div className="col-md-2  mt-4" style={{display:"flex",justifyContent:"space-around",paddingTop:"33px"}}>
    <div className="mb-3">
    <button
             type="button"
             className="btn btn-danger"
             onClick={() => handleDeleteProduct(field.id)}
           >
             Cancel
           </button>

           
    </div>
    <div className="mb-3">
    <button
             type="button"
             className="btn btn-danger"
             onClick={handleAddItems}
           >
             Save
           </button>

           
    </div>
  </div>
</div>
       </>
     ))}





                                 





                                    <div className="table-responsive"  >

{noProductError ? <span style={{ color: 'red' }}>Add Atleast One Product</span> : null}


<table key={renderKey} className="table table-striped table-bordered"  style={{marginTop:"3px", width: "-webkit-fill-available" }}>
  <thead style={{textAlign:"center"}}>
    <tr>
      <th >Sr. <br/> No</th>
      <th>Product <br/>Type</th>
    

      <th>Product<br/> Description</th>
      <th>Product <br/>Qty</th>
      <th>UOM</th>

 
      <th>Re-Mark</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody style={{textAlign:'center'}}>
    {displayData?.map((field: any, index: any) => (
      <tr key={index} >
        <td>{index+1}</td>


        <td>
          {/* <input
            className="form-control"
            type="text"
            disabled
            value={field.product_type?.type}

          /> */}
          {field.product_type?.type}

        </td>

        

        <td>
          {/* <input
            className="form-control"
            type="text"
            disabled
            value={field.product_description}

          /> */}

{field.product_description}

        </td>

        <td>
          {/* <input
            className="form-control"
            type="text"
            disabled
            value={field.product_qty}

          /> */}

{field.product_qty}

        </td>

        <td>
          {/* <input
            className="form-control"
            type="text"
            disabled
            value={field.uom?.name?.name}

          /> */}

{field.uom?.name?.name?field.uom?.name?.name:'-'}

        </td>
        <td>
          {/* <input
            className="form-control"
            type="text"
            disabled
            value={field.remark ? field.remark : '-'}

          /> */}

{field.remark ? field.remark : '-'}

        </td>

        <td>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handleDeleteProductInTable(field.id)}
          >
            Delete
          </button>
        </td>
      
      </tr>
    ))}
  </tbody>
</table>


</div>






                                    
                                </div>
                                <div className="row  mt-3">
                                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <div className="row  ">
                                            <div className="col-lg-12 col-md-12 col-sm-12  text-md-center text-sm-center ">
                                            <button 
                                                type="button" className="border-1 rounded text-white px-4 py-1 mt-2 mx-3" 
                                                style={{ borderColor: "rgb(0, 54, 101)", background: "rgb(0, 54, 101)", color: "white" }}
                                                
                                                onClick={handleSubmit(onSubmit)}
                                                >Submit</button>
<button type="button" className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3" style={{ borderColor: "blue", background: "blue", color: "white" }} onClick={handleReset}>Reset</button>

<button type="button" className=" border-1 rounded  px-4 py-1 mx-3 mt-2" style={{ borderColor: "red", background: "red", color: "white" }} onClick={() => navigate("/login")}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div className="contact-links">
                            <h2 className='text-light' >CONTACT US</h2>
                            <div className='row mt-2'>
                                <div className='col-lg-12 mt-3'>
                                    <GrLocation className='float-left' style={{background:"#98C2EC",border:"0px solid black",borderRadius:"10px",padding:"8px"}} size={40}/>
                                

                                    <p className='text-light float-left ps-3'>
                                        <strong className='pe-1'>Address :</strong>
                                        302-Abhishilp Complex, <br />
                                        Near Keshav Baug Party Plot,<br />
                                        Opp. ITC Narmada Hotels,<br />
                                        Satellite, <br />
                                        Ahmedabad - 380015 Gujarat, <br />
                                        India</p>
                                </div>
                                <div className='col-lg-12'>
                                <BsTelephone className='float-left' style={{background:"#98C2EC",border:"0px solid black",borderRadius:"10px",padding:"8px"}} size={40}/>

                                    <p className='text-light float-left ps-3 pt-1'><strong className='pe-1'>Phone :</strong>+91 9099223452</p>
                                </div>
                                <div className='col-lg-12 mt-3'>
                                <AiOutlineMail className='float-left' style={{background:"#98C2EC",border:"0px solid black",borderRadius:"10px",padding:"8px"}} size={40}/>

                                    <p className='text-light float-left ps-3 pt-1'><strong className='pe-1'>Email :</strong>info@quantumbot.in</p>
                                </div>
                                <div className='col-lg-12 mt-3'>
                                <CgWebsite className='float-left' style={{background:"#98C2EC",border:"0px solid black",borderRadius:"10px",padding:"8px"}} size={40}/>

                                    <p className='text-light float-left ps-3 pt-1'><strong className='pe-1'>Website :</strong>quantumbot.in</p>
                                </div>
                            </div>





                        </div> */}

                    </div>
                </section>

                <City_Model refetchForm={cityRefetch} open={openCity} setOpen={setOpenCity} setSubmitted={setSubmittedCity} />




                <Modal
                show={inquiryModal}
                onHide={() => setInquiryModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Inquiry Sent Successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {" "}
                        <span className="text-center">Thank you! We've received your inquiry and will respond promptly.</span>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button
                        variant="secondary"
                        onClick={() => {
                            setInquiryModal(false);
                        }}
                    >
                        Cancel
                    </Button> */}
                    <Button
                        variant="info"
                        className="text-white"
                        onClick={()=>setInquiryModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
                
            </div>
        </>
    )
}

export default OnlineInquiry
