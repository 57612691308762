import React, { useEffect, useRef, useState } from "react";
import "../../../components/Header/header.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiPencilLine } from "react-icons/ri";
import { useCreate } from "../../../hooks/Api";
import { Alert } from "../../../components/Model/Model";
import useFocusOnEnter from "../../../components/focus";
import { useFetchDepartmentDropDown } from "../../../hooks/DropDown_Api";
import "./department.css";


const Add_HrDepartment = () => {
  const [valueDepartment, setValueDepartment] = React.useState<any | null>(
    null
  );

  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_HR_DEPARTMENT_API?.toString();

  const {
    mutate: gst,
    data,
    isError,
    isLoading,
    isSuccess,
    error,
  }: any = useCreate(apiname);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  }: any = useForm();

  // useEffect(() => {
  //     if (data) {

  //         reset(data)
  //     }
  // }, [data]);

  const handleReset = () => {
    reset();
    setInput('')
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };

  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listDepartment", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  

  const [optionsDepartment, setOptionsDepartment]: any = useState();

  const { data: departmentData, refetch: departmentRefetch } =
    useFetchDepartmentDropDown();

  useEffect(() => {
    setOptionsDepartment(departmentData);
  }, [departmentData]);


  //   const top100Films = [
  //     { label: "The Shawshank Redemption", year: 1994 },
  //     { label: "The Godfather", year: 1972 },
  //     { label: "The Godfather: Part II", year: 1974 },
  //     { label: "The Dark Knight", year: 2008 },
  //     { label: "12 Angry Men", year: 1957 },
  //     { label: "Schindler's List", year: 1993 },
  //     { label: "Pulp Fiction", year: 1994 },
  //   ];


  // useEffect(() => {
  //   updateSampleSection();
  // }, []);
  // define the array of string

  // const temp:string = 'sportsData'

  // const sportsData: string[] = optionsDepartment[temp]


  const [input, setInput] = useState("");
  const [suggestion, setSuggestions]: any = useState([]);

  const handleChange = (e: any) => {
    let value = e.target.value = e.target.value.toLowerCase()
    .split(' ')
    .map((s:any) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
    let matches = [];
    if (value.length >= 1) {
      const regex = new RegExp(`${value}`, "gi");
      matches = optionsDepartment?.filter((item: any) => regex.test(item.label));
    }
    setSuggestions(matches);
    setInput(value);
  };

  const [select, setSelect] = useState("");
  // to capture the value on click
  const selectValue = (item: any) => {
    setSelect(item);
    setSuggestions("");
    setInput("");
  };
  const clearSearch = () => {
    setInput("");
    setSuggestions("");
    setSelect("");
  };


 

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>All Department</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to="/listColor"
                      className="text-decoration-none text-black"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      HR
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/listDepartment"
                      className="text-decoration-none text-black"
                    >
                      Department
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Add Department</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
     

              <form
                ref={focus}
                onKeyUp={(event) => onEnterKey(event)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div
                  className="card-body"
                  //   style={{ maxHeight: "80vh", overflow: "auto" }}
                >
                  {show && showmessage === "danger" ? (
                    <Alert
                      color={showmessage}
                      message={error?.response?.data?.message}
                    />
                  ) : (
                    ""
                  )}

                  {/* {show && showmessage === "success" && <Alert color={showmessage} message="Brand successfully Updated" />} */}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-sm-3 mt-2">
                          <label>
                            Department Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="input-group">
                            <input
                            {...register("department", {onChange:handleChange, required: { value: true, message: "Department Name is required" } })}
                              className="form-control"
                              type="text"
                              placeholder="Department Name"
                              value={input}
                              
                            />
                            {suggestion?.length > 0 ? (
                              <div className="suggestion-wrapper">
                                {suggestion.map((item: any) => {
                                  return (
                                    <div
                                      className="suggestions"
                                      key={item.value}
                                    >
                                      {item.label}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : null}

                            <div className="input-group-append">
                              <span className="input-group-text border-start-0 bg-primary">
                                <RiPencilLine
                                  color="white"
                                  // style={{backgroundColor:"blue",color:"green"}}
                                />
                              </span>
                            </div>
                          </div>
                          <span className="text-red w-100">
                            {errors?.department?.message}
                          </span>
                        </div>

                        <div className="col-sm-3 mt-2">
                          <label htmlFor="exampleInputEmail1">
                            Status <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register("status", {
                              required: {
                                value: true,
                                message: "Status is required",
                              },
                            })}
                          >
                            <option value={""} selected>
                              Select Any
                            </option>
                            <option value={"active"}>Active</option>
                            <option value={"inactive"}>Inactive</option>
                          </select>
                          <span className="text-red w-100">
                            {errors?.status?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex  ">
                    <div className=" col-md-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                      <div className="row d-flex ">
                        <div className="col-lg-12 col-md-12 col-sm-12 text-lg-end text-md-center text-sm-center ">
                          <button
                            type="button"
                            className="border-1 rounded text-white px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "rgb(0, 54, 101)",
                              background: "rgb(0, 54, 101)",
                              color: "white",
                            }}
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className=" border-1 rounded  border-1 px-4 py-1 mt-2 mx-3"
                            style={{
                              borderColor: "blue",
                              background: "blue",
                              color: "white",
                            }}
                            onClick={handleReset}
                          >
                            Reset
                          </button>

                          <button
                            type="button"
                            className=" border-1 rounded  px-4 py-1 mx-3 mt-2"
                            style={{
                              borderColor: "red",
                              background: "red",
                              color: "white",
                            }}
                            onClick={() => navigate("/listDepartment")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add_HrDepartment;
