import { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputField from '../../../components/HRMS Components/Inpute/input';
import { useCreate } from '../../../hooks/Api';
import useFocusOnEnter from '../../../components/focus';
import SubmitField from '../../../components/HRMS Components/Button/Submit';
import { useFetchHrmsAccountDropdown } from '../../../hooks/DropDown_Api';
import ReactSelect from '../../../components/HRMS Components/Select/select';
import { Alert } from '../../../components/Model/Model';
// import Date from '../../../components/HRMS Components/Date and Time/Date';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import Date_Picker from '../../../components/HRMS Components/Date and Time/Date';

const Add_Cash_Transaction = () => {

  let pageName = "Cash Transaction"
  const focus: any = useRef();
  const { onEnterKey } = useFocusOnEnter(focus);
  const navigate = useNavigate();
  const apiname = process.env.REACT_APP_ACCOUNTING_CASH_TRANSACTION_ENTRY_API?.toString();

  const { mutate: gst, data, isError, isLoading, isSuccess, error }: any = useCreate(apiname);
  const { register, handleSubmit, setError, formState: { errors }, reset, setValue }: any = useForm();

  const [TransactionTypeValue, setTransactionTypeValue]: any = useState()

  const TransactionType = [ 
    { label:'Cash Withdraw' ,value:'Cash Withdraw' },
    { label:'Cash Deposit' ,value:'Cash Deposit' }
  ]

  const handleTransactionType = (e:any) =>{
    setTransactionTypeValue(e)
    setValue("type",e?.value)
  }

  const [forDate, setForDate]: any = useState('')
  const handleDate = (date: any,dateString:any) => {
    
    setForDate(date)
    setValue("date_time",  dateString)
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => { return current && current < dayjs().startOf('day'); };

  const handleReset = () => {
    reset();
    setTransactionTypeValue(null)
    setForDate(null)
  };

  const onSubmit = (branddetail: any) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(branddetail)) {
      const formvalue: any = value;
      if (typeof value !== "object") {
        formData.append(key, formvalue);
      } else {
        formData.append(key, formvalue[0]);
      }
      if (typeof value === "object" && value !== null) {
        formData.append(key, "");
      }
    }
    gst(formData);
  };


  const [show, setShow] = useState(false);
  const [showmessage, setshowmessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
      setshowmessage("success");
      navigate("/listCashTransaction", { state: { showToast: true } });
    } else if (isError) {
      setShow(true);
      setshowmessage("danger");
    }
  }, [data, error]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);
console.log(TransactionTypeValue == 'Cash Withdraw');

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "1345.31px" }}>
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{pageName}</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/listColor" className="text-decoration-none text-black">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black"> HRMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listBankTransaction" className="text-decoration-none text-black">{pageName}</Link>
                  </li>
                  <li className="breadcrumb-item">Add {pageName}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <form ref={focus} onKeyUp={(event) => onEnterKey(event)} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body" >
                  {show && showmessage === "danger" ? (<Alert color={showmessage} message={error?.response?.data?.message} />) : ("")}
                  <div className="row d-flex justify-content-md-center justify-content-lg-start">
                    <div className="col-md-12">
                      <div className="row">
                        <ReactSelect label='Transaction Type' name='type' message='Transaction Type is required' register={register} isRequired={true} isModal={false} isEdit={false} isMulti={false} errors={errors?.type?.message} options={TransactionType} Selvalue={TransactionTypeValue} onChange={(e)=>handleTransactionType(e)}/>
                        <Date_Picker name='date_time' label='Date Time' isPencil={true} message='Date is required' register={register} isRequired={true} hrmserrors={errors?.date_time?.message} isShowTime={true} value={forDate} isDisabled={disabledDate} onChange={(date, dateString) => handleDate(date, dateString)}/>
                        <InputField type='text' name='note' label='Transaction Note' isPencil={true} message='Transaction note is required' register={register} isRequired={false} hrmserrors={errors?.note?.message} />
                        <InputField type='number' name={TransactionTypeValue?.value === 'Cash Withdraw'?'out_amount':'in_amount'} label='Transaction Amount' isPencil={true} message='Transaction Amount is required' register={register} isRequired={true} hrmserrors={TransactionTypeValue==='Cash Withdraw'?errors?.out_amount?.message:errors?.in_amount?.message} />
                       
                      
                      </div>
                    </div>
                  </div>
                  <SubmitField Submit={handleSubmit(onSubmit)} Reset={handleReset} Cancel={() => navigate("/listCashTransaction")} />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Add_Cash_Transaction