import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Columns_DisposableProduct, titleofheaders_Disposable } from "./Columns_Disposable"
import Table from "../../components/Dynamic_Table/Table/Table";
import { useFetch } from "../../hooks/Api";
const List_Disposable_Disposable = (type:any) => {
  const Breadcrumb_Primary_Title = "Home";
  const Breadcrumb_Primary_Link = "Color";

  const Breadcrumb_Parent_Title = "Product Details";

  const Breadcrumb_Title_Name = "All Product Code";

  const apiname = process.env.REACT_APP_DISPOSABLE_PRODUCT_API?.toString();

  // const filter = {
  //   product_name_and_grade:"",
  //   product_color:"",
  //   panni_code_and_grade_name:"",
  //   panni_printing_color_name:"",
  //   panni_printing_total_color:"",
  //   product_brand:"",
  //   our_product_brand:"",
  //   product_ml_price_list:"",
  //   product_ml_actual:"",
  //   stick_packing_type:"",
  //   stick_noes:"",
  //   stick_qty:"",
  //   carton_total_nos:"",
  //   carton_code_and_grade_and_name:"",
  //   carton_in_stick_packing:"",
  //   single_piece_code:"",
  //   product_price_list_1_piece_weight:"",
  //   product_production_list_1_piece_weight:"",
  //   product_list_mould_diameter:"",

  // };







  // const[productType,setProuctType] = useState("Disposable")

  // let page = 1
  // let pagesize = 10

  // let search:any = ""

  // const handleSearch = (e: any) => {
  //   setSearch(e.target.value);
  // };


  // const {
  //   isLoading: fetchLoading,
  //   isError,
  //   data: fetchAllData,
  //   error,
  //   isFetching,
  //   refetch,
  //   isSuccess,
  //   isPreviousData,
  //   isRefetching,
  // } = useFetch(search,page,pagesize,apiname);

  // useEffect(()=>{

  //   if(apiname === "disposableProduct"){
  //     if(isSuccess){
  //       setProuctType(fetchAllData?.results[0]?.product_type?.type)
  //       // refetch()
  //     }
  //   }

   


  // },[apiname,isSuccess,fetchAllData,productType])


  // const [filter,setFilter] = useState({})

  const filter_Disposable = {
    // product_type: "Disposable",
       product_name_and_grade:"",
    product_color:"",
    panni_code_and_grade_name:"",
    panni_printing_color_name:"",
    panni_printing_total_color:"",
    product_brand:"",
    our_product_brand:"",
    product_ml_price_list:"",
    product_ml_actual:"",
    stick_packing_type:"",
    stick_noes:"",
    stick_qty:"",
    carton_total_nos:"",
    carton_code_and_grade_and_name:"",
    carton_in_stick_packing:"",
    single_piece_code:"",
    product_price_list_1_piece_weight:"",
    product_production_list_1_piece_weight:"",
    product_list_mould_diameter:"",
  };

  const filter_LLDP = {
    product_type: "LLDP",
    product_name_and_grade:"",
    product_color:"",
    panni_code_and_grade_name:"",
    panni_printing_color_name:"",
    panni_printing_total_color:"",
    product_brand:"",
    our_product_brand:"",
    product_ml_price_list:"",
    product_ml_actual:"",
    stick_packing_type:"",
    stick_noes:"",
    stick_qty:"",
    carton_total_nos:"",
    carton_code_and_grade_and_name:"",
    carton_in_stick_packing:"",
    product_single_piece_code:"",
    product_price_list_1_piece_weight:"",
    product_production_list_1_piece_weight:"",
    mouldshape:"",
    product_list_mould_diameter:"",
    };

  const filter_LD = {
    product_type: "LD",
       product_name_and_grade:"",
    product_color:"",
    panni_code_and_grade_name:"",
    panni_printing_color_name:"",
    panni_printing_total_color:"",
    product_brand:"",
    our_product_brand:"",
    product_ml_price_list:"",
    product_ml_actual:"",
    stick_packing_type:"",
    stick_noes:"",
    stick_qty:"",
    carton_total_nos:"",
    carton_code_and_grade_and_name:"",
    carton_in_stick_packing:"",
    single_piece_code:"",
    product_price_list_1_piece_weight:"",
    product_production_list_1_piece_weight:"",
    product_list_mould_diameter:"",
  };














  const addButtonName = "All Product";
  const addButtonPageName = "DisposableProduct"
  const permissionClass = {
    front: "disposable_product",
    back: "disposableproducts"
  }
  const stickyStart = 1;
  const stickyEnd = 3;


 
  // const [titleofheaders,setTitleOfHeaders]:any = useState([])
  // const [columnsof,setColumns]:any = useState(Columns_DisposableProduct)


  // useEffect(()=>{
    
  //   if(productType === "Disposable"){
  //     setTitleOfHeaders([...titleofheaders_Disposable])
  //     setColumns(Columns_DisposableProduct)
  //     setFilter(filter_Disposable)
  //   }
  //   if(productType === "LLDP"){
  //     setTitleOfHeaders([...titleofheaders_LLDP])
  //     setColumns(Columns_LLDP)
  //     setFilter(filter_LLDP)
  //   }
  //   if(productType === "LD"){
  //     setTitleOfHeaders([...titleofheaders_LLDP])
  //     setColumns(Columns_LLDP)
  //     setFilter(filter_LD)
  //   }

  // },[productType])

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header py-1 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h4>{Breadcrumb_Title_Name} List</h4>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link
                      to={`/list${Breadcrumb_Primary_Link}`}
                      className="text-decoration-none text-black"
                    >
                      {Breadcrumb_Primary_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="text-decoration-none text-black">
                      {Breadcrumb_Parent_Title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {Breadcrumb_Title_Name} List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-default">
              <div className="container p-0" style={{ maxWidth: "100%" }}>
                {/* <BasicTable/> */}
                <Table
                  apiname={apiname}
                  filterFields={filter_Disposable}
                  addButtonName={addButtonName}
                  stickyStart={stickyStart}
                  stickyEnd={stickyEnd}
                  TableColumns={Columns_DisposableProduct}
                  addButtonPageName={addButtonPageName}
                  titleofheaders={titleofheaders_Disposable}
                  // setProuctType={setProuctType}
                  // productType={productType}
                  permissionClass={permissionClass}
                />
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default List_Disposable_Disposable;
